import axiosInstance from '../../interceptor/axiosInstance';
import {
  BULK_CONTENT_QUESTIONS,
  CONTENT_LIBRARY,
  CONTENT_LIBRARY_QUESTIONS,
  CONTENT_LIBRARY_QUESTION_ID,
} from '../../routes/routeConstants/apiRoutes';
import { deserialize } from 'serializr';
import Notification from '../../shared/components/Notification';
import { NotificationTypes } from '../../enums/notificationTypes';
import { PaginationModel } from '../../models/pagination.model';
import {
  ContentLibraryModel,
  ContentLibraryQuestionModel,
} from '../../models/Learn/ContentLibraryModel/contentLibrary.model';
import { generatePath } from 'react-router';

export default class ContentLibraryService {
  static getContentLibraryList(
    queryParams: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(CONTENT_LIBRARY + queryParams)
      .then((response) => {
        const contentLibrary = deserialize(
          ContentLibraryModel,
          response.data['content_libraries']
        );
        const pageMeta = deserialize(PaginationModel, response.data['meta']);
        onSuccess(contentLibrary, pageMeta);
      })
      .catch((error) => {
        Notification({
          message: 'Failed',
          description: 'Unable to fetch the content libraries list',
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static getContentLibraryDetails(
    id: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(CONTENT_LIBRARY + `/${id}`)
      .then((response) => {
        const content = deserialize(
          ContentLibraryModel,
          response.data['content_library']
        );
        onSuccess(content);
      })
      .catch((error) => {
        Notification({
          message: 'Failed',
          description: 'Unable to fetch the content library',
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static createContent(
    data: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .post(CONTENT_LIBRARY, data)
      .then((response) => {
        const content = deserialize(
          ContentLibraryModel,
          response.data['content_library']
        );
        onSuccess(content);
      })
      .catch((error) => {
        Notification({
          message: 'Failed',
          description: 'Unable to create the content',
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static updateContent(
    id: number,
    data: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .put(CONTENT_LIBRARY + `/${id}`, data)
      .then((response) => {
        const content = deserialize(
          ContentLibraryModel,
          response.data['content_library']
        );
        onSuccess(content);
      })
      .catch((error) => {
        Notification({
          message: 'Failed',
          description: 'Unable to update the content',
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static deleteContent(
    id: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .delete(CONTENT_LIBRARY + `/${id}`)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: 'Failed',
          description: 'Unable to delete the content',
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static createContentQuestion(
    payload: any,
    onSuccess: (question: ContentLibraryQuestionModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .post(CONTENT_LIBRARY_QUESTIONS, payload)
      .then((question) => {
        const data = deserialize(
          ContentLibraryQuestionModel,
          question['data']['content_question']
        );
        onSuccess(data);
      })
      .catch((error) => {
        Notification({
          message: 'Failed',
          description: 'Unable to create question',
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }
  static getContentQuestions(
    contentLibraryId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(CONTENT_LIBRARY_QUESTIONS, {
        params: { content_library_id: contentLibraryId },
      })
      .then((response) => {
        const questions = deserialize(
          ContentLibraryQuestionModel,
          response['data']['content_questions']
        );
        onSuccess(questions);
      })
      .catch((error) => {
        Notification({
          message: 'Failed',
          description: 'Unable to get questions',
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }
  static updateContentQuestion(
    questionId: any,
    payload: any,
    onSuccess: (question: ContentLibraryQuestionModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .put(generatePath(CONTENT_LIBRARY_QUESTION_ID, { questionId }), payload)
      .then((question) => {
        const data = deserialize(
          ContentLibraryQuestionModel,
          question['data']['content_question']
        );
        onSuccess(data);
      })
      .catch((error) => {
        Notification({
          message: 'Failed',
          description: 'Unable to update question',
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }
  static deleteContentQuestion(
    questionId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .delete(generatePath(CONTENT_LIBRARY_QUESTION_ID, { questionId }))
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: 'Failed',
          description: 'Unable to delete question',
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }
  static uploadBulkContentQuestions(
    payload: any,
    onSuccess: () => void,
    onError: (errors: any, row: any) => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .post(BULK_CONTENT_QUESTIONS, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        const { response } = error;
        onError(response?.data[0]?.error, response?.data[0]?.row);
        Notification({
          message: 'Uploading failed',
          description: 'Something went wrong',
          type: NotificationTypes.ERROR,
        });
      })
      .finally(onFinal);
  }
}
