import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useLocation, withRouter } from "react-router-dom";

import { Skeleton, Spin } from "antd";
import { Col, Row, Button, Space } from "antd";

import { CloudDownloadOutlined } from '@ant-design/icons';
import { ClockCircleOutlined } from "@ant-design/icons";
import SubjectIcon from "../../../assets/icons/subject.svg";
import pdfIcon from "../../../assets/icons/pdf-chapter-icon.svg";

import EmptyState from "../../../shared/components/EmptyState";
import PageView from "../../../shared/components/PageView";
import ActivityPerformance from "./ActivityPerformance";

import { FileMetaModel, WorksheetModel } from "../../../models/worksheet.model";
import WorksheetService from "../../../services/WorksheetService/Worksheet.service";
// style
import "./activityDetails.scss";
import moment from "moment";
import FileDownloadService from "../../../services/FileDownloadService/FileDownloadService";



export const SkeletonLoader = (): any => {
  return Array(2)
    .fill(" ")
    .map((value) => {
      return (
        <div className="skeleton-loader">
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        </div>
      );
    });
};



const Activity = (props) => {
  const history = useHistory();
  const { state }: { state: WorksheetModel } = useLocation();
  //console.log(state);


  const [worksheetInfo, setWorksheetInfo] = useState<WorksheetModel>({});
  const [loading, setLoading] = useState(true);
  const [loadingFile, setFileLoading] = useState({ id: 0, status: false });

  const fetchWorksheetInfo = () => {
    setLoading(true);
    WorksheetService.fetchWorksheetInfo(
      state.worksheetId || 0,
      (activities) => {
        setWorksheetInfo(activities);
      },
      (error) => { console.log(error) },
      () => { setLoading(false); }
    );
  };

  useEffect(() => {
    fetchWorksheetInfo();
  }, []);

  const downloadFile = (file: FileMetaModel) => {
    setFileLoading({ id: file?.attachmentId ?? 0, status: true })
    FileDownloadService.fileDownload(
      file,
      () => {
        setWorksheetInfo((prevState) => {
          return {
            ...prevState,
            attachmentList: prevState?.attachmentList?.map(data => {
              if (data.attachmentId === file?.attachmentId)
                data.isDownload = true
              return data
            })
          }
        })
      },
      () => { },
      () => { setFileLoading({ id: 0, status: true }) }
    );
  }


  return (
    <PageView
      title="Actvity"
      className="activity-details"
      showBack
    >


      <div className="activity-details-info">
        {loading ? (
          <SkeletonLoader />
        ) : (
          <>

            <Row className={`details-card`}>
              <Col span={12} className="">
                <div className="card-title__container">
                  <p className="activity-card__title">
                    <span>
                      <img src={SubjectIcon} />
                    </span>
                    <span className="__text">
                      <span className="__sub_title">{worksheetInfo?.className}{" : "}{worksheetInfo?.sectionName}{" : "}{worksheetInfo.subjectName}</span>
                      {worksheetInfo.name}
                    </span>
                  </p>
                  <div className="activity-card-time__container">

                    <p className="activity-card__time">
                      <ClockCircleOutlined />
                      {"Posted on"}:{" "}
                      {moment(worksheetInfo?.createdOn).format('MMMM Do YYYY  h:mm a')}
                    </p>

                    <p className="activity-card__time">
                      <ClockCircleOutlined />
                      {"Due on"}:{" "}
                      {moment(worksheetInfo?.dueDate).format('MMMM Do YYYY')}
                    </p>

                  </div>
                </div>

                <div className="card-file__container">
                  <p className="assignment-card__instruction">
                    {worksheetInfo?.instructions}
                  </p>

                  {worksheetInfo?.attachmentList?.length ?
                    <>
                      <br></br>
                      <p className="assignment-card__instruction ">
                        <span className="__sub_title">{"Worksheet "}</span>
                      </p>
                      <Row  >
                        {worksheetInfo.attachmentList?.map(attachment => (
                          <Col span={10} offset={1} className="file__container "  >
                            <div className={`activity_file ${attachment.isDownload && "download"} `} key={attachment.attachmentId}>
                              {(loadingFile.id === attachment.attachmentId && loadingFile.status === true) && <Spin size="small" />}
                              <img src={pdfIcon} alt="" className="pdf_icon" />
                              <div onClick={() => downloadFile(attachment)} className="file_title">{attachment.name}</div>
                            </div>
                            {/* <div>
                              <div className="content-list__thumbnail">
                                <img src={pdfIcon} alt="" className="content-list__thumbnail__image" />
                              </div>
                              <div className="info_text">
                                {attachment.name}
                                <Button
                                  shape="circle"
                                  size="middle"
                                  icon={<CloudDownloadOutlined style={{ fontSize: '25px' }} />} />
                              </div>
                            </div> */}
                          </Col>
                        ))}
                      </Row>
                    </>
                    : null}
                </div>
              </Col>

              <Col span={12}>
                <ActivityPerformance
                  data={worksheetInfo}
                />
              </Col>
            </Row>

          </>
        )}
      </div>

    </PageView>
  );
};

export default withRouter(Activity);
