export enum ExerciseXslxHeaders {
  EXERCISE_ID = "exercise_id",
  QUESTION_CONTENT = "question_content",
  QUESTION_IMAGE = "question_image",
  ONLY_FREE_TEXT = "only_free_text",
  OPTION1 = "option1",
  OPTION2 = "option2",
  OPTION3 = "option3",
  OPTION4 = "option4",
  IMAGE_OPTION1 = "image_option1",
  IMAGE_OPTION2 = "image_option2",
  IMAGE_OPTION3 = "image_option3",
  IMAGE_OPTION4 = "image_option4",
  CORRECT_ANSWER = "correct_answer",
  SOLUTION = "solution",
  SOLUTION_IMAGE = "solution_image",
}
