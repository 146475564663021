import { serializable, alias, object, list, primitive } from 'serializr';


export class FilterChapterwise {
    @serializable(alias('InstId', primitive()))
    instituteId?: number;

    @serializable(alias('BranchId', primitive()))
    branchId?: number;

    @serializable(alias('BoardId', primitive()))
    boardId?: number;

    @serializable(alias('SectionId', primitive()))
    sectionId?: number;

    @serializable(alias('SubjectId', primitive()))
    sectionSubjectId?: number;

    @serializable(alias('GradeId', primitive()))
    branchClassRoomId?: number;

    @serializable(alias('ChapterId', primitive()))
    chapterId?: string;
}

export class ContentModel {
    @serializable(alias("name", primitive()))
    name?: string;
    @serializable(alias("status", primitive()))
    status?: string;
    @serializable(alias("points", primitive()))
    points?: string;
    @serializable(alias("quickquiz", primitive()))
    quickquiz?: string;
    @serializable(alias("quickquizpoint", primitive()))
    quickquizpoint?: string;
}



export class ChapterwiseSummaryModel {
    @serializable(alias("studentid", primitive()))
    studentid?: number;
    @serializable(alias("studentname", primitive()))
    studentname?: string;
    @serializable(alias("grade", primitive()))
    grade?: string;
    @serializable(alias("section", primitive()))
    section?: string;
    @serializable(alias("chapername", primitive()))
    chapername?: string;
    @serializable(alias("contentviwed", list(object(ContentModel))))
    contentviwed?: ContentModel[];
    @serializable(alias("pdfviwed", list(object(ContentModel))))
    pdfviwed?: ContentModel[];
    @serializable(alias("excercise", list(object(ContentModel))))
    excercise?: ContentModel[];
}