import { alias, object, primitive, serializable } from 'serializr';
export class PaperAttemptModel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('title', primitive()))
  title?: string;

  @serializable(alias('description', primitive()))
  description?: string;

  @serializable(alias('time', primitive()))
  time?: string;

  @serializable(alias('started_at', primitive()))
  startedAt?: string;

  @serializable(alias('completed_at', primitive()))
  completedAt?: string;

  @serializable(alias('timer_options', primitive()))
  timerOptions?: string;
}

export class PreviousPaperModel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('title', primitive()))
  title?: string;

  @serializable(alias('timer_options', primitive()))
  timerOptions?: string;

  @serializable(alias('time', primitive()))
  time?: Float32Array;

  @serializable(alias('is_active', primitive()))
  isActive?: boolean;

  @serializable(alias('paper_attempt', object(PaperAttemptModel)))
  paperAttempt?: PaperAttemptModel;

  @serializable(alias('paper_questions_count', primitive()))
  paperQuestionsCount?: number;
}
export class CreatePaperAttemptModel {
  @serializable(alias('paper_id', primitive()))
  paperId?: number;
}
