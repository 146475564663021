import React, { FC, useCallback, useEffect, useState } from 'react';
import VideoCard from '../../../shared/components/VideoCard';
import TitleContainer from '../../../store/container/TitleContainer';
import { ITitleState } from '../../../store/reducers/titleReducer';
import { LearnService } from '../../../services/LearnService/learn.service';
import { useLocation, useParams } from 'react-router-dom';
import { Chapter as ChapterModel } from '../../../models/Learn/Chapters/chapter.model';

import Chapter from './Chapter';
import './listChapters.scss';
import AuthContainer from '../../../store/container/AuthContainer';
import PageView from '../../../shared/components/PageView';

interface ListChaptersProps extends ITitleState {
  setTitle: Function;
  type: any;
  currentCourse: any;
}

const ListChapters: FC<ListChaptersProps> = ({
  setTitle,
  type,
  currentCourse,
  onSearch,
}) => {
  const params: any = useParams();
  const { state }: { state: any } = useLocation();
  const [chapters, setChapters] = useState<ChapterModel[]>();

  const fetchChapters = useCallback((queryParams: any) => {
    LearnService.getChapters(
      queryParams,
      {
        subjectId: params?.subjectId,
        courseId: currentCourse?.id
      },
      (chapters: ChapterModel[]) => {
        setChapters(chapters.filter((data) => data.chapterContents?.length));
      }
    );
  }, [currentCourse, params]);

  useEffect(() => {
    setTitle({
      title: state?.subjectName,
      showBack: true,
      subText: '',
      icon: null,
      searchText: 'Search chapters',
      showSearch: {placeholder: 'Search chapters...'}
    });
    if (currentCourse?.id) {
      fetchChapters({});
    }
  }, [currentCourse, fetchChapters, setTitle, state]);

  useEffect(() => {
    fetchChapters({ search: onSearch });
  }, [fetchChapters, onSearch]);

  return (
    <PageView className={'no-bg-image'} >
      <div className='list-chapter__container'>
        {chapters?.map(({ name, chapterContents, id }, index) => (
          <Chapter
            id={id}
            chapterNo={index + 1}
            title={name || ''}
            subjectName={state?.subjectName}
            videos={chapterContents?.map((content, index) => {
              return (
                <VideoCard
                  chapterName={name}
                  key={index}
                  id={content?.id}
                  color='#FD6C00'
                  subject={state?.subjectName}
                  thumbnail={content?.contentLibrary?.thumbnailUrl}
                  content={content.title}
                  description={content.description}
                  contentLibrary={content.contentLibrary}
                  url={content?.contentLibrary?.attachmentUrl}
                  contentType={content?.contentLibrary?.contentType}
                  chapterId={id}
                  subjectId={params?.subjectId}
                />
              );
            })}
          />
        ))}
      </div>
    </PageView>
  );
};

export default AuthContainer(TitleContainer(ListChapters));
