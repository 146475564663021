import moment from "moment";

export const buildFormData = (formData: any, data: any, parentKey?: any) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
};

export function convertJSONToFormData(data: any) {
    console.log(data)
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}

export const dateFilterFunc = (classified?: string, currentMonth?: any) => {
    switch (classified) {
      case 'today': {
        return ({ startingDate: moment().format("YYYY-MM-DD"), endingDate: moment().add(1, 'days').format("YYYY-MM-DD") })
      }
      case 'yesterday': {
        return ({ startingDate: moment().subtract(1, 'days').format("YYYY-MM-DD"), endingDate: moment().format("YYYY-MM-DD") })
      }
      case 'week': {
        return ({ startingDate: moment().subtract(7, 'days').format("YYYY-MM-DD"), endingDate: moment().add(1, 'days').format("YYYY-MM-DD") })
      }
      case 'month': {
        return ({ startingDate: currentMonth?.startOf('month').format('YYYY-MM-DD'), endingDate: currentMonth?.add(1, 'months').startOf('month').format('YYYY-MM-DD') })
      }
      default: {
        return ({ startingDate: 0, endingDate: 0 })
      }
    }
  }
