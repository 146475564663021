// Base URL
export const BASE_URL = process.env.REACT_APP_BASE_URL+"/api/v1";
export const NET_BASE_URL = process.env.REACT_APP_NET_BASE_URL;
// Cable URL
export const CABLE_URL = process.env.REACT_APP_CABLE_URL + "/cable"
const STUB_URL = "https://private-anon-18c4a63342-panditji.apiary-mock.com/api/v1"
export const TUTOR = "/course/tutor"

// Login URL
const USER_MANAGEMENT = "/user_management/tutor"
export const AUTH = USER_MANAGEMENT + "/auth";
export const USER_LOGIN = AUTH+"/login";
export const USER_LOGOUT = AUTH+"/logout";
export const FORGOT_PASSWORD = AUTH+"/forgot_password";
export const RESET_PASSWORD = AUTH+"/password";
export const RESET_PASSWORD_EMAIL = AUTH + "/reset_password"

// Queries URL
export const LIST_QUERIES = "/ask/tutor/queries";
export const LIST_ANSWERS = LIST_QUERIES + '/answered';
export const CREATE_COMMENT = LIST_QUERIES + "/:queryId/comments"
export const UPDATE_QUERY = LIST_QUERIES + "/:queryId"

// Assessments URL
export const LIST_ASSESSMENTS = TUTOR + "/assessments";
export const EXERCISE_ATTEMPT = TUTOR +  '/excercise_attempts/:exerciseAttemptId';
export const EXERCISE_ATTEMPT_QUESTIONS = EXERCISE_ATTEMPT + '/excercise_attempt_questions';
export const EXERCISE_ATTEMPT_QUESTION_ID = EXERCISE_ATTEMPT_QUESTIONS + '/:exerciseAttemptQuestionId';
export const EXAM_EXERCISE_ATTEMPT = TUTOR +  '/exam_exercise_attempts/:examExerciseAttemptId'
export const EXAM_EXERCISE_ATTEMPT_QUESTIONS = EXAM_EXERCISE_ATTEMPT + '/exam_exercise_attempt_questions';
export const EXAM_EXERCISE_ATTEMPT_QUESTION_ID = EXAM_EXERCISE_ATTEMPT_QUESTIONS + "/:examExerciseAttemptQuestionId"

//Dashboard
export const _DASHBOARD = "/dashboard"
export const DASHBOARD_MANAGEMENT = "/dashboard/tutor"
export const DASHBOARD = DASHBOARD_MANAGEMENT + "/tutor_activities"
export const DASHBOARD_STUDENT_ACTIVITIES = _DASHBOARD + "/GetStudentActivitiesTutor"
export const DASHBOARD_CLASS_PROGRESS = _DASHBOARD + "/GetTutorClassProgress"
export const DOWNLOAD_CLASS_PROGRESS = _DASHBOARD + "/DownloadTutorClassProgress"
export const DASHBOARD_TUTOR_LOGIN_REPORT = _DASHBOARD + "/GetTutorLoginReport"

//Learn
export const LEARN = "/learn"

//Live Class
const LIVE_CLASS_MANAGEMENT = "/live_class_management/tutor"
export const UPCOMING_LIVE_CLASSES = LIVE_CLASS_MANAGEMENT + "/upcoming_live_classes"
export const LIVE_CLASS_CREATE = LIVE_CLASS_MANAGEMENT + "/live_class"
export const LIVE_CLASS = LIVE_CLASS_CREATE + "/:liveClassId"
export const LIVE_CLASSES_NOW = LIVE_CLASS_MANAGEMENT + "/live_now"
export const LIVE_CLASS_DETAILS = LIVE_CLASS_MANAGEMENT + "/live_classes/:liveClassId"
export const LIVE_CLASS_JOIN = LIVE_CLASS_DETAILS + "/join_live"
export const LIVE_CLASS_END = LIVE_CLASS_DETAILS + "/end_live"
export const LIVE_CLASS_CREATE_UPDATE = '/Tutor/InsertUpdateLiveClass'
export const LIVE_CLASS_JOIN_NEW = '/Tutor/JoinLiveClass'

//Profile
export const PROFILE_DETAILS = USER_MANAGEMENT + "/me"

//Notifications
export const NOTIFICATIONS = "/notice/tutor/notifications"
export const NOTIFICATIONS_CLEAR = "/notice/tutor/clear_all_notifications"
export const LIST_NOTIFICATIONS = "/notice/tutor/notifications"
export const SINGLE_NOTIFICATION = LIST_NOTIFICATIONS + "/:notificationId"
export const CLEAR_NOTIFICATIONS = LIST_NOTIFICATIONS + "/destroy_all"
export const VIEW_NOTIFICATION = SINGLE_NOTIFICATION + "/viewed"

//Meta
export const CLASS_ROOMS = "/meta/tutor/branch_class_rooms"
export const SECTIONS = "/meta/tutor/sections"
export const TUTORS = "/meta/tutor/list"
export const SUBJECTS = "/meta/tutor/subjects"
export const CLASS_ROOM_SUBJECTS = "/meta/tutor/class_room_subjects"
export const CHAPTERS_MASTER = "/Master/GetClassSubjectsChapter"

// Learn URL
export const CHAPTERS = CLASS_ROOM_SUBJECTS + "/:subjectId/chaptors";
export const CHAPTER_CONTENTS = CHAPTERS + "/:chapterId/chaptor_contents";

// Worksheet
export const WORKSHEET = "/worksheet"
export const WORKSHEET_LIST = WORKSHEET+"/GetWorkSheetList"
export const WORKSHEET_INFO = WORKSHEET+"/GetWorkSheetInfo"
export const STUDENT_WORK_TURNINLIST=WORKSHEET+"/GetStudentWorkTurnInList"
export const STUDENT_WORK_ASSIGNLIST=WORKSHEET+"/GetStudentWorksheetAssignList"
export const STUDENT_WORKSHEET_INFO=WORKSHEET+"/GetStudentWorksheetInfo"
export const UPDATE_STUDENT_SUBMISSION=WORKSHEET+"/UpdateStudentSubmission"
export const CREATE_WORKSHEET=WORKSHEET+"/CreateWorksheet"
export const DELETE_WORKSHEET=WORKSHEET+"/DeleteWorksheet"

// Report
const REPORT='/Report'
export const ACTIVITY_SUMMARY = REPORT + "/GetActivitySummary"
export const DOWNLOAD_ACTIVITY_SUMMARY = REPORT + "/DownloadActivityDetails"
export const ACTIVITY_SUBMISSION_DETAILS = REPORT + "/GetActivitySubmissionDetails"

export const LOGIN_ACTIVITY_SUMMARY = REPORT + "/GetLoginActivityReport"
export const DOWNLOAD_LOGIN_ACTIVITY_SUMMARY = REPORT + "/GetLoginActivityReportDownload"

export const CHAPTERWISE_SUMMARY = REPORT + "/GetChapterwiseReport"
export const DOWNLOAD_CHAPTERWISE_SUMMARY = REPORT + "/GetChapterwiseReportDownload"

//Announcement
export const ANNOUNCEMENT="/Announcement"
export const CREATE_ANNOUNCEMENT=ANNOUNCEMENT+"/CreateAnnouncement"
export const ANNOUNCEMENT_LIST = ANNOUNCEMENT+"/GetAnnouncementList"
export const ANNOUNCEMENT_INFO = ANNOUNCEMENT+"/GetAnnouncement"
export const ADD_COMMENT = ANNOUNCEMENT+"/AddComment"


export const STUDENT_COURSES = "/course/tutor/tutor_courses";
export const CREATE_CONTENT_ATTEMPT = STUDENT_COURSES + "/:studentCourseId/chaptors/:chapterId/content_libraries/:contentLibraryId/content_exercise_attempts"
export const VIEW_CONTENT_ATTEMPT = CREATE_CONTENT_ATTEMPT + "/:contentAttemptId"
export const COMPLETE_CONTENT_ATTEMPT = VIEW_CONTENT_ATTEMPT + "/complete"
export const CONTENT_ATTEMPT_QUESTIONS = STUDENT_COURSES + "/:studentCourseId/content_exercise_attempts/:contentAttemptId/content_exercise_attempt_questions"
export const CONTENT_ATTEMPT_QUESTIONS_ANSWERS = CONTENT_ATTEMPT_QUESTIONS + "/answers"
export const EXAM_CHAPTER_CONTENTS = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_chapter_contents"
export const PENDING_CHAPTER_CONTENTS = STUDENT_COURSES + "/:courseId/pending_chapter_contents"
export const PENDING_EXAM_CHAPTER_CONTENTS = STUDENT_COURSES + "/:courseId/pending_exam_chapter_contents"
export const RESET_CONTENT_ATTEMPT = VIEW_CONTENT_ATTEMPT + "/reset"
export const VIEW_CHAPTER_CONTENT = STUDENT_COURSES + "/:courseId/chaptors/:chapterId/chaptor_contents/:contentId";
export const VIEW_EXAM_CHAPTER_CONTENT = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_chapter_contents/:contentId";
export const VIEW_UPDATE_CONTENT_ATTEMPT_QUESTION = CONTENT_ATTEMPT_QUESTIONS + "/:contentAttemptQuestionId"


export const COMPLETE_EXERCISE_QUIZ = STUDENT_COURSES + "/:courseId/chaptors/:chaptorId/excercise_attempts/:attemptId/complete";
export const EXAM_ATTEMPT = STUDENT_COURSES + "/:courseId/chaptors/:chaptorId/excercise_attempts"
export const EXAM_SUBJECTS = STUDENT_COURSES + "/:courseId/exam_subjects"
export const EXAM_CHAPTERS = EXAM_SUBJECTS + "/:subjectId/exam_chapters"
export const EXAM_CHAPTER_EXERCISES = EXAM_CHAPTERS + "/:chapterId/exam_exercises"
export const GET_ALL_QUESTIONS_WITH_ANSWERS = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/excercise_attempt_questions/answers";
export const GET_QUESTIONS = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/excercise_attempt_questions";
export const GET_SINGLE_QUESTION = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/excercise_attempt_questions/:id";
export const LIST_EXAM_EXERCISES = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercises"
export const RESET_EXERCISE_EXAM = STUDENT_COURSES + "/:courseId/chaptors/:chapterId/excercise_attempts/:attemptId/reset";
export const UPDATE_ANSWER = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/excercise_attempt_questions/:id"
export const VIEW_EXERCISE_ATTEMPT = STUDENT_COURSES + "/:courseId/chaptors/:chaptorId/excercise_attempts/:attemptId"


export const CREATE_EXAM_EXERCISE_ATTEMPT = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercise_attempts"
export const EXERCISES = STUDENT_COURSES + "/:courseId/chaptors/:chaptorId/excercises";
export const GET_EXAM_EXERCISE_QUESTIONS = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_questions"
export const GET_EXAM_EXERCISE_QUESTIONS_WITH_ANSWERS = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_questions/answers"
export const VIEW_EXAM_EXERCISE_QUESTION = GET_EXAM_EXERCISE_QUESTIONS + "/:id"
export const UPDATE_EXAM_EXERCISE_QUESTION = VIEW_EXAM_EXERCISE_QUESTION
export const COMPLETE_EXAM_EXERCISE_QUESTION = VIEW_EXAM_EXERCISE_QUESTION + "/complete"
export const RESET_EXAM_EXERCISE_EXAM = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercise_attempts/:attemptId/reset";
export const COMPLETE_EXAM_EXERCISE = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercise_attempts/:attemptId/complete"
export const EXERCISE_QUESTION_ATTACHMENT = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/exc_atmpt_quest_attchmts"
export const EXAM_EXERCISE_QUESTION_ATTACHMENT = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_question_attachments"
export const PAPER_EXERCISE_QUESTION_ATTACHMENT = STUDENT_COURSES + "/:courseId/paper_attempts/:attemptId/paper_attempt_question_attachments"
export const VIEW_EXAM_EXERCISE_ATTEMPT = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercise_attempts/:attemptId"


//Analytics URL
export const STUDENT_REPORT = "/StudentReport";
export const ANALYTICS_CLASS = STUDENT_COURSES + "/:courseId/student_class_results/view"
export const ANALYTICS_CLASS_PROGRESS = STUDENT_REPORT + "/GetProgressByCourse"
export const ANALYTICS_SUBJECTS = STUDENT_COURSES + "/:courseId/class_room_subjects/:subjectId/student_subject_results/view"
export const ANALYTICS_CHAPTERS = STUDENT_COURSES + "/:courseId/chaptors/:chapterId/student_chapter_results/view"
export const ANALYTICS_EXERCISES = ANALYTICS_CHAPTERS + "/:courseId/excercise_attempts/:attemptId/exercise_attempt_results/:attemptResultId"
export const ANALYTICS_EXERCISES_ATTEMPTS = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/exercise_attempt_results"
export const ANALYTICS_QUICK_QUIZ = STUDENT_COURSES + "/:courseId/content_exercise_attempts/:attemptId/content_exercise_attempt_results"
export const ANALYTICS_EXAM = STUDENT_COURSES + "/:courseId/exam_results/view"
export const ANALYTICS_SUBJECTS_EXAM = STUDENT_COURSES + "/:courseId/exam_subjects/:subjectId/exam_subject_results/view"
export const ANALYTICS_CHAPTERS_EXAM = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_chapter_results/view"
export const ANALYTICS_EXERCISES_ATTEMPTS_EXAM = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_results"
export const ANALYTICS_QUICK_QUIZ_EXAM = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_results"
export const ANALYTICS_PAPER_ATTEMPTS = STUDENT_COURSES + "/:courseId/paper_attempts/:attemptId/paper_attempt_results"
export const ANALYTICS_PAPER = STUDENT_COURSES + "/:courseId/exam_paper_results/view"


//Previous Paper URL
export const PAPER_LIST = STUDENT_COURSES + "/:studentCourseId/papers"
export const CREATE_PAPER_ATTEMPT = STUDENT_COURSES + "/:studentCourseId/paper_attempts"
export const VIEW_PAPER_ATTEMPT = CREATE_PAPER_ATTEMPT + "/:paperAttemptId"
export const COMPLETE_PAPER_ATTEMPT = CREATE_PAPER_ATTEMPT + "/:paperAttemptId/complete"
export const RESET_PAPER_ATTEMPT = CREATE_PAPER_ATTEMPT + "/:paperAttemptId/reset"
export const PAPER_ATTEMPT_QUESTIONS = VIEW_PAPER_ATTEMPT + "/paper_attempt_questions"
export const VIEW_UPDATE_PAPER_ATTEMPT_QUESTION = PAPER_ATTEMPT_QUESTIONS + "/:paperAttemptQuestionId"
export const START_PAPER_ATTEMPT_QUESTION = VIEW_UPDATE_PAPER_ATTEMPT_QUESTION + "/start"
export const PAPER_ATTEMPT_QUESTIONS_ANSWERS = VIEW_PAPER_ATTEMPT + "/paper_attempt_questions/answers"


//Content library
const TUTOR_COURSE = '/course/tutor';
export const CONTENT_LIBRARY = '/content_management/tutor/content_libraries';
export const CONTENT_LIBRARY_QUESTIONS = TUTOR_COURSE + "/content_questions"
export const CONTENT_LIBRARY_QUESTION_ID = CONTENT_LIBRARY_QUESTIONS + "/:questionId"
export const BULK_CONTENT_QUESTIONS = CONTENT_LIBRARY + "/bulk_questions"


// Question
export const QUESTION = TUTOR + "/questions";
export const QUESTION_ID = QUESTION + "/:id";
export const QUESTION_ATTACHMENTS = TUTOR_COURSE + "/question_attachments"
export const SOLUTION_ATTACHMENTS = TUTOR_COURSE + "/question_solution_attachments"
export const QUESTION_OPTION_ATTACHMENTS = TUTOR_COURSE + "/question_option_images"

export const CONTENT_QUESTION_ATTACHMENTS = TUTOR_COURSE + "/content_question_attachments"
export const CONTENT_SOLUTION_ATTACHMENTS = TUTOR_COURSE + "/cont_quest_sol_atchmnts"
export const CONTENT_OPTION_ATTACHMENTS = TUTOR_COURSE + "/cont_quest_option_images"

export const EXAM_QUESTION_ATTACHMENTS = TUTOR_COURSE + "/exam_question_attachments"
export const EXAM_SOLUTION_ATTACHMENTS = TUTOR_COURSE + "/exam_question_solution_attachments"
export const EXAM_QUESTION_OPTION_ATTACHMENTS = TUTOR_COURSE + "/exam_question_option_images"


export const PAPER_QUESTION_ATTACHMENTS = TUTOR_COURSE + "/paper_question_attachments"
export const PAPER_SOLUTION_ATTACHMENTS = TUTOR_COURSE + "/paper_question_solution_attachments"
export const PAPER_QUESTION_OPTION_ATTACHMENTS = TUTOR_COURSE + "/paper_exam_question_option_images"


// Content History
export const RECENT_CONTENT_HISTORY = `${TUTOR}/content_histories`
export const UPDATE_RECENT_CONTENT_HISTORY = `${RECENT_CONTENT_HISTORY}/:id`
