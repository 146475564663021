import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { CreatePaperAttemptModel, PaperAttemptModel, PreviousPaperModel } from "../../models/Learn/Exam/PaperModel/paper.model";
import { PaginationModel } from "../../models/pagination.model";
import { Question } from '../../models/Learn/Question/question.model';
import * as apiRoutes from "../../routes/routeConstants/apiRoutes"
import { LocalStorage } from "../../shared/utils/LocalStorageHelpers";
export class PreviousPaperService {
    static fetchPreviousPaperList(
        onSuccess: (data: any, meta: PaginationModel) => void,
        onError: Function
    ) {
        return axiosInstance
        .get(generatePath(apiRoutes.PAPER_LIST, {studentCourseId: LocalStorage.getItem("COURSE_ID")}))
        .then(response => {

            const data = deserialize(PreviousPaperModel,response["data"]["papers"])
            const meta = deserialize(PaginationModel, response["data"]["meta"])
            onSuccess(data, meta)
        })
        .catch(error => {
            onError(error)
        })
    }
    static createPaperAttempt(
        data: any,
        onSuccess: (data: PaperAttemptModel) => void,
        onError: Function
    ) {
        const serializeData = serialize(CreatePaperAttemptModel, data)
        const payload = {paper_attempt: serializeData}
        return axiosInstance
        .post(generatePath(apiRoutes.CREATE_PAPER_ATTEMPT, {studentCourseId: LocalStorage.getItem("COURSE_ID")}) , payload)
        .then(response => {
            const attempt = deserialize(PaperAttemptModel, response["data"]["paper_attempt"])
            onSuccess(attempt)
        })
        .catch(error => {
            onError(error)
        })
    }
    static getPaperQuestionsWithAnswers(
        paperAttemptId: any,
        onSuccess: (questions: any) => void,
        onError: Function
    ) {
        return axiosInstance
        .get(generatePath(apiRoutes.PAPER_ATTEMPT_QUESTIONS_ANSWERS, {paperAttemptId, studentCourseId: LocalStorage.getItem("COURSE_ID")}))
        .then(response => {
            const data = deserialize(Question, response["data"]["paper_attempt_questions"])
            onSuccess(data)
        })
        .catch(error => {
            onError(error)
        })
    }
    static getPaperQuestions(
        paperAttemptId: any,
        onSuccess: (questions: any) => void,
        onError: Function
    ) {
        return axiosInstance
        .get(generatePath(apiRoutes.PAPER_ATTEMPT_QUESTIONS, {paperAttemptId, studentCourseId: LocalStorage.getItem("COURSE_ID")}))
        .then(response => {
            const data = deserialize(Question, response["data"]["paper_attempt_questions"])
            onSuccess(data)
        })
        .catch(error => {
            onError(error)
        })
    }
    static getPaperAttemptQuestion(
        data: any,
        onSuccess: (question: Question) => void,
        onError: Function
    ) {
        return axiosInstance
        .get(generatePath(apiRoutes.VIEW_UPDATE_PAPER_ATTEMPT_QUESTION, {paperAttemptId: data.attemptId, paperAttemptQuestionId: data.id, studentCourseId: LocalStorage.getItem("COURSE_ID")}))
        .then(response => {
            const data = deserialize(Question, response["data"]["paper_attempt_question"])
            onSuccess(data)
        })
        .catch(error => {
            onError(error)
        })
    }
    static updatePaperAttemptAnswer(
        data: any,
        answer: any,
        onSuccess: () => void,
        onError: Function
    ) {
        const payload = {
            paper_attempt_question: answer
          };
        return axiosInstance
        .put(generatePath(apiRoutes.VIEW_UPDATE_PAPER_ATTEMPT_QUESTION, {paperAttemptId: data.attemptId, paperAttemptQuestionId: data.id, studentCourseId: LocalStorage.getItem("COURSE_ID")}), payload)
        .then(() => {
            onSuccess()
        })
        .catch(error => {
            onError(error)
        })
    }
    static completePaperExam(
        data: any,
        onSuccess: Function,
        onError: Function
    ) {
        return axiosInstance
        .put(generatePath(apiRoutes.COMPLETE_PAPER_ATTEMPT, {paperAttemptId: data.attemptId, studentCourseId: LocalStorage.getItem("COURSE_ID")}))
        .then(() => {
            onSuccess()
        }).catch(error => {
            onError()
        })
    }
    static resetPaperExam(
        data: any,
        onSuccess: Function,
        onError: Function
    ) {
        return axiosInstance
        .put(generatePath(apiRoutes.RESET_PAPER_ATTEMPT, {paperAttemptId: data.attemptId, studentCourseId: LocalStorage.getItem("COURSE_ID")}))
        .then((response) => {
            const attempt = deserialize(PaperAttemptModel, response["data"]["paper_attempt"])
            onSuccess(attempt)
        }).catch(error => {
            onError()
        })
    }
    static deletePaperExerciseAttempt(
        attemptId: any,
        onSuccess: Function,
        onError: Function
      ) {
        return axiosInstance.delete(
          generatePath(apiRoutes.VIEW_PAPER_ATTEMPT, {
            studentCourseId: LocalStorage.getItem("COURSE_ID"),
            paperAttemptId: attemptId,
          })
        ).then(() => {
          onSuccess()
        }).catch(() => {
          onError()
        })
      }

}
