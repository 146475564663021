import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { generatePath } from "react-router-dom";
import { UserModel } from "../../models/user.model";
import { store } from "../../store";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import { LocalStorage } from "../../shared/utils/LocalStorageHelpers";

export default class UserService {
  static getProfileDetails(
    onSuccess: (value: UserModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PROFILE_DETAILS)
      .then((response) => {
        const data = deserialize(UserModel, response.data["tutor"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateProfileDetails(
    data: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .put(ApiRoutes.PROFILE_DETAILS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const data = deserialize(UserModel, response.data["tutor"]);
        onSuccess(data);
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            user: data,
          },
        });
        LocalStorage.setItem("USER", data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
