import * as React from 'react';
import NoDataIcon from '../../../assets/placeholder/no-data.svg'
import './nodata.scss'
interface Props {
    placeholder: string
}
export const NoData = (props: Props) => {
    const {placeholder} = props
    return (
        <div className="no-data__container">
            <p>{placeholder}</p>
            <img src={NoDataIcon} />
        </div>

    )
}