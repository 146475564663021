import React, { FC, useEffect, useState } from 'react';
import './pageView.scss';
import { PlusCircleFilled } from '@ant-design/icons';
import { CheckCircleFilled } from '@ant-design/icons';
import NotificationIcon from '../../../assets/icons/notification.svg';
import PlaceholderIcon from '../../../assets/icons/placeholder-dark.svg';
import MyProfileIcon from '../../../assets/icons/my-profile.svg';
import SettingsIcon from '../../../assets/icons/settings.svg';
import LogoutIcon from '../../../assets/icons/logout.svg';
import ProceedIcon from '../../../assets/icons/proceed.svg';
import SearchIcon from '../../../assets/icons/search.svg';
import filterIcon from '../../../assets/icons/filter-icon.svg';
import { Col, Dropdown, Input, Row, Space } from 'antd';
import AuthContainer from '../../../store/container/AuthContainer';
import { LoginCredentials, UserModel } from '../../../models/user.model';
import { useHistory, withRouter } from 'react-router-dom';
import { MY_PROFILE } from '../../../routes/routeConstants/appRoutes';
import UserService from "../../../services/AuthService/auth.service";
import { MetaService } from '../../../services/MetaService/meta.service';
import { ClassRoomMetaModel } from '../../../models/meta.model';
import ContentPlaceholder from '../../../assets/images/content-placeholder.svg';
import * as AppRoutes from '../../../routes/routeConstants/appRoutes';
import TitleContainer from '../../../store/container/TitleContainer';
import BackIcon from '../../../assets/icons/SVG/back.svg';
import BackWhiteIcon from '../../../assets/icons/SVG/backWhite.svg';
import { store } from '../../../store';
import { UNAUTHENTICATED } from '../../../store/definitions/authConstants';
import { LocalStorage } from '../../utils/LocalStorageHelpers';
import { USER_LOGOUT } from '../../../routes/routeConstants/apiRoutes';

import {
  NotificationMeta,
  Notifications,
} from "../../../models/Notifications/notifications.model";
import NotificationService from "../../../services/NotificationService/notifications.service";
import NotificationsModal from './NotificationsModal';

interface PageViewProps {
  title: string;
  showBack?: boolean;
  showAdd?: boolean;
  showFilter?: boolean;
  handleFilter?: () => void;
  showSearch?: {
    placeholder: string;
    onChange: (value: string) => void;
  };
  addHandler?: () => void;
  className?: string;
  user: UserModel;
  setUnAuthenticated: Function;
  setCurrentClassRoom: (classRoom: any) => void;
  currentClassRoom: any;
  twoBgs?: boolean;
  color?: string;
  subText?: string;
}

const PageView: FC<PageViewProps> = (props) => {
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  const [classRooms, setClassRooms] = useState<ClassRoomMetaModel[]>([]);
  const [showNotifications, setNotificationsVisibility] = useState(false);
  const [notifications, setNotifications] = useState<Notifications[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<any>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const {
    title,
    children,
    showBack = false,
    showAdd = false,
    showFilter = false,
    showSearch,
    addHandler,
    handleFilter,
    className,
    user,
    currentClassRoom,
    setCurrentClassRoom,
    twoBgs,
    subText,
  } = props;

  const onSelectClassRoom = (classRoom: ClassRoomMetaModel) => {
    if (currentClassRoom?.id !== classRoom?.id) {
      history.push(AppRoutes.LEARN);
      LocalStorage.setItem("CURRENT_CLASS_ROOM", classRoom);
      setCurrentClassRoom(classRoom);
    }
  };

  const handleLogout = () => {
    UserService.logoutUser(
      () => {},
      (error: Error) => { console.log(error.message); },
      () => {
        store.dispatch({
          type: UNAUTHENTICATED,
          payload: {
            authenticated: false,
            user: null,
            courseId: null,
            currentCourse: null,
          },
        });
        localStorage.clear();
      }
    ).then();
  };

  useEffect(() => {
    const fetchClassRooms = () => {
      MetaService.fetchClassRoomsMeta(
        (classRooms) => {
          if (classRooms.length) {
            setClassRooms(classRooms);
            const classRoom = currentClassRoom || classRooms[0]
            setCurrentClassRoom(classRoom);
            LocalStorage.setItem("CURRENT_CLASS_ROOM", classRoom);
          }
        },
        () => { },
        () => { }
      );
    };

    fetchClassRooms();
  }, [className, currentClassRoom, setCurrentClassRoom]);

  const profileDropdownData = [
    {
      icon: MyProfileIcon,
      path: MY_PROFILE,
      title: 'My Profile',
    },
    {
      icon: SettingsIcon,
      path: MY_PROFILE,
      title: 'Settings',
    },
    {
      icon: LogoutIcon,
      path: USER_LOGOUT,
      title: 'Logout',
    },
  ];

  const fetchNotifications = (params: any) => {
    NotificationService.fetchNotifications(
      params,
      (data: Notifications[], meta: NotificationMeta) => {        
        setNotifications(data);
        setPaginationMeta(meta);
      },
      () => { },
      () => { }
    );
  };

  const handlePageChange = (page: number, pageSize: any) => {
    fetchNotifications({ page });
    setCurrentPageNumber(page);
  };

  useEffect(() => {
    fetchNotifications({ page: 1 });
  }, [user])

  return (
    <div className={`page-view  ${className}`}>
      <NotificationsModal
        refreshList={() => fetchNotifications({ page: currentPageNumber })}
        notifications={notifications}
        visible={showNotifications}
        closeHandler={() => setNotificationsVisibility(false)}
        paginationMeta={paginationMeta}
        handlePageChange={handlePageChange}
      />
      <div className='page-view__header'>
        <div className='content-left '>
          <div className={`heading`}>
            {showBack ? (
              <img
                alt=''
                src={twoBgs ? BackWhiteIcon : BackIcon}
                onClick={handleGoBack}
                className='pb-10'
                style={{ cursor: 'pointer' }}
              />
            ) : null}
            <h2>
              {title}
              {showAdd && <PlusCircleFilled onClick={addHandler} />}
            </h2>

          </div>
          <p className={`subtext ${showBack ? 'subtext--margin' : ''}`}>
            {subText}
          </p>
        </div>
        <div className='page-view-header__right'>
          {showSearch && (
            <Input
              className='search__input'
              prefix={<img alt='' src={SearchIcon} />}
              placeholder={showSearch?.placeholder}
              onChange={(e) => showSearch?.onChange(e?.target?.value)}
            />
          )}
          {showFilter && (
            <div className='header-options__filter'>
              <img src={filterIcon} alt='' onClick={handleFilter} />
            </div>
          )}
          <Dropdown
            trigger={['click']}

            overlay={<div></div>} className='notifications__dropdown'>
            <img onClick={() => setNotificationsVisibility(true)} alt='' src={NotificationIcon} />
          </Dropdown>
          <Dropdown
            trigger={['click']}
            overlay={
              <div className='profile-dropdown__container'>
                <Space className='profile-dropdown__details' size={'middle'}>
                  <img
                    alt=''
                    className='display-picture'
                    src={user?.displayPictureUrl ?? PlaceholderIcon}
                  />
                  <div>
                    <p className='name'>{user?.fullName}</p>
                    <p className='school'>{user?.institute?.name}</p>
                  </div>
                </Space>
                <div className='profile-courses__container'>
                  {classRooms?.map((value, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => onSelectClassRoom(value)}
                        className={`profile-courses__card ${currentClassRoom?.id == value.id &&
                          'profile-courses-card__selected'
                          }`}
                      >
                        <div className='profile-courses-checkbox__container'>
                          {currentClassRoom?.id == value.id ? (
                            <CheckCircleFilled className='profile-courses__check' />
                          ) : (
                            <div className='profile-courses__uncheck' />
                          )}
                        </div>
                        <div className='profile-course__logo'>
                          <img
                            alt=''
                            src={
                              ContentPlaceholder
                            }
                          />
                        </div>
                        <div className='profile-course__details'>
                          <p className='profile-course__board'>
                            {value?.board?.acronym}
                          </p>
                          <p className='profile-course__name'>{value?.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className='profile-dropdown-items__container'>
                  {profileDropdownData?.map((value, index) => {
                    return (
                      <Row
                        key={'profile#' + value.title + index}
                        className='profile-dropdown__item'
                        onClick={() => {
                          if (value.title === 'Logout') {
                            handleLogout();
                          } else {
                            history?.push(value?.path);
                          }
                        }}
                      >
                        <Col span={2}>
                          <img alt='' src={value.icon} className='icon' />
                        </Col>
                        <Col span={18}>
                          <p className='title'>{value.title}</p>
                        </Col>
                        <Col span={4}>
                          <div className='proceed-icon'>
                            <img alt='' src={ProceedIcon} />
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </div>
            }
            className='profile__dropdown'
          >
            <img alt='' src={user?.displayPictureUrl ?? PlaceholderIcon} />
          </Dropdown>
        </div>
      </div>
      {children}
    </div>
  );
};

export default withRouter(AuthContainer(TitleContainer(PageView)));
