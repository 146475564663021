import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/_axiosInstance";
import { ClassProgressModel, StudentActivitiesModel } from "../../models/dashboard.model";
import {
  DASHBOARD_CLASS_PROGRESS,
  DASHBOARD_TUTOR_LOGIN_REPORT,
  DASHBOARD_STUDENT_ACTIVITIES,
  DOWNLOAD_CLASS_PROGRESS
} from "../../routes/routeConstants/apiRoutes";

export default class DashboardService {
  static fetchStudentActivities(
    params: any,
    onSuccess: (data: StudentActivitiesModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(DASHBOARD_STUDENT_ACTIVITIES, { params })
      .then((response) => {
        const data = deserialize(
          StudentActivitiesModel,
          response?.["data"]
        );
        onSuccess(data);
      })
      .catch((e) => {
        onError(e);
      })
      .finally(onFinal);
  }

  static fetchClassProgress(
    params: any,
    onSuccess: (data: ClassProgressModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(DASHBOARD_CLASS_PROGRESS, { params })
      .then((response) => {
        const data = deserialize(
          ClassProgressModel,
          response?.["data"]
        ) as ClassProgressModel[];
        onSuccess(data);
      })
      .catch((e) => {
        onError(e);
      })
      .finally(onFinal);


  }

  static fetchLoginReport(
    params: any,
    onSuccess: (value: any) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(DASHBOARD_TUTOR_LOGIN_REPORT, { params })
      .then((response) => new Blob([response.data]))
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `LoginReport.csv`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);

        onSuccess("Download");
      })
      .catch((e) => {
        onError(e);
      })
      .finally(onFinal);
  }

  static downloadClassProgress(
    params: any,
    onSuccess: (value: any) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(DOWNLOAD_CLASS_PROGRESS, { params })
      .then((response) => new Blob([response.data]))
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Report.csv`,
        );
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);

        onSuccess("Download");
      })
      .catch((e) => {
        onError(e);
      })
      .finally(onFinal);
  }

}
