import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import _axiosInstance from "../../interceptor/_axiosInstance";

import {
  JoinLiveClassModel,
  JoinLiveClassResponseModel,
  LiveClassCreateModel,
  LiveClassModel,
} from "../../models/liveClass.model";
import { deserialize, serialize } from "serializr";
import { generatePath } from "react-router-dom";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class LiveClassService {
  static fetchUpcomingLiveClasses(
    params: { search?: string },
    onSuccess: (value: LiveClassModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.UPCOMING_LIVE_CLASSES, { params })
      .then((response) => {
        const data = deserialize(
          LiveClassModel,
          response.data["live_classes"]
        ) as LiveClassModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  // ----old---
  // static createLiveClass( 
  //   data: LiveClassCreateModel,
  //   onSuccess: Function,
  //   onError: Function,
  //   onFinal: () => void
  // ) {
  //   const payload = { live_class: serialize(LiveClassCreateModel, data) };
  //   axiosInstance
  //     .post(ApiRoutes.LIVE_CLASS_CREATE, payload)
  //     .then((response) => {
  //       const data = deserialize(
  //         LiveClassCreateModel,
  //         response.data["live_class"]
  //       );
  //       onSuccess(data);
  //     })
  //     .catch((error) => {
  //       onError();
  //     })
  //     .finally(() => {
  //       onFinal();
  //     });
  // }


  static createLiveClass(
    data: LiveClassCreateModel,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = serialize(LiveClassCreateModel, data);
    _axiosInstance
      .post(ApiRoutes.LIVE_CLASS_CREATE_UPDATE, payload)
      .then((response) => {
        if (response.data.isSuccess) {
          Notification({ message: "Create", description: "create successfully", type: NotificationTypes.SUCCESS });
        } else {
          Notification({ message: "Error", description: response.data.message, type: NotificationTypes.ERROR });
        }
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  // static updateLiveClass(
  //   liveClassId: number,
  //   data: LiveClassCreateModel,
  //   onSuccess: Function,
  //   onError: Function,
  //   onFinal: () => void
  // ) {
  //   const payload = { live_class: serialize(LiveClassCreateModel, data) };
  //   const API_URL = generatePath(ApiRoutes.LIVE_CLASS, {
  //     liveClassId,
  //   });
  //   axiosInstance
  //     .put(API_URL, payload)
  //     .then((response) => {
  //       const LiveClass = deserialize(
  //         LiveClassModel,
  //         response.data["live_class"]
  //       );
  //       onSuccess(LiveClass);
  //     })
  //     .catch((error) => {
  //       onError();
  //     })
  //     .finally(() => {
  //       onFinal();
  //     });
  // }

  static updateLiveClass(
    liveClassId: number,
    data: LiveClassCreateModel,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = serialize(LiveClassCreateModel, data);
    _axiosInstance
      .post(ApiRoutes.LIVE_CLASS_CREATE_UPDATE, { ...payload, liveClassId })
      .then((response) => {
        if (response.data.isSuccess) {
          Notification({ message: "Create", description: "update successfully", type: NotificationTypes.SUCCESS });
        } else {
          Notification({ message: "Error", description: response.data.message, type: NotificationTypes.ERROR });
        }
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteLiveClass(
    liveClassId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.LIVE_CLASS, { liveClassId });
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchLiveClassesNow(
    params: { search?: string },
    onSuccess: (value: LiveClassModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.LIVE_CLASSES_NOW, { params })
      .then((response) => {
        const data = deserialize(
          LiveClassModel,
          response.data["live_classes"]
        ) as LiveClassModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  // Old
  static joinLiveClass(
    liveClassId: number,
    onSuccess: (value: JoinLiveClassResponseModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.LIVE_CLASS_JOIN, { liveClassId });
    axiosInstance
      .post(API_URL)
      .then((response) => {
        const data = deserialize(
          JoinLiveClassResponseModel,
          response.data["live_class"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static joinLiveClass_Zoom(
    data: JoinLiveClassModel,
    onSuccess: (value: string) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = serialize(JoinLiveClassModel, data);
    _axiosInstance.post(ApiRoutes.LIVE_CLASS_JOIN_NEW, payload)
      .then((response) => {
        if (response.data.isSuccess) {
          onSuccess(response.data.message);
        } else {
          Notification({ message: "Error", description: 'something went wrong!', type: NotificationTypes.ERROR });
        }
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }


  static fetchLiveClassDetails(
    liveClassId: number,
    onSuccess: (value: LiveClassModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.LIVE_CLASS_DETAILS, { liveClassId });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(LiveClassModel, response.data["live_class"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static endLiveClass(
    liveClassId: number,
    onSuccess: (value: JoinLiveClassResponseModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.LIVE_CLASS_END, { liveClassId });
    axiosInstance
      .post(API_URL)
      .then((response) => {
        const data = deserialize(
          JoinLiveClassResponseModel,
          response.data["live_class"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
