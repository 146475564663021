import { serializable, alias, primitive, object } from "serializr";

export class UpdateQuery {
  @serializable(alias("is_correct", primitive()))
  isCorrect?: boolean;

  @serializable(alias("comment", primitive()))
  comment?: "";
  @serializable(alias("comment_remove", primitive()))
  commentRemove?: string;
}
export class EvaluteAttemptQuestion {
  @serializable(alias("evaluation", primitive()))
  evaluation?: string;
}
export class Tags {
  @serializable(alias("exam_name", primitive()))
  examName?: string;
  @serializable(alias("subject_name", primitive()))
  subjectName?: string;
  @serializable(alias("board_name", primitive()))
  boardName?: string;
  @serializable(alias("class_room_name", primitive()))
  classRoomName?: string;
}
export class AssessmentQuestions {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("title", primitive()))
  title?: string;
  @serializable(alias("description", primitive()))
  description?: string;
  @serializable(alias("timer_options", primitive()))
  timerOptions?: string;
  @serializable(alias("time", primitive()))
  time?: Float32Array;
  @serializable(alias("started_at", primitive()))
  startedAt?: string;
  @serializable(alias("completed_at", primitive()))
  completedAt?: string;
  @serializable(alias("assessment_type", primitive()))
  assessmentType?: string;
  @serializable(alias("tags", object(Tags)))
  tags?: Tags;
}

export class Tutor {
  @serializable(alias("date_of_birth", primitive()))
  dateOfBirth?: string;
  @serializable(alias("display_picture_url", primitive()))
  displayPictureUrl?: string;
  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("first_name", primitive()))
  firstName?: string;
  @serializable(alias("full_name", primitive()))
  fullName?: string;
  @serializable(alias("gender", primitive()))
  gender?: string;
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("is_active", primitive()))
  isActive?: boolean;
  @serializable(alias("last_name", primitive()))
  lastName?: string;
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;
  @serializable(alias("qualification", primitive()))
  qualification?: string;
}
export class ExerciseAttemptQuestions {
  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;
  @serializable(alias("content", primitive()))
  content?: string;
  @serializable(alias("comment", primitive()))
  comment?: string;
  @serializable(alias("correct_answer", primitive()))
  correctAnswer?: string;
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("is_correct_for_tutor", primitive()))
  isCorrect?: boolean;
  @serializable(alias("only_free_text", primitive()))
  onlyFreeText?: boolean;
  @serializable(alias("options", primitive()))
  options?: string;
  @serializable(alias("solution", primitive()))
  solution?: string;
  @serializable(alias("started_at", primitive()))
  startedAt?: string;
  @serializable(alias("tutor_answer", primitive()))
  tutorAnswer?: string;
  @serializable(alias("tutor", object(Tutor)))
  tutor?: Tutor;

}
