import React, { FC, useEffect, useState } from "react";

import faker from "faker";
import { serialize } from "serializr";

import { ActivitySummaryModel, ActivitySummaryFilterModel } from "../../../models/Report/report.model";


import ReportService from "../../../services/ReportsService/Report.service";
import { Button, Skeleton, Table } from "antd";
import { SkeletonLoader } from "../../LiveClass";
import ReportFilter from "./ReportFilter";
import { UserModel } from "../../../models/user.model";
import AuthContainer from "../../../store/container/AuthContainer";
import PageView from "../../../shared/components/PageView";
import SubmissionDetails from "./SubmissionDetails";

import "./activityReport.scss";


interface ActivityReportProps {
    user: UserModel;
}

const ActivityReport: FC<ActivityReportProps> = (props) => {
    const { user } = props;

    const defaultFilter = {
        SectionId: 0,
        SubjectId: 0
    }

    const [activitySummary, setActivitySummary] = useState<ActivitySummaryModel[]>([]);
    const [filters, setFilters] = useState<ActivitySummaryFilterModel>({});
    const [loading, setLoading] = useState(false);

    const [currentActivity, setCurrentActivity] = useState<any>("");
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const fetchActivitySummary = (params?: ActivitySummaryFilterModel) => {
        setLoading(true);
        const payload = serialize(ActivitySummaryFilterModel, params ?? defaultFilter);
        ReportService.fetchActivitySummary(
            payload,
            (liveClasses) => {
                setActivitySummary(liveClasses);
            },
            () => { },
            () => {
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        if (user?.tutorClassRooms) {
            setFilters({ ...defaultFilter });
            fetchActivitySummary({ ...defaultFilter })
        }
    }, []);

    // Download Report 
    const handleDownload = (params?: any) => {
        setLoading(true)
        const payload = serialize(ActivitySummaryFilterModel, params ?? defaultFilter);
        ReportService.downloadActivitySummary(
            payload,
            (contents) => { },
            () => { },
            () => { setLoading(false) }
        );
    }
    return (
        <PageView title="Report">
            {activitySummary ? (
                <div className="activity-report">
                    <div className="activity-report__header">
                        <div className="activity-report__left">
                            <div className="activity-report__title">Activity Report</div>
                            <ReportFilter
                                applyHandler={(values) => {
                                    setFilters({ ...defaultFilter, ...values });
                                    fetchActivitySummary({ ...defaultFilter, ...values })

                                }}
                                clearHandler={(values) => {
                                    setFilters({ ...defaultFilter, ...values });
                                    fetchActivitySummary({ ...defaultFilter, ...values })
                                }}
                            />
                        </div>

                        <div>
                            <Button onClick={() => handleDownload(filters)}>Download Report</Button>
                        </div>
                    </div>
                    {loading ? (
                        <div className="activity-report">
                            <SkeletonLoader />
                        </div>
                    ) : activitySummary?.length ? (
                        <Table
                            bordered
                            dataSource={activitySummary}
                            columns={[
                                {
                                    title: 'Name',
                                    dataIndex: 'activityName',
                                    key: 'name',
                                },
                                {
                                    title: 'Class',
                                    dataIndex: 'className',
                                    key: 'className',
                                },
                                {
                                    title: 'Section',
                                    dataIndex: 'sectionName',
                                    key: 'sectionName',
                                },
                                {
                                    title: 'Subject',
                                    dataIndex: 'subjectName',
                                    key: 'subjectName',
                                },
                                {
                                    title: 'Max Points',
                                    dataIndex: 'maxPoints',
                                    key: 'maxPoints',
                                },

                                {
                                    title: 'Assigned Count',
                                    dataIndex: 'assignedCount',
                                    key: 'assignedCount',
                                },
                                {
                                    title: 'TurnIn Count',
                                    dataIndex: 'turnInCount',
                                    key: 'turnInCount',
                                },
                                {
                                    title: 'Reviewed Count',
                                    dataIndex: 'reviewedCount',
                                    key: 'reviewedCount',
                                },
                                {
                                    title: "action",
                                    dataIndex: "",
                                    key: "action",
                                    render: (text: string, record: any) => (
                                        <Button
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setCurrentActivity(record);
                                                setShowDetails(true);
                                            }}
                                        >
                                            View Details
                                        </Button>
                                    ),
                                },

                            ]}
                            pagination={{ defaultPageSize: 10 }}
                        />
                    ) : (
                        <div className="no-activity-report-text">No Activity Summary !</div>
                    )}
                </div>
            ) : (
                <div />
            )
            }

            <SubmissionDetails
                visible={showDetails}
                setVisible={setShowDetails}
                content={currentActivity}
                setCurrentContent={setCurrentActivity}
            />
        </PageView>
    )

};

export default AuthContainer(ActivityReport);
