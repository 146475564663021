export const buildFormData = (formData: any, data: any, parentKey?: any, removeIndex?:boolean) => {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        buildFormData(
          formData,
          data[key],
          // parentKey ? `${parentKey}[${key}]` : key,
          parentKey ? (removeIndex && Array.isArray(data))  ? `${parentKey}[]` : `${parentKey}[${key}]` : key,
          removeIndex
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
  }
};

export function convertJSONToFormData(data: any, removeIndex?: boolean) {
  // console.log(data);
  const formData = new FormData();

  buildFormData(formData, data, undefined, removeIndex);

  return formData;
}
