import React, { FC } from "react";
import PopupModal from "../../../../../shared/components/PopupModal";
import InfoIcon from "../../../../../assets/icons/info.svg";
import "./instruction.scss";
import QuestionContainer from "../../../../../store/container/QuestionContainer";
import { ExcerciseAttempt } from "../../../../../models/Learn/Exercise/exercise.model";

interface InstructionProps {
  visiblity: boolean;
  closeHandler: () => void;
  okHandler: () => void;
  attempt: ExcerciseAttempt;
}


const InstructionPopup: FC<InstructionProps> = ({attempt, ...rest }) => {
	const instructions = [
		attempt?.timerOptions == "whole_quiz" ? `This quiz has a time limit. Duration is ${attempt?.time} mins.` : attempt?.timerOptions == "per_question" ? `This quiz has a time limit. Duration is ${attempt?.time} seconds per question.` : "This quiz has no time limit.",
		"Each answer need to be submitted.",
		"Free text answers will be evaluated by the tutor.",
		"Don't quit the quiz you may lose your progress",
	  ];

  return (
    <div>
      <PopupModal className="instruction__wrapper" {...rest}>
        <img src={InfoIcon} alt="" />
        <div className="instructions">
          <h2>Exercise Instructions</h2>
          <ul>
            {instructions?.map((instruction) => (
              <li>{instruction}</li>
            ))}
          </ul>
        </div>
      </PopupModal>
    </div>
  );
};

export default QuestionContainer(InstructionPopup);
