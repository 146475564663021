import { Collapse, Upload, message } from 'antd';
import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import React, { FC, Fragment, useEffect, useState } from 'react';
import UIModal from '../../../shared/components/UIModal';
import { CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import ButtonComponent from '../../../shared/components/ButtonComponent';
import './quickQuizForm.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AcceptedUploadTypes } from '../../../shared/components/EmptyState/acceptedUploadTypes';
import { convertJSONToFormData } from '../../../shared/utils/convertJsonToFormData';
import Question from './Question';
import ContentLibraryService from '../../../services/ContentLibraryService/contentLibrary.service';
import BulkUpload from '../StudyMaterial/BulkUpload';
import Notification from '../../../shared/components/Notification';
import { NotificationTypes } from '../../../enums/notificationTypes';

interface QuickQuizFormValue {
  questions: any[];
}
interface QuickQuizFormProps {
  refreshList: Function;
  currentContent: any;
  visible: boolean;
  closeModal: () => void;
}
const { Panel } = Collapse;
const QuickQuizForm: FC<QuickQuizFormProps> = ({
  currentContent,
  refreshList,
  visible,
  closeModal,
}) => {
  const [initialValue, setInitialValue] = useState<QuickQuizFormValue>({
    questions: [],
  });
  const [active, setActive] = useState<number[]>([]);
  const [contentId, setContentId] = useState<any>();
  const [deleteIndex, setDeleteIndex] = useState();
  const [showBulkUpload, setShowBulkUpload] = useState<boolean>(false);

  const handleAddquestion = (arrayHelper: FieldArrayRenderProps) => {
    const initialState = {
      question: '',
      correctAnswer: '',
      option1: null,
      option2: null,
      option3: null,
      option4: null,
      image_option1: null,
      image_option2: null,
      image_option3: null,
      image_option4: null,
      noOptions: false,
      solution: '',
      questionAttachments: [],
      questionSolutionAttachments: [],
      questionOptionImages: [],
      attachment: undefined,
      imageUrl: undefined,
    };

    arrayHelper.push(initialState);
  };

  // new
  const [uploadedDoc, setUploadedDoc] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<any[]>([]);
  const [errorRow, setErrorRow] = useState<any>('');
  const [contentLibraryId, setContentLibararyId] = useState<
    number | string | undefined
  >('');

  const beforeUpload = (file: File) => {
    const validTypes = AcceptedUploadTypes.exerciseUpload.allFiles.includes(
      file.type
    );
    if (!validTypes) {
      message.error('You can only upload ZIP/XSLX file!').then();
      return Upload.LIST_IGNORE;
    }
    return validTypes;
  };

  useEffect(() => {
    if (currentContent) {
      setContentId(currentContent?.id);
      setContentLibararyId(currentContent?.contentLibrary?.id);
      ContentLibraryService.getContentQuestions(
        currentContent?.contentLibrary?.id,
        (questions: any) => {
          setInitialValue({
            ...currentContent,
            questions,
          });
        },
        () => {},
        () => {}
      );
    }
  }, [currentContent]);

  const handleSubmit = () => {
    if (uploadedDoc) {
      setButtonLoading(true);
      setUploadErrors([]);
      const payload: any = {};
      payload['file'] = uploadedDoc?.originFileObj;
      payload['ContentLibraryId'] = currentContent?.id || 0;
      payload['ClassroomId'] = 0;
      payload['SubjectId'] = 0;
      payload['ChapterId'] = 0;

      const formData = convertJSONToFormData(payload);
      // excerciseService.uploadExerciseQuestionsDocument(
      //   formData,
      //   () => {
      //     message.success("Uploaded successfully!");
      //     setUploadedDoc(null);
      //     closeModal();
      //   },
      //   (errors: any, row: any) => {
      //     setUploadErrors(errors);
      //     setErrorRow(row);
      //   },
      //   () => {
      //     setButtonLoading(false);
      //   }
      // );
    }
  };

  return (
    <>
      <BulkUpload
        visible={showBulkUpload}
        exerciseId={contentLibraryId}
        closeHandler={() => {
          setShowBulkUpload(false);
        }}
      />
      <Formik
        initialValues={initialValue}
        onSubmit={() => {}}
        enableReinitialize
      >
        {({ values, setFieldValue, resetForm }) => (
          <UIModal
            visible={visible}
            closeModal={closeModal}
            onClosed={() => {
              resetForm();
            }}
            submitProps={{
              text: 'Submit',
              clickHandler: () => handleSubmit(),
              buttonLoading: buttonLoading,
            }}
            cancelProps={{
              text: 'Cancel',
              clickHandler: () => {
                closeModal();
                setUploadErrors([]);
              },
            }}
          >
            <Form
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className='board-form__title add-content-modal__title'>
                {currentContent?.title}
              </div>
              <div>Add Questions</div>
              <FieldArray
                name='questions'
                render={(arrayHelper) => (
                  <Fragment>
                    <Collapse
                      accordion
                      bordered={false}
                      activeKey={active}
                      className='pj-list-question__collapse'
                      onChange={(key) => {
                        setActive([parseInt(key as string)]);
                      }}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined
                          className='collapse-icon__question'
                          rotate={isActive ? 90 : 0}
                        />
                      )}
                      destroyInactivePanel
                    >
                      {values?.questions?.map((_, index) => (
                        <Panel
                          header={`Question ${index + 1}`}
                          key={index}
                          className='site-collapse-custom-panel'
                          extra={
                            <DeleteOutlined
                              className='pj-remove-minus__question'
                              onClick={(event) => {
                                if (values?.questions[index]?.id) {
                                  const question: any =
                                    values?.questions[index];
                                  ContentLibraryService?.deleteContentQuestion(
                                    question?.id,
                                    () => {
                                      const questions = values.questions;
                                      questions?.splice(index, 1);
                                      setFieldValue('questions', questions);
                                      Notification({
                                        message: 'Question',
                                        description:
                                          'Question deleted successfully',
                                        type: NotificationTypes.SUCCESS,
                                      });
                                    },
                                    () => {
                                      Notification({
                                        message: 'Question',
                                        description:
                                          'Unable to delete question!',
                                        type: NotificationTypes.ERROR,
                                      });
                                    },
                                    () => {},
                                  );
                                  event?.stopPropagation();
                                } else {
                                  const questions = values.questions;
                                  questions?.splice(index, 1);
                                  setFieldValue('questions', questions);
                                }
                              }}
                            />
                          }
                        >
                          <Question
                            index={index}
                            showAttachment
                            exerciseId={contentLibraryId}
                            deleteIndex={deleteIndex}
                            value={values.questions[index]}
                            setFieldValue={setFieldValue}
                            showFreeText={false}
                            onCreate={(question, index) => {
                              setActive([-1]);

                              setFieldValue(
                                'questions',
                                [...values?.questions]?.map((ques, i) => {
                                  if (i === index) {
                                    return {
                                      ...question,
                                      submitted: true,
                                    };
                                  }
                                  return ques;
                                })
                              );
                            }}
                          />
                        </Panel>
                      ))}
                    </Collapse>
                    <ButtonComponent
                      className='pj-add-question no-border'
                      type='ghost'
                      buttonContent='+ Add Question'
                      onClick={() => {
                        setActive([values?.questions?.length]);
                        handleAddquestion(arrayHelper);
                      }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ButtonComponent
                        className='pj-add-question no-border'
                        type='ghost'
                        onClick={() => setShowBulkUpload(true)}
                        buttonContent='Bulk Upload'
                      />
                    </div>
                    {uploadErrors?.length ? (
                      <div className='upload__errors'>
                        {uploadErrors?.map((value, index) => {
                          return (
                            <p className='error__text'>
                              <span>
                                <ExclamationCircleOutlined />
                              </span>{' '}
                              {errorRow === 'no_row'
                                ? `${value?.questionId}: ${value?.errorMessage}`
                                : `row: ${errorRow}; ${value}`}
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                  </Fragment>
                )}
              />
            </Form>
          </UIModal>
        )}
      </Formik>
    </>
  );
};
export default QuickQuizForm;
