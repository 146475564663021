import React, { FC, useState } from 'react';
import UIModal from '../../../../shared/components/UIModal';
import ContentLibraryService from '../../../../services/ContentLibraryService/contentLibrary.service';
import ButtonComponent from '../../../../shared/components/ButtonComponent';

import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { AcceptedUploadTypes } from '../../../../shared/components/UploadContent/acceptedUploadTypes';
import { convertJSONToFormData } from '../../../../shared/utils/convertJsonToFormData';

import { convertJsonToXslx } from '../../../../shared/utils/xlsxConverter';
import { ExerciseXslxHeaders } from '../../../../enums/exerciseXslxHeaders';
import { DownloadOutlined } from '@ant-design/icons';

import './bulkUpload.scss';

interface BulkUploadProps {
  showDocUpload?: boolean;
  params?: any;
  visible: boolean;
  closeHandler: () => void;
  exerciseId: number | string | undefined
}

const BulkUpload: FC<BulkUploadProps> = (props) => {
  const { visible, closeHandler, exerciseId } = props;
  const [uploadedXslx, setUploadedXslx] = useState<any>(null);
  const [uploadedZip, setUploadedZip] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<any[]>([]);
  const [errorRow, setErrorRow] = useState<any>('');

  const beforeUpload = (file: File) => {
    const validTypes = AcceptedUploadTypes.exerciseUpload.allFiles.includes(
      file.type
    );
    if (!validTypes) {
      message.error('You can only upload ZIP/XSLX file!').then();
      return Upload.LIST_IGNORE;
    }
    return validTypes;
  };

  const handleChangeXlsx = (files: any) => {
    if (AcceptedUploadTypes.exerciseUpload.xslx.includes(files?.file?.type)) {
      setUploadedXslx(files?.file);
    } else {
      message.error('You can only upload XSLX file!').then();
    }
  };
  const handleChangeZip = (files: any) => {
    if (AcceptedUploadTypes.exerciseUpload.zip.includes(files?.file?.type)) {
      setUploadedZip(files?.file);
    } else {
      message.error('You can only upload ZIP file!').then();
    }
  };

  const handleSubmit = () => {
    if (uploadedXslx) {
      setButtonLoading(true);
      setUploadErrors([]);
      const payload: any = {};
      if (uploadedXslx) {
        payload['data_attachment'] = uploadedXslx?.originFileObj;
        if (uploadedZip)
          payload['image_attachment'] = uploadedZip?.originFileObj;
      }
      const formData = convertJSONToFormData(payload);

      ContentLibraryService.uploadBulkContentQuestions(
        formData,
        () => {
          message.success('Uploaded successfully!');
          setUploadedXslx(null);
          setUploadedZip(null);
          closeHandler();
        },
        (errors: any, row: any) => {
          setUploadErrors(errors);
          setErrorRow(row);
        },
        () => {
          setButtonLoading(false);
        }
      );
    } else {
      message.warn('Attach file!');
    }
  };

  const handleDownload = () => {
    convertJsonToXslx('questions.xlsx', 'Questions', [
      ExerciseXslxHeaders.EXERCISE_ID,
      ExerciseXslxHeaders.QUESTION_CONTENT,
      ExerciseXslxHeaders.QUESTION_IMAGE,
      ExerciseXslxHeaders.ONLY_FREE_TEXT,
      ExerciseXslxHeaders.OPTION1,
      ExerciseXslxHeaders.IMAGE_OPTION1,
      ExerciseXslxHeaders.OPTION2,
      ExerciseXslxHeaders.IMAGE_OPTION2,
      ExerciseXslxHeaders.OPTION3,
      ExerciseXslxHeaders.IMAGE_OPTION3,
      ExerciseXslxHeaders.OPTION4,
      ExerciseXslxHeaders.IMAGE_OPTION4,
      ExerciseXslxHeaders.CORRECT_ANSWER,
      ExerciseXslxHeaders.SOLUTION,
      ExerciseXslxHeaders.SOLUTION_IMAGE,
    ]);
  };

  return (
    <UIModal
      visible={visible}
      closeModal={() => {
        setUploadErrors([]);

        setUploadedXslx(null);
        setUploadedZip(null);
        closeHandler();
      }}
      closable={false}
      submitProps={{
        text: 'Submit',
        clickHandler: () => handleSubmit(),
        buttonLoading: buttonLoading,
      }}
      cancelProps={{
        text: 'Cancel',
        clickHandler: () => {
          setUploadErrors([]);

          setUploadedXslx(null);
          setUploadedZip(null);
          closeHandler();
        },
      }}
    >
      <>
        <p className='bulk-upload__title'>Bulk Upload (Exercise ID: {exerciseId}) </p>
        <div className='upload__wrapper'>
          <div className='upload__item'>
            <div className='file__type'>XLSX: </div>
            <Upload
              maxCount={1}
              beforeUpload={beforeUpload}
              onChange={handleChangeXlsx}
              accept={
                '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              }
            >
              <Button
                className='ant-btn ant-btn-primary'
                icon={<UploadOutlined />}
              >
                Click to Upload
              </Button>
            </Upload>
          </div>

          <div className='upload__item'>
            <div className='file__type'>ZIP: </div>
            <Upload
              maxCount={1}
              beforeUpload={beforeUpload}
              onChange={handleChangeZip}
              accept={
                'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'
              }
            >
              <Button
                className='ant-btn ant-btn-primary'
                icon={<UploadOutlined />}
              >
                Click to Upload
              </Button>
            </Upload>
          </div>
          <div className='upload__item'>
            <ButtonComponent
              type='text'
              iconLeft={<DownloadOutlined />}
              buttonContent='Template Excel'
              onClick={handleDownload}
            />
          </div>
        </div>
        <div className='info__note'>
          * ZIP is optional, upload only when you have images in xlsx.
        </div>
        {uploadErrors?.length ? (
          <div className='upload__errors'>
            {uploadErrors?.map((value, index) => {
              return (
                <p className='error__text'>
                  <span>
                    <ExclamationCircleOutlined />
                  </span>{' '}
                  {errorRow === 'no_row'
                    ? `${value?.questionId}: ${value?.errorMessage}`
                    : `row: ${errorRow}; ${value}`}
                </p>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </>
    </UIModal>
  );
};

export default BulkUpload;
