import React, { FC, useEffect } from "react";
import DefaultThumbnail from "../../../assets/images/video.jpeg";
import play from "../../../assets/icons/play.svg";
import "./videoCard.scss";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import { STUDY_MATERIAL } from "../../../routes/routeConstants/appRoutes";
import faker from "faker";
import ContentPlaceholder from "../../../assets/images/content-placeholder.svg"
interface VideoCardProps {
  icon?: any;
  subject?: string;
  color: string;
  thumbnail?: any;
  content?: string;
  id?: number;
  url?: string;
  contentType?: string;
  chapterName?: string;
  description?: string;
  contentLibrary?: any;
  chapterId?: number;
  subjectId?: number;
}

const VideoCard: FC<VideoCardProps> = ({
  icon,
  subject,
  color,
  thumbnail = "",
  content = "",
  id,
  description,
  contentLibrary,
  chapterId,
  contentType,
  subjectId,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const handlePlayVideo = () => {
    if (id) {
      const URL = generatePath(STUDY_MATERIAL, {
        ...params,
        subjectId,
        contentId: id,
        chapterId,
      });
      if(location.pathname.includes("learn") && location.pathname.includes("content")) {
        history.replace({
          pathname: URL,
          state: {
            ...rest,
            ...location.state as Object,
            contentId: id,
            chapterId,
            contentName: content,
            contentLibrary,
            description: description,
            subjectName: subject,
            chapterName: rest?.chapterName
          },
        });
      } else {
        history.push({
          pathname: URL,
          state: {
            ...rest,
            ...location.state as Object,
            contentId: id,
            chapterId,
            contentName: content,
            contentLibrary,
            description: description,
            subjectName: subject,
            chapterName: rest?.chapterName
          },
        });
      }
    }
  };

  return (
    <div className="video-card__container">
      <div className="thumbnail__wrapper" onClick={handlePlayVideo}>
        <img
          src={thumbnail ? thumbnail : ContentPlaceholder}
          alt=""
          className="thubnai-image"
        />
        {contentType == "video" && <img src={play} alt="" className="play" />}
      </div>
      <div className="note">
        {icon ? (
          <p className="subject" style={{ color }}>
            {" "}
            <img src={icon} /> {subject}
          </p>
        ) : null}
        <p className="content">{content}</p>
      </div>
    </div>
  );
};

export default VideoCard;
