import { Col, Row } from 'antd';
import React, { FC, useEffect } from 'react';
import './learn.scss';
import TitleContainer from '../../../store/container/TitleContainer';
import PlaceholderIcon from '../../../assets/images/placeholder-dark.svg';

import AuthContainer from '../../../store/container/AuthContainer';
import PageView from '../../../shared/components/PageView';
import { ClassRoomMetaModel } from '../../../models/meta.model';
import ListSubjects from './ListSubjects';
import { CHAPTERS } from '../../../routes/routeConstants/appRoutes';
import { Course } from '../../../models/Learn/Course/course.model';
import ContinueWhereYouLeft from '../ContinueWhereYouLeft';

interface LearnProps {
  currentClassRoom: ClassRoomMetaModel;
  user: any;
  setTitle: Function;
  currentCourse: Course | null;
  app_name: string
}

let LearnCard = ({ appName, ...rest }) => {
  try {
    return <div style={{ background: `url(${require(`../../../assets/${appName}/carousel-bg.svg`)}) no-repeat` }}  {...rest} />;
  }
  catch (err) {
    return <div style={{ background: `url(${require('../../../assets/images/carousel-bg.svg')}) no-repeat` }} {...rest} />;
  }
}

const Learn: FC<LearnProps> = ({ user, currentClassRoom, setTitle, app_name }) => {
  useEffect(() => {
    setTitle({
      title: `Learn`,
      showBack: false,
      subText: "You are doing good, Keep it up!",
      icon: null,
      showSearch: {
        placeholder: "Search subjects, topics ...",
        onChange: (value: string) => {}
      },
      float: false,
      searchText: "Search subjects, topics ...",
      twoBgs: false,
    });
  }, [setTitle]);

  return (
    <PageView className='no-bg-image'>
      <div className='learn__container'>
        <Row>
          <Col className='learn__carousel__wrapper' span={24}>
          <LearnCard appName={app_name} className="learn-card__container">
              <div className='content__left'>
                <div className='profile-image__container'>
                  <img src={user?.displayPictureUrl ?? PlaceholderIcon} alt='' />
                </div>
                <div className='profile-details__container'>
                  <p className='profile__name'>
                    Welcome <span>{`${user?.fullName}`}</span>
                  </p>
                  <p className='profile__class'>{currentClassRoom?.name}</p>
                </div>
              </div>
            </LearnCard>
          </Col>
        </Row>
      </div>
      <ListSubjects url={CHAPTERS} />
      <ContinueWhereYouLeft />
    </PageView>
  );
};

export default AuthContainer(TitleContainer(Learn));
