import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { ChapterContent } from '../../models/Learn/Chapters/chapter.model';
import { ContentAttemptModel } from '../../models/Learn/Content/content.model';
import { PaginationModel } from "../../models/pagination.model";
import { Question } from '../../models/Learn/Question/question.model';
import {
  CHAPTER_CONTENTS,
  COMPLETE_CONTENT_ATTEMPT,
  CONTENT_ATTEMPT_QUESTIONS,
  CONTENT_ATTEMPT_QUESTIONS_ANSWERS,
  CREATE_CONTENT_ATTEMPT,
  EXAM_CHAPTER_CONTENTS,
  PENDING_CHAPTER_CONTENTS,
  PENDING_EXAM_CHAPTER_CONTENTS,
  RESET_CONTENT_ATTEMPT,
  VIEW_CHAPTER_CONTENT,
  VIEW_CONTENT_ATTEMPT,
  VIEW_EXAM_CHAPTER_CONTENT,
  VIEW_UPDATE_CONTENT_ATTEMPT_QUESTION,
} from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { LocalStorage } from "../../shared/utils/LocalStorageHelpers";

export class ContentService {
  static getChapterContents(params: any, data: any, onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(
        generatePath(CHAPTER_CONTENTS, {
          courseId: data?.courseId,
          subjectId: data.subjectId,
          chapterId: data?.chapterId,
        }),
        {
          params: params
        }
      )
      .then((response) => {

        const contents = deserialize(
          ChapterContent,
          response["data"]["chaptor_contents"]
        );
        const meta = deserialize(
          PaginationModel,
          response["data"]["meta"]
        );
        onSuccess(contents, meta);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static getExamChapterContents(
    params: any,
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(
        generatePath(EXAM_CHAPTER_CONTENTS, {
          courseId: data?.courseId,
          chapterId: data?.chapterId,
        }),
        {
          params: params
        }
      )
      .then((response) => {
        const contents = deserialize(
          ChapterContent,
          response["data"]["exam_chapter_contents"]
        );
        const meta = deserialize(
          PaginationModel,
          response["data"]["meta"]
        );
        onSuccess(contents, meta);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static getPendingChapterContents(data: any, onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(
        generatePath(PENDING_CHAPTER_CONTENTS, {
          courseId: data?.courseId
        })
      )
      .then((response) => {
        const contents = deserialize(
          ChapterContent,
          response["data"]["chaptor_contents"]
        );
        onSuccess(contents);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static getPendingExamChapterContents(
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(
        generatePath(PENDING_EXAM_CHAPTER_CONTENTS, {
          courseId: data?.courseId
        })
      )
      .then((response) => {
        const contents = deserialize(
          ChapterContent,
          response["data"]["exam_chapter_contents"]
        );
        onSuccess(contents);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static createContentAttempt(
    data: any,
    onSuccess: (value: ContentAttemptModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(
        generatePath(CREATE_CONTENT_ATTEMPT, {
          studentCourseId: data.courseId,
          chapterId: data.chapterId,
          contentLibraryId: data.contentLibraryId,
        })
      )
      .then((response) => {
        const attempt = deserialize(
          ContentAttemptModel,
          response["data"]["content_exercise_attempt"]
        );
        onSuccess(attempt);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Not available",
        //   description: "Quick quiz isn't available!",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static getContentQuestionsWithAnswers(
    attemptId: any,
    courseId: any,
    onSuccess: (value: any) => void,
    onError: Function
  ) {
    return axiosInstance
      .get(
        generatePath(CONTENT_ATTEMPT_QUESTIONS_ANSWERS, {
          studentCourseId: courseId,
          contentAttemptId: attemptId,
        })
      )
      .then((response) => {
        const questions = deserialize(
          Question,
          response["data"]["content_exercise_attempt_questions"]
        );
        onSuccess(questions);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Cannot get results",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static getContentQuestions(
    attemptId: any,
    courseId: any,
    onSuccess: (value: any) => void,
    onError: Function
  ) {
    return axiosInstance
      .get(
        generatePath(CONTENT_ATTEMPT_QUESTIONS, {
          studentCourseId: courseId,
          contentAttemptId: attemptId,
        })
      )
      .then((response) => {
        const questions = deserialize(
          Question,
          response["data"]["content_exercise_attempt_questions"]
        );
        onSuccess(questions);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Cannot get questions",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static getContentAttemptQuestion(
    data: any,
    onSuccess: (question: Question) => void,
    onError: Function
  ) {
    return axiosInstance
      .get(
        generatePath(VIEW_UPDATE_CONTENT_ATTEMPT_QUESTION, {
          contentAttemptId: data.attemptId,
          contentAttemptQuestionId: data.questionId,
          studentCourseId: data.courseId,
        })
      )
      .then((response) => {
        const data = deserialize(
          Question,
          response["data"]["content_exercise_attempt_question"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Cannot get question",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static updateContentAttemptAnswer(
    data: any,
    answer: any,
    onSuccess: () => void,
    onError: Function
  ) {
    const payload = {
      content_exercise_attempt_question: answer,
    };
    return axiosInstance
      .put(
        generatePath(VIEW_UPDATE_CONTENT_ATTEMPT_QUESTION, {
          contentAttemptId: data.attemptId,
          contentAttemptQuestionId: data.questionId,
          studentCourseId: data.courseId,
        }),
        payload
      )
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
        Notification({
          message: "Failed",
          description: "Cannot update answer",
          type: NotificationTypes.ERROR,
        });
      });
  }
  static completeContentExam(
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .put(
        generatePath(COMPLETE_CONTENT_ATTEMPT, {
          contentAttemptId: data.attemptId,
          studentCourseId: data.courseId,
          contentLibraryId: data.contentLibraryId,
          chapterId: data.chapterId
        })
      )
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Cannot complete attempt",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static resetContentExam(
    data: any,
    onSuccess: (attempt: ContentAttemptModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .put(
        generatePath(RESET_CONTENT_ATTEMPT, {
          contentAttemptId: data.attemptId,
          studentCourseId: data.courseId,
          contentLibraryId: data.contentLibraryId,
          chapterId: data.chapterId
        })
      )
      .then((response) => {
        const attempt = deserialize(
          ContentAttemptModel,
          response["data"]["content_exercise_attempt"]
        );
        onSuccess(attempt);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Cannot retake quiz",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static getChapterContent(
    courseId: string | number,
    chapterId: number,
    contentId: number,
    onSuccess: (value: ChapterContent) => void,
    onError: Function
  ) {
    return axiosInstance
      .get(
        generatePath(VIEW_CHAPTER_CONTENT, {
          courseId,
          chapterId,
          contentId,
        })
      )
      .then((response) => {
        const data = deserialize(
          ChapterContent,
          response["data"]["tutor_chapter_content"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Cannot fetch content",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static getExamChapterContent(
    chapterId: number,
    contentId: number,
    onSuccess: (value: ChapterContent) => void,
    onError: Function
  ) {
    return axiosInstance
      .get(
        generatePath(VIEW_EXAM_CHAPTER_CONTENT, {
          courseId: LocalStorage.getItem("COURSE_ID"),
          chapterId,
          contentId,
        })
      )
      .then((response) => {
        const data = deserialize(
          ChapterContent,
          response["data"]["student_exam_chapter_content"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Cannot fetch content",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static updateChapterContent(
    data: any,
    chapterId: number,
    contentId: number,
    onSuccess: () => void,
    onError: Function
  ) {
    const serializeData = serialize(ChapterContent, data)
    const payload = { student_chaptor_content: serializeData };
    return axiosInstance
      .put(
        generatePath(VIEW_CHAPTER_CONTENT, {
          courseId: LocalStorage.getItem("COURSE_ID"),
          chapterId,
          contentId,
        }),
        payload
      )
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Cannot fetch content",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static updateExamChapterContent(
    data: any,
    chapterId: number,
    contentId: number,
    onSuccess: () => void,
    onError: Function
  ) {
    const serializeData = serialize(ChapterContent, data)
    const payload = { student_exam_chapter_content: serializeData };
    return axiosInstance
      .put(
        generatePath(VIEW_EXAM_CHAPTER_CONTENT, {
          courseId: LocalStorage.getItem("COURSE_ID"),
          chapterId,
          contentId,
        }),
        payload
      )
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Cannot fetch content",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static deleteContentExerciseAttempt(
    contentLibraryId: any,
    attemptId: any,
    courseId: any,
    chapterId: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .delete(
        generatePath(VIEW_CONTENT_ATTEMPT, {
          studentCourseId: courseId,
          contentLibraryId: contentLibraryId,
          contentAttemptId: attemptId,
          chapterId: chapterId
        })
      )
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  }
}
