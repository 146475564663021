import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/_axiosInstance";
import { WorksheetModel } from "../../models/worksheet.model";
import { PaginationModel } from "../../models/pagination.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class WorksheetService {
  static fetchWorksheets(
    queryParams: Object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.WORKSHEET_LIST, { params: queryParams })
      .then((response) => {
        const data = deserialize(WorksheetModel, response.data["worksheet"]) as WorksheetModel[];
        const pageMeta = deserialize(PaginationModel, response.data["meta"]);
        onSuccess(data, pageMeta);

      })
      .catch((error) => {
        onError(error);
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchWorksheetInfo(
    WorksheetId: Number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.WORKSHEET_INFO, { params: { WorksheetId } })
      .then((response) => {
        const data = deserialize(WorksheetModel, response.data) as WorksheetModel;
        onSuccess(data);
      })
      .catch((error) => {
        onError();
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchStudentWorkTurnInList(
    queryParams: Object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.STUDENT_WORK_TURNINLIST, { params: queryParams })
      .then((response) => {
        const data = deserialize(WorksheetModel, response.data["studentWorksheet"]) as WorksheetModel[];
        const pageMeta = deserialize(PaginationModel, response.data["meta"]);
        onSuccess(data, pageMeta);
      })
      .catch((error) => {
        onError();
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });

      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchStudentWorksheetAssignList(
    queryParams: Object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.STUDENT_WORK_ASSIGNLIST, { params: queryParams })
      .then((response) => {
        const data = deserialize(WorksheetModel, response.data["studentWorksheet"]) as WorksheetModel[];
        const pageMeta = deserialize(PaginationModel, response.data["meta"]);
        onSuccess(data, pageMeta);
      })
      .catch((error) => {
        onError();
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });

      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchStudentWorksheetInfo(
    queryParams: Object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.STUDENT_WORKSHEET_INFO, { params: queryParams })
      .then((response) => {
        const data = deserialize(WorksheetModel, response.data) as WorksheetModel;
        onSuccess(data);
      })
      .catch((error) => {
        onError();
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateStudentsWorkSheet(
    formData: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.UPDATE_STUDENT_SUBMISSION, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.data.isSucess) {
          Notification({ message: "Update", description: "Update successfully", type: NotificationTypes.SUCCESS });
        } else {
          Notification({ message: "Error", description: response.data.errorMessage, type: NotificationTypes.ERROR });
        }
        onSuccess("");
      })
      .catch((error) => {
        onError();
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }

  static createWorksheet(
    formData: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.CREATE_WORKSHEET, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.data.isSucess) {
          Notification({ message: "Create", description: "Worksheet create successfully", type: NotificationTypes.SUCCESS });
        } else {
          Notification({ message: "Error", description: response.data.errorMessage, type: NotificationTypes.ERROR });
        }
        onSuccess("");
      })
      .catch((error) => {
        onError();
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteWorksheet(
    WorksheetId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {

    axiosInstance
      .post(ApiRoutes.DELETE_WORKSHEET, null, {
        params: {WorksheetId}
      })
      .then((response) => {
        if (response.data.isSucess) {
          Notification({ message: "Delete", description: "Worksheet delete successfully", type: NotificationTypes.SUCCESS });
        } else {
          Notification({ message: "Error", description: response.data.errorMessage, type: NotificationTypes.ERROR });
        }
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
