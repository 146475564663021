import AgoraRTC, {
  IAgoraRTCRemoteUser,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from "agora-rtc-sdk-ng";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  useClient,
  useMicrophoneAndCameraTracks,
  useMicrophoneTrack,
} from "..";
import { JoinLiveClassResponseModel } from "../../../../models/liveClass.model";
import { UserModel } from "../../../../models/user.model";
import AuthContainer from "../../../../store/container/AuthContainer";
import Controls from "../Controls";
import LiveClassInfo from "../LiveClassInfo";
import LiveClassParticipants from "../LiveClassParticipants";
import Videos from "../Videos";
import "./videoCall.scss";
import AgoraRTM, { RtmChannel } from "agora-rtm-sdk";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";

interface VideoCallProps {
  leaveCall: () => void;
  channel: JoinLiveClassResponseModel;
  user: UserModel;
}

const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID;

const VideoCall: FC<VideoCallProps> = (props) => {
  const { leaveCall, channel, user } = props;
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const usersRef = useRef(users);
  const [start, setStart] = useState<boolean>(false);
  const client = useClient();
  const videoTrack = useMicrophoneAndCameraTracks();
  const audioTrack = useMicrophoneTrack();
  const [showParticipants, setParticipantsVisibility] = useState(false);
  const [showInfo, setInfoVisibility] = useState(false);
  const [screenTrack, setScreenTrack] = useState<ILocalVideoTrack>();
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [trackState, setTrackState] = useState({
    video: true,
    audio: true,
  });
  const rtmClient = AgoraRTM.createInstance(AGORA_APP_ID ?? "");
  const [rtmChannel, setRtmChannel] = useState<RtmChannel>();
  useEffect(() => {
    let init = async (channel: JoinLiveClassResponseModel) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === "video") {
          let userIndex = usersRef.current?.findIndex(
            (User) => User?.uid == user?.uid
          );
          if (userIndex > -1) {
            let usersHolder = usersRef.current?.map((User) => User);
            usersHolder[userIndex] = user;
            setUsers(usersHolder);
          } else {
            setUsers([...usersRef.current, user]);
          }
        }
        if (mediaType === "audio") {
          let userIndex = usersRef.current?.findIndex(
            (User) => User?.uid == user?.uid
          );
          if (userIndex > -1) {
            user.audioTrack?.play();
            setUsers((prevUsers) => {
              return prevUsers.map((User) =>
                User.uid == user.uid ? user : User
              );
            });
          } else {
            user.audioTrack?.play();
            setUsers([...usersRef.current, user]);
          }
        }
      });

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
          setUsers((prevUsers) => {
            return prevUsers.map((User) =>
              User.uid == user.uid ? user : User
            );
          });
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.map((User) =>
              User.uid == user.uid ? user : User
            );
          });
        }
      });

      client.on("user-left", (user) => {
        console.log("leaving", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      if (
        AGORA_APP_ID &&
        channel?.channelName &&
        channel?.token &&
        channel?.rtmTokenUid &&
        channel?.rtmToken
      ) {
        await client.join(
          AGORA_APP_ID,
          channel?.channelName,
          channel?.token,
          user?.fullName?.replace(/ /gi, "-") + "-" + user?.id + "-host"
        );

        await rtmClient
          .login({
            uid: channel?.rtmTokenUid,
            token: channel?.rtmToken,
          })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });

        let rtmChannelHolder = rtmClient.createChannel(channel?.channelName);
        rtmChannelHolder?.join();
        setRtmChannel(rtmChannelHolder);
      }
    };

    if (
      (videoTrack?.ready && videoTrack?.tracks) ||
      (audioTrack?.ready && audioTrack?.track)
    ) {
      console.log("init ready");
      init(channel);
    }
  }, [
    channel,
    client,
    videoTrack?.ready,
    audioTrack?.ready,
    videoTrack?.tracks,
    audioTrack?.track,
  ]);

  useEffect(() => {
    if (videoTrack?.tracks) {
      client.publish([videoTrack?.tracks[0], videoTrack?.tracks[1]]);
      setStart(true);
    } else {
      if (audioTrack?.track) client.publish(audioTrack?.track);
      setStart(true);
    }
  }, [videoTrack, audioTrack]);

  const handleParticipants = () => {
    setParticipantsVisibility((visible) => !visible);
  };

  const handleInfo = () => {
    setInfoVisibility((visible) => !visible);
  };

  console.log(client);
  const handleScreenShare = () => {
    if (screenTrack) {
      client.unpublish(screenTrack);
      if (videoTrack?.tracks) client.publish([videoTrack?.tracks[1]]);
      else if (audioTrack?.track) client.publish(audioTrack?.track);
      else return;
      screenTrack?.close();
      setIsScreenSharing(false);
      setScreenTrack(undefined);
    } else {
      AgoraRTC.createScreenVideoTrack({
        encoderConfig: "1080p_1",
        optimizationMode: "detail",
      }).then((localScreenTrack) => {
        if (videoTrack?.tracks) client.unpublish(videoTrack?.tracks[1]);
        client.publish(localScreenTrack);
        if (localScreenTrack && !Array.isArray(localScreenTrack)) {
          setIsScreenSharing(true);
          setScreenTrack(localScreenTrack);
        }
      });
    }
  };

  const handleMuteAll = (value: "mute" | "unmute") => {
    rtmChannel
      ?.sendMessage({ text: value })
      .then((value) => {
        console.log("sent");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLeave = () => {
    rtmChannel
      ?.sendMessage({ text: "leave_channel" })
      .then((value) => {
        console.log("sent");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    screenTrack?.on("track-ended", () => {
      setIsScreenSharing(false);
      setScreenTrack(undefined);
    });
  }, [screenTrack]);

  return (
    <div className="video-call">
      {start && (videoTrack?.tracks || audioTrack?.track) && (
        <Videos
          isParticipantsVisible={showParticipants}
          isInfoVisible={showInfo}
          users={users}
          videoTrack={videoTrack?.tracks}
          audioTrack={audioTrack?.track}
          client={client}
        />
      )}
      {((videoTrack?.ready && videoTrack?.tracks) ||
        (audioTrack?.ready && audioTrack?.track)) && (
        <Controls
          users={users}
          videoTrack={videoTrack?.tracks}
          audioTrack={audioTrack?.track}
          callType={videoTrack?.ready && videoTrack?.tracks ? "video" : "audio"}
          setStart={setStart}
          leaveHandler={() => {
            leaveCall();
            handleLeave();
            rtmChannel?.leave();
            rtmClient?.logout();
          }}
          participantsHandler={handleParticipants}
          infoHandler={handleInfo}
          shareScreenHandler={handleScreenShare}
          isScreenSharing={isScreenSharing}
          clientControlsHandler={(controls) => {
            setTrackState(controls);
          }}
          muteAllHandler={(value) => handleMuteAll(value)}
        />
      )}

      <LiveClassParticipants
        visible={showParticipants}
        closeHandler={() => setParticipantsVisibility(false)}
        users={users}
        client={client}
        trackState={trackState}
        isAudioOnly={audioTrack?.track && !videoTrack?.tracks ? true : false}
      />
      <LiveClassInfo
        visible={showInfo}
        closeHandler={() => setInfoVisibility(false)}
      />
    </div>
  );
};

export default AuthContainer(VideoCall);
