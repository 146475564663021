import React, { FC, useEffect, useState } from "react";
import "./classProgress.scss";
import faker from "faker";
import { serialize } from "serializr";

import { ClassProgressModel, DashboardProgressFilterModel } from "../../../models/dashboard.model";
import DashboardService from "../../../services/DashboardService/dashboard.service";
import { Button, Skeleton, Table } from "antd";
import { SkeletonLoader } from "../../LiveClass";
import DashboardFilter from "../DashboardFilter/progressFilter";
import { UserModel } from "../../../models/user.model";
import AuthContainer from "../../../store/container/AuthContainer";


interface ClassProgressProps {
  user: UserModel;
}

const ClassProgress: FC<ClassProgressProps> = (props) => {
  const { user } = props;

  const defaultFilter = {
    SectionId: 0,
    ClassId: 0,
    StartDate: '0',
    EndDate: '0',
  }

  const [classProgress, setClassProgress] = useState<ClassProgressModel[]>([]);
  const [filters, setFilters] = useState<DashboardProgressFilterModel>({});
  const [loading, setLoading] = useState(false);

  const fetchClassProgress = (params?: DashboardProgressFilterModel) => {
    setLoading(true);
    const payload = serialize(DashboardProgressFilterModel, params ?? defaultFilter);
    DashboardService.fetchClassProgress(
      payload,
      (liveClasses) => {
        setClassProgress(liveClasses);
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  };
  
  useEffect(() => {
    if (user?.tutorClassRooms) {
      let classId = Number(user?.tutorClassRooms[0]?.id)
      setFilters({ ...defaultFilter, ClassId: classId ?? 0 });
      fetchClassProgress({ ...defaultFilter, ClassId: classId ?? 0 })
    }
    // fetchClassProgress();
  }, []);

  // Download Report 
  const handleDownload = (params?: any) => {
    setLoading(true)
    const payload = serialize(DashboardProgressFilterModel, params ?? defaultFilter);
    DashboardService.downloadClassProgress(
      payload,
      (contents) => { },
      () => { },
      () => { setLoading(false) }
    );
  }
  return loading ? (
    <SkeletonLoader />
  ) : classProgress ? (
    <div className="upcoming-classes">
      <div className="upcoming-classes__header">
        <div className="upcoming-classes__left">
          <div className="upcoming-classes__title">Class Progress</div>
          <DashboardFilter
            applyHandler={(values) => {
              setFilters({ ...defaultFilter, ...values });
              fetchClassProgress({ ...defaultFilter, ...values })

            }}
            clearHandler={() => {
              setFilters({});
              fetchClassProgress()
            }}
          />
        </div>

        <div>
          <Button onClick={() => handleDownload(filters)}>Download Report</Button>
        </div>
      </div>
      {classProgress?.length ? (
        <Table
          bordered

          dataSource={classProgress}
          columns={[
            {
              title: 'Name',
              dataIndex: 'studentName',
              key: 'name',
            },
            {
              title: 'Class',
              dataIndex: 'className',
              key: 'className',
            },
            {
              title: 'Section',
              dataIndex: 'sectionName',
              key: 'sectionName',
            },
            {
              title: 'Content Watched',
              dataIndex: ['contentWatched', 'contentActive'],
              key: 'contentWatched',
              render: (text, row) => <div>{`${row['contentWatched']} / ${row['contentActive']}`}</div>,

            },
            {
              title: 'Attempted Quiz',
              dataIndex: ['quizAttempted', 'quizActive'],
              render: (text, row) => <div>{`${row['quizAttempted']} / ${row['quizActive']}`}</div>,
              key: 'quizAttempted',
            },
            {
              title: 'Excercise',
              dataIndex: ['excerciseAttempted', 'excerciseActive'],
              render: (text, row) => <div>{`${row['excerciseAttempted']} / ${row['excerciseActive']}`}</div>,
              key: 'excerciseAttempted',
            },
            {
              title: 'Activity',
              dataIndex: ['activityAttempted', 'activityActive'],
              render: (text, row) => <div>{`${row['activityAttempted']} / ${row['activityActive']}`}</div>,
              key: 'activityAttempted',
            },
            {
              title: 'Points',
              dataIndex: ['points'],
              key: 'points',
            },
          ]}
          pagination={{ defaultPageSize: 10 }}
        />
      ) : (
        <div className="no-upcoming-classes-text">No Class Progress !</div>
      )}
    </div>
  ) : (
    <div />
  );
};

export default AuthContainer(ClassProgress);
