import React, { useEffect } from "react";
import PageView from "../../shared/components/PageView";
import StudentActivities from "./StudentActivities";
import UpcomingClasses from "./UpcomingClasses";
import ClassProgress from "./ClassProgress";
import TitleContainer from '../../store/container/TitleContainer';

interface DashboardProps {
  setTitle: Function;
}


const Dashboard = (props: DashboardProps) => {
  useEffect(() => {
    props.setTitle({
      title: `Learn`,
      showBack: false,
      subText: "",
      icon: null,
      showSearch: false,
      float: false,
      searchText: "Search subjects, topics ...",
      twoBgs: false,
    });
  }, [props]);
    return (
        <PageView title="Dashboard">
            <StudentActivities/>
            <UpcomingClasses />
            <ClassProgress/>
        </PageView>
    )
}

export default TitleContainer(Dashboard);
