import { message, Upload } from "antd";
import { Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import { UserModel, UserUpdateModel } from "../../../models/user.model";
import InputField from "../../../shared/components/InputField";
import UIModal from "../../../shared/components/UIModal";
import getBase64 from "../../../shared/utils/getBase64";
import "./profileForm.scss";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import UserService from "../../../services/UserService/user.service";
import { serialize } from "serializr";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import { UploadFile } from "antd/lib/upload/interface";

interface ProfileFormProps {
  visible: boolean;
  closeHandler: () => void;
  data: UserModel;
}

const ProfileForm: FC<ProfileFormProps> = (props) => {
  const {} = props;

  const { visible, closeHandler, data } = props;
  const [initialValues, setInitialValues] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    displayPictureUrl: "",
  });
  const formRef = useRef<any>();
  const [pictureLoading, setPictureLoading] = useState(false);

  useEffect(() => {
    setInitialValues({
      fullName: data?.fullName ?? "",
      phoneNumber: data?.phoneNumber ?? "",
      email: data?.email ?? "",
      displayPictureUrl: data?.displayPictureUrl ?? "",
    });
  }, [data]);

  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  };

  const onSubmit = (values: FormikValues) => {
    let payload = serialize(UserUpdateModel, values);
    if (values?.displayPicture) {
      payload["display_picture"] = values?.displayPicture;
    }
    
    
    let formData = convertJSONToFormData({ tutor: payload });
    UserService.updateProfileDetails(
      formData,
      () => {
        closeHandler();
      },
      () => {},
      () => {}
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, setFieldValue, resetForm, handleSubmit, errors }) => (
        <UIModal
          visible={visible}
          closeModal={closeHandler}
          onClosed={resetForm}
          submitProps={{
            text: "Update",
            type: "submit",
            clickHandler: () => {
              handleSubmit();
            },
          }}
          cancelProps={{
            text: "Cancel",
            clickHandler: closeHandler,
          }}
          className="profile-form"
        >
          <Form>
            <div className="profile-form__header">
              <div className="profile-form__title">{"Edit Profile"}</div>
            </div>
            <div className="profile-form__input">
              {values?.displayPictureUrl ? (
                <div className="profile-form-display-picture__container">
                  <img src={values?.displayPictureUrl} />
                  <div
                    className="remove__btn"
                    onClick={() => {
                      setFieldValue("displayPictureUrl", "");
                      setFieldValue("displayPicture", null);
                    }}
                  >
                    Remove
                  </div>
                </div>
              ) : (
                <Upload
                  showUploadList={false}
                  name="avatar"
                  listType="picture-card"
                  beforeUpload={beforeUpload}
                  accept="image/*"
                  onChange={({file}: {file: UploadFile}) => {
                    if (file.status === "uploading") {
                      setPictureLoading(true);
                      return;
                    }
                    if (file.originFileObj) {
                      setFieldValue("displayPicture", file.originFileObj);
                      getBase64(file.originFileObj, (imageUrl) => {
                        setFieldValue("displayPictureUrl", imageUrl);
                      });
                      setPictureLoading(false);
                    }
                  }}
                  className="profile-form-display-picture__upload"
                >
                  <div>
                    {pictureLoading ? <LoadingOutlined /> : <UploadOutlined />}
                    <div className="upload__text">Upload</div>
                  </div>
                </Upload>
              )}
              <div className=""></div>
            </div>
            <div className="profile-form__input">
              <div className="profile-form__label">Name</div>
              <InputField type="text" name="fullName" placeholder="Type Name" />
            </div>
            <div className="profile-form__input">
              <div className="profile-form__label">Phone number</div>
              <InputField
                type="text"
                name="phoneNumber"
                placeholder="Type Phone number"
              />
            </div>
            <div className="profile-form__input">
              <div className="profile-form__label">Email ID</div>
              <InputField
                type="text"
                name="email"
                placeholder="Type Email ID"
              />
            </div>
          </Form>
        </UIModal>
      )}
    </Formik>
  );
};

export default ProfileForm;
