import React, { FC, useRef, useState } from "react";
import { Field, ErrorMessage } from "formik";
import { Col, Input, Row } from "antd";
import Error from "../Error";
import { PaperClipOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "./inputField.scss";

const { TextArea } = Input;
const BASE_URL = process.env.REACT_APP_BASE_URL
interface InputFieldProps {
  title?: string;
  type: string;
  name: string;
  placeholder: string;
  prefix?: string;
  rows?: number;
  suffix?: string;
  className?: string;
  disabled?: boolean;
  attachment?: boolean;
  onAttach?: (value: any, list?: any[]) => void;
  multipleAttachments?: boolean;
  data?: any;
  handleDelete?: (index?: number) => void;
  maxLength?: number;
  style?: object,
  showCount?: boolean;
}

const InputField: FC<InputFieldProps> = ({
  title,
  className,
  disabled = false,
  attachment,
  data,
  handleDelete,
  multipleAttachments = false,
  ...rest
}) => {
  const attachmentRef = useRef<HTMLInputElement>(null);
  const { name, type, onAttach } = rest;
  return (
    <div className={`input-field ${className}`}>
      {multipleAttachments ? (
        <Row>
          {data?.map((value: any, index: number) => {
            return (
              <Col span={7}>
                <div className="attached-image__container">
                  <CloseCircleOutlined
                    className="attached-image__close"
                    onClick={() => {
                      handleDelete && handleDelete(index);
                    }}
                  />
                  <img src={value.questionAttachmentUrl || value.questionSolutionAttachmentUrl} className="attached__image" />
                </div>
              </Col>
            );
          })}
        </Row>
      ) : (
        attachment &&
        data && (
          <div className="attached-image__container">
            <CloseCircleOutlined
              className="attached-image__close"
              onClick={() => handleDelete && handleDelete()}
            />
            <img src={data?.questionOptionImageUrl} className="attached__image" />
          </div>
        )
      )}
      <input
        ref={attachmentRef}
        accept="image/*"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          let file = e.target.files?.length && e.target.files[0];
          onAttach && onAttach(file);

        }}
      />
      <label htmlFor={name}>{title}</label>
      <Field
        as={
          type === 'textarea'
            ? TextArea
            : type === "password" ? Input.Password : Input
        }
        prefix={
          multipleAttachments || (attachment && !data) ? (
            <PaperClipOutlined
              className="input-clip__icon"
              onClick={() => attachmentRef.current?.click()}
            />
          ) : (
            ""
          )
        }
        disabled={disabled}
        {...rest}
      />
      <ErrorMessage name={name}>
        {(message: string) => <Error message={message} />}
      </ErrorMessage>
    </div>
  );
};

export default InputField;
