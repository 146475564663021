import React, { useEffect, useRef, useState } from "react";
import { generatePath, withRouter } from "react-router-dom";

import { Skeleton } from "antd";
import { Field, Form, Formik } from "formik";
import EmptyState from "../../shared/components/EmptyState";
import PageView from "../../shared/components/PageView";
import Filters from "../../shared/components/Filters";
import DropdownField from "../../shared/components/DropdownField";
import PaginationComponent from "../../shared/components/PaginationComponent";

import AnnouncementForm from "./AnnouncementForm";
import AnnouncementCard from "./AnnouncementCard";

import { FilterWorksheet, AnnouncementModel } from "../../models/Announcement.model";
import AnnouncementService from "../../services/AnnouncementService/Announcement.service";

import { MetaModel } from "../../models/meta.model";
import { MetaService } from "../../services/MetaService/meta.service";



import "./announcement.scss";
import { ANNOUNCEMENT_VIEW } from "../../routes/routeConstants/appRoutes";

export const SkeletonLoader = (): any => {
  return Array(2)
    .fill(" ")
    .map((value) => {
      return (
        <div className="skeleton-loader">
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        </div>
      );
    });
};
const Announcement = (props: { history: any }) => {
  const { history } = props;

  const [announcements, setAnnouncements] = useState<AnnouncementModel[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<any>({});
  const [loadingAnnouncements, setLoadinAnnouncements] = useState(false);

  const [currentAnnouncement, setCurrentAnnouncement] = useState<AnnouncementModel>();
  const [showForm, setFormVisibility] = useState(false);
  const [isDeleleAlertShown, setIsDeleleAlertShown] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const formRef = useRef<any>();
  const [showFilter, setFilterVisibility] = useState(false);

  const defaultFilter = { CurrentPage: 1, Pagesize: 10 }
  const [filters, setFilters] = useState<FilterWorksheet>(defaultFilter);
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  const [sections, setSections] = useState<MetaModel[]>([]);


  const fetchAnnouncements = (queryParams: FilterWorksheet) => {
    setLoadinAnnouncements(true);
    AnnouncementService.fetchAnnouncements(
      queryParams,
      (announcements: any, pageMeta: any) => {
        setAnnouncements(announcements);
        setPaginationMeta(pageMeta);
      },
      () => { },
      () => {
        setLoadinAnnouncements(false);
      }
    );
  };
  useEffect(() => {
    fetchAnnouncements(filters);
    fetchClassRooms()
  }, []);

  const refreshHandler = () => {
    fetchAnnouncements(filters);
  }

  const handlePageChange = (page: number, pageSize: any) => {
    fetchAnnouncements({ ...filters, CurrentPage: page, Pagesize: pageSize });
    setFilters({ ...filters, CurrentPage: page, Pagesize: pageSize });
  };

  const fetchClassRooms = () => {
    MetaService.fetchClassRoomsMeta(
      (classRooms) => {
        if (classRooms.length) {
          setClassRooms(classRooms?.map((value) => ({ label: value?.name, value: value?.id })));
          fetchAnnouncements({ ...filters, classId: classRooms[0].id });
          setFilters({ ...filters, classId: classRooms[0].id })
          fetchSections(classRooms[0].id ?? 0)
        } else {
          fetchAnnouncements(filters);
        }
        // setClassRooms(
        //   classRooms?.map((value) => ({ label: value?.name, value: value?.id }))
        // );
      },
      () => { },
      () => { }
    );
  };

  const fetchSections = (classRoomId: number) => {
    MetaService.fetchSectionsMeta(
      classRoomId,
      (sections) => {
        setSections(sections);
      },
      () => { },
      () => { }
    );
  };





  return (
    <PageView
      title="Messages"
      className="announcement-class"
      showAdd
      addHandler={() => { setFormVisibility(true); }}
      showFilter={true}
      handleFilter={() => setFilterVisibility(true)}
    >
      <AnnouncementForm
        closeHandler={() => setFormVisibility(false)}
        visible={showForm}
        refreshList={refreshHandler}
      />

      {loadingAnnouncements || announcements?.length ? (
        <div className="announcement-class-announcement-now">

          {loadingAnnouncements ? (
            <SkeletonLoader />
          ) : (<>
            <h3>Latest Post</h3>
            {announcements?.map((record, index) => {
              return (
                <AnnouncementCard
                  refreshList={refreshHandler}
                  key={record.announcementId}
                  data={record}
                  onClick={() => {
                    history.push(
                      generatePath(ANNOUNCEMENT_VIEW, {
                        announcementId: record?.announcementId,
                      }),
                      record
                    )
                  }}
                />
              );
            })}
            <div className="pagination__footer">
              <PaginationComponent
                handlePageChange={handlePageChange}
                paginationMeta={paginationMeta}
              />
            </div>
          </>
          )
          }
        </div>
      ) : (
        <EmptyState text={"No Messages found."} />
      )}

      <Formik
        initialValues={filters}
        onSubmit={() => { }}
        enableReinitialize
        innerRef={formRef}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Filters
            expand={showFilter}
            handleApply={() => {
              fetchAnnouncements(values)
              setFilters(values)
              setFilterVisibility(false)
            }}
            handleReset={() => {
              resetForm({});
              setFilterVisibility(false);
              fetchClassRooms()
              //fetchAnnouncements(defaultFilter);
            }}
            closeHandler={() => setFilterVisibility(false)}
          >
            <Form>
              <div className="filter-form__input">
                <div className="filter-form__label">Class</div>
                <DropdownField
                  name="classId"
                  mode="single"
                  placeholder="Select"
                  value={values?.classId}
                  options={classRooms}
                  showSearch={false}
                  optionFilterProp="label"
                  onChange={(value) => {
                    setFieldValue("classId", value);
                    fetchSections(value);
                  }}
                />
              </div>
              <div className="filter-form__input">
                <div className="filter-form__label">Section</div>
                <DropdownField
                  name="sectionId"
                  mode="single"
                  placeholder="Select"
                  value={values?.sectionId}
                  options={sections}
                  showSearch={false}
                  optionFilterProp="label"
                  onChange={(value) => {
                    setFieldValue("sectionId", value);
                  }}
                />
              </div>
            </Form>
          </Filters>
        )}
      </Formik>


    </PageView>
  );
};

export default withRouter(Announcement);
