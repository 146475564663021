import * as Yup from  "yup";

export const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email is not valid!')
        .required('Email is required!'),
    password: Yup.string()
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required('Password is required!')
})
