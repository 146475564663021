import React, {FC} from "react";
import './emptyState.scss';
import emptyStateImage from "../../../assets/placeholder/empty-state.svg";

interface EmptyStateProps{
    text: string;
}

const EmptyState:FC<EmptyStateProps> = ({text}) => {
    return (
        <div className='empty-state'>
            <img src={emptyStateImage} alt='' className='empty-state__image'/>
            <div className='empty-state__message'>{text}</div>
        </div>
    )
}

export default EmptyState;
