import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./LoginValidation";
import UserService from "../../../services/AuthService/auth.service";
import { withRouter } from "react-router-dom";
import "../auth.scss";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { LoginCredentials } from "../../../models/user.model";
import { message } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import PasswordResetLink from "../PasswordResetLink";
import { useHistory } from "react-router-dom";

interface User {
  email: string;
  password: string;
}

const initialValue = {
  email: "",
  password: "",
};

const LoginForm = (props: any) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const formRef = useRef<any>(null);
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const history = useHistory();

  const onSubmit = (user: User) => {
    setButtonLoading(true);
    let data: LoginCredentials = {
      ...user,
      grantType: "password",
    };

    UserService.loginUser(
      data,
      (tutor) => {
        props.history.push(appRoutes.DASHBOARD)
        // if (tutor?.confirmedAt) props.history.push(appRoutes.DASHBOARD);
        // else {
        //   AuthService.sendPasswordResetEmail(
        //     {
        //       email: user?.email,
        //       redirectUrl: `${window.location.origin}${appRoutes.RESET_PASSWORD}`,
        //     },
        //     () => {
        //       formRef?.current?.resetForm({ values: {} });
        //       setEmail(user?.email)
        //       setStep(1);
        //       // message.success(`Password Reset Link is sent to ${user?.email}`);
        //     },
        //     () => {},
        //     () => {}
        //   );
        // }
      },
      (error: Error) => {
        console.log(error.message);
      },
      () => {
        setButtonLoading(false);
      }
    ).then();
  };

  return step ? (
    <PasswordResetLink
      email={email}
      wrongHandler={() => {
        setStep(0);
        setEmail("");
      }}
      loginHandler={() => {
        history.push(appRoutes.LOGIN);
      }}
    />
  ) : (
    <div className="auth__form">
      <h2 className="auth__header">Tutor Login</h2>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        <Form>
          <div className="auth__input">
            <InputField type="email" name="email" placeholder="Email ID" />
          </div>
          <div className="auth__input">
            <InputField
              type="password"
              name="password"
              placeholder="Password"
            />
          </div>
          <div
            className="auth__option"
            onClick={() => props.history.push(appRoutes.FORGOT_PASSWORD)}
          >
            Forgot Password?
          </div>
          <div className="auth__action">
            <ButtonComponent
              buttonContent="Login"
              type="primary"
              htmlType="submit"
              loading={buttonLoading}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default withRouter(LoginForm);
