import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./ResetPasswordValidation";
import UserService from "../../../services/AuthService/auth.service";
import { withRouter, useLocation } from "react-router-dom";
import "../auth.scss";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { UpdatePasswordModel } from "../../../models/user.model";

interface User {
  password: string;
  confirmPassword: string;
}

const initialValue = {
  password: "",
  confirmPassword: "",
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = (props: any) => {
  const query = useQuery();
  const formRef = useRef<any>(null)

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const onSubmit = (user: User) => {
    setButtonLoading(true);

    let data: UpdatePasswordModel = {
      email: query.get("email")?.replace(/ /gi, "+") ?? "",
      resetPasswordToken: query.get("reset_password_token") ?? "",
      newPassword: user?.password,
    };
    UserService.resetPassword(
      data,
      () => {
        formRef?.current?.resetForm({values: {}})
        return props.history.push(appRoutes.LOGIN);
      },
      (error: Error) => {
        console.log(error.message);
      },
      () => {
        setButtonLoading(false);
      }
    ).then();
  };
  
  return (
    <div className="auth__form">
      <h2 className="auth__header">Reset Password</h2>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        <Form>
          <div className="auth__input">
            <InputField
              type="password"
              name="password"
              placeholder="New Password"
            />
          </div>
          <div className="auth__input">
            <InputField
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
            />
          </div>
          <div className="auth__action reset-password__action">
            <ButtonComponent
              buttonContent="Update"
              type="primary"
              htmlType="submit"
              loading={buttonLoading}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default withRouter(ResetPassword);
