import { ExcerciseAttempt } from '../../models/Learn/Exercise/exercise.model';
import { Question } from '../../models/Learn/Question/question.model';
import { SET_QUESTIONS, SET_EXAM_ATTEMPT, SET_CURRENT_QUESTION, SET_VISITED, SET_MARK_REVIEW, UPDATE_QUESTION } from "../definitions/questionConstant";

export const setQuestions = (questions: Question[]) => {
    return {
        type: SET_QUESTIONS,
        payload: questions
    }
}

export const setExamAttempt = (examAttempt: ExcerciseAttempt[]) => {
    return {
        type: SET_EXAM_ATTEMPT,
        payload: examAttempt
    }
}

export const setCurrentQuestion = (currentQuestion: number) => {
    return {
        type: SET_CURRENT_QUESTION,
        payload: currentQuestion
    }
}

export const setVisitedQuestion = (questionIndex: number) => {
    return {
        type: SET_VISITED,
        payload: questionIndex
    }
}

export const setMarkedQuestion = (questionIndex: number) => {
    return {
        type: SET_MARK_REVIEW,
        payload: questionIndex
    }
}
export const updateQuestion = (question: Question) => {
    return {
        type: UPDATE_QUESTION,
        payload: question
    }
}
