import React from 'react';
import { Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as appRoutes from './routeConstants/appRoutes';
import AuthWrapper from '../views/Auth/AuthWrapper';
import requiredAuth from '../shared/components/HOC/requireAuth';
import Queries from '../views/Queries';
import Dashboard from '../views/Dashboard';
import Assessment from '../views/Assessment';
import LiveClass from '../views/LiveClass';
import ChangePassword from '../shared/components/Navbar/ChangePassword';
import Sidebar from '../shared/components/Sidebar';
import Profile from '../views/Profile';
import LiveClassMeeting from '../views/LiveClass/LiveClassMeeting';

import Activity from '../views/Activity';
import ActivityDetails from '../views/Activity/ActivityDetails';
import ActivityTurned from '../views/Activity/ActivityTurnedLists';
import ActivityAssigned from '../views/Activity/AssignedList';
import ActivityTurnedDetails from '../views/Activity/ActivityTurnedDetails';

import ActivityReport from '../views/Reports/ActivityReport';
import LoginReport from '../views/Reports/LoginReport';
import ChapterwiseReport from '../views/Reports/ChapterwiseReport';

import Announcement from '../views/Announcement';
import AnnouncementDetails from '../views/Announcement/AnnouncementDetails';
import Learn from '../views/LearnModule/Learn';
import ListChapters from '../views/LearnModule/ListChapters';
import Chapter from '../views/LearnModule/ListChapterContents';
import StudyMaterial from '../views/LearnModule/StudyMaterial';
import ExerciseExam from '../views/LearnModule/PracticeModule/ExerciseExam';
import ExerciseExamResult from '../views/LearnModule/PracticeModule/ExerciseExamResult';

export const appHistory = createBrowserHistory();

const AppRoutes = () => {
  const isAuthenticated = (component: any) => {
    return requiredAuth(component);
    //return component;
  };

  const routes = [
    { exact: false, path: appRoutes.AUTH, component: AuthWrapper },
    {
      exact: true,
      path: appRoutes.QUERIES,
      component: isAuthenticated(Queries),
    },
    {
      exact: true,
      path: appRoutes.LEARN,
      component: isAuthenticated(Learn),
    },
    {
      path: appRoutes.QUICK_QUIZ,
      component: isAuthenticated(ExerciseExam),
      exact: true,
    },
    {
      path: appRoutes.QUICK_QUIZ_RESULTS,
      component: isAuthenticated(ExerciseExamResult),
      exact: true,
    },
    {
      path: appRoutes.CHAPTERS,
      component: isAuthenticated(ListChapters),
      exact: true,
    },
    {
      path: appRoutes.CHAPTER,
      component: isAuthenticated(Chapter),
      exact: true,
    },
    {
      path: appRoutes.STUDY_MATERIAL,
      component: isAuthenticated(StudyMaterial),
      exact: true,
    },
    {
      exact: true,
      path: appRoutes.DASHBOARD,
      component: isAuthenticated(Dashboard),
    },
    {
      exact: true,
      path: appRoutes.ASSESSMENT,
      component: isAuthenticated(Assessment),
    },
    {
      exact: true,
      path: appRoutes.LIVE_CLASS,
      component: isAuthenticated(LiveClass),
    },
    {
      exact: true,
      path: appRoutes.CHANGE_PASSWORD,
      component: isAuthenticated(ChangePassword),
    },
    {
      exact: true,
      path: appRoutes.MY_PROFILE,
      component: isAuthenticated(Profile),
    },
    {
      exact: true,
      path: appRoutes.LIVE_CLASS_MEETING,
      component: isAuthenticated(LiveClassMeeting),
    },
    {
      exact: true,
      path: appRoutes.ACTIVITY,
      component: isAuthenticated(Activity),
    },
    {
      exact: true,
      path: appRoutes.ACTIVITY_VIEW,
      component: isAuthenticated(ActivityDetails),
    },
    {
      exact: true,
      path: appRoutes.ACTIVITY_TURNED,
      component: isAuthenticated(ActivityTurned),
    },
    {
      exact: true,
      path: appRoutes.ACTIVITY_ASSIGNED,
      component: isAuthenticated(ActivityAssigned),
    },
    {
      exact: true,
      path: appRoutes.ACTIVITY_TURNED_VIEW,
      component: isAuthenticated(ActivityTurnedDetails),
    },
    {
      exact: true,
      path: appRoutes.ANNOUNCEMENT_VIEW,
      component: isAuthenticated(AnnouncementDetails),
    },
    {
      exact: true,
      path: appRoutes.ANNOUNCEMENT,
      component: isAuthenticated(Announcement),
    },
    {
      exact: true,
      path: appRoutes.ACTIVITY_REPORT,
      component: isAuthenticated(ActivityReport),
    },
    {
      exact: true,
      path: appRoutes.LOGIN_REPORT,
      component: isAuthenticated(LoginReport),
    },
    {
      exact: true,
      path: appRoutes.CHAPTERWISR_REPORT,
      component: isAuthenticated(ChapterwiseReport),
    },
  ];

  const SidebarComponent = () => {
    const location = useLocation();
    const checkPath = () => {
      return location?.pathname.includes('content');
    };

    return checkPath() ? null : <Sidebar />;
  };


  return (
    <div>
      <Router history={appHistory}>
        <SidebarComponent />
        <Switch>
          {routes.map((route, index) => {
            return <Route key={index} {...route} component={route.component} />;
          })}
          <Route path='*' render={() => <Redirect to={appRoutes.DASHBOARD} />} />
        </Switch>
      </Router>
    </div>
  );
};

export default AppRoutes;
