import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useLocation, withRouter } from "react-router-dom";

import { Skeleton } from "antd";
import { Col, Row } from "antd";
import AssignedCard from "./AssignedCard";

import { ClockCircleOutlined } from "@ant-design/icons";
import SubjectIcon from "../../../assets/icons/subject.svg";

import EmptyState from "../../../shared/components/EmptyState";
import PageView from "../../../shared/components/PageView";
import PaginationComponent from "../../../shared/components/PaginationComponent";

import { WorksheetModel } from "../../../models/worksheet.model";
import WorksheetService from "../../../services/WorksheetService/Worksheet.service";
import { ACTIVITY_TURNED_VIEW } from "../../../routes/routeConstants/appRoutes";

// style
import "./assigned.scss";

export const SkeletonLoader = (): any => {
  return (
    <>
      {[1, 2, 3, 5, 6].map(rw => <Skeleton active />)}
    </>
  );

};



const ActivityAssigned = (props: { history: any }) => {
  const { history } = props;
  const { state }: { state: WorksheetModel } = useLocation();
  const [assignedList, setAssignedList] = useState<WorksheetModel[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const fetchWorksheetAssignedList = (
    pageNumber: number,
    perPage: number,
    searchString?: string
  ) => {
    setLoading(true);
    let queryParams = { WorksheetId: state.worksheetId, CurrentPage: pageNumber, Pagesize: perPage }
    WorksheetService.fetchStudentWorksheetAssignList(
      queryParams,
      (AssignedList: any, pageMeta: any) => {
        setAssignedList(AssignedList);
        setPaginationMeta(pageMeta);
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    fetchWorksheetAssignedList(1, 20, "");
  }, []);

  const handlePageChange = (page: number, pageSize: any) => {
    fetchWorksheetAssignedList(page, pageSize, "");
  };


  return (
    <PageView
      title="Actvity"
      className="activity-turned"
      showBack
    >


      <div className="activity-turned-info">
        {loading ? (
          <Row className={`details-card`}>
            <SkeletonLoader />
          </Row>
        ) : (
          <div className={`details-card`}>
            <Row className="content-row" gutter={[24, 12]}>
              {assignedList?.map((record, index) => (<Col key={record?.worksheetId} xs={8} xl={6}>
                <AssignedCard
                  data={record}
                  onClick={() => {
                    if (record.turnInStatus)
                      history.push(
                        generatePath(ACTIVITY_TURNED_VIEW, {
                          worksheetId: record?.worksheetId,
                          studentId: record?.studentId
                        }),
                        record
                      )
                  }}
                />
              </Col>))}


            </Row>
            <div className="pagination__footer">
              <PaginationComponent
                handlePageChange={handlePageChange}
                paginationMeta={paginationMeta}
              />
            </div>
          </div>
        )}
      </div>

    </PageView>
  );
};

export default withRouter(ActivityAssigned);
