import React, { FC } from "react";
import "./passwordResetLink.scss";
import ButtonComponent from "../../../shared/components/ButtonComponent";
interface PasswordResetLinkProps {
  email: string;
  loginHandler: () => void;
  wrongHandler: () => void;
}

const PasswordResetLink: FC<PasswordResetLinkProps> = (props) => {
  const { email, loginHandler, wrongHandler } = props;

  return (
    <div className="auth__form password-reset-link">
      <h2 className="auth__header">
        Password Reset Link is sent to
        <br />
        {email}
      </h2>
      <p className="password-reset-link__info">
        Check your email inbox to find the password reset link
      </p>
      <div className="auth__action password-reset-link__controllers">
        <ButtonComponent
          buttonContent="Proceed to Login"
          type="primary"
          htmlType="submit"
          onClick={loginHandler}
        />
      </div>
      <p className="password-reset-link__wrong">
        Wrong Mail ID? <span onClick={wrongHandler}>Click here</span>
      </p>
    </div>
  );
};

export default PasswordResetLink;
