import * as Yup from  "yup";

export const validationSchema = Yup.object().shape({
    password: Yup.string()
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required('Password is required!'),
    confirmPassword: Yup.string()
        .test('passwords-match', 'Passwords must match', function(value){
            return this.parent.password === value
        })
})
