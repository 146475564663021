import { Badge, Button, Col, DatePicker, Dropdown, Row, Select } from "antd";
import moment from "moment";
import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import "./reportFilter.scss";
import CalendarIcon from "../../../../assets/icons/calendar-primary.svg";
import FilterIcon from "../../../../assets/icons/filter-icon-filled.svg";
import { FilterLoginActivity } from "../../../../models/Report/loginActivity.model";
import { MetaModel } from "../../../../models/meta.model";
import { MetaService } from "../../../../services/MetaService/meta.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import { UserModel } from "../../../../models/user.model";


const { RangePicker } = DatePicker;

interface ReportFilterProps {
  applyHandler: (values: FilterLoginActivity) => void;
  clearHandler: (values: FilterLoginActivity) => void;
  user: UserModel;
}

const ReportFilter: FC<ReportFilterProps> = (props) => {
  const { applyHandler, clearHandler, user } = props;

  const [initialValues, setInitialValues] = useState<FilterLoginActivity>({});
  const [defaultValues, setDefaultValues] = useState<FilterLoginActivity>({});
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  const [sections, setSections] = useState<MetaModel[]>([]);


  const fetchClassRooms = () => {
    let data = user?.tutorClassRooms?.map((value) => {
      return {
        value: value?.id,
        label: value?.name,
      };
    }) as MetaModel[];

    if (data.length) {
      setDefaultValues({ branchClassRoomId: Number(data[0].value) })
      setInitialValues({ branchClassRoomId: Number(data[0].value) })
      fetchSections(Number(data[0].value) ?? 0)
    }
    setClassRooms(data);
  };

  const fetchSections = (classRoomId: number) => {
    setSections([])
    MetaService.fetchSectionsMeta(
      classRoomId,
      (sections) => {
        setSections(sections);
        if (sections.length) {
          setDefaultValues((prevState) => {
            return {
              ...prevState, branchClassRoomId: classRoomId, sectionId: Number(sections[0].value)
            }
          })
          setInitialValues((prevState) => {
            return {
              ...prevState, branchClassRoomId: classRoomId, sectionId: Number(sections[0].value)
            }
          })
          //applyHandler({ ...defaultValues, branchClassRoomId: classRoomId, sectionId: Number(sections[0].value) })
        }
      },
      () => { },
      () => { }
    );
  };


  useEffect(() => {
    fetchClassRooms();
  }, []);

  const fetchCount = () => {
    let count = 0;
    if (initialValues?.branchClassRoomId) count += 1;
    if (initialValues?.sectionId) count += 1;
    if (initialValues?.startingDate && initialValues?.endingDate ) count += 1;
    return count;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      enableReinitialize
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Dropdown
            overlayClassName="dashboard-filter__overlay"
            className="dashboard-filter"
            trigger={["click"]}
            overlay={
              <div className="dashboard-filter-form__wrapper">
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Class</label>
                  <Field
                    as={Select}
                    name="branchClassRoomId"
                    placeholder="Select Class"
                    options={classRooms}
                    value={values?.branchClassRoomId}
                    onChange={(value) => {
                      setFieldValue("branchClassRoomId", value);
                      setFieldValue("sectionId", undefined);
                      setFieldValue("SubjectId", undefined);
                      fetchSections(value);
                    }}
                  />
                </div>
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Section</label>
                  <Field
                    as={Select}
                    name="sectionId"
                    placeholder="Select Class"
                    options={sections}
                    value={values?.sectionId}
                    onChange={(value) => {
                      setFieldValue("sectionId", value);;
                    }}
                  />
                </div>

                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Date Range</label>
                  <Field
                    name="date"
                    as={RangePicker}
                    value={[
                      values?.startingDate
                        ? moment(values?.startingDate, "YYYY-MM-DD")
                        : undefined,
                      values?.endingDate
                        ? moment(values?.endingDate, "YYYY-MM-DD")
                        : undefined,
                    ]}
                    onChange={(dates) => {
                      if (dates?.length) {
                        setFieldValue(
                          "startingDate",
                          moment(dates?.[0])?.format()
                        );
                        setFieldValue(
                          "endingDate",
                          moment(dates?.[1])?.format()
                        );
                      } else {
                        setFieldValue("startingDate", null);
                        setFieldValue("endingDate", null);
                      }
                    }}
                    suffixIcon={
                      <img className="calendar__icon" src={CalendarIcon} />
                    }
                  />
                </div>

                <Row gutter={12} className="dashboard-filter__controllers">
                  <Col span={12}>
                    <Button
                      type="default"
                      onClick={() => {
                        resetForm({});
                        setInitialValues({ ...defaultValues });
                        clearHandler({ ...defaultValues });
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      onClick={() => {
                        resetForm({ values });
                        setInitialValues(values);
                        applyHandler(values);
                      }}
                    >
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            }
          >
            <Badge count={fetchCount()}>
              <div className="filter__icon">
                <img src={FilterIcon} />
              </div>
            </Badge>
          </Dropdown>
        </Form>
      )}
    </Formik>
  );
};

export default AuthContainer(ReportFilter);
