import * as XLSX from "xlsx";
export const convertJsonToXslx = (
  fileName: string,
  wsName: string,
  headers: string[],
  data?: any
) => {
  let createXLSLFormatObj: any = [];
  createXLSLFormatObj.push(headers);

  var filename = fileName;
  var ws_name = wsName;

  if (typeof console !== "undefined") console.log(new Date());
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  if (typeof console !== "undefined") console.log(new Date());
  XLSX.writeFile(wb, filename);
  if (typeof console !== "undefined") console.log(new Date());
};
