import React, { FC, useEffect, useState } from "react";
import "./upcomingClasses.scss";
import faker from "faker";
import LiveClassCard from "../../LiveClass/LiveClassCard";
import { LiveClassModel } from "../../../models/liveClass.model";
import LiveClassService from "../../../services/LiveClassService/LiveClass.service";
import { Skeleton } from "antd";
import LiveClassForm from "../../LiveClass/LiveClassForm";
import DeleteLiveClassAlert from "../../LiveClass/LiveClassMeeting/DeleteLiveClassAlert";
import LoginsIcon from "../../../assets/icons/logins.svg";
import StudentsIcon from "../../../assets/icons/self-onboarded-students.svg";
import { SkeletonLoader } from "../../LiveClass";
interface UpcomingClassesProps { }

const UpcomingClasses: FC<UpcomingClassesProps> = (props) => {
  const { } = props;
  const [upcomingClasses, setUpcomingClasses] = useState<LiveClassModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [isDeleleAlertShown, setIsDeleleAlertShown] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [currentLiveClass, setCurrentLiveClass] = useState<LiveClassModel>();
  const [showForm, setFormVisibility] = useState(false);

  const fetchUpcomingClasses = () => {
    setLoading(true);
    LiveClassService.fetchUpcomingLiveClasses(
      {},
      (liveClasses) => {
        setUpcomingClasses(liveClasses);
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchUpcomingClasses();
  }, []);

  const handleDelete = () => {
    if (currentLiveClass?.id) {
      setIsDeleteLoading(true);
      LiveClassService.deleteLiveClass(
        currentLiveClass?.id?.toString(),
        () => {
          fetchUpcomingClasses();
          setIsDeleleAlertShown(false);
        },
        () => { },
        () => {
          setIsDeleteLoading(false);
        }
      );
    }
  };
  return loading ? (
    <SkeletonLoader />
  ) : upcomingClasses ? (
    <div className="upcoming-classes">
      <LiveClassForm
        data={currentLiveClass}
        closeHandler={() => setFormVisibility(false)}
        visible={showForm}
        refreshList={() => fetchUpcomingClasses()}
      />
      <DeleteLiveClassAlert
        liveClass={currentLiveClass}
        visible={isDeleleAlertShown}
        successHandler={handleDelete}
        cancelHandler={() => {
          setCurrentLiveClass(undefined);
          setIsDeleleAlertShown(false);
        }}
        isButtonLoading={isDeleteLoading}
      />
      <div className="upcoming-classes__title">Live  Classes</div>
      <div className="student-activities-content__container">
        <div className="student-activities-content__card signups">
          <div className="student-activities-content__icon">
            <img src={StudentsIcon} />
          </div>
          <div className="student-activities-content__count">
            <p className="title">Scheduled</p>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <p className="value">10</p>
            )}
          </div>
        </div>


        <div className="student-activities-content__card logins">
          <div className="student-activities-content__icon">
            <img src={LoginsIcon} />
          </div>
          <div className="student-activities-content__count">
            <p className="title">conducted</p>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <p className="value">10</p>
            )}
          </div>
        </div>
      </div>
      <div className="upcoming-classes__title">Upcoming Classes</div>
      {upcomingClasses?.length ? (
        upcomingClasses?.map((value, index) => {
          return (
            <LiveClassCard
              data={value}
              editHandler={() => {
                setCurrentLiveClass(value);
                setFormVisibility(true);
              }}
              deleteHandler={() => {
                setCurrentLiveClass(value);
                setIsDeleleAlertShown(true);
              }}
            />
          );
        })
      ) : (
        <div className="no-upcoming-classes-text">No upcoming classes !</div>
      )}
    </div>
  ) : (
    <div />
  );
};

export default UpcomingClasses;
