import React, { useEffect, useMemo, useRef, useState } from "react";
import { generatePath, useLocation, withRouter } from "react-router-dom";

import * as Yup from "yup";
import { LeftOutlined, RightOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Radio, Skeleton, Spin } from "antd";
import { Col, Row } from "antd";
import { Form, Formik, FormikValues } from "formik";
import { serialize } from "serializr";

import pdfIcon from "../../../assets/icons/pdf-chapter-icon.svg";
import SubjectIcon from "../../../assets/icons/subject.svg";

import PageView from "../../../shared/components/PageView";
import InputField from "../../../shared/components/InputField";
import ButtonUI from "../../../shared/components/ButtonComponent";

// style
import "./activityTurnedDetails.scss";

// pdf
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import UploadContent from "../../../shared/components/UploadContent";
import { FileMetaModel, WorksheetModel, StudentSubmissionModel } from "../../../models/worksheet.model";
import WorksheetService from "../../../services/WorksheetService/Worksheet.service";
import moment from "moment";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import FileDownloadService from "../../../services/FileDownloadService/FileDownloadService";
import { TurninFormValidation } from "./turninValidation";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const SkeletonLoader = (): any => {
  return (
    <>
      {[1, 2, 3, 5].map(rw =>
        <Skeleton active />
      )}
    </>
  );

};



const ActivityTurnedDetails = (props: { history: any }) => {
  const { state }: { state: WorksheetModel } = useLocation();
  const formRef = useRef<any>();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [worksheetInfo, setWorksheetInfo] = useState<WorksheetModel>({});
  const [loading, setLoading] = useState(true);
  const [fileObject, setFileObject] = useState<FileMetaModel>({})
  const [loadingFile, setFileLoading] = useState({ id: 0, status: false });

  const [initialValues, setInitialValues] = useState<any>({
    StudentPoint: undefined,
    PrivateMessage: '',
    AttachmentList: null
  });



  const fetchWorksheetInfo = () => {
    setLoading(true);
    let queryParams = { WorksheetId: state.worksheetId, StudentId: state.studentId }
    WorksheetService.fetchStudentWorksheetInfo(
      queryParams,
      (activities) => {
        setWorksheetInfo(activities);
        setInitialValues(
          {
            StudentPoint: activities.studentPoint,
            PrivateMessage: activities.privateMessage,
            AttachmentList: null
          }
        )
        if (activities?.studentAttachmentList.length)
          setFileObject(activities?.studentAttachmentList[0])
      },
      () => { },
      () => { setLoading(false); }
    );
  };

  useEffect(() => {
    fetchWorksheetInfo();
  }, []);



  const handleSubmit = (values: FormikValues, { setErrors, resetForm }) => {
    if (values.StudentPoint > (worksheetInfo?.points ?? 0)) {
      setErrors({ 'StudentPoint': `Grade must be less than or equal to ${worksheetInfo?.points} points` });
    }
    else {
      setLoading(true);
      let jsonData = serialize(StudentSubmissionModel, { WorksheetId: state.worksheetId, StudentId: state.studentId, ...values });
      let formData = convertJSONToFormData({ ...jsonData });
      if (values?.AttachmentList && values?.AttachmentList.length)
        values?.AttachmentList.forEach(file => {
          formData.append('AttachmentList', file)
        })

      WorksheetService.updateStudentsWorkSheet(
        formData,
        (activities) => {
          fetchWorksheetInfo();
        },
        () => { },
        () => { setLoading(false); }
      );
    }
  };



  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleViewFile = (file: FileMetaModel) => {
    setLoading(true)
    setFileObject(file)
    setTimeout(() => {
      setLoading(false)
    }, 1000);

  };

  const downloadFile = (file: FileMetaModel, type: string) => {
    setFileLoading({ id: file?.attachmentId ?? 0, status: true })
    FileDownloadService.fileDownload(
      file,
      () => {
        if (type === 'studentAttachmentList')
          setWorksheetInfo((prevState) => {
            return {
              ...prevState,
              studentAttachmentList: prevState?.studentAttachmentList?.map(data => {
                if (data.attachmentId === file?.attachmentId)
                  data.isDownload = true
                return data
              })
            }
          })
        if (type === 'evaluationAttachmentList')
          setWorksheetInfo((prevState) => {
            return {
              ...prevState,
              evaluationAttachmentList: prevState?.evaluationAttachmentList?.map(data => {
                if (data.attachmentId === file?.attachmentId)
                  data.isDownload = true
                return data
              })
            }
          })
      },
      () => { },
      () => { setFileLoading({ id: 0, status: true }) }
    );
  }

  const handleNext = () => {
    setPageNumber((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setPageNumber((prevActiveStep) => prevActiveStep - 1);

  };





  return (
    <PageView
      title="Actvity"
      className="activity-turned"
      showBack
    >


      <div className="activity-turned-info">
        {loading ? (
          <Row className={`details-card`}>
            <SkeletonLoader />
          </Row>
        ) : (
          <div className={`details-card`}>
            {/* <PDFViewer></PDFViewer> */}
            <p className="activity-card__title">
              <span>
                <img src={SubjectIcon} />
              </span>
              <span className="__text">
                <span className="__sub_title">{worksheetInfo?.className}{" : "}{worksheetInfo?.sectionName}{" : "}{worksheetInfo.subjectName}</span>
                {worksheetInfo.name}
              </span>
            </p>
            <Row >
              <Col span={18}>
                {Object.keys(fileObject).length ? (
                  <div>
                    {fileObject.name?.split('.').pop() === "pdf"
                      ? <div>
                        <Document
                          file={{ url: fileObject?.path }}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page
                            pageNumber={pageNumber}
                            renderMode={'svg'}
                            scale={1.0}
                          />
                        </Document>
                        {numPages !== null && <div className="page-controls">
                          <Radio.Group >
                            <Radio.Button
                              value="large"
                              onClick={handleBack}
                              disabled={pageNumber === 1}

                            ><LeftOutlined /></Radio.Button>
                            <Radio.Button disabled>{pageNumber} of {numPages}</Radio.Button>
                            <Radio.Button
                              onClick={handleNext}
                              disabled={pageNumber === numPages}
                              value="small"><RightOutlined /></Radio.Button>
                          </Radio.Group>
                        </div>}
                      </div>
                      : <div className="img_viewer">
                        <img src={fileObject?.path}></img>
                      </div>
                    }

                  </div>
                ) : null}

              </Col>
              <Col span={6}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize
                  innerRef={formRef}
                  validationSchema={TurninFormValidation}
                >
                  {({ values, setFieldValue, resetForm, handleSubmit, errors }) => (<Form>
                    <div className="activity-turned-form__input">
                      <div className="activity-turned-form__label">Files</div>
                      <div >Turned in on : {moment(worksheetInfo.turnInDate).format('MMMM Do YYYY  h:mm a')}</div>
                      {/* <div className="more_label">See history</div> */}
                    </div>
                    <div>
                      {worksheetInfo?.studentAttachmentList?.map(fileInfo => (
                        <div className={`activity-turned_download_container ${fileInfo.isDownload && "download"}`}>
                          <div onClick={() => handleViewFile(fileInfo)} className="activity-turned_file" key={fileInfo.attachmentId}>
                            {(loadingFile.id === fileInfo.attachmentId && loadingFile.status === true) && <Spin size="small" />}
                            <img src={pdfIcon} alt="" className="pdf_icon" />
                            <div className="file_title">{fileInfo.name}</div>
                          </div>

                          <div style={{ cursor: 'pointer' }} onClick={() => downloadFile(fileInfo, 'studentAttachmentList')}>
                            <DownloadOutlined />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="activity-turned-form__input">
                      <div className="activity-turned-form__label">Attachment</div>
                      <UploadContent
                        setUploadedFile={(file) => setFieldValue("AttachmentList", file)}
                      />
                    </div>
                    <div>
                      {worksheetInfo?.evaluationAttachmentList?.map(fileInfo => (
                        <div onClick={() => downloadFile(fileInfo, 'evaluationAttachmentList')} className={`activity-turned_file  ${fileInfo.isDownload && "download"}`} key={fileInfo.attachmentId}>
                          {(loadingFile.id === fileInfo.attachmentId && loadingFile.status === true) && <Spin size="small" />}
                          <img src={pdfIcon} alt="" className="pdf_icon" />
                          <div className="file_title">{fileInfo.name}</div>
                        </div>
                      ))}
                    </div>

                    <div className="activity-turned-form__input">
                      <div className="activity-turned-form__label">Grade</div>
                      <InputField
                        type="number"
                        name="StudentPoint"
                        placeholder="Type Grade"
                        maxLength={100}
                      />
                      <div className="activity-turned-form__label right">{"Out of  "}{worksheetInfo.points}</div>
                    </div>

                    <div className="activity-turned-form__input">
                      <div className="activity-turned-form__label">Private comment</div>
                      <InputField
                        type="textarea"
                        name="PrivateMessage"
                        placeholder="Type comment"
                        style={{ height: 120, resize: 'none' }}
                        showCount
                        maxLength={300}
                      />
                    </div>
                    <ButtonUI
                      buttonContent="Return"
                      type="primary"
                      htmlType="submit"
                    ></ButtonUI>
                  </Form>)}
                </Formik>
              </Col>
            </Row>
          </div>
        )}
      </div>

    </PageView>
  );
};

export default withRouter(ActivityTurnedDetails);
