import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { AssessmentQuestions, EvaluteAttemptQuestion, ExerciseAttemptQuestions, UpdateQuery } from "../../models/assessment.model";
import {
  EXAM_EXERCISE_ATTEMPT,
  EXAM_EXERCISE_ATTEMPT_QUESTIONS,
  EXAM_EXERCISE_ATTEMPT_QUESTION_ID,
    EXERCISE_ATTEMPT,
    EXERCISE_ATTEMPT_QUESTIONS,
    EXERCISE_ATTEMPT_QUESTION_ID,
  LIST_ASSESSMENTS
} from "../../routes/routeConstants/apiRoutes";

export class AssessmentService {
  static fetchExercises(onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(LIST_ASSESSMENTS)
      .then((response) => {
        const data  = deserialize(AssessmentQuestions, response["data"]["assessments"])
        onSuccess(data);
      })
      .catch((e) => {
        onError(e);
      });
  }
  static fetchExerciseQuestions(
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(generatePath(EXERCISE_ATTEMPT_QUESTIONS, {exerciseAttemptId: data.id}))
      .then((response) => {
        console.log(response)
        const data = deserialize(ExerciseAttemptQuestions, response["data"]["excercise_attempt_questions"])
        onSuccess(data);
      })
      .catch((e) => {
        onError(e);
      });
  }
  static updateExerciseQuestion(
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    const serializeData = serialize(UpdateQuery, data["payload"])
    const payload = { excercise_attempt_question: serializeData };
    return axiosInstance
      .put(
        generatePath(EXERCISE_ATTEMPT_QUESTION_ID, { exerciseAttemptId: data.exerciseAttemptId, exerciseAttemptQuestionId: data.exerciseAttemptQuestionId}),
        payload
      )
      .then((response) => {
        console.log(response)
        const data = deserialize(ExerciseAttemptQuestions, response["data"]["excercise_attempt_question"])
        onSuccess(data);
      })
      .catch((e) => {
        onError(e);
      });
  }
  static evaluateExerciseAttempt(
      data: any,
      onSuccess: Function,
      onError: Function
  ) {
    const serializeData = serialize(EvaluteAttemptQuestion, data.payload)
    const payload = { excercise_attempt: serializeData}
      return axiosInstance
      .put(
        generatePath(EXERCISE_ATTEMPT, {exerciseAttemptId: data.id}),
          payload
      )
      .then((response) => {
        onSuccess(response);
      })
      .catch((e) => {
        onError(e);
      });
  }
  static fetchExamExerciseQuestions(
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(generatePath(EXAM_EXERCISE_ATTEMPT_QUESTIONS, {examExerciseAttemptId: data.id}))
      .then((response) => {
        console.log(response)
        const data = deserialize(ExerciseAttemptQuestions, response["data"]["exam_exercise_attempt_questions"])
        onSuccess(data);
      })
      .catch((e) => {
        onError(e);
      });
  }
  static evaluateExamExerciseAttempt (
    data: {id: number | undefined, payload: { evaluation: string}},
    onSuccess: Function,
    onError: Function
  ) {
    const serializeData = serialize(EvaluteAttemptQuestion, data.payload)
    const payload = { exam_exercise_attempt: serializeData}
    return axiosInstance
    .put(
      generatePath(EXAM_EXERCISE_ATTEMPT, {examExerciseAttemptId: data.id}),
      payload
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((e) => {
      onError(e);
    });
  }
  static updateExamExerciseQuestion(
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    const serializeData = serialize(UpdateQuery, data["payload"])
    const payload = { exam_exercise_attempt_question: serializeData };
    return axiosInstance
      .put(
        generatePath(EXAM_EXERCISE_ATTEMPT_QUESTION_ID, { examExerciseAttemptId: data.examExerciseAttemptId, examExerciseAttemptQuestionId: data.examExerciseAttemptQuestionId}) ,
        payload
      )
      .then((response) => {
        const data = deserialize(ExerciseAttemptQuestions, response["data"]["exam_exercise_attempt_question"])
        onSuccess(data);
      })
      .catch((e) => {
        onError(e);
      });
  }
}
