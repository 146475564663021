
import {serializable, alias, object, list, primitive} from 'serializr';

export class StudentActivitiesModel {

	@serializable(alias('signUps', primitive()))
	totalSignups?: number;

	@serializable(alias('activeUsers', primitive()))
	totalActiveUsers?: number;

	@serializable(alias('newLogins', primitive()))
	totalLogins?: number;

	@serializable(alias('timeSpent', primitive()))
	timeSpents?: number;

}

export class DashboardFilterModel {
	@serializable(alias('Id', primitive()))
    id?: number;
	@serializable(alias('InstId', primitive()))
    institute?: number;
    @serializable(alias('BranchId', primitive()))
    branch?: number;
    @serializable(alias('BoardId', primitive()))
    board?: number;
    @serializable(alias('GradeId', primitive()))
    classRoom?: number;
    @serializable(alias('StartDate', primitive()))
    startingDate?: string;
    @serializable(alias('EndDate', primitive()))
    endingDate?: string;
    @serializable(alias('section', primitive()))
    section?: number;
    @serializable(alias('last_login_starting_date', primitive()))
    lastLoginStartingDate?: string;
    @serializable(alias('last_login_ending_date', primitive()))
    lastLoginEndingDate?: string;
    @serializable(alias('loggedIn', primitive()))
    loggedIn?: string;
}

export class DashboardProgressFilterModel {
	@serializable(alias('ClassId', primitive()))
    ClassId?: number;
    @serializable(alias('SectionId', primitive()))
    SectionId?: number;
    @serializable(alias('StartDate', primitive()))
    StartDate?: string;
    @serializable(alias('EndDate', primitive()))
    EndDate?: string;
}

export class ClassProgressModel{
    @serializable(alias('studentid', primitive()))
    studentid?: number;
    @serializable(alias('studentName', primitive()))
    studentName?: string;
    @serializable(alias('className', primitive()))
    className?: string;
    @serializable(alias('sectionName', primitive()))
    sectionName?: number;
    @serializable(alias('contentActive', primitive()))
    contentActive?: string;
    @serializable(alias('contentWatched', primitive()))
    contentWatched?: string;
    @serializable(alias('quizActive', primitive()))
    quizActive?: string;
    @serializable(alias('quizAttempted', primitive()))
    quizAttempted?: string;
    @serializable(alias('excerciseActive', primitive()))
    excerciseActive?: string;
    @serializable(alias('excerciseAttempted', primitive()))
    excerciseAttempted?: string;
    @serializable(alias('activityActive', primitive()))
    activityActive?: string;
    @serializable(alias('activityAttempted', primitive()))
    activityAttempted?: string;
    @serializable(alias('points', primitive()))
    points?: string;

}
