import React, { FC } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-classic-with-mathtype";
import "./examEditor.scss";
interface CKEditorComponentProps {
  value: any;
  className?: string;
}
const CKEditorComponent: FC<CKEditorComponentProps> = ({ value, className }) => {
  return (
    <div className={`ck-editor-component__container ${className}`}>
      <CKEditor
        disabled={true}
        config={{
          toolbar: {
            items: []
          },
        }}
        editor={ClassicEditor}
        data={value}
      />
    </div>
  );
};
export default CKEditorComponent;
