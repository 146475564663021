import { ClassRoomMetaModel } from '../../models/meta.model';
import {  SET_CURRENT_COURSE, UNAUTHENTICATED, SET_APP_NAME, SET_TYPE, SET_CURRENT_PROFILE, AUTHENTICATED, SET_CURRENT_CLASS_ROOM, SET_RECENT_WATCHED_CONTENT } from "../definitions/authConstants";


export const setUnAuthenticated = () => {
    return {
        type: UNAUTHENTICATED,
        payload: {
            authenticated: false,
            user: null
        }
    }
}

export const setAppName = (app_name: any) => {
    return {
        type: SET_APP_NAME,
        payload: {
            app_name
        }
    }
}

export const setAuthenticated = (user: any) => {
  return {
      type: AUTHENTICATED,
      payload: {
          authenticated: true,
          user: user
      }
  }
}

//dont use without parent
export const setCurrentProfile = (currentProfile: any) => {
  return {
      type: SET_CURRENT_PROFILE,
      payload: {
          currentProfile
      }
  }
}

export const setCurrentCourse = (course: {}) => {
  return {
      type: SET_CURRENT_COURSE,
      payload: {
          course
      }
  }
}
export const setType = (type: any) => {
  return {
      type: SET_TYPE,
      payload: {
          type
      }
  }
}

export const setCurrentClassRoom = (classRoom: ClassRoomMetaModel) => {
  return {
      type: SET_CURRENT_CLASS_ROOM,
      payload: {
          currentClassRoom: classRoom
      }
  }
}

export const setRecentWatchedContent = (recentWatchedContent: any) => {
  return {
      type: SET_RECENT_WATCHED_CONTENT,
      payload: {
        recentWatchedContent: recentWatchedContent
      }
  }
}
