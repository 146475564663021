import React, { useEffect, useState, useRef } from "react";
import { QueryService } from "../../../services/QueryService/queries.service";
import { AddComment } from "../../../shared/components/AddComment";
import { Conversation } from "../../../shared/components/Conversation";
import EmptyState from "../../../shared/components/EmptyState";
import { UIButton } from "../../../shared/components/UIButton";
import "../queries.scss";
import { DateFormatter } from "../../../shared/components/HOC/dateFormatter";
import { NoData } from "../../../shared/components/NoData";
import { CloseOutlined } from "@ant-design/icons";
import { Query } from "../../../models/query.model";
import Modal from "antd/lib/modal/Modal";
import { ArrowRightOutlined } from "@ant-design/icons";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

export const MyAnswers = () => {
  const [answers, setAnswers] = useState<any[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState(-1);
  const [attachment, setAttachment] = useState<any>();
  const attachmentRef = useRef<HTMLInputElement>(null);
  
  const getAnswers = () => {
    QueryService.fetchAnswers(
      (response: any) => {
        console.log(response);
        setAnswers(response);
      },
      (error: any) => {
        console.log(error);
      }
    );
  };
  const createComment = (content: string) => {
    if (content !== "") {
      let jsonData = {
        comment: {
          content,
          attachment,
        },
      };
      let formData = convertJSONToFormData(jsonData);
      console.log(formData);
      QueryService.createComment(
        answers[selectedAnswer].id,
        formData,
        () => {
            getAnswers()
        },
        () => {}
      );
    } else {
      Notification({
        message: "Comment not sent",
        description: "Please add some content",
        type: NotificationTypes.ERROR,
      });
    }
  };
  useEffect(() => {
    getAnswers();
  }, []);


  return answers?.length == 0 ? (
    <div className="page-view">
      <EmptyState text={"No queries found."} />
    </div>
  ) : (
    <div className="query-open-quesions">
      <div className="query-list__wrapper">
        {answers?.length &&
          answers.map((value: Query, index: number) => {
            return (
              <div
                className={`${
                  selectedAnswer == index
                    ? "query-list__item query-list-item__selected"
                    : "query-list__item"
                }`}
                onClick={() => {
                  setSelectedAnswer(index);
                }}
              >
                <div className="flex-space-between">
                  <div className="query-list-item-tag__wrapper">
                    <div className="query-list-item__tag">
                      {value.relatedSubject?.type == "class_room_subject"
                        ? value.relatedSubject.classRoomSubject?.classRoom
                            ?.boardAcronym
                        : value.relatedSubject?.examSubject?.examName}
                    </div>
                    {value.relatedSubject?.type == "class_room_subject" && (
                      <div className="query-list-item__tag">
                        {value.relatedSubject.classRoomSubject?.classRoomName}
                      </div>
                    )}

                    <div className="query-list-item__tag">
                      {value.relatedSubject?.type == "class_room_subject"
                        ? value.relatedSubject.classRoomSubject?.subjectName
                        : value.relatedSubject?.examSubject?.subjectName}
                    </div>
                  </div>
                  <div>
                    <CloseOutlined />
                  </div>
                </div>
                <div className="query-list-item__question query-list-item--margin">
                  {value.content}
                </div>
                <div className="query-list-item__datetime query-list-item--margin">
                  {DateFormatter(value.createdAt)}
                </div>
                {selectedAnswer == index ? (
                  <div />
                ) : (
                  <div
                    className="query-list-item__answer query-list-item--margin"
                    onClick={() => setSelectedAnswer(index)}
                  >
                    Answer{" "}
                    <span className="query-list-item-answer__arrow">
                      <ArrowRightOutlined />
                    </span>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      <div className="query-wrapper__right">
        {selectedAnswer > -1 ? (
          <div className="query-have__data">
            <div className="query-header__wrapper">
              <div className="query-header__left">
                <div className="query-tag__wrapper">
                  <div className="query__tag">
                    {answers &&
                    answers[selectedAnswer].relatedSubject?.type ==
                      "class_room_subject"
                      ? answers &&
                        answers[selectedAnswer].relatedSubject?.classRoomSubject
                          ?.classRoom?.boardAcronym
                      : answers &&
                        answers[selectedAnswer].relatedSubject?.examSubject
                          ?.examName}
                  </div>
                  {answers &&
                    answers[selectedAnswer].relatedSubject?.type ==
                      "class_room_subject" && (
                      <div className="query__tag">
                        {answers &&
                          answers[selectedAnswer].relatedSubject
                            ?.classRoomSubject?.classRoomName}
                      </div>
                    )}
                  <div className="query__tag">
                    {answers &&
                    answers[selectedAnswer].relatedSubject?.type ==
                      "class_room_subject"
                      ? answers &&
                        answers[selectedAnswer].relatedSubject?.classRoomSubject
                          ?.subjectName
                      : answers &&
                        answers[selectedAnswer].relatedSubject?.examSubject
                          ?.subjectName}
                  </div>
                </div>
                <div className="query-list-item__question">
                  {answers && answers[selectedAnswer].content}
                </div>
                <div className="query-list-item__datetime">
                  {DateFormatter(answers && answers[selectedAnswer].createdAt)}
                </div>
              </div>
              <div className="query-header__right">
                <UIButton type="solid" title={"Resolved"} onClick={() => {}} />
              </div>
            </div>
            <div className="conversation__wrapper">
              <Conversation
                data={answers && answers[selectedAnswer].comments}
              />
            </div>
            <input
              accept="image/*"
              type="file"
              ref={attachmentRef}
              style={{ display: "none" }}
              onChange={(e) => {
                let file = e.target.files?.length && e.target.files[0];
                setAttachment(file);
              }}
            />
            <AddComment
              placeholder="Add Comment"
              onClick={(value) => createComment(value)}
              onAttach={() => attachmentRef.current?.click()}
            />
          </div>
        ) : (
          <NoData placeholder={"Select a question to answer"} />
        )}
      </div>
    </div>
  );
};
