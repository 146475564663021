import React from 'react';

import Heading from '../../../shared/components/Heading';
import VideoCard from '../../../shared/components/VideoCard';
import UICarousel from '../../../shared/components/UICarousel';
import AuthContainer from '../../../store/container/AuthContainer';
import SubIcon from '../../../assets/icons/math.svg';

import { Course } from '../../../models/Learn/Course/course.model';
import { FC } from 'react';
import { useState } from 'react';
import { ChapterContent } from '../../../models/Learn/Chapters/chapter.model';
import { useEffect } from 'react';
import { ContentHistoryService } from '../../../services/ContentHistoryService/contentHistory.service';

import './continueWhereYouLeft.scss';

interface ContinueWhereYouLeftProps {
  type: string;
  currentClassRoom: Course;
  isTrialCourse: boolean;
  isCourseExpired: boolean;
}
const ContinueWhereYouLeft: FC<ContinueWhereYouLeftProps> = ({
  type,
  currentClassRoom,
}) => {
  const [contents, setContents] = useState<ChapterContent[]>([]);

  useEffect(() => {
    const fetchContents = () => {
      ContentHistoryService.getRecentWatchedHistory(
        {
          branch_class_room_id: currentClassRoom.id,
        },
        (contents) => {
          setContents(contents);
        },
        () => {}
      );
    };

    if (currentClassRoom?.id) {
      fetchContents();
    }
  }, [currentClassRoom, type]);
  return (
    <div
      className={
        contents?.length > 4
          ? 'continue-where-you-left__container'
          : 'continue-where-you-left-no-btn__container'
      }
    >
      {contents?.length ? <Heading sub>Continue where you left</Heading> : ''}
      <UICarousel
        data={contents.map((value, index) => (
          <VideoCard
            color='#FD6C00'
            thumbnail={value?.contentLibrary?.thumbnailUrl}
            icon={SubIcon}
            subject={value?.classRoomSubjectName || value?.examSubjectName}
            content={value?.title}
            chapterName={value?.examChapterName || value?.chapterName}
            key={index}
            id={value?.id}
            description={value?.description}
            contentLibrary={value?.contentLibrary}
            contentType={value?.contentLibrary?.contentType}
            chapterId={value?.chapterId || value?.examChapterId}
            subjectId={value?.classRoomSubjectId || value?.examSubjectId}
          />
        ))}
      />
    </div>
  );
};

export default AuthContainer(ContinueWhereYouLeft);
