import { SET_NAV_CONTENT } from "../definitions/titleConstants";
import { ITitleState } from "../reducers/titleReducer";

export const setTitle = ({ showBack = false, icon = null, subText = "", color="", ...rest }: ITitleState) => {
    return {
        type: SET_NAV_CONTENT,
        payload: {
            ...rest,
            showBack,
            icon,
            subText,
            color
        }
    }
}
