import React, { FC } from "react";
import { NotificationCardTypes } from "../../../../../enums/notificationCardTypes";
import { CloseOutlined } from "@ant-design/icons";
import "./notificationCard.scss";
import { Notifications } from "../../../../../models/Notifications/notifications.model";
import { generatePath, useHistory, useParams } from "react-router-dom";
import {
  PRACTICE_CHAPTERS,
  STUDY_MATERIAL,
  LIVE_CLASS
} from "../../../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../../../store/container/AuthContainer";
import Notification from "../../../Notification";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import { Student } from "../../../../../models/Learn/Student/student.model";
import VideoIcon from "../../../../../assets/icons/play2.svg";
import PdfIcon from "../../../../../assets/icons/pdf.svg";
import VideoIconSeen from "../../../../../assets/icons/play2-gray.svg";
import PdfIconSeen from "../../../../../assets/icons/pdf-gray.svg";
import moment from "moment";
import NotificationService from "../../../../../services/NotificationService/notifications.service";
import OtherNotificationIcon from "../../../../../assets/icons/other-notification-viewed.svg";
import OtherNotificationViewedIcon from "../../../../../assets/icons/other-notification-viewed.svg";
interface NotificationCardProps {
  data: Notifications;
  notificationType: string | undefined;
  image?: string | undefined;
  time: any;
  title: string | undefined;
  description: string | undefined;
  otherData?: string;
  subtitle: string | undefined;
  onClickDelete: () => void;
  closeHandler?: () => void;
  type: string;
  user: Student;
  // currentProfile: Student;
  refreshList: () => void;
}
const NotificationCard: FC<NotificationCardProps> = ({
  notificationType,
  image,
  time,
  title,
  subtitle,
  description,
  otherData,
  onClickDelete,
  data,
  closeHandler,
  user,
  // currentProfile,
  type,
  refreshList,
}) => {
  const history = useHistory();
  const params = useParams();
  const renderIcon = (isSeen: boolean) => {
    
    switch (notificationType) {
      case NotificationCardTypes.EXERCISE: {
        return isSeen ? PdfIconSeen : PdfIcon;
      }
      case NotificationCardTypes.EXAM_EXERCISE: {
        return isSeen ? PdfIconSeen : PdfIcon;
      }
      case NotificationCardTypes.CHAPTER_CONTENT: {
        if (
          data?.data?.chapterContent?.contentType == NotificationCardTypes.VIDEO
        ) {
          return isSeen ? VideoIconSeen :VideoIcon;
        } else if (
          data?.data?.chapterContent?.contentType == NotificationCardTypes.PDF
        ) {
          return isSeen ? PdfIconSeen : PdfIcon;
        } else {
          return isSeen ? PdfIconSeen : PdfIcon;
        }
      }
      case NotificationCardTypes.EXAM_CHAPTER_CONTENT: {
        if (
          data?.data?.examChapterContent?.contentType ==
          NotificationCardTypes.VIDEO
        ) {
          return isSeen ? VideoIconSeen : VideoIcon;
        } else if (
          data?.data?.examChapterContent?.contentType ==
          NotificationCardTypes.PDF
        ) {
          return isSeen ? PdfIconSeen : PdfIcon;
        } else {
          return isSeen ? PdfIconSeen : PdfIcon;
        }
      }
      default: {
        return isSeen ? OtherNotificationViewedIcon : OtherNotificationIcon
      }
    }
  };

  const renderTime = (time: string) => {
    let diff = moment(time).fromNow();
    return diff;
  };

console.log("--------------------------------");

  console.log(notificationType);
  console.log(type);
  

  return (
    <div
      className={`notification-card__container`}
      onClick={(e) => {
        NotificationService.readNotification(
          data?.id,
          () => {
            refreshList();
          },
          () => {},
          () => {}
        );

        if (data?.receiver?.student?.id == user?.id) {
          switch (notificationType) {
            case "chaptor_content": {
              if (type == "class_room") {
                history.push(
                  generatePath(STUDY_MATERIAL, {
                    ...params,
                    chapterId: data?.data?.chapterContent?.chapterId,
                    contentId: data?.data?.chapterContent?.id,
                    subjectId: data?.data?.chapterContent?.classRoomSubjectId
                  }),
                  {
                    chapterId: data?.data?.chapterContent?.chapterId,
                    contentId: data?.data?.chapterContent?.id,
                    chapterName: data?.data?.chapterContent?.chapterName,
                    subjectName: data?.data?.chapterContent?.classRoomSubjectName,
                  }
                );
              } else {
                Notification({
                  message: "Activate class",
                  description: "Please activate class to take you there!",
                  type: NotificationTypes.ERROR,
                });
              }
              break;
            }
            case "exam_chapter_content": {
              if (type == "exam") {
                history.push(
                  generatePath(STUDY_MATERIAL, {
                    ...params,
                    chapterId: data?.data?.examChapterContent?.examChapterId,
                    contentId: data?.data?.examChapterContent?.id,
                    subjectId: data?.data?.examChapterContent?.examSubjectId,
                  }),
                  {
                    chapterId: data?.data?.examChapterContent?.examChapterId,
                    contentId: data?.data?.examChapterContent?.id,
                    chapterName:
                      data?.data?.examChapterContent?.examChapterName,
                    subjectName:
                      data?.data?.examChapterContent?.examSubjectName,
                  }
                );
              } else {
                Notification({
                  message: "Activate exam",
                  description: "Please activate exam to take you there!",
                  type: NotificationTypes.ERROR,
                });
              }

              break;
            }
            case "excercise": {
              if (type == "class_room") {
                history.push(
                  generatePath(PRACTICE_CHAPTERS, {
                    subjectId: data?.data?.excercise?.classRoomSubjectId,
                  }),
                  {
                    subjectName: data?.data?.excercise?.classRoomSubjectName,
                    subjectId: data?.data?.excercise?.classRoomSubjectId,
                  }
                );
              } else {
                Notification({
                  message: "Activate class",
                  description: "Please activate class to take you there!",
                  type: NotificationTypes.ERROR,
                });
              }
              break;
            }
            case "exam_exercise": {
              if (type == "exam") {
                history.push(
                  generatePath(PRACTICE_CHAPTERS, {
                    subjectId: data?.data?.examExercise?.examSubjectId,
                  }),
                  {
                    subjectName: data?.data?.examExercise?.examSubjectName,
                    subjectId: data?.data?.examExercise?.examSubjectId,
                  }
                );
              } else {
                Notification({
                  message: "Activate exam",
                  description: "Please activate exam to take you there!",
                  type: NotificationTypes.ERROR,
                });
              }
              break;
            }
            case "live_class" : {
              history.push(LIVE_CLASS)
            }
          }
        } else {
          Notification({
            message: `Notification for ${data?.receiver?.student?.firstName}`,
            description: "Please switch profile to take you there!",
            type: NotificationTypes.ERROR,
          });
        }
        closeHandler && closeHandler();
      }}
    >
      <div
        className={`notification-card__type 
      ${
        (((notificationType == NotificationCardTypes.CHAPTER_CONTENT ||
          notificationType == NotificationCardTypes.EXAM_CHAPTER_CONTENT) &&
          data?.data?.chapterContent?.contentType ==
            NotificationCardTypes.PDF) ||
          data?.data?.examChapterContent?.contentType ==
            NotificationCardTypes.PDF) &&
        "notification__pdf"
      }
      ${
        (((notificationType == NotificationCardTypes.CHAPTER_CONTENT ||
          notificationType == NotificationCardTypes.EXAM_CHAPTER_CONTENT) &&
          data?.data?.chapterContent?.contentType ==
            NotificationCardTypes.VIDEO) ||
          data?.data?.examChapterContent?.contentType ==
            NotificationCardTypes.VIDEO) &&
        "notification__video"
      }
      ${
        (notificationType == NotificationCardTypes.EXAM_EXERCISE ||
          notificationType == NotificationCardTypes.EXERCISE) &&
        "notification__exercise"
      }
      ${
        notificationType == NotificationCardTypes.ADMIN_MESSAGE &&
        "notification__admin"
      }
      ${
        notificationType == NotificationCardTypes.TUTOR_REPLY &&
        "notification__tutor"
      }

      ${data?.isSeen && "notification__read"}
      `}
      >
        <p className="type__title">
          <span>
            <img className="type__icon" src={renderIcon(data?.isSeen !== undefined && data?.isSeen)} />
          </span>
          {subtitle}
        </p>
        <p className="notification__type">{time ? renderTime(time) : ""}</p>
      </div>

      {image ? (
        <div className="notification-card-image__wrapper">
          <div className="notification__details">
            <p className="notification__title">{title}</p>
            <p>{description}</p>
          </div>
          <img src={image} />
        </div>
      ) : (
        <div className="notification__details">
          <p className="notification__title">
            {otherData && <span>{otherData} </span>}
            {title}
          </p>
          <p>{description}</p>
        </div>
      )}
      <div
        className="close__icon"
        onClick={(e) => {
          onClickDelete();
          e.stopPropagation();
        }}
      >
        <CloseOutlined />
      </div>
    </div>
  );
};
export default AuthContainer(NotificationCard);
