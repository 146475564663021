import { serializable, alias, primitive } from 'serializr';
import QuestionModel from '../Question/question.model';

export class ContentLibraryModel {
  @serializable(alias('id', primitive()))
  id?: string;
  @serializable(alias('title', primitive()))
  title?: string;
  @serializable(alias('description', primitive()))
  description?: string;
  @serializable(alias('content_type', primitive()))
  contentType?: string;
  @serializable(alias('thumbnail_url', primitive()))
  displayPictureUrl?: string;
  @serializable(alias('content_length', primitive()))
  contentLength?: string;
  @serializable(alias('s3_attachment_url', primitive()))
  s3AttachmentUrl?: string;
  @serializable(alias('s3_id', primitive()))
  s3Id?: string;
  @serializable(alias('content_url', primitive()))
  contentUrl?: string;
}
export class ContentLibraryQuestionModel extends QuestionModel {
  @serializable(alias('content_library_id', primitive()))
  contentLibraryId?: string;
}
