import { serializable, alias, object, list, primitive } from "serializr";
import { ChapterContent } from "../Learn/Chapters/chapter.model";
import { Exercise } from "../Learn/Exercise/exercise.model";
import { PaginationModel } from "../pagination.model";
import { Student } from "../Learn/Student/student.model";

export class Admin {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;

  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("role_name", primitive()))
  roleName?: string;

  @serializable(alias("display_picture_url", primitive()))
  displayPictureUrl?: string;

  @serializable(alias("is_active", primitive()))
  isActive?: string;
}

export class Sender {
  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("admin", object(Admin)))
  admin?: Admin;
}

export class Receiver {
  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("student", object(Student)))
  student?: Student;
}

export class Data {
  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("excercise", object(Exercise)))
  excercise?: Exercise;

  @serializable(alias("chaptor_content", object(ChapterContent)))
  chapterContent?: ChapterContent;

  @serializable(alias("exam_exercise", object(Exercise)))
  examExercise?: Exercise;

  @serializable(alias("exam_chapter_content", object(ChapterContent)))
  examChapterContent?: ChapterContent;
}

export class Notifications {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("action", primitive()))
  action?: string;

  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("short_title", primitive()))
  shortTitle?: string;

  @serializable(alias("is_seen", primitive()))
  isSeen?: boolean;

  @serializable(alias("body", primitive()))
  body?: string;

  @serializable(alias("sender", object(Sender)))
  sender?: Sender;

  @serializable(alias("receiver", object(Receiver)))
  receiver?: Receiver;

  @serializable(alias("data", object(Data)))
  data?: Data;
}

export class NotificationMeta extends PaginationModel {

  @serializable(alias("new_notification_count", primitive()))
  notificationCount?: Data;

}
