import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import _axiosInstance from "../../interceptor/_axiosInstance";
import {
  ClassRoomMetaModel,
  MetaModel,
  TutorMetaModel,
} from "../../models/meta.model";
import { deserialize } from "serializr";

export class MetaService {
  static fetchClassRoomsMeta(
    onSuccess: (value: ClassRoomMetaModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.CLASS_ROOMS)
      .then((response) => {
        const data = deserialize(
          ClassRoomMetaModel,
          response.data["branch_class_rooms"]
        ) as ClassRoomMetaModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchSectionsMeta(
    classRoomId: number,
    onSuccess: (value: MetaModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.SECTIONS, {
        params: {
          "branch_class_room_id": classRoomId,
        },
      })
      .then((response) => {
        const data = deserialize(
          MetaModel,
          response.data["sections"]
        ) as MetaModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchTutorsMeta(
    onSuccess: (value: TutorMetaModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.TUTORS)
      .then((response) => {
        const data = deserialize(
          TutorMetaModel,
          response.data["tutors"]
        ) as TutorMetaModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchSubjectsMeta(
    sectionIds: number[],
    onSuccess: (value: MetaModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.SUBJECTS, {
        params: {
          section_ids: sectionIds,
        },
      })
      .then((response) => {
        const data = deserialize(
          MetaModel,
          response.data["subjects"]
        ) as MetaModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchChaptersMeta(
    params: any,
    onSuccess: (value: MetaModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return _axiosInstance
      .get(ApiRoutes.CHAPTERS_MASTER, { params })
      .then((response) => {
        const data = deserialize(MetaModel, response.data) as MetaModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
