import { serializable, alias, primitive, list, object } from "serializr";
import { Student } from "./Learn/Student/student.model";

export class IdNameModel {
  @serializable(alias("id", primitive()))
  id!: number;

  @serializable(alias("name", primitive()))
  name?: string;
}

export class LoginCredentials {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("grant_type", primitive()))
  grantType?: string = "password";
}

class BranchModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("is_active", primitive()))
  isActive?: number;

  @serializable(alias("display_logo_url", primitive()))
  displayLogoUrl?: string;
}

export class InstituteTutorFiltersModel {
  @serializable(alias("branch_id", primitive()))
  branchId?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("institute_id", primitive()))
  instituteId?: number;
}
export class InstituteTutorSectionsModel extends IdNameModel {
  @serializable(alias("tutor_subjects", list(object(IdNameModel))))
  tutorSubjects?: IdNameModel[];
}
export class InstituteTutorClassRoomSubjectsModel extends IdNameModel {
  @serializable(alias("class_teacher", primitive()))
  classTeacher?: boolean;

  @serializable(alias("class_room_id", primitive()))
  classRoomId?: boolean;

  @serializable(
    alias("tutor_sections", list(object(InstituteTutorSectionsModel)))
  )
  tutorSections?: InstituteTutorSectionsModel[];
}

export class InstituteTutorModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("id", primitive()))
  key?: number;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;

  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("qualification", primitive()))
  qualification?: string;

  @serializable(alias("display_picture_url", primitive()))
  displayPictureUrl?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("is_active", primitive()))
  isActive?: string;

  @serializable(
    alias(
      "tutor_class_rooms",
      list(object(InstituteTutorClassRoomSubjectsModel))
    )
  )
  tutorClassRooms?: InstituteTutorClassRoomSubjectsModel[];

  @serializable(alias("branch", object(BranchModel)))
  branch?: BranchModel;

  @serializable(alias("institutes", object(IdNameModel)))
  institute?: IdNameModel;
}
export class UserInstituteModel extends IdNameModel {
  @serializable(alias("display_logo_url", primitive()))
  displayLogoUrl?: string;
}
export class UserModel {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("first_name", primitive()))
  firstName?: string;
  @serializable(alias("last_name", primitive()))
  lastName?: string;
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;
  @serializable(alias("full_name", primitive()))
  fullName?: string;
  @serializable(alias("date_of_birth", primitive()))
  dateOfBirth?: string;
  @serializable(alias("qualification", primitive()))
  qualification?: string;
  @serializable(alias("display_picture_url", primitive()))
  displayPictureUrl?: string;
  @serializable(alias("gender", primitive()))
  gender?: string;
  @serializable(alias("is_active", primitive()))
  isActive?: boolean;
  @serializable(alias("confirmed_at", primitive()))
  confirmedAt?: string;
  @serializable(alias("institutes", object(IdNameModel)))
  institute?: IdNameModel;
  @serializable(alias("branch", object(BranchModel)))
  branch?: BranchModel;
  @serializable(
    alias(
      "tutor_class_rooms",
      list(object(InstituteTutorClassRoomSubjectsModel))
    )
  )
  tutorClassRooms?: InstituteTutorClassRoomSubjectsModel[];
}

export class UserUpdateModel {

  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;
  @serializable(alias("full_name", primitive()))
  fullName?: string;
}

export class ResetPasswordEmail {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("redirect_url", primitive()))
  redirectUrl?: string;
}

export class UpdatePasswordModel {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("reset_password_token", primitive()))
  resetPasswordToken?: string;

  @serializable(alias("new_password", primitive()))
  newPassword?: string;
}

export class Parent {
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;
  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("step", primitive()))
  step?: number;
  @serializable(alias("current_student", object(Student)))
  currentStudent?: Student;
}
