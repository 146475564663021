export const SET_APP_NAME: string = 'app_name';
export const AUTHENTICATED: string = 'authenticated_user';
export const UNAUTHENTICATED: string = 'unauthenticated_user';
export const AUTHENTICATION_ERROR: string = 'authentication_error';
export const REQUEST_LOGIN: string = "login_request";
export const REQUEST_LOGOUT: string = "logout_request";
export const REQUEST_FORGOT_PASSWORD: string = "forgot_password_request";
export const REQUEST_RESET_PASSWORD: string = "reset_password_request";
export const SET_CURRENT_COURSE: string = 'set_current_course';
export const SET_CURRENT_CLASS_ROOM: string = 'set_current_class_room';
export const SET_TYPE: string = "set_type";
export const SET_STEP: string = "set_step";
export const SET_CURRENT_PROFILE: string = "set_current_profile"
export const SET_RECENT_WATCHED_CONTENT: string = "set_recent_watch_content"
