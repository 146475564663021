import React, { FC, useState } from "react";
import { UserModel } from "../../models/user.model";
import PageView from "../../shared/components/PageView";
import AuthContainer from "../../store/container/AuthContainer";
import MailIcon from "../../assets/icons/mail-blue-dark.svg";
import PhoneIcon from "../../assets/icons/phone-blue-dark.svg";
import PlaceholderIcon from "../../assets/icons/placeholder-dark.svg";
import EditIcon from "../../assets/icons/edit.svg";
import faker from "faker";
import "./profile.scss";
import { Col, Row, Space } from "antd";
import ProfileForm from "./ProfileForm";

interface ProfileProps {
  user: UserModel;
}

const Profile: FC<ProfileProps> = (props) => {
  const { user } = props;
  const [showForm, setFormVisibility] = useState(false);
  return (
    <PageView className="profile">
      <ProfileForm
        data={user}
        closeHandler={() => setFormVisibility(false)}
        visible={showForm}
      />
      <div className="profile__details">
        <img
          className="display-picture"
          src={user?.displayPictureUrl ?? PlaceholderIcon}
        />
        <div className="details">
          <Space className="name">
            <h1>{user?.fullName}</h1>
            <div className="edit" onClick={() => setFormVisibility(true)}>
              <img src={EditIcon} />
            </div>
          </Space>

          <p className="email">
            <img src={MailIcon} /> {user?.email}
          </p>
          <p className="phone-number">
            <img src={PhoneIcon} /> {user?.phoneNumber}
          </p>
        </div>
      </div>
      <div className="profile__content">
        <div className="branch-details">
          {user?.branch?.displayLogoUrl ? (
            <img src={user?.branch?.displayLogoUrl} />
          ) : (
            ""
          )}

          <div className="branch-name__container">
            <p className="branch__name">{user?.branch?.name}</p>
            <p className="institute__name">{user?.institute?.name}</p>
          </div>
        </div>
        <Row className="class-details">
          <Col span={4}>
            <div className="head">Class</div>
          </Col>
          <Col span={4}>
            <div className="head">Sections</div>
          </Col>
          <Col span={16}>
            <div className="head">Subjects</div>
          </Col>
        </Row>
        {user?.tutorClassRooms?.map((classroom) => {
          return classroom?.tutorSections?.map((section) => {
            return (
              <Row className="class-details__values">
                <Col span={4}>
                  <div className="value">{classroom?.name}</div>
                </Col>
                <Col span={4}>
                  <div className="value">{section?.name}</div>
                </Col>
                <Col span={16}>
                  <div className="value">
                    {section?.tutorSubjects?.map((subject, index) => {
                      return `${subject?.name}${
                        section?.tutorSubjects?.length
                          ? section?.tutorSubjects?.length - 1 !== index
                            ? ", "
                            : ""
                          : ""
                      }`;
                    })}
                  </div>
                </Col>
              </Row>
            );
          });
        })}
      </div>
    </PageView>
  );
};

export default AuthContainer(Profile);
