import { serializable, alias, object, list, primitive } from 'serializr';
import { StudentSubjectResult } from './subject.model';

export class StudentClassResult {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('score', primitive()))
  score?: number;

  @serializable(alias('accuracy', primitive()))
  accuracy?: number;

  @serializable(alias('speed', primitive()))
  averageTimePerQuestion?: number;

  @serializable(alias('duration', primitive()))
  exerciseAttemptDuration?: number;

  @serializable(alias('excerciseDone', primitive()))
  numberOfExerciseAttempts?: number;

  @serializable(alias('subjectData', list(object(StudentSubjectResult))))
  studentSubjects?: StudentSubjectResult[] = [];

  @serializable(alias('totalContent', primitive()))
  totalContentCount?: number;

  @serializable(alias('contentDone', primitive()))
  completedContentCount?: number;

  @serializable(alias('totalExcercise', primitive()))
  totalExerciseCount?: number;

  @serializable(alias('excerciseDone', primitive()))
  completedExerciseCount?: number;

  @serializable(alias('excerciseCompleted', primitive()))
  exerciseCompletionPercentage?: number;

  @serializable(alias('contentCompleted', primitive()))
  contentCompletionPercentage?: number;

  @serializable(alias('cummulative_completion_percentage', primitive()))
  cummulativeCompletionPercentage?: number;
}

export class StudentExamResult {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('score', primitive()))
  score?: number;

  @serializable(alias('accuracy', primitive()))
  accuracy?: number;

  @serializable(alias('average_time_per_question', primitive()))
  averageTimePerQuestion?: number;

  @serializable(alias('exercise_attempt_duration', primitive()))
  exerciseAttemptDuration?: number;

  @serializable(alias('number_of_attempts', primitive()))
  numberOfAttempts?: number;

  @serializable(alias('exam_content_count', primitive()))
  totalContentCount?: number;

  @serializable(alias('student_exam_content_count', primitive()))
  completedContentCount?: number;

  @serializable(alias('total_exam_exercise_count', primitive()))
  totalExerciseCount?: number;

  @serializable(alias('attempted_exam_exercise_count', primitive()))
  completedExerciseCount?: number;

  @serializable(alias('exercise_completion_percentage', primitive()))
  exerciseCompletionPercentage?: number;

  @serializable(alias('content_completion_percentage', primitive()))
  contentCompletionPercentage?: number;

  @serializable(alias('cummulative_completion_percentage', primitive()))
  cummulativeCompletionPercentage?: number;
}
