import React, { FC, useRef } from "react";
import { Col, Row, Upload } from "antd";
import { CloseCircleOutlined, PaperClipOutlined } from "@ant-design/icons";
import "./questionAttachment.scss";
import { BASE_URL } from "../../../routes/routeConstants/apiRoutes";
interface QuestionAttachmentProps {
  onAttach: (value: any, list?: any[]) => void;
  multipleAttachments?: boolean;
  data?: any;
  handleDelete?: (index?: number) => void;
}
const QuestionAttachment: FC<QuestionAttachmentProps> = ({
  data,
  multipleAttachments = false,
  onAttach,
  handleDelete,
}) => {
  const attachmentRef = useRef<HTMLInputElement>(null);

  return (
    <div className="question-attachment__container">
      {multipleAttachments ? (
        <Row>
          {data?.map((value: any, index: number) => {
            return (
              <Col span={7}>
                <div className="attached-image__container">
                  <CloseCircleOutlined
                    className="attached-image__close"
                    onClick={() => {
                      handleDelete && handleDelete(index);
                    }}
                  />
                  <img
                    alt=''
                    src={
                      value.questionAttachmentUrl ||
                      value.questionSolutionAttachmentUrl
                    }
                    className="attached__image"
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      ) : (
        data && (
          <div className="attached-image__container">
            <CloseCircleOutlined
              className="attached-image__close"
              onClick={() => handleDelete && handleDelete()}
            />
            <img
              alt=''
              src={data?.questionOptionImageUrl}
              className="attached__image"
            />
          </div>
        )
      )}
      <input
        ref={attachmentRef}
        accept="image/*"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          let file = e.target.files?.length && e.target.files[0];
          onAttach(file);
        }}
      />

      <p
        className="question-attachment__title"
        onClick={() => attachmentRef.current?.click()}
      >
        <span>
          <PaperClipOutlined />
        </span>
        Add attachment
      </p>
    </div>
  );
};
export default QuestionAttachment;
