import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { setTitle } from "../actions/titleAction";

const mapStateToProps = (state: any, ownProps: any) => {
	return {
		showBack: state.nav.showBack,
		title: state.nav.title,
		icon: state.nav.icon,
        subText: state.nav.subText,
        color: state.nav.color,
		showSearch: state.nav.showSearch,
		float: state.nav.float,
		searchText: state.nav.searchText,
		twoBgs: state.nav.twoBgs,
		onSearch: state.nav.onSearch,
        ...ownProps,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			setTitle,
		},
		dispatch
	);

const TitleContainer = (component: any) => {
	return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default TitleContainer;
