import * as React from 'react';
import { Select } from 'antd';
import './dropdown.scss';

interface DropdownProps {
  name?: string;
  placeholder?: string;
  mode?: 'tags' | 'multiple';
  options: any;
  showSearch: boolean;
  value: any;
  onChange: (value: any, option: any) => void;
  optionFilterProp?: string;
  defaultValue?: any;
  className?: string;
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  return (
    <div className='dropdown-field'>
      <Select {...props} />
    </div>
  );
};

export default Dropdown;
