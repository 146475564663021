import React, { useEffect, useState } from "react";
import { AssessmentService } from "../../services/AssessmentService/assessment.service";
import EmptyState from "../../shared/components/EmptyState";
import { DateFormatter } from "../../shared/components/HOC/dateFormatter";
import { NoData } from "../../shared/components/NoData";
import { CloseOutlined } from "@ant-design/icons";
import { UIButton } from "../../shared/components/UIButton";
import "./assessment.scss";
import { AddComment } from "../../shared/components/AddComment";
import { WarningModal } from "../../shared/components/WarningModal";
import { Comment } from "../../shared/components/Comments";
import {
  AssessmentQuestions,
  ExerciseAttemptQuestions,
} from "../../models/assessment.model";

const Assessment = (props: any) => {
  const [exercises, setExercises] = useState<any>([]);
  const [questions, setQuestions] = useState<any>([]);
  const [currentQuestion, setCurrentQuestion] = useState<number>(-1);
  const [showComment, setCommentVisibility] = useState(false);
  const [selectedSwitch, setSelected] = useState("exercise");
  const [selectedExercise, setselectedExercise] = useState(-1);
  const [showModal, setModalVisibility] = useState(false);
  const [comments, setComments] = useState<any>([]);
  const EXAM_EXERCISE_TYPE = "ExamExerciseAttempt";

  const getExercises = () => {
    AssessmentService.fetchExercises(
      (response: any) => {
        console.log(response);
        setExercises(response);
      },
      (error: any) => {
        console.log(error);
      }
    );
  };
  const getAssignments = () => {};
  const onClickAssess = (id: any, type: any) => {
    type == EXAM_EXERCISE_TYPE
      ? AssessmentService.fetchExamExerciseQuestions(
          { id: id },
          (response: any) => {
            console.log(response);
            if (response.length !== 0) {
              setCurrentQuestion(0);
              setQuestions(response);
              evaluateQuery("in_progress", EXAM_EXERCISE_TYPE);
            }
          },
          (error: any) => {
            console.log(error);
          }
        )
      : AssessmentService.fetchExerciseQuestions(
          {
            id: id,
          },
          (response: any) => {
            console.log(response);
            if (response.length !== 0) {
              setCurrentQuestion(0);
              setQuestions(response);
              evaluateQuery("in_progress", "ExerciseAttempt");
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
  };

  const updateQuery = (type: string, payload: any) => {
    console.log(type);
    type == EXAM_EXERCISE_TYPE
      ? AssessmentService.updateExamExerciseQuestion(
          {
            examExerciseAttemptId: exercises[selectedExercise].id,
            examExerciseAttemptQuestionId: questions[currentQuestion].id,
            payload: payload,
          },
          (response: ExerciseAttemptQuestions) => {
            let data = questions;
            data[currentQuestion].isCorrect = response?.isCorrect;
            data[currentQuestion].tutor = response.tutor;
            data[currentQuestion].comment = response.comment;
            setQuestions([...data]);
            setCommentVisibility(true);
          },
          (error: any) => {
            console.log(error);
          }
        )
      : AssessmentService.updateExerciseQuestion(
          {
            exerciseAttemptId: exercises[selectedExercise].id,
            exerciseAttemptQuestionId: questions[currentQuestion].id,
            payload: payload,
          },
          (response: ExerciseAttemptQuestions) => {
            let data = questions;
            data[currentQuestion].isCorrect = response?.isCorrect;
            data[currentQuestion].tutor = response.tutor;
            data[currentQuestion].comment = response.comment;
            setQuestions([...data]);
            setCommentVisibility(true);
          },
          (error: any) => {
            console.log(error);
          }
        );
  };

  const evaluateQuery = (value: string, type: string) => {
    type == EXAM_EXERCISE_TYPE
      ? AssessmentService.evaluateExamExerciseAttempt(
          {
            id: exercises[selectedExercise].id,
            payload: {
              evaluation: value,
            },
          },
          (response: any) => {
            if (value == "completed") {
              let data = exercises.filter(
                (exercise: AssessmentQuestions) =>
                  exercise.id !== exercises[selectedExercise].id
              );
              setExercises(data);
            }
          },
          (error: any) => {}
        )
      : AssessmentService.evaluateExerciseAttempt(
          {
            id: exercises[selectedExercise].id,
            payload: {
              evaluation: value,
            },
          },
          (response: any) => {
            if (value == "completed") {
              let data = exercises.filter(
                (exercise: AssessmentQuestions) =>
                  exercise.id !== exercises[selectedExercise].id
              );
              setExercises(data);
            }
          },
          (error: any) => {}
        );
  };

  useEffect(() => {
    getExercises();
  }, []);

  return exercises.length == 0 ? (
    <div className="page-view">
      <EmptyState text={"No assessments found."} />
    </div>
  ) : (
    <div className="page-view">
      <WarningModal
        visible={showModal}
        onContinue={() => {
          setModalVisibility(false);
        }}
        onDiscard={() => {
          setModalVisibility(false);
        }}
      />
      <p className="assessment__title">Assessments</p>
      <div className="assessment__wrapper">
        <div className="assessment-wrapper__left">
          <div className="assessment-switch__wrapper">
            <div
              className={`assessment--switch ${
                selectedSwitch == "exercise"
                  ? "assessment-switch__selected"
                  : "assessment-switch__unselected"
              }`}
              onClick={() => {
                setSelected("exercise");
                getExercises();
              }}
            >
              Exercise
            </div>
            <div
              className={`assessment--switch ${
                selectedSwitch == "assignment"
                  ? "assessment-switch__selected"
                  : "assessment-switch__unselected"
              }`}
              onClick={() => {
                setSelected("assignment");
                getAssignments();
              }}
            >
              Assignments
            </div>
          </div>
          <div className="assessment-list__wrapper">
            {exercises.map((value: AssessmentQuestions, index: number) => {
              return (
                <div
                  className={`${
                    selectedExercise == index
                      ? "assessment-list__item assessment-list-item__selected"
                      : "assessment-list__item"
                  }`}
                  onClick={() => {
                    if (selectedExercise !== -1) {
                      if (exercises[selectedExercise].evaluation == "ongoing") {
                        setModalVisibility(true);
                      } else {
                        setselectedExercise(index);
                      }
                    }
                  }}
                >
                  <div className="flex-space-between">
                    <div className="assessment-list-item-tag__wrapper">
                      <div className="assessment-list-item__tag">
                        {value?.assessmentType == "ExcerciseAttempt"
                          ? value.tags?.boardName
                          : value.tags?.examName}
                      </div>
                      {value?.assessmentType == "ExcerciseAttempt" && (
                        <div className="assessment-list-item__tag">
                          {value?.tags?.classRoomName}
                        </div>
                      )}

                      <div className="assessment-list-item__tag">
                        {value?.tags?.subjectName}
                      </div>
                    </div>
                    <div>
                      <CloseOutlined />
                    </div>
                  </div>
                  <div className="assessment-list-item__question assessment-list-item--margin">
                    {value.title}
                  </div>
                  <div className="assessment-list-item__datetime assessment-list-item--margin">
                    {value?.completedAt && DateFormatter(value?.completedAt)}
                  </div>
                  {selectedExercise == index ? (
                    <div />
                  ) : (
                    <div
                      className="assessment-list-item__answer assessment-list-item--margin"
                      onClick={() => {
                        setselectedExercise(index);
                        onClickAssess(value.id, value.assessmentType);
                      }}
                    >
                      Assess
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="assessment-wrapper__right">
          {selectedExercise > -1 ? (
            <div className="assessment-have__data">
              <div className="assessment-header__wrapper">
                <div className="assessment-header__left">
                  <div className="assessment-tag__wrapper">
                    <div className="assessment__tag">
                      {exercises[selectedExercise]?.assessmentType ==
                      "ExcerciseAttempt"
                        ? exercises[selectedExercise].tags?.boardName
                        : exercises[selectedExercise].tags?.examName}
                    </div>
                    {exercises[selectedExercise]?.assessmentType ==
                      "ExcerciseAttempt" && (
                      <div className="assessment__tag">
                        {exercises[selectedExercise]?.tags?.classRoomName}
                      </div>
                    )}
                    <div className="assessment__tag">
                      {exercises[selectedExercise]?.tags?.subjectName}
                    </div>
                  </div>
                  <div className="assessment-list-item__question">
                    {exercises[selectedExercise]?.title}
                  </div>
                  <div className="assessment-list-item__datetime">
                    {exercises[selectedExercise]?.completedAt &&
                      DateFormatter(exercises[selectedExercise]?.completedAt)}
                  </div>
                </div>
                <div className="assessment-header__right">
                  <UIButton
                    type="solid"
                    title="Submit"
                    disabled={
                      exercises[selectedExercise].evaluation == "completed"
                    }
                    onClick={() => {
                      evaluateQuery(
                        "completed",
                        exercises[selectedExercise]?.assessmentType
                      );
                      let data = exercises;
                      data[selectedExercise].evaluation = "completed";
                      setExercises([...data]);
                    }}
                  />
                </div>
              </div>
              {currentQuestion > -1 && questions.length > 0 ? (
                <div className="exercise-questions__wrapper">
                  <div className="exercise-questions__counter">
                    {(currentQuestion + 1).toString().padStart(2, "0")}
                    <span>/{questions.length.toString().padStart(2, "0")}</span>
                  </div>
                  <div>
                    <div className="exercise__question">
                      {questions.length > 0 &&
                        questions[currentQuestion].content}
                    </div>
                    <div className="exercise__answer">
                      &emsp;{" "}
                      {questions.length > 0 &&
                        questions[currentQuestion].studentAnswer}
                    </div>
                  </div>
                  {questions[currentQuestion]?.comment &&
                  questions[currentQuestion]?.comment !== "" ? (
                    <Comment
                      data={questions[currentQuestion]}
                      onClickRemove={() =>
                        updateQuery(
                          exercises[selectedExercise].assessmentType,
                          {
                            commentRemove: true,
                          }
                        )
                      }
                    />
                  ) : showComment ? (
                    <AddComment
                      onAttach={() => {}}
                      onClick={(value) => {
                        updateQuery(
                          exercises[selectedExercise].assessmentType,
                          {
                            comment: value,
                          }
                        );
                      }}
                      placeholder="Type your comment here..."
                    />
                  ) : (
                    <div
                      className="exercise-question__addcomment"
                      onClick={() => {
                        setCommentVisibility(true);
                      }}
                    >
                      Add Comment
                    </div>
                  )}

                  <div className="exercise-questions__controls">
                    <div
                      onClick={() => {
                        if (currentQuestion !== 0) {
                          setCommentVisibility(false);
                          setCurrentQuestion(currentQuestion - 1);
                        }
                      }}
                    >
                      {"< Prev"}
                    </div>
                    <UIButton
                      title="Incorrect"
                      type={
                        questions[currentQuestion].isCorrect == "correct"
                          ? "solid"
                          : "outline"
                      }
                      onClick={() => {
                        updateQuery(
                          exercises[selectedExercise].assessmentType,
                          {
                            isCorrect: "no",
                          }
                        );
                      }}
                    />
                    <UIButton
                      title="Correct"
                      type={
                        questions[currentQuestion].isCorrect == "correct"
                          ? "solid"
                          : "outline"
                      }
                      onClick={() => {
                        updateQuery(
                          exercises[selectedExercise].assessmentType,
                          {
                            isCorrect: "correct",
                          }
                        );
                      }}
                    />
                    <div
                      onClick={() => {
                        if (currentQuestion !== questions.length - 1) {
                          setCommentVisibility(false);
                          setCurrentQuestion(currentQuestion + 1);
                        }
                      }}
                    >
                      {"Next >"}
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
          ) : (
            <NoData placeholder={"Select an exercise to assess"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Assessment;
