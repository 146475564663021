import React, { FC } from "react";
import "./deleteLiveClassAlert.scss";
import AlertIcon from "../../../../assets/icons/alert.svg";
import UIModal from "../../../../shared/components/UIModal";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { LiveClassModel } from "../../../../models/liveClass.model";
interface DeleteLiveClassAlertProps {
  visible: boolean;
  successHandler: () => void;
  cancelHandler: () => void;
  isButtonLoading: boolean;
  liveClass?: LiveClassModel;
}

const DeleteLiveClassAlert: FC<DeleteLiveClassAlertProps> = (props) => {
  const { visible, successHandler, cancelHandler, isButtonLoading, liveClass } = props;

  return (
    <UIModal
      visible={visible}
      closeModal={cancelHandler}
      className="delete-live-class-alert__modal"
      noControl
    >
      <div className="delete-live-class-alert">
        <img className="alert-icon" src={AlertIcon} />
        <p className="title">Are you sure to leave the live class ?</p>
        <p className="subtitle">{liveClass?.title}</p>
        <div className="controllers">
          <ButtonComponent
            buttonContent={"Confirm"}
            onClick={successHandler}
            type="default"
            loading={isButtonLoading}
            disabled={isButtonLoading}
          />
          <ButtonComponent
            buttonContent={"Cancel"}
            onClick={cancelHandler}
            type="primary"
          />
        </div>
      </div>
    </UIModal>
  );
};

export default DeleteLiveClassAlert;
