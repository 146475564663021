import React, { FC } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-classic-with-mathtype";
import "ckeditor5-classic-with-mathtype/build/translations/en"
import "./ckEditorComponent.scss";
interface CKEditorComponentProps {
  title?: string;
  value: any;
  onChange?: (data: any) => void;
  disabled?: boolean;
}
const CKEditorComponent: FC<CKEditorComponentProps> = ({
  value,
  onChange,
  disabled = false,
  title,
}) => {

  return (
    <div className="ck-editor-component__container">
      <p className="ck-editor__title">{title}</p>
      <CKEditor
        disabled={disabled}
        config={{
          toolbar: {
            items: [
              "heading",
              "MathType",
              "ChemType",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "undo",
              "redo",
            ],
          },
        }}
        editor={ClassicEditor}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange && onChange(data);
        }}
      />
    </div>
  );
};
export default CKEditorComponent;
