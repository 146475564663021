type KEY = "ACCESS_TOKEN" | "REFRESH_TOKEN" | "USER" | "COURSE_ID" | "CURRENT_CLASS_ROOM" | 'RECENT_WATCHED_CONTENT';

export class LocalStorage {
    static setItem(key: KEY, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static getItem(key: KEY)  {
        const data = localStorage.getItem(key);
        if(data) {
            return JSON.parse(data);
        }
        return null
    }
}
