import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import {  setUnAuthenticated, setAppName, setCurrentCourse, setType, setCurrentProfile, setAuthenticated, setCurrentClassRoom, setRecentWatchedContent } from "../actions/auth";

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        authenticated: state.auth.authenticated,
        user: state.auth.user,
        currentClassRoom: state.auth.currentClassRoom,
        currentCourse: state.auth.currentCourse,
        type :state.auth.type,
        app_name:state.auth.app_name,
        recentWatchedContent: state.auth.recentWatchedContent,
        ...ownProps
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    setUnAuthenticated,
    setCurrentCourse,
    setCurrentClassRoom,
    setType,
    setAuthenticated,
    setAppName,
    setRecentWatchedContent
}, dispatch);

const AuthContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

export default AuthContainer;
