import React, { FC, useEffect, useRef, useState } from 'react';
import 'video-react/dist/video-react.css';

import { LocalStorage } from '../../../../shared/utils/LocalStorageHelpers';
import './videoPlayer.scss';
import ReactPlayer from 'react-player';
import { UserModel } from '../../../../models/user.model';
import AppIcon from '../../../../assets/background-images/app-icon-white.svg';
import { NET_BASE_URL } from '../../../../routes/routeConstants/apiRoutes';
import AuthContainer from '../../../../store/container/AuthContainer';
import { ContentHistoryService } from '../../../../services/ContentHistoryService/contentHistory.service';
import { ContehtHistoryModel } from '../../../../models/Learn/ContentHistory/contentHistory';
interface VideoPlayerProps {
  fileUrl: string;
  user: UserModel;
  authenticated: boolean;
  contentLibraryId?: number;
  setRecentWatchedContent: (payload) => void;
  recentWatchedContent: any;
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  fileUrl,
  user,
  contentLibraryId,
  recentWatchedContent,
  setRecentWatchedContent,
}) => {
  const playerRef = useRef<any>({});
  const [isReady, setIsReady] = useState<boolean>(false);

  const onReady = () => {
    setIsReady(true);
  };

  function toggleFullscreen() {
    if (!document.fullscreenElement) {
      document.getElementById('vid-cont')?.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  function handleAESencryption(xhr, url) {
    if (url.includes('mindjjo.com')) {
      url = `${NET_BASE_URL}/Content/GetKeyById?contentLibraryId=${contentLibraryId}`;
      xhr.open('GET', url, true);
    }
    if (url.includes('GetKey')) {
      const accessToken = LocalStorage.getItem('ACCESS_TOKEN');
      xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    }
  }

  useEffect(() => {
    if (contentLibraryId) {
      ContentHistoryService.createRecentlyWatchedHistory(
        { content_library_id: contentLibraryId },
        (contentHistory: ContehtHistoryModel) => {
          setRecentWatchedContent(contentHistory);
          const timeToStart = contentHistory?.playedSeconds || 0;
          playerRef.current.seekTo(timeToStart, 'seconds');
        },
        () => {}
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLibraryId, isReady]);

  useEffect(() => {
    return () => {
      let history = LocalStorage.getItem('RECENT_WATCHED_CONTENT');
      if (history) {
        ContentHistoryService.updateContentHistory(
          history,
          { id: history.id },
          () => {
            localStorage.removeItem('RECENT_WATCHED_CONTENT');
          },
          () => {}
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentWatchedContent]);

  const updateLastPlayed = (payload: any) => {
    setRecentWatchedContent({
      ...recentWatchedContent,
      playedSeconds: payload.playedSeconds,
    });
  };

  return (
    <div className='video-container' id='vid-cont'>
      <div className='react-player'>
        <ReactPlayer
          controls={true}
          className='player-wrapper'
          url={fileUrl}
          width='100%'
          height='100%'
          onContextMenu={(e) => e.preventDefault()}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload nofullscreen',
                disablepictureinpicture: 'true',
              },
              hlsOptions: {
                forceHLS: true,
                debug: false,
                xhrSetup: (xhr, url) => handleAESencryption(xhr, url),
              },
            },
          }}
          ref={playerRef}
          onReady={onReady}
          onProgress={(payload) => updateLastPlayed(payload)}
        />
      </div>
      <div className='marquee'>
        <div>
          <p>{user?.firstName}</p>
          <p>STD{user?.id}</p>
        </div>
      </div>
      <div className='watermark'>
        <img src={AppIcon} alt='' />
      </div>

      <button className='fullscreen-button' onClick={() => toggleFullscreen()}>
        Toggle Full Screen View
      </button>
    </div>
  );
};

export default AuthContainer(VideoPlayer);
