import { serializable, alias, object, list, primitive } from 'serializr';
export class PaperAttempts {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('title', primitive()))
  title?: string;

  @serializable(alias('description', primitive()))
  description?: string;

  @serializable(alias('timer_options', primitive()))
  timerOptions?: string;

  @serializable(alias('time', primitive()))
  time?: number;

  @serializable(alias('started_at', primitive()))
  startedAt?: string;

  @serializable(alias('completed_at', primitive()))
  completedAt?: string;
}

export class Paper {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('title', primitive()))
  title?: string;

  @serializable(alias('deadline', primitive()))
  deadline?: string;

  @serializable(alias('correct_answer_point', primitive()))
  correctAnswerPoint?: number;

  @serializable(alias('penalty_point', primitive()))
  penaltyPoint?: number;

  @serializable(alias('timer_options', primitive()))
  timerOptions?: string;

  @serializable(alias('time', primitive()))
  time?: number;

  @serializable(alias('is_active', primitive()))
  isActive?: string;

  @serializable(alias('month', primitive()))
  month?: string;

  @serializable(alias('year', primitive()))
  year?: number;

  @serializable(alias('paper_questions_count', primitive()))
  paperQuestionsCount?: number;
}
export class PaperResult {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('score', primitive()))
  score?: number;

  @serializable(alias('accuracy', primitive()))
  accuracy?: number;

  @serializable(alias('average_time_per_question', primitive()))
  averageTimePerQuestion?: number;

  @serializable(alias('duration', primitive()))
  duration?: number;

  @serializable(alias('number_of_attempts', primitive()))
  numberOfAttempts?: number;

  @serializable(alias('paper_id', primitive()))
  paperId?: number;

  @serializable(alias('paper_title', primitive()))
  paperTitle?: string;

  @serializable(alias('paper', object(Paper)))
  paper?: Paper;

  @serializable(alias('paper_attempts', list(object(PaperAttempts))))
  paperAttempts?: PaperAttempts[] = [];
}
