import { Modal } from "antd";
import React, { FC } from "react";
import "./metricsDescription.scss";
import ScoreIcon from "../../../assets/icons/reward.svg";
import AccuracyIcon from "../../../assets/icons/dart.svg";
import SpeedIcon from "../../../assets/icons/sketch.svg";
import UIButton from "../UIButton";
interface MetricsDescriptionProps {
  visible: boolean;
  closeHandler: () => void;
}
const MetricsDescription: FC<MetricsDescriptionProps> = ({
  visible,
  closeHandler,
}) => {
  return (
    <Modal
      className="metrics-description"
      visible={visible}
      onCancel={closeHandler}
      centered
    >
      <div className="metrics-description__wrapper">
        <div className="metric__container">
          <div className="metric___image">
            <img src={ScoreIcon} />
          </div>
          <div className="metric-desc__container">
            <p className="metric__name">Score</p>
            <p>Correct answers / Total questions</p>
          </div>
        </div>
        <div className="metric__container">
          <div className="metric___image">
            <img src={AccuracyIcon} />
          </div>
          <div className="metric-desc__container">
            <p className="metric__name">Accuracy</p>
            <p>Correct answers / attempted questions</p>
          </div>
        </div>
        <div className="metric__container">
          <div className="metric___image">
            <img src={SpeedIcon} />
          </div>
          <div className="metric-desc__container">
            <p className="metric__name">Speed</p>
            <p>attempted questions / time taken</p>
          </div>
        </div>
        <div className="metric__btn">
          <UIButton type="button" info clickHandler={closeHandler}>Okay</UIButton>
        </div>
      </div>
    </Modal>
  );
};

export default MetricsDescription;
