import moment from 'moment'

export const DateFormatter = (date?: string) => {
    const convertUTC = moment.utc(date ? date : moment().utc())
    let month = convertUTC.month().toString()
    month = moment(month, 'M').format('MMM')
    let day = convertUTC.date()
    let year = convertUTC.year()
    let time = convertUTC.format("h:mm a")
    return `${month} ${day}, ${year} at ${time}`
}