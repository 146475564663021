import { Pagination } from "antd";
import React, { FC, useEffect } from "react";
import './pagination.scss';

interface PaginationComponentProps {
    handlePageChange: (page: number, pageSize?: number) => void;
    paginationMeta: any;
}

const PaginationComponent: FC<PaginationComponentProps> = (props) => {

    const { handlePageChange, paginationMeta } = props;    

    return (
        <div className="course__pagination">
            <Pagination onChange={handlePageChange}
                current={paginationMeta?.currentPage}
                total={paginationMeta?.totalCount || 0}
                size={"small"}
                hideOnSinglePage={true}
                pageSize={Number(paginationMeta?.pageSize||(paginationMeta?.totalCount / paginationMeta?.totalPages).toFixed()) || 0}
            />
        </div>
    )
}

export default PaginationComponent;
