import React, { useEffect, useState, useRef } from "react";
import { QueryService } from "../../../services/QueryService/queries.service";
import { AddComment } from "../../../shared/components/AddComment";
import { Conversation } from "../../../shared/components/Conversation";
import EmptyState from "../../../shared/components/EmptyState";
import { UIButton } from "../../../shared/components/UIButton";
import "../queries.scss";
import { DateFormatter } from "../../../shared/components/HOC/dateFormatter";
import { NoData } from "../../../shared/components/NoData";
import { CloseOutlined } from "@ant-design/icons";
import { Query } from "../../../models/query.model";
import Modal from "antd/lib/modal/Modal";
import { ArrowRightOutlined } from "@ant-design/icons";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

export const OpenQuestions = () => {
    const [queries, setQueries] = useState<Query[]>([]);
  const [selectedQuery, setSelectedQuery] = useState(-1);
  const [attachment, setAttachment] = useState<any>();
  const attachmentRef = useRef<HTMLInputElement>(null);
  const [
    confirmationModalVisibility,
    setConfirmationModalVisibility,
  ] = useState(false);
  const getAllQueries = () => {
    QueryService.fetchAllQueries(
      (response: any) => {
        setQueries(response);
      },
      (error: any) => {
        console.log(error);
      }
    );
  };
  const createComment = (content: string) => {
    if (content !== "") {
      let jsonData = {
        comment: {
          content,
          attachment,
        },
      };
      let formData = convertJSONToFormData(jsonData);
      console.log(formData);
      QueryService.createComment(
        queries[selectedQuery].id,
        formData,
        () => {
            getAllQueries()
        },
        () => {}
      );
    } else {
      Notification({
        message: "Comment not sent",
        description: "Please add some content",
        type: NotificationTypes.ERROR,
      });
    }
  };
  const onMarkAsResolved = () => {
    QueryService.updateQuery(
      {
        id: queries[selectedQuery].id,
        isResolved: !queries[selectedQuery].isResolved,
      },
      () => {
        getAllQueries();
        setConfirmationModalVisibility(false);
      },
      () => {}
    );
  };
  useEffect(() => {
    getAllQueries();
  }, []);
  return queries?.length == 0 ? (
    <div className="page-view">
      <EmptyState text={"No queries found."} />
    </div>
  ) : (
    <div className="query-open-quesions">
          <Modal
        className="query__modal"
        footer={null}
        title={null}
        visible={confirmationModalVisibility}
        centered
      >
        <p>Are you sure to mark the question resolved</p>
        <div className="query-modal__btns">
          <UIButton
            type="ghost"
            title="Cancel"
            className="m-10 w-40"
            onClick={() => {
              setConfirmationModalVisibility(false);
            }}
          />
          <UIButton
            type="solid"
            title="Mark resolved"
            className="m-10 w-40"
            onClick={onMarkAsResolved}
          />
        </div>
      </Modal>
      <div className="query-list__wrapper">
        {queries &&
          queries.map((value: Query, index: number) => {
            return (
              <div
                className={`${
                  selectedQuery == index
                    ? "query-list__item query-list-item__selected"
                    : "query-list__item"
                }`}
                onClick={() => {
                  setSelectedQuery(index);
                }}
              >
                <div className="flex-space-between">
                  <div className="query-list-item-tag__wrapper">
                    <div className="query-list-item__tag">
                      {value.relatedSubject?.type == "class_room_subject"
                        ? value.relatedSubject.classRoomSubject?.classRoom
                            ?.boardAcronym
                        : value.relatedSubject?.examSubject?.examName}
                    </div>
                    {value.relatedSubject?.type == "class_room_subject" && (
                      <div className="query-list-item__tag">
                        {value.relatedSubject.classRoomSubject?.classRoomName}
                      </div>
                    )}

                    <div className="query-list-item__tag">
                      {value.relatedSubject?.type == "class_room_subject"
                        ? value.relatedSubject.classRoomSubject?.subjectName
                        : value.relatedSubject?.examSubject?.subjectName}
                    </div>
                  </div>
                  <div>
                    <CloseOutlined />
                  </div>
                </div>
                <div className="query-list-item__question query-list-item--margin">
                  {value.content}
                </div>
                <div className="query-list-item__datetime query-list-item--margin">
                  {DateFormatter(value.createdAt)}
                </div>
                {selectedQuery == index ? (
                  <div />
                ) : (
                  <div
                    className="query-list-item__answer query-list-item--margin"
                    onClick={() => setSelectedQuery(index)}
                  >
                    Answer{" "}
                    <span className="query-list-item-answer__arrow">
                      <ArrowRightOutlined />
                    </span>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      <div className="query-wrapper__right">
        {selectedQuery > -1 ? (
          <div className="query-have__data">
            <div className="query-header__wrapper">
              <div className="query-header__left">
                <div className="query-tag__wrapper">
                  <div className="query__tag">
                    {
                    queries[selectedQuery].relatedSubject?.type ==
                      "class_room_subject"
                      ? queries &&
                        queries[selectedQuery].relatedSubject?.classRoomSubject
                          ?.classRoom?.boardAcronym
                      :
                        queries &&
                        queries[selectedQuery].relatedSubject?.examSubject
                          ?.examName}
                  </div>
                  {queries &&
                    queries[selectedQuery].relatedSubject?.type ==
                      "class_room_subject" && (
                      <div className="query__tag">
                        {queries &&
                          queries[selectedQuery].relatedSubject
                            ?.classRoomSubject?.classRoomName}
                      </div>
                    )}
                  <div className="query__tag">
                    {queries &&
                    queries[selectedQuery].relatedSubject?.type ==
                      "class_room_subject"
                      ? queries &&
                        queries[selectedQuery].relatedSubject?.classRoomSubject
                          ?.subjectName
                      : queries &&
                        queries[selectedQuery].relatedSubject?.examSubject
                          ?.subjectName}
                  </div>
                </div>
                <div className="query-list-item__question">
                  {
                    queries &&
                    queries[selectedQuery].content}
                </div>
                <div className="query-list-item__datetime">
                  {DateFormatter(queries && queries[selectedQuery].createdAt)}
                </div>
              </div>
              <div className="query-header__right">
                <UIButton type="solid" title={queries && queries[selectedQuery].isResolved ? "Resolved" : "Mark as Resolved"} onClick={() => setConfirmationModalVisibility(true)}/>
              </div>
            </div>
            <div className="conversation__wrapper">
              <Conversation
                data={queries && queries[selectedQuery].comments}
              />
            </div>
            <input
              accept="image/*"
              type="file"
              ref={attachmentRef}
              style={{ display: "none" }}
              onChange={(e) => {
                let file = e.target.files?.length && e.target.files[0];
                setAttachment(file);
              }}
            />
            <AddComment
              placeholder="Add Comment"
              onClick={(value) => createComment(value)}
              onAttach={() => attachmentRef.current?.click()}
            />
          </div>
        ) : (
          <NoData placeholder={"Select a question to answer"} />
        )}
      </div>
    </div>
  );
};
