import React, { FC, useEffect, useState } from "react";
import "./liveClassMeeting.scss";
import {
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
  createMicrophoneAudioTrack
} from "agora-rtc-react";
import VideoCall from "./VideoCall";
import ChannelForm from "./ChannelForm";
import { useHistory, useParams } from "react-router";
import LiveClassService from "../../../services/LiveClassService/LiveClass.service";
import { JoinLiveClassResponseModel } from "../../../models/liveClass.model";
import { LoadingComponent } from "../../../shared/components/LoadingComponent";

const config: ClientConfig = {
  mode: "rtc",
  codec: "vp8",
};

export const useClient = createClient(config);
export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
export const useMicrophoneTrack = createMicrophoneAudioTrack();
 interface LiveClassMeetingProps {}

const LiveClassMeeting: FC<LiveClassMeetingProps> = (props) => {
  const {} = props;
  const [channel, setChannel] = useState<JoinLiveClassResponseModel>({});
  const [joinLoading, setJoinLoading] = useState(true);
  const params: any = useParams();
  const history = useHistory();

  const joinLiveClass = () => {
    if (params?.liveClassId) {
      LiveClassService.joinLiveClass(
        params?.liveClassId,
        (response) => {
          setChannel(response);
          setJoinLoading(false);
        },
        () => {},
        () => {}
      );
    }
  };

  useEffect(() => {
    joinLiveClass();
  }, []);

  const leaveHandler = () => {
    history.goBack();
  };

  return (
    <div className="live-class-meeting">
      {joinLoading ? (
        <LoadingComponent title={"Loading meeting..."} />
      ) : (
        <VideoCall
          leaveCall={leaveHandler}
          channel={channel}
        />
      )}
    </div>
  );
};

export default LiveClassMeeting;
