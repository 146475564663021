import React, { FC } from "react";
import { ExerciseAttemptQuestions } from "../../../models/assessment.model";
import './comments.scss'
interface CommentsProps {
  data: ExerciseAttemptQuestions;
  onClickRemove: () => void
}
export const Comment: FC<CommentsProps> = ({ data, onClickRemove }) => {
  return (
    // <div className="comments">
    //   {comments?.map((value, index) => {
    //     return (
          <div className="comments__item">
            <div className="comments-item-header__wrapper">
              <div className="comments-item-header__left">
                <img src={data.tutor?.displayPictureUrl} />
                <p>{data.tutor?.fullName}</p>
              </div>
              <div className="comments-item-header__right" onClick={onClickRemove}>Remove</div>
            </div>
            <div className="comments-item__comment">{data.comment}</div>
          </div>
    //     );
    //   })}
    // </div>
  );
};
