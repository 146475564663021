import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { CreateComment, Query } from "../../models/query.model";
import { CREATE_COMMENT, LIST_ANSWERS, LIST_QUERIES, UPDATE_QUERY } from "../../routes/routeConstants/apiRoutes";

export class QueryService {
    static fetchAllQueries(
        onSuccess: Function,
        onError: Function
    ) {
        return axiosInstance
        .get(LIST_QUERIES)
        .then(response => {
            const data = deserialize(Query, response["data"]["queries"])
            onSuccess(data)
        })
        .catch(e => {
            onError(e)
        })
    }
    static fetchAnswers(
        onSuccess: Function,
        onError: Function
    ) {
        return axiosInstance
        .get(LIST_ANSWERS)
        .then(response => {
            const data = deserialize(Query, response["data"]["queries"])
            onSuccess(data)
        })
        .catch(e => {
            onError(e)
        })
    }
    static createComment (
        id: any,
        data: any,
        onSuccess: Function,
        onError: Function
    ) {
        // const serializedComment = Object.assign(new CreateComment, data)
        // const payload = {comment: serialize(serializedComment)}
        return axiosInstance
        .post(generatePath(CREATE_COMMENT, {queryId: id}), data, {headers:{'Content-Type':'multipart/form-data'}})
        .then(response => {
            onSuccess(response)
        })
        .catch(e => {
            onError(e)
        })
    }
    static updateQuery (
        data: any,
        onSuccess: Function,
        onError: Function
    ) {
        const serializeData = serialize(Query, data)
        const payload = {query: serializeData}
        return axiosInstance
        .put(generatePath(UPDATE_QUERY, {queryId: data.id}), payload)
        .then(response => {
            onSuccess(response)
        })
        .catch(e => {
            onError(e)
        })
    }
}