import {Switch} from "antd";
import React, {FC} from "react";
import './toggleSwitch.scss';

interface ToggleSwitchProps{
    checked: boolean;
    handleChange: (checked:boolean)=>void;
}

const ToggleSwitch:FC<ToggleSwitchProps> = (props) => {

    const {checked,handleChange} = props;

    return (
        <Switch checked={checked}
                onChange={handleChange}
        />
    )
}

export default ToggleSwitch;
