import { FileMetaModel } from "../../models/worksheet.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class FileDownloadService {

    static fileDownload(
        file: FileMetaModel,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        if (file?.path !== undefined)
            fetch(file?.path)
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    let objectURL = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = objectURL;
                    document.body.appendChild(link);
                    link.setAttribute('download', file?.name ?? "");
                    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                    // Remove link from body
                    document.body.removeChild(link);
                    onSuccess();
                }).catch((error) => {
                    console.log(error);
                    
                    onError(error);
                    Notification({
                        message: "Error",
                        description: "Something went wrong!",
                        type: NotificationTypes.ERROR,
                    });
                }).finally(() => {
                    onFinal();
                });

    }
}