import React, { FC, useEffect, useRef, useState } from "react";
import "./submissionDetails.scss";
import { Divider, Modal, Table } from "antd";
import closeIcon from "../../../../assets/icons/close.svg";

import ReportService from "../../../../services/ReportsService/Report.service";
import { SubmissionDetailsModel } from "../../../../models/Report/report.model";
import { SkeletonLoader } from "../../../LiveClass";




interface SubmissionDetailsProps {
  visible: boolean;
  setVisible: Function;
  setCurrentContent: Function;
  content: any;
}

const SubmissionDetails: FC<SubmissionDetailsProps> = (props) => {
  const { visible, setVisible, content, setCurrentContent } = props;

  const [activityDetails, setActivityDetails] = useState<SubmissionDetailsModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchStudentDetails = () => {
    setLoading(true)
    ReportService.fetchActivitySubmissionDetails(
      {ActivityId:content?.activityId},
      (details) => {
        setActivityDetails(details);
        setLoading(false)
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  }



  useEffect(() => {   
    if (content?.activityId) {
      fetchStudentDetails()
    }
  }, [visible]);


  return (
    <Modal
      title={null}
      centered
      visible={visible}
      onCancel={() => {
        setVisible(false)
        setCurrentContent("")
      }}
      footer={null}
      // className="board-form__modal video-content__modal"
      closeIcon={<img src={closeIcon} alt="" />}
      width={1000}
    >
      {loading ? (
        <div className="activity-report">
          <SkeletonLoader />
        </div>
      ) : (<div>
        <div className="board-form__header">
          <div className="board-form__title">{content?.activityName}</div>
        </div>
        {activityDetails?.length ? (
          <Table
            bordered
            dataSource={activityDetails}
            columns={[
              {
                title: 'Name',
                dataIndex: 'studentName',
                key: 'name',
              },
              {
                title: 'Section',
                dataIndex: 'sectionName',
                key: 'sectionName',
              },
              {
                title: 'Subject',
                dataIndex: 'subjectName',
                key: 'subjectName',
              },
              {
                title: 'Max Points',
                dataIndex: 'maxPoints',
                key: 'maxPoints',
              },

              {
                title: 'points',
                dataIndex: 'points',
                key: 'points',
              },
              {
                title: 'TurnIn Count',
                dataIndex: 'turnInCount',
                key: 'turnInCount',
              },


            ]}
            pagination={{ defaultPageSize: 10 }}
          />
        ) : (
          <div className="no-activity-report-text">No Activity Summary !</div>
        )}
      </div>)
      }




    </Modal>
  );
};

export default SubmissionDetails;
