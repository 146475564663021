import axiosInstance from "../../interceptor/axiosInstance";
import {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_EMAIL,
  USER_LOGIN,
  USER_LOGOUT,
} from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import {
  LoginCredentials,
  ResetPasswordEmail,
  UpdatePasswordModel,
  UserModel,
} from "../../models/user.model";
import { store } from "../../store";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { LocalStorage } from "../../shared/utils/LocalStorageHelpers";

export default class AuthService {
  static loginUser(
    data: LoginCredentials,
    onSuccess: (tutor: UserModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = { tutor: serialize(LoginCredentials, data) };
    return axiosInstance
      .post(USER_LOGIN, payload)
      .then((response) => {
        const userDetails = deserialize(UserModel, response.data["tutor"]);
        if (userDetails?.confirmedAt) {
          store.dispatch({
            type: AUTHENTICATED,
            payload: {
              authenticated: true,
              user: userDetails,
            },
          });
          const token = response.data["token"];
          LocalStorage.setItem("ACCESS_TOKEN", token?.access_token);
          LocalStorage.setItem("REFRESH_TOKEN", token?.refresh_token);
          LocalStorage.setItem("USER", userDetails);
          Notification({
            message: "Login",
            description: "Logged in successfully",
            type: NotificationTypes.SUCCESS,
          });
        }

        onSuccess(userDetails);
      })
      .catch((error) => {
        Notification({
          message: "Login failed",
          description: "incorrect email or password",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static logoutUser(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .delete(USER_LOGOUT)
      .then(() => {
        localStorage.clear();
        Notification({
          message: "Logout",
          description: "Logged out successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Logout failed",
          description: "Unable to logout",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static forgotPassword(
    data: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .post(FORGOT_PASSWORD, data)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Error",
          description: "Unable to send email",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static resetPassword(
    data: UpdatePasswordModel,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = { tutor: serialize(UpdatePasswordModel, data) };
    return axiosInstance
      .put(RESET_PASSWORD, payload)
      .then((response) => {
        Notification({
          message: "Success",
          description: "Password updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
        const {response} = error;
        Notification({
          message: "Failed",
          description:  response?.data?.error,
          type: NotificationTypes.ERROR,
        });
      })
      .finally(onFinal);
  }

  static sendPasswordResetEmail(
    data: ResetPasswordEmail,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = { tutor: serialize(ResetPasswordEmail, data) };
    return axiosInstance
      .post(RESET_PASSWORD_EMAIL, payload)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Error",
          description: "Unable to send email",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }
}
