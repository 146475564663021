import { Skeleton } from "antd";
import React, { FC, useEffect, useState } from "react";
import { generatePath, useHistory, useLocation } from "react-router";


import { WorksheetModel } from "../../../../models/worksheet.model"
import { UserModel } from "../../../../models/user.model";
import AuthContainer from "../../../../store/container/AuthContainer";

import "./activityPerformance.scss";
import { ACTIVITY_TURNED ,ACTIVITY_ASSIGNED} from "../../../../routes/routeConstants/appRoutes";

interface ActivityPerformanceProps {
  data: WorksheetModel;
}

const ActivityPerformance: FC<ActivityPerformanceProps> = (props) => {
  const history = useHistory();
  const { data } = props;

  return (
    <div className="activity-performance">
      <div className="activity-performance-content__card active-points">
        <div className="activity-performance-content__count">
          <p className="title">Points</p>
          <p className="value">{data?.points}</p>

        </div>
      </div>
      <div
        className="activity-performance-content__card active-users"
        onClick={() => {
          if (data?.assignedCount)
            history.push(generatePath(ACTIVITY_ASSIGNED, { worksheetId: data?.worksheetId }), data)
        }}
      >
        <div className="activity-performance-content__count">
          <p className="title">Assigned</p>
          <p className="value">{data?.assignedCount}</p>

        </div>
      </div>
      <div
        className="activity-performance-content__card time-spent"
        onClick={() => {
          if (data?.turnitCount)
            history.push(generatePath(ACTIVITY_TURNED, { worksheetId: data?.worksheetId }), data)
        }}>
        <div className="activity-performance-content__count">
          <p className="title">Turned In</p>
          <p className="value">
            {data?.turnitCount}
          </p>
        </div>
      </div>
    </div>

  );
};

export default AuthContainer(ActivityPerformance);
