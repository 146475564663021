import { alias, object, primitive, serializable } from 'serializr';
import { Subject } from '../../Query/query.model';
import { ClassRoom, Exam } from '../../Student/student.model';
class ExamSubjectDetail {
  @serializable(alias('display_picture_url', primitive()))
  img?: number;

  @serializable(alias('name', primitive()))
  name?: number;
}
export class ExamSubjectModel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('subject_name', primitive()))
  name?: string;

  @serializable(alias('subject', object(Subject)))
  subject?: Subject;
}

export class SubjectModel {
  @serializable(alias('id', primitive()))
  id?: number;
  @serializable(alias('subject_name', primitive()))
  name?: string;
  @serializable(alias('amount_paise', primitive()))
  amountPaise?: string;
  @serializable(alias('amount_rupees', primitive()))
  amountRupees?: string;
  @serializable(alias('amount', primitive()))
  amount?: number;
  @serializable(alias('is_active', primitive()))
  isActive?: boolean;
  @serializable(alias('class_room', object(ClassRoom)))
  classRoom?: ClassRoom;
  @serializable(alias('subject', object(Subject)))
  subject?: Subject;
  @serializable(alias('subject', object(Exam)))
  exam?: Exam;
  @serializable(alias('chaptor_count', primitive()))
  chapterCount?: number;
  @serializable(alias('exam_chapter_count', primitive()))
  examChapterCount?: number;
  @serializable(alias('cummulative_completion_percentage', primitive()))
  cummulativeCompletionPercentage?: number;
  @serializable(alias('exam_completion_percentage', primitive()))
  examExerciseCompletionPercentage?: number;
  @serializable(alias('exam_content_completion_percentage', primitive()))
  examContentCompletionPercentage?: number;
  @serializable(alias('exercise_completion_percentage', primitive()))
  exerciseCompletionPercentage?: number;
  @serializable(alias('content_completion_percentage', primitive()))
  contentCompletionPercentage?: number;

  @serializable(alias('display', primitive()))
  display?: string;
}
