import React from "react";
import Modal from "antd/lib/modal/Modal";
import { WarningOutlined } from "@ant-design/icons";
import './warningmodal.scss'
import { UIButton } from "../UIButton";
interface Props {
    visible: boolean,
    onContinue: () => void,
    onDiscard: () => void
}
export const WarningModal = (props: Props) => {
    const {visible, onContinue, onDiscard} = props
  return (
    <Modal title="" visible={visible} className="warning__modal" footer={null}>
      <WarningOutlined className="warning__icon"/>
      <p>Please assess all the questions to complete the assessment.</p>
      <div className="warning-btn__wrapper"> 
        <UIButton type="ghost" title={"Discard"} onClick={onDiscard}/>
        <UIButton type="solid" title={"Continue"} onClick={onContinue}/>
      </div>
    </Modal>
  );
};
