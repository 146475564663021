import React, { FC } from 'react';
import Viewer from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

interface PDFReaderProps {
  fileUrl: string;
}

const PDFReader: FC<PDFReaderProps> = ({ fileUrl }) => {
  return (
    <div className='pdf-content'>
      <div style={{ height: '750px' }}>
        {fileUrl && <Viewer fileUrl={fileUrl} />}
      </div>
    </div>
  );
};

export default PDFReader;
