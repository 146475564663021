import React, { FC } from "react";
import { Dropdown, Menu } from "antd";
import menuIcon from "../../../assets/icons/menu-icon.svg";
import menuIconWhite from "../../../assets/icons/menu-icon-white.svg";
import "./cardMenu.scss";

interface CardMenuProps {
  menuText: string;
  handleEdit: Function;
  handleDelete: Function;
  handleVisibleChange?: Function;
  useMenuIconWhite?: boolean;
}

const CardMenu: FC<CardMenuProps> = (props) => {
  const {
    menuText,
    handleEdit,
    handleDelete,
    handleVisibleChange,
    useMenuIconWhite,
  } = props;

  const menu = (
    <Menu className="card__menu">
      <Menu.Item
        key="edit"
        onClick={() => {
          handleEdit(true);
        }}
      >
        Edit {menuText}
      </Menu.Item>
      <Menu.Item
        key="delete"
        onClick={() => {
          handleDelete(true);
        }}
      >
        Delete {menuText}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement={"bottomRight"}
      className="card-menu__dropdown"
      onVisibleChange={(visible) =>
        handleVisibleChange && handleVisibleChange(visible)
      }
    >
      <img
        src={useMenuIconWhite ? menuIconWhite : menuIcon}
        alt=""
      />
    </Dropdown>
  );
};

export default CardMenu;
