import { TimePicker } from "antd";
import { Field } from "formik";
import moment from "moment";
import React, { FC } from "react";
import "./timePickerField.scss";
import ClockIcon from "../../../assets/icons/clock-filled.svg"

interface TimePickerFieldProps {
  title?: string;
  name: string;
  placeholder: string;
  className?: string;
  value?: string | null;
  format: string;
  onSelect: (date: moment.Moment | null) => void;
}

const TimePickerField: FC<TimePickerFieldProps> = (props) => {
  const {
    title,
    className,
    value,
    onSelect,
    name,
    format,
    ...rest
  } = props;

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className={`time-picker-field ${className}`}>
          <label htmlFor={name}>{title}</label>
          <TimePicker
            value={value ? moment(value, format) : null}
            format={format}
            onChange={(value) => onSelect(value)}
            suffixIcon={<img src={ClockIcon}/>}
            {...rest}
          />
        </div>
      )}
    </Field>
  );
};

export default TimePickerField;
