import { Col, Row } from "antd";
import React, { FC, Fragment } from "react";

import UsernameIcon from "../../../../assets/icons/username.svg";
import pdfIcon from "../../../../assets/icons/pdf-chapter-icon.svg";

import { WorksheetModel } from "../../../../models/worksheet.model";

// styles
import "./activityTurnedCard.scss";

interface TurnedListsProps {
  data: WorksheetModel;
  onClick?: () => void;
}

const TurnedLists: FC<TurnedListsProps> = (props) => {
  const { data, onClick } = props;


  return (
    <div
      className="content-list__card"
      onClick={onClick}
    >
      <div className="content-list__title">
        <span>
          <img src={UsernameIcon} />
        </span>
        {data?.studentName}
      </div>

      <Row className="content-list__info" align="middle" justify="space-around">
        <Col span={4}>
          <div className="content-list__thumbnail">
            <img src={pdfIcon} alt="" className="content-list__thumbnail__image" />
          </div>
        </Col>
        <Col span={12} >
          {data?.studentPoint
            ? <div className="info_text">
              {data?.studentPoint + " / " + data?.points}
            </div>
            : null
          }
          <div className="info_text">
            {'Turned In'}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TurnedLists;
