import React, { useEffect, useState, useRef } from "react";
import { QueryService } from "../../services/QueryService/queries.service";
import { AddComment } from "../../shared/components/AddComment";
import { Conversation } from "../../shared/components/Conversation";
import EmptyState from "../../shared/components/EmptyState";
import { UIButton } from "../../shared/components/UIButton";
import "./queries.scss";
import { DateFormatter } from "../../shared/components/HOC/dateFormatter";
import { NoData } from "../../shared/components/NoData";
import { CloseOutlined } from "@ant-design/icons";
import { Query } from "../../models/query.model";
import Modal from "antd/lib/modal/Modal";
import { ArrowRightOutlined } from "@ant-design/icons";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { OpenQuestions } from "./OpenQuestions";
import { MyAnswers } from "./MyAnswers";
interface SwitchProps {
  type: "open" | "my";
}
const Queries = (props: any) => {

  const [selectedSwitch, setSelected] = useState<SwitchProps["type"]>("open");


  return (
    <div className="page-view">
      <p className="query__title">Queries</p>
      <div className="query__wrapper">
        <div className="query-switch__wrapper">
          <div
            className={`query--switch ${
              selectedSwitch == "open"
                ? "query-switch__selected"
                : "query-switch__unselected"
            }`}
            onClick={() => {
              setSelected("open");
            }}
          >
            Open questions
          </div>
          <div
            className={`query--switch ${
              selectedSwitch == "my"
                ? "query-switch__selected"
                : "query-switch__unselected"
            }`}
            onClick={() => {
              setSelected("my");
            }}
          >
            My answers
          </div>
        </div>

        {selectedSwitch == "open" ? <OpenQuestions /> : <MyAnswers />}
      </div>
    </div>
  );
};

export default Queries;
