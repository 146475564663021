import { alias, primitive, serializable, list, object } from "serializr";



export class CreateComment {
    @serializable(alias("content", primitive()))
    content?: string

    @serializable(alias("attachment", primitive()))
    attachment?: any
}
export class QueryAttachment {
    @serializable(alias("original_filename", primitive()))
    originalFilename?: string

    @serializable(alias("tempfile", primitive()))
    tempfile?: string

    @serializable(alias("content_type", primitive()))
    contentType?: string
}
export class CreateQuery {
    @serializable(alias("content", primitive()))
    content?: string

    @serializable(alias("attachment", object(QueryAttachment)))
    attachment?: QueryAttachment

    @serializable(alias("related_subject_type", primitive()))
    relatedSubjectType?: string

    @serializable(alias("related_subject_id", primitive()))
    relatedSubjectId?: number
}
export class Subject {
    @serializable(alias("id", primitive()))
    id?: number

    @serializable(alias("name", primitive()))
    name?: string

    @serializable(alias("display_picture_url", primitive()))
    displayPictureUrl?: string

    @serializable(alias("color_code", primitive()))
    colorCode?: string

}

export class ClassRoom {
    @serializable(alias("board_acronym", primitive()))
    boardAcronym?: string

}

export class ClassRoomSubject {
    @serializable(alias("id", primitive()))
    id?: number

    @serializable(alias("subject", object(Subject)))
    subject?: Subject

    @serializable(alias("class_room", object(ClassRoom)))
    classRoom?: ClassRoom

    @serializable(alias("board_name", primitive()))
    boardName?: string

    @serializable(alias("class_room_name", primitive()))
    classRoomName?: string

    @serializable(alias("subject_name", primitive()))
    subjectName?: string
}


export class ExamSubject {
    @serializable(alias("exam_name", primitive()))
    examName?: string
    @serializable(alias("subject_name", primitive()))
    subjectName?: string
}

export class RelatedSubject {
    @serializable(alias("type", primitive()))
    type?: string

    @serializable(alias("class_room_subject", object(ClassRoomSubject)))
    classRoomSubject?: ClassRoomSubject

    @serializable(alias("exam_subject", object(ExamSubject)))
    examSubject?: ExamSubject
}
export class UserDetails {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('first_name', primitive()))
    firstName?: string;

    @serializable(alias('last_name', primitive()))
    lastName?: string;

    @serializable(alias('phone_number', primitive()))
    phoneNumber?: string;

    @serializable(alias('full_name', primitive()))
    fullName?: string;

    @serializable(alias('display_picture_url', primitive()))
    displayPictureUrl?: string;
}

export class QueryUser {
    @serializable(alias("type", primitive()))
    type?: string

    @serializable(alias("student", object(UserDetails)))
    student?: UserDetails

    @serializable(alias("tutor", object(UserDetails)))
    tutor?: UserDetails
}

export class Comments {
    @serializable(alias("id", primitive()))
    id?: number

    @serializable(alias("content", primitive()))
    content?: string

    @serializable(alias("attachment_url", primitive()))
    attachmentUrl?: string

    @serializable(alias("created_at", primitive()))
    createdAt?: string

    @serializable(alias("user", object(QueryUser)))
    user?: QueryUser


}
export class Query {
    @serializable(alias("id", primitive()))
    id?: number

    @serializable(alias("content", primitive()))
    content?: string

    @serializable(alias("attachment_url", primitive()))
    attachmentUrl?: string

    @serializable(alias("is_resolved", primitive()))
    isResolved?: boolean

    @serializable(alias("created_at", primitive()))
    createdAt?: string

    @serializable(alias("related_subject", object(RelatedSubject)))
    relatedSubject?: RelatedSubject

    @serializable(alias("comments", list(object(Comments))))
    comments?: Comments[]

}
