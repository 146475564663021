import React, { FC, ReactElement } from "react";
import { Modal } from "antd";
import ButtonComponent from "../ButtonComponent";
import "./uiModal.scss";

interface ControllerProps {
  text: string;
  type?: "submit" | "button" | "submit" | undefined;
  clickHandler: () => void;
  buttonLoading?: boolean
}

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
  children: ReactElement;
  submitProps?: ControllerProps;
  cancelProps?: ControllerProps;
  onClosed?: () => void;
  noControl?: boolean;
  className?: string;
  closable?: boolean;
  title?: string | undefined;
  subtitle?: string | undefined;
}

const UIModal: FC<ModalProps> = ({
  visible,
  closeModal,
  children,
  submitProps,
  cancelProps,
  onClosed,
  noControl = false,
  className,
  closable = true
}) => {
  return (
    <Modal
      className={`ui-modal ${className}`}
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      centered
      closable={closable}
      afterClose={onClosed}
    >
      {children}

      {noControl ? null : (
        <div className="ui-modal__controllers">
          <ButtonComponent
            buttonContent={cancelProps?.text}
            htmlType={cancelProps?.type ?? "button"}
            onClick={cancelProps?.clickHandler}
            type="text"
          />
          <ButtonComponent
            buttonContent={submitProps?.text}
            htmlType={submitProps?.type ?? "button"}
            onClick={submitProps?.clickHandler}
            type="primary"
            loading={submitProps?.buttonLoading}
            disabled={submitProps?.buttonLoading}
          />
        </div>
      )}
    </Modal>
  );
};

export default UIModal;
