import React, { FC, useEffect, useState } from "react";

import faker from "faker";
import { serialize } from "serializr";

import { LoginActivitySummaryModel, FilterLoginActivity } from "../../../models/Report/loginActivity.model";


import ReportService from "../../../services/ReportsService/LoginReport.service";
import { Button, Skeleton, Table } from "antd";
import { SkeletonLoader } from "../../LiveClass";
import ReportFilter from "./ReportFilter";
import { UserModel } from "../../../models/user.model";
import { Course } from '../../../models/Learn/Course/course.model';
import AuthContainer from "../../../store/container/AuthContainer";
import PageView from "../../../shared/components/PageView";


import "./style.scss";


interface ActivityReportProps {
    user: UserModel;
    currentCourse: any;
}

const ActivityReport: FC<ActivityReportProps> = (props) => {
    const { user ,currentCourse} = props;  

    const defaultFilter = {
      instituteId:user?.institute?.id,
      branchId:user?.branch?.id,
      boardId:0,
      sectionId:0,
      sectionSubjectId:0,
      branchClassRoomId:0
    }

    const [activitySummary, setActivitySummary] = useState<LoginActivitySummaryModel[]>([]);
    const [filters, setFilters] = useState<FilterLoginActivity>({});
    const [loading, setLoading] = useState(false);

    const fetchActivitySummary = (params?: FilterLoginActivity) => {
        setLoading(true);
        const payload = serialize(FilterLoginActivity, params ?? defaultFilter);
        ReportService.fetchActivitySummary(
            payload,
            (liveClasses) => {
                setActivitySummary(liveClasses);
            },
            () => { },
            () => {
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        if (user?.tutorClassRooms) {
            setFilters({ ...defaultFilter });
            //fetchActivitySummary({ ...defaultFilter })
        }
    }, []);

    // Download Report 
    const handleDownload = (params?: any) => {
        setLoading(true)
        const payload = serialize(FilterLoginActivity, params ?? defaultFilter);
        ReportService.downloadActivitySummary(
            payload,
            (contents) => { },
            () => { },
            () => { setLoading(false) }
        );
    }
    return (
        <PageView title="Report">
            {activitySummary ? (
                <div className="activity-report">
                    <div className="activity-report__header">
                        <div className="activity-report__left">
                            <div className="activity-report__title">Login Activity Report</div>
                            <ReportFilter
                                applyHandler={(values) => {
                                    setFilters({ ...defaultFilter, ...values });
                                    fetchActivitySummary({ ...defaultFilter, ...values })

                                }}
                                clearHandler={(values) => {
                                    setFilters({ ...defaultFilter, ...values });
                                    fetchActivitySummary({ ...defaultFilter, ...values })
                                }}
                            />
                        </div>

                        <div>
                            <Button onClick={() => handleDownload(filters)}>Download Report</Button>
                        </div>
                    </div>
                    {loading ? (
                        <div className="activity-report">
                            <SkeletonLoader />
                        </div>
                    ) : activitySummary?.length ? (
                        <Table
                        columns={[
                            {
                              title: 'Name',
                              dataIndex: 'studentname',
                              key: 'name',
                              width: 150,
                              fixed: 'left',
                              render: (text, row) => <div>
                                <strong>{row['studentname']}</strong>
                              </div>,
                  
                            },
                            {
                              title: 'Grade',
                              dataIndex: 'grade',
                              key: 'grade',
                              width: 100,
                            },
                            {
                              title: 'Section',
                              dataIndex: 'section',
                              key: 'section',
                              width: 100,
                            },
                            {
                              title: 'Logins',
                              dataIndex: 'login',
                              children: [
                                {
                                  title: 'Login Time',
                                  render: (text, row) => <div className="sub_div">
                                    {row['login']?.map(data => <div>{data.logintime}</div>)}
                                  </div>
                                },
                                {
                                  title: 'Long Used',
                                  render: (text, row) => <div className="sub_div">
                                    {row['login']?.map(data => <div>{Number(data.timeused).toFixed(2)}</div>)}
                                  </div>
                                },
                              ]
                            },
                            {
                              title: 'Contents Viewed',
                              dataIndex: 'contentviwed',
                              width: 400,
                              children: [
                                {
                                  title: 'Subject name',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['contentviwed']?.map(data => <div >
                                        <div className="sub_row_div">
                                          <div  className="data_text" style={{ width: 100 }}>{data.subjectname}</div>
                                          <div className="hidden_div">
                                            {data?.chapter?.map(chapter => (<div className="sub_row_div">
                                              <strong  className="data_text" style={{ minWidth: 100 }}>{chapter.chaptername}</strong>
                                              <div className="sub_div">
                                                {chapter['content']?.map(content => <strong  className="data_text" style={{ width: 100 }}>{content.name}</strong>)}
                                              </div>
                                            </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>)
                                    }
                                  </div>
                                },
                                {
                                  title: 'Chapter name',
                                  render: (text, row) => row['contentviwed']?.map(data =>
                                    <div className="sub_div">
                                      {data?.chapter?.map(chapter => (<div className="sub_row_div">
                                        <div  className="data_text" style={{ minWidth: 100 }}>{chapter.chaptername}</div>
                                        <div className="hidden_div">
                                          {chapter['content']?.map(content => <strong  className="data_text" style={{ width: 100 }}>{content.name}</strong>)}
                                        </div>
                                      </div>
                                      ))}
                                    </div>
                  
                                  )
                                },
                                {
                                  title: 'Content name',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['contentviwed']?.map(data =>
                                        data?.chapter?.map(chapter => (<div className="sub_row_div">
                                          <div className="sub_div">
                                            {chapter['content']?.map(content => <div  className="data_text" style={{ width: 100 }}>{content.name}</div>)}
                                          </div>
                                        </div>
                                        ))
                                      )
                                    }
                                  </div>
                                },
                                {
                                  title: 'Quick Quiz',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['contentviwed']?.map(data =>
                                        data?.chapter?.map(chapter => (<div className="sub_row_div">
                                          <div className="hidden_div">
                                            {chapter['content']?.map(content => <div  className="data_text" style={{ width: 100 }}>{content.name}</div>)}
                                          </div>
                                          <div className="sub_div">
                                            {chapter['content']?.map(content => <div  className="data_text" style={{ width: 100 }}>{content.quickquiz}</div>)}
                                          </div>
                                        </div>
                                        ))
                                      )
                                    }
                                  </div>
                                },
                              ]
                            },
                            {
                              title: 'Worksheet(PDF)',
                              dataIndex: 'pdfviwed',
                              width: 400,
                              children: [
                                {
                                  title: 'Subject name',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['pdfviwed']?.map(data => <div >
                                        <div className="sub_row_div">
                                          <div className="data_text" style={{ width: 100 }}>{data.subjectname}</div>
                                          <div className="hidden_div">
                                            {data?.chapter?.map(chapter => (<div className="sub_row_div">
                                              <strong  className="data_text" style={{ minWidth: 100 }}>{chapter.chaptername}</strong>
                                              <div className="sub_div">
                                                {chapter['content']?.map(content => <strong  className="data_text" style={{ width: 100 }}>{content.name}</strong>)}
                                              </div>
                                            </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>)
                                    }
                                  </div>
                                },
                                {
                                  title: 'Chapter name',
                                  render: (text, row) => row['pdfviwed']?.map(data =>
                                    <div className="sub_div">
                                      {data?.chapter?.map(chapter => (<div className="sub_row_div">
                                        <div  className="data_text" style={{ minWidth: 100 }}>{chapter.chaptername}</div>
                                        <div className="hidden_div">
                                          {chapter['content']?.map(content => <strong  className="data_text" style={{ width: 100 }}>{content.name}</strong>)}
                                        </div>
                                      </div>
                                      ))}
                                    </div>
                  
                                  )
                                },
                                {
                                  title: 'Content name',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['pdfviwed']?.map(data =>
                                        data?.chapter?.map(chapter => (<div className="sub_row_div">
                                          <div className="sub_div">
                                            {chapter['content']?.map(content => <div  className="data_text" style={{ width: 100 }}>{content.name}</div>)}
                                          </div>
                                        </div>
                                        ))
                                      )
                                    }
                                  </div>
                                }
                              ]
                            },
                            {
                              title: 'Exercise quiz',
                              dataIndex: 'excercise',
                              width: 400,
                              children: [
                                {
                                  title: 'Subject name',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['excercise']?.map(data => <div >
                                        <div className="sub_row_div">
                                          <div  className="data_text" style={{ width: 100 }}>{data.subjectname}</div>
                                          <div className="hidden_div">
                                            {data?.chapter?.map(chapter => (<div className="sub_row_div">
                                              <strong  className="data_text" style={{ minWidth: 100 }}>{chapter.chaptername}</strong>
                                              <div className="sub_div">
                                                {chapter['content']?.map(content => <strong  className="data_text" style={{ width: 100 }}>{content.name}</strong>)}
                                              </div>
                                            </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>)
                                    }
                                  </div>
                                },
                                {
                                  title: 'Chapter name',
                                  render: (text, row) => row['excercise']?.map(data =>
                                    <div className="sub_div">
                                      {data?.chapter?.map(chapter => (<div className="sub_row_div">
                                        <div  className="data_text" style={{ minWidth: 100 }}>{chapter.chaptername}</div>
                                        <div className="hidden_div">
                                          {chapter['content']?.map(content => <strong  className="data_text" style={{ width: 100 }}>{content.name}</strong>)}
                                        </div>
                                      </div>
                                      ))}
                                    </div>
                  
                                  )
                                },
                                {
                                  title: 'Excercise name',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['excercise']?.map(data =>
                                        data?.chapter?.map(chapter => (<div className="sub_row_div">
                                          <div className="sub_div">
                                            {chapter['content']?.map(content => <div  className="data_text" style={{ width: 100 }}>{content.name}</div>)}
                                          </div>
                                        </div>
                                        ))
                                      )
                                    }
                                  </div>
                                }
                              ]
                            },
                            {
                              title: 'Activity',
                              dataIndex: 'activityviwed',
                              width: 400,
                              children: [
                                {
                                  title: 'Subject name',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['activityviwed']?.map(data => <div >
                                        <div className="sub_row_div">
                                          <div  className="data_text" style={{ width: 100 }}>{data.subjectname}</div>
                                          <div className="hidden_div">
                                            {data?.activities?.map(chapter => (<div className="sub_row_div">
                                              <strong  className="data_text" style={{ minWidth: 100 }}>{chapter.activityname}</strong>
                                            </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>)
                                    }
                                  </div>
                                },
                                {
                                  title: 'Content name',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['activityviwed']?.map(data =>
                                        data?.activities?.map(chapter => (<div className="sub_row_div">
                                          <div className="sub_div">
                                            <div  className="data_text" style={{ minWidth: 100 }}>{chapter.activityname}</div>
                                          </div>
                                        </div>
                                        ))
                                      )
                                    }
                                  </div>
                                },
                                {
                                  title: 'Quick Quiz',
                                  render: (text, row) => <div className="sub_div">
                                    {
                                      row['activityviwed']?.map(data =>
                                        data?.activities?.map(chapter => (<div className="sub_row_div">
                                          <div className="hidden_div">
                                            <div  className="data_text" style={{ minWidth: 100 }}>{chapter.activityname}</div>
                                          </div>
                                          <div className="sub_div">
                                            <div  className="data_text" style={{ minWidth: 100 }}>{chapter.status}</div>
                                          </div>
                                        </div>
                                        ))
                                      )
                                    }
                                  </div>
                                },
                              ]
                            },
                          ]}
                          bordered
                          scroll={{ x: 3000 }}
                          pagination={{ defaultPageSize: 10 }}
                          dataSource={activitySummary}
                          loading={loading}                        />
                    ) : (
                        <div className="no-activity-report-text">No Activity Summary !</div>
                    )}
                </div>
            ) : (
                <div />
            )
            }

         
        </PageView>
    )

};

export default AuthContainer(ActivityReport);
