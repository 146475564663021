import { Button } from "antd"
import * as React from 'react';
import './uibutton.scss'

type HtmlType = "button" | "submit" | "reset" | undefined;

interface ButtonProps {
    title: string,
    onClick: () => void,
    type?: "outline" | "solid" | "ghost"
    disabled?: boolean
    className?: string
}

interface ButtonProps2 {
  children: string;
  type?: HtmlType;
  primary?: boolean;
  icon?: React.ReactElement;
  className?: string;
  clickHandler?: Function;
  info?: boolean;
  center?: boolean;
  ghost?: boolean;
  secondary?: boolean;
  disabled?:boolean;
  loading?: boolean;
  danger?: boolean;
  outline?: boolean;
  success?:boolean
}

export const UIButton = (props: ButtonProps) => {

    const { title, onClick, type, disabled, className } = props

    return (
        <Button className={`${type == "outline" && "button--outline"} ${type == "solid" && "button--solid"} ${type == "ghost" && "button--ghost"} ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {title}
        </Button>
    )
}


const UIButton2: React.FC<ButtonProps2> = ({
  children,
  type = "button",
  primary,
  icon,
  className,
  clickHandler,
  info,
  center,
  secondary,
  ghost,
  loading = false,
  danger,
  outline,
  success,
  ...rest
}) => {
  const content = () => {
    if (icon) {
      return (
        <React.Fragment>
          <span>{children}</span>
          {/* {icon} */}
          {/* {loading ? <LoadingOutlined color="#fff"/> : icon} */}
        </React.Fragment>
      );
    }
    return children;
  };
  return (
    //${icon && "ui-button__flex"}
    <Button
      className={`ui-button ${className}  ${info && "ui-button__info"} ${
        center && "ui-button__center"
      } ${secondary && "ui-button__secondary"} ${ghost && "ui-button__ghost"}
      ${danger && "ui-button__danger"}
      ${outline && "ui-button__outline"}
      ${success && "ui-button__success"}`
    }
      htmlType={type}
      onClick={(e) => clickHandler && clickHandler(e)}
      loading={loading}
      {...rest}
    >
      {icon ? content() : children}
    </Button>
  );
};

export default UIButton2;
