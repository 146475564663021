import { Badge, Button, Col, DatePicker, Dropdown, Row, Select } from "antd";
import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import "./dashboardFilter.scss";
import FilterIcon from "../../../assets/icons/filter-icon-filled.svg";
import CalendarIcon from "../../../assets/icons/calendar-primary.svg";
import { deserialize } from "serializr";

import moment from "moment";
import { DashboardFilterModel } from "../../../models/dashboard.model";
import { MetaModel } from "../../../models/meta.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { UserModel } from "../../../models/user.model";
const { RangePicker } = DatePicker;
interface DashboardFilterProps {
  applyHandler: (values: DashboardFilterModel) => void;
  clearHandler: (values: DashboardFilterModel) => void;
  user: UserModel;
}

const DashboardFilter: FC<DashboardFilterProps> = (props) => {
  const { applyHandler, clearHandler, user } = props;

  const [initialValues, setInitialValues] = useState<DashboardFilterModel>({});
  const [defaultValues, setDefaultValues] = useState<DashboardFilterModel>({});
  const [boards, setBoards] = useState<MetaModel[]>([]);
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);

  const fetchBoards = () => {
    // LeaderboardService.fetchBoardsMeta(
    //   (data) => {
    //     const meta = deserialize(MetaModel, data) as MetaModel[];
    //     setBoards(meta);
    //   },
    //   () => {},
    //   () => {}
    // );
  };

  const fetchClassRooms = () => {
    // DashboardService.fetchClassRoomsMeta(
    //   boardId,
    //   (data) => {
    //     const meta = deserialize(MetaModel, data) as MetaModel[];
    //     setClassRooms(meta);
    //   },
    //   () => {},
    //   () => {}
    // );
    let data = user?.tutorClassRooms?.map((value) => {
      return {
        value: value?.id,
        label: value?.name,
      };
    }) as MetaModel[];

    if (data.length) {
      setDefaultValues({ classRoom: Number(data[0].value) })
      setInitialValues({ classRoom: Number(data[0].value) })
    }
    setClassRooms(data);
  };

  useEffect(() => {
    fetchClassRooms();
  }, []);

  const fetchCount = () => {
    let count = 0;
    if (initialValues?.startingDate) count += 1;
    if (initialValues?.endingDate) count += 1;
    if (initialValues?.board) count += 1;
    if (initialValues?.classRoom) count += 1;
    return count;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Dropdown
            overlayClassName="dashboard-filter__overlay"
            className="dashboard-filter"
            trigger={["click"]}
            overlay={
              <div className="dashboard-filter-form__wrapper">
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Date Range</label>
                  <Field
                    name="date"
                    as={RangePicker}
                    value={[
                      values?.startingDate
                        ? moment(values?.startingDate, "YYYY-MM-DD")
                        : undefined,
                      values?.endingDate
                        ? moment(values?.endingDate, "YYYY-MM-DD")
                        : undefined,
                    ]}
                    onChange={(dates) => {
                      if (dates?.length) {
                        setFieldValue(
                          "startingDate",
                          moment(dates?.[0])?.format()
                        );
                        setFieldValue(
                          "endingDate",
                          moment(dates?.[1])?.format()
                        );
                      } else {
                        setFieldValue("startingDate", null);
                        setFieldValue("endingDate", null);
                      }
                    }}
                    suffixIcon={
                      <img className="calendar__icon" src={CalendarIcon} />
                    }
                  />
                </div>
                {/* <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Board</label>
                  <Field
                    as={Select}
                    name="board"
                    placeholder="Select Board"
                    options={boards}
                    value={values?.board}
                    onChange={(value) => {
                      setFieldValue("board", value);
                      fetchClassRooms(value)
                    }}
                  />
                </div> */}
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Class</label>
                  <Field
                    as={Select}
                    name="classRoom"
                    placeholder="Select Class"
                    options={classRooms}
                    value={values?.classRoom}
                    onChange={(value) => {
                      setFieldValue("classRoom", value);
                    }}
                  />
                </div>
                <Row gutter={12} className="dashboard-filter__controllers">
                  <Col span={12}>
                    <Button
                      type="default"
                      onClick={() => {
                        resetForm({ values,...defaultValues });
                        setInitialValues({...values,...defaultValues});
                        clearHandler({...values,...defaultValues});
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      onClick={() => {
                        resetForm({ values });
                        setInitialValues(values);
                        applyHandler(values);
                      }}
                    >
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            }
          >
            <Badge count={fetchCount()}>
              <div className="filter__icon">
                <img src={FilterIcon} />
              </div>
            </Badge>
          </Dropdown>
        </Form>
      )}
    </Formik>
  );
};

export default AuthContainer(DashboardFilter);
