import React, { FC } from 'react';
import './heading.scss';

interface HeadingProps {
  color?: string;
  sub?: boolean;
}

const Heading: FC<HeadingProps> = ({ children, color, sub = false }) => {
  return (
    <h1 className={`pj-heading ${sub && 'pj-subheading'}`} style={{ color }}>
      {children}
    </h1>
  );
};

export default Heading;
