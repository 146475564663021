import { generatePath } from 'react-router-dom';
import { date, deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import {
  ExamExcerciseAttempt,
  ExamExercise,
} from '../../models/Learn/Exam/ExerciseModel/exercise.model';
import {
  ExcerciseAttempt,
  Exercise,
  Exercises,
} from '../../models/Learn/Exercise/exercise.model';
import { PaginationModel } from '../../models/pagination.model';
import {
  ExcAtmptQuestAttchmtModel,
  Question,
} from '../../models/Learn/Question/question.model';
import {
  CREATE_EXAM_EXERCISE_ATTEMPT,
  EXERCISES,
  GET_EXAM_EXERCISE_QUESTIONS,
  GET_EXAM_EXERCISE_QUESTIONS_WITH_ANSWERS,
  UPDATE_EXAM_EXERCISE_QUESTION,
  VIEW_EXAM_EXERCISE_QUESTION,
  COMPLETE_EXAM_EXERCISE_QUESTION,
  RESET_EXAM_EXERCISE_EXAM,
  COMPLETE_EXAM_EXERCISE,
  EXERCISE_QUESTION_ATTACHMENT,
  EXAM_EXERCISE_QUESTION_ATTACHMENT,
  PAPER_EXERCISE_QUESTION_ATTACHMENT,
  VIEW_EXAM_EXERCISE_ATTEMPT,
} from '../../routes/routeConstants/apiRoutes';
import { LocalStorage } from '../../shared/utils/LocalStorageHelpers';

export class ExerciseService {
  static getExercises(
    { ...param }: { chaptorId: any; subjectId: any; search: any },
    onSuccess: (exercises: Exercise[], meta: PaginationModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    if (param?.chaptorId) {
      const params = {
        ...param,
        courseId: LocalStorage.getItem('COURSE_ID'),
      };

      return axiosInstance
        .get(generatePath(EXERCISES, params), { params: params })
        .then((response) => {
          const { excercises } = deserialize(Exercises, response?.data);
          const meta = deserialize(PaginationModel, response['data']?.meta);
          onSuccess(excercises as Exercise[], meta);
        })
        .catch((error) => {
          onError();
        })
        .finally(onFinal);
    }
  }
  static createAttempt(
    params: { chapterId: number },
    payload: {
      exam_exercise_attempt: {
        exam_exercise_id: number;
      };
    },
    onSuccess: (exerciseAttempt: ExcerciseAttempt) => void,
    onError: Function
  ) {
    const courseId = LocalStorage.getItem('COURSE_ID');
    return axiosInstance
      .post(
        generatePath(CREATE_EXAM_EXERCISE_ATTEMPT, { ...params, courseId }),
        payload
      )
      .then((response) => {
        const data = deserialize(
          ExamExcerciseAttempt,
          response?.data?.exam_exercise_attempt
        );
        onSuccess(data ?? new ExamExcerciseAttempt());
      })
      .catch((error) => {
        onError(error);
      });
  }

  static getExamQuestions(
    attemptId: number,
    onSuccess: (questions: any) => void
  ) {
    const courseId = LocalStorage.getItem('COURSE_ID');
    if (courseId) {
      return axiosInstance
        .get(
          generatePath(GET_EXAM_EXERCISE_QUESTIONS, {
            courseId,
            attemptId,
          })
        )
        .then((response) => {
          const questions = deserialize(
            Question,
            response['data']?.exam_exercise_attempt_questions
          );
          onSuccess(questions);
        })
        .catch((error) => {});
    }
  }

  static getExamQuestionsWithAnswers(
    attemptId: number,
    onSuccess: (questions: any) => void
  ) {
    const courseId = LocalStorage.getItem('COURSE_ID');
    if (courseId) {
      return axiosInstance
        .get(
          generatePath(GET_EXAM_EXERCISE_QUESTIONS_WITH_ANSWERS, {
            courseId,
            attemptId,
          })
        )
        .then((response) => {
          const questions = deserialize(
            Question,
            response['data']?.exam_exercise_attempt_questions
          );
          onSuccess(questions);
        })
        .catch((error) => {});
    }
  }

  static getQuestion(
    params: { attemptId: any; id: any },
    onSuccess: (question: Question) => void,
    onError: () => void
  ) {
    const courseId = LocalStorage.getItem('COURSE_ID');

    return axiosInstance
      .get(
        generatePath(VIEW_EXAM_EXERCISE_QUESTION, {
          ...params,
          courseId,
        })
      )
      .then((response) => {
        const question = deserialize(
          Question,
          response?.data?.exam_exercise_attempt_question
        );
        onSuccess(question);
      })
      .catch((error) => {});
  }

  static updateAnswer(
    params: { attemptId: any; id: any },
    answer: any,
    onSuccess: () => void
  ) {
    const courseId = LocalStorage.getItem('COURSE_ID');
    const payload = {
      exam_exercise_attempt_question: answer,
    };
    return axiosInstance
      .put(
        generatePath(UPDATE_EXAM_EXERCISE_QUESTION, {
          ...params,
          courseId,
        }),
        payload
      )
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {});
  }

  static completeExam(
    params: {
      chapterId: any;
      attemptId: any;
    },
    onSuccess: () => void
  ) {
    const courseId = LocalStorage.getItem('COURSE_ID');
    return axiosInstance
      .put(
        generatePath(COMPLETE_EXAM_EXERCISE, {
          ...params,
          courseId,
        })
      )
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {});
  }

  static resetExam(
    params: {
      chapterId: any;
      attemptId: any;
    },
    onSuccess: (exerciseAttempt: ExcerciseAttempt) => void
  ) {
    const courseId = LocalStorage.getItem('COURSE_ID');
    return axiosInstance
      .put(
        generatePath(RESET_EXAM_EXERCISE_EXAM, {
          ...params,
          courseId,
        })
      )
      .then((response) => {
        const examExerciseAttempt = deserialize(
          ExcerciseAttempt,
          response?.data['exam_exercise_attempt']
        );
        onSuccess(examExerciseAttempt ?? new ExcerciseAttempt());
      })
      .catch((error) => {});
  }
  static createExerciseAttemptQuestionAttachment(
    attemptId: any,
    data: any,
    onSuccess: (value: ExcAtmptQuestAttchmtModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(
        generatePath(EXERCISE_QUESTION_ATTACHMENT, {
          courseId: LocalStorage.getItem('COURSE_ID'),
          attemptId: attemptId,
        }),
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .then((response) => {
        const attachment = deserialize(
          ExcAtmptQuestAttchmtModel,
          response['data']['exc_atmpt_quest_attchmt']
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static createExamExerciseAttemptQuestionAttachment(
    attemptId: any,
    data: any,
    onSuccess: (value: ExcAtmptQuestAttchmtModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(
        generatePath(EXAM_EXERCISE_QUESTION_ATTACHMENT, {
          courseId: LocalStorage.getItem('COURSE_ID'),
          attemptId: attemptId,
        }),
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .then((response) => {
        const attachment = deserialize(
          ExcAtmptQuestAttchmtModel,
          response['data']['exam_exercise_attempt_question_attachment']
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static createContentExerciseAttemptQuestionAttachment(
    attemptId: any,
    data: any,
    onSuccess: (value: ExcAtmptQuestAttchmtModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(
        generatePath(EXERCISE_QUESTION_ATTACHMENT, {
          courseId: LocalStorage.getItem('COURSE_ID'),
          attemptId: attemptId,
        }),
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .then((response) => {
        const attachment = deserialize(
          ExcAtmptQuestAttchmtModel,
          response['data']['content_attempt_question_attachment']
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static createPaperExerciseAttemptQuestionAttachment(
    attemptId: any,
    data: any,
    onSuccess: (value: ExcAtmptQuestAttchmtModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(
        generatePath(PAPER_EXERCISE_QUESTION_ATTACHMENT, {
          courseId: LocalStorage.getItem('COURSE_ID'),
          attemptId: attemptId,
        }),
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .then((response) => {
        const attachment = deserialize(
          ExcAtmptQuestAttchmtModel,
          response['data']['paper_attempt_question_attachment']
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static deleteExamExerciseAttempt(
    chapterId: any,
    attemptId: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .delete(
        generatePath(VIEW_EXAM_EXERCISE_ATTEMPT, {
          courseId: LocalStorage.getItem('COURSE_ID'),
          chapterId: chapterId,
          attemptId: attemptId,
        })
      )
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  }
}
