import { deserialize } from "serializr";
import { Badge, Button, Col, DatePicker, Dropdown, Row, Select } from "antd";
import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import "./reportFilter.scss";
import FilterIcon from "../../../../assets/icons/filter-icon-filled.svg";
import { FilterChapterwise } from "../../../../models/Report/chapterwise.model";
import { MetaModel } from "../../../../models/meta.model";
import { MetaService } from "../../../../services/MetaService/meta.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import { UserModel } from "../../../../models/user.model";

interface ReportFilterProps {
  applyHandler: (values: FilterChapterwise) => void;
  clearHandler: (values: FilterChapterwise) => void;
  user: UserModel;
}

const ReportFilter: FC<ReportFilterProps> = (props) => {
  const { applyHandler, clearHandler, user } = props;

  const [initialValues, setInitialValues] = useState<FilterChapterwise>({});
  const [defaultValues, setDefaultValues] = useState<FilterChapterwise>({});
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  const [subjects, setSubjects] = useState<MetaModel[]>([]);
  const [sections, setSections] = useState<MetaModel[]>([]);
  const [chapters, setChapters] = useState<MetaModel[]>([]);


  const fetchClassRooms = () => {
    // DashboardService.fetchClassRoomsMeta(
    //   boardId,
    //   (data) => {
    //     const meta = deserialize(MetaModel, data) as MetaModel[];
    //     setClassRooms(meta);
    //   },
    //   () => {},
    //   () => {}
    // );


    const meta = deserialize(MetaModel, user?.tutorClassRooms) as MetaModel[];
    if (meta.length) {
      setDefaultValues({ branchClassRoomId: Number(meta[0].value) })
      setInitialValues({ branchClassRoomId: Number(meta[0].value) })
      fetchSections(Number(meta[0].value) ?? 0)
    }
    setClassRooms(meta);
  };

  const fetchSections = (classRoomId: number) => {
    setSections([])
    MetaService.fetchSectionsMeta(
      classRoomId,
      (sections) => {
        setSections(sections);
        if (sections.length) {
          setDefaultValues((prevState) => {
            return {
              ...prevState, branchClassRoomId: classRoomId, sectionId: Number(sections[0].value)
            }
          })
          setInitialValues((prevState) => {
            return {
              ...prevState, branchClassRoomId: classRoomId, sectionId: Number(sections[0].value)
            }
          })
          //applyHandler({ ...defaultValues, branchClassRoomId: classRoomId, sectionId: Number(sections[0].value) })
          fetchSubjects([Number(sections[0].value) ?? 0])
        }
      },
      () => { },
      () => { }
    );
  };


  const fetchSubjects = (sectionIds: number[]) => {
    setSubjects([]);
    MetaService.fetchSubjectsMeta(
      sectionIds,
      (subjects) => {
        setSubjects(subjects);

      },
      () => { },
      () => { }
    );
  };

  const fetchChapters = (classId: number, subjectId: number) => {
    let selectedClassRoom = user?.tutorClassRooms?.find(data => data.id === classId)
    setChapters([]);
    MetaService.fetchChaptersMeta(
      { ClassId: selectedClassRoom?.classRoomId, SubjectId: subjectId },
      (data) => {
        setChapters(data);
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    fetchClassRooms();
  }, []);

  const fetchCount = () => {
    let count = 0;
    if (initialValues?.branchClassRoomId) count += 1;
    if (initialValues?.sectionId) count += 1;
    if (initialValues?.sectionSubjectId) count += 1;
    if (initialValues?.chapterId) count += 1;
    return count;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      enableReinitialize
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Dropdown
            overlayClassName="dashboard-filter__overlay"
            className="dashboard-filter"
            trigger={["click"]}
            overlay={
              <div className="dashboard-filter-form__wrapper">
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Class</label>
                  <Field
                    as={Select}
                    name="branchClassRoomId"
                    placeholder="Select Class"
                    options={classRooms}
                    value={values?.branchClassRoomId}
                    onChange={(value) => {
                      setFieldValue("branchClassRoomId", value);
                      setFieldValue("sectionId", undefined);
                      setFieldValue("sectionSubjectId", undefined);
                      fetchSections(value);
                    }}
                  />
                </div>
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Section</label>
                  <Field
                    as={Select}
                    name="sectionId"
                    placeholder="Select Class"
                    options={sections}
                    value={values?.sectionId}
                    onChange={(value) => {
                      setFieldValue("sectionId", value);
                      setFieldValue("sectionSubjectId", undefined);
                      setFieldValue("chapterId", null);
                      fetchSubjects(value);
                    }}
                  />
                </div>
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Subject</label>
                  <Field
                    as={Select}
                    name="sectionSubjectId"
                    placeholder="Select Subject"
                    options={subjects}
                    value={values?.sectionSubjectId}
                    onChange={(value) => {
                      setFieldValue("sectionSubjectId", value);
                      setFieldValue("chapterId", null);
                      values?.branchClassRoomId &&
                        fetchChapters(values?.branchClassRoomId, value);
                    }}
                  />
                </div>
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Chapter</label>
                  <Field
                    as={Select}
                    name="chapterId"
                    placeholder="Select Chapter"
                    options={chapters}
                    value={values?.chapterId}
                    onChange={(value) => {
                      setFieldValue("chapterId", value);
                    }}
                  />
                </div>

                <Row gutter={12} className="dashboard-filter__controllers">
                  <Col span={12}>
                    <Button
                      type="default"
                      onClick={() => {
                        resetForm({});
                        setInitialValues({ ...defaultValues });
                        clearHandler({ ...defaultValues });
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      onClick={() => {
                        resetForm({ values });
                        setInitialValues(values);
                        applyHandler(values);
                      }}
                    >
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            }
          >
            <Badge count={fetchCount()}>
              <div className="filter__icon">
                <img src={FilterIcon} />
              </div>
            </Badge>
          </Dropdown>
        </Form>
      )}
    </Formik>
  );
};

export default AuthContainer(ReportFilter);
