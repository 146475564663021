import { IAgoraRTCClient, IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import { Col, Drawer, Row } from "antd";
import React, { FC, useEffect } from "react";
import "./liveClassParticipants.scss";
import ParticipantsIcon from "../../../../assets/icons/live-class/users-fill-white.svg";
import CloseIcon from "../../../../assets/icons/close-white.svg";
import ProfilePlaceholderIcon from "../../../../assets/placeholder/profile-placeholder.svg";
import MuteIcon from "../../../../assets/icons/live-class/microphone-fill.svg";
import UnmuteIcon from "../../../../assets/icons/live-class/microphone-slash-fill.svg";
import StopVideoIcon from "../../../../assets/icons/live-class/video-camera-fill.svg";
import StartVideoIcon from "../../../../assets/icons/live-class/video-camera-slash-fill.svg";
interface LiveClassParticipantsProps {
  visible: boolean;
  closeHandler: () => void;
  users: IAgoraRTCRemoteUser[];
  client: IAgoraRTCClient;
  isAudioOnly: boolean;
  trackState: {
    audio: boolean;
    video: boolean;
  };
}

const LiveClassParticipants: FC<LiveClassParticipantsProps> = (props) => {
  const { visible, closeHandler, users, client, trackState, isAudioOnly } = props;

  return (
    <Drawer
      className="live-class-participants"
      placement="right"
      onClose={closeHandler}
      visible={visible}
      mask={false}
    >
      <div className="live-class-participants__header">
        <div className="live-class-participants-header__left">
          <img src={ParticipantsIcon} />
          <h4>Participants</h4>
        </div>
        <div className="live-class-participants-header__right">
          <img src={CloseIcon} onClick={closeHandler} />
        </div>
      </div>
      <div className="live-class-participants__list">
        <Row className="live-class-participants__list-item" align="middle">
          <Col span={4}>
            <img
              className="participant-profile__placeholder"
              src={ProfilePlaceholderIcon}
            />
          </Col>
          <Col span={15}>
            <p className="participant-profile__uid">
              {client?.uid?.toString()?.split("-")?.[0]} (You)
            </p>
          </Col>
          <Col span={3}>
            <img
              className="participant-profile__audio"
              src={trackState?.audio ? MuteIcon : UnmuteIcon}
            />
          </Col>
          <Col span={2}>
            <img
              className="participant-profile__video"
              src={isAudioOnly ? StartVideoIcon : (trackState?.video) ? StopVideoIcon : StartVideoIcon}
            />
          </Col>
        </Row>
        {users?.map((user) => {
          return (
            <Row className="live-class-participants__list-item" align="middle">
              <Col span={4}>
                <img
                  className="participant-profile__placeholder"
                  src={ProfilePlaceholderIcon}
                />
              </Col>
              <Col span={15}>
                <p className="participant-profile__uid">
                  {user?.uid?.toString()?.split("-")?.[0]}
                </p>
              </Col>
              <Col span={3}>
                <img
                  className="participant-profile__audio"
                  src={user?.hasAudio ? MuteIcon : UnmuteIcon}
                />
              </Col>
              <Col span={2}>
                <img
                  className="participant-profile__video"
                  src={user?.hasVideo ? StopVideoIcon : StartVideoIcon}
                />
              </Col>
            </Row>
          );
        })}
      </div>
    </Drawer>
  );
};

export default LiveClassParticipants;
