import React, { FC, useState } from "react";
import authImage from "../../../../assets/background-images/auth-image.svg";
import authLogo from "../../../../assets/background-images/auth-logo.png";
import { Formik, Form } from "formik";
import InputField from "../../../../shared/components/InputField";
import "../../../../views/Auth/auth.scss";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import backArrow from "../../../../assets/icons/back_arrow.svg";
import * as appRoutes from "../../../../routes/routeConstants/appRoutes";
import { validationSchema } from "../../../../views/Auth/ResetPassword/ResetPasswordValidation";
import { useHistory } from "react-router";
interface User {
  password: string;
  confirmPassword: string;
}

const initialValue = {
  password: "",
  confirmPassword: "",
};
const ChangePassword: FC = () => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const history = useHistory()
  const onSubmit = () => {};
  return (
    <div className="auth-component">
      <div className="auth-image-section">
        <div className="auth-image">
          <img src={authImage} alt="" />
        </div>
        <div className="auth-logo">
          <img src={authLogo} alt="" />
        </div>
      </div>
      <div className="auth__form">
        <div className="auth__header">
          <img src={backArrow} alt="" className="auth__back" onClick={() => history.goBack()}/> Change Password
        </div>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <div className="auth__input">
              <InputField
                type="password"
                name="password"
                placeholder="New Password"
              />
            </div>
            <div className="auth__input">
              <InputField
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
              />
            </div>
            <div className="auth__action reset-password__action">
              <ButtonComponent
                buttonContent="Update"
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
export default ChangePassword;
