import { Field, Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";

import { WorksheetModel, WorksheetCreateModel, WorksheetUpdateModel } from "../../../models/worksheet.model";
import { MetaModel } from "../../../models/meta.model";
import { MetaService } from "../../../services/MetaService/meta.service";

import UploadContent from "../../../shared/components/UploadContent";
import DatePickerField from "../../../shared/components/DatePickerField";
import InputField from "../../../shared/components/InputField";
import UIModal from "../../../shared/components/UIModal";
import DropdownField from "../../../shared/components/DropdownField";


import "./activityForm.scss";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import { serialize } from "serializr";
import WorksheetService from "../../../services/WorksheetService/Worksheet.service";


interface ActivityFormProps {
  visible: boolean;
  closeHandler: () => void;
  data?: WorksheetModel;
  refreshList: () => void;
}

const ActivityForm: FC<ActivityFormProps> = (props) => {
  const { visible, closeHandler, data, refreshList } = props;
  const [initialValues, setInitialValues] = useState<any>({
    WorksheetId: 0,
    Name: '',
    Instructions: '',
    DueDate: '',
    ClassId: undefined,
    Points: undefined,
    SectionId: undefined,
    SubjectId: undefined,
    AttachmentList: null
  });

  const [loading, setLoading] = useState(false);
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  const [subjects, setSubjects] = useState<MetaModel[]>([]);
  const [sections, setSections] = useState<MetaModel[]>([]);

  const handleSubmit = (values: FormikValues) => {
    setLoading(true);
    let jsonData = serialize(data ? WorksheetUpdateModel : WorksheetCreateModel, values);

    // if (values?.AttachmentList.length)
    //   jsonData["AttachmentList"] = values?.AttachmentList
    let formData = convertJSONToFormData({ ...jsonData });
    if (values?.AttachmentList)
      values?.AttachmentList.forEach(file => {
        formData.append('AttachmentList', file)
      })

    if (data && data?.worksheetId) {
    } else {
      WorksheetService.createWorksheet(
        formData,
        () => {
          closeHandler();
          refreshList();
        },
        () => { },
        () => {
          setLoading(false);
        }
      );
    }
  };

  const formRef = useRef<any>();

  useEffect(() => {
    // if (data) {
    //   if (data?.classRoom?.branchClassRoomId) fetchSections(data?.classRoom?.branchClassRoomId);
    //   if (data?.sections) fetchSubjects(data?.sections?.map((section) => section?.id))
    //   setInitialValues({
    //     title: data?.title,
    //     tutorIds: data?.tutors?.filter((tutor) => tutor?.id !== data?.createdTutorId)?.map(value => value?.id),
    //     startTime: moment(data?.startTime)?.format("HH:mm"),
    //     date: data?.date,
    //     classRoomId: data?.classRoom?.branchClassRoomId,
    //     sectionIds: data?.sections?.map((section) => section?.id),
    //     subjectId: data?.subject?.id,
    //     duration: data?.duration,
    //   });
    // }
  }, [data]);

  const fetchClassRooms = () => {
    MetaService.fetchClassRoomsMeta(
      (classRooms) => {
        setClassRooms(
          classRooms?.map((value) => ({ label: value?.name, value: value?.id }))
        );
      },
      () => { },
      () => { }
    );
  };

  const fetchSections = (classRoomId: number) => {
    MetaService.fetchSectionsMeta(
      classRoomId,
      (sections) => {
        setSections(sections);
      },
      () => { },
      () => { }
    );
  };


  const fetchSubjects = (sectionIds: number[]) => {
    MetaService.fetchSubjectsMeta(
      sectionIds,
      (subjects) => {
        setSubjects(subjects);
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    fetchClassRooms();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, setFieldValue, resetForm, handleSubmit, errors }) => (
        <UIModal
          visible={visible}
          closeModal={closeHandler}
          onClosed={resetForm}
          submitProps={{
            text: data ? "Update" : "Create",
            type: "submit",
            clickHandler: handleSubmit,
            buttonLoading: loading,
          }}
          cancelProps={{
            text: "Cancel",
            clickHandler: closeHandler,
          }}
          className="activity-form"
        >
          <Form>
            <div className="activity-form__header">
              <div className="activity-form__title">
                {data ? "Update Worksheet" : "New Worksheet"}
              </div>
            </div>
            <div className="activity-form__input">
              <div className="activity-form__label">Worksheet Name</div>
              <InputField
                type="text"
                name="Name"
                placeholder="Type Worksheet Name"
                maxLength={100}
              />
            </div>

            <div className="activity-form__input">
              <div className="activity-form__label">Instruction</div>
              <InputField
                type="textarea"
                name="Instructions"
                placeholder="Type Instruction"
                style={{ height: 120, resize: 'none' }}
                showCount
                maxLength={300}
              />
            </div>

            <div className="activity-form__input">
              <div className="activity-form__label">Due Date</div>
              <DatePickerField
                name="DueDate"
                placeholder="Select Date"
                value={values?.DueDate}
                format="YYYY-MM-DD"
                onSelect={(date) => {
                  setFieldValue("DueDate", date?.format("YYYY-MM-DD"));
                }}
              />
            </div>

            <div className="activity-form__input">
              <div className="activity-form__label">Points</div>
              <InputField
                type="number"
                name="Points"
                placeholder="Type Points"
                maxLength={100}
              />
            </div>

            <div className="activity-form__input">
              <div className="activity-form__label">Class</div>
              <DropdownField
                name="ClassId"
                mode="single"
                placeholder="Select"
                value={values?.ClassId}
                options={classRooms}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("ClassId", value);
                  fetchSections(value);
                }}
              />
            </div>

            <div className="activity-form__input">
              <div className="activity-form__label">Section</div>
              <DropdownField
                name="SectionId"
                mode= {data ? "single" : "multiple" }
                placeholder="Select"
                value={values?.SectionId}
                options={sections}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("SectionId", value);
                  fetchSubjects(value);
                }}
              />
            </div>

            <div className="activity-form__input">
              <div className="activity-form__label">Subject</div>
              <DropdownField
                name="SubjectId"
                mode="single"
                placeholder="Select"
                value={values?.SubjectId}
                options={subjects}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("SubjectId", value);
                }}
              />
            </div>

            <div className="activity-form__input">
              <div className="activity-form__label">Attachment</div>
              <UploadContent
                setUploadedFile={(file) => setFieldValue("AttachmentList", file)}
              />
            </div>


          </Form>
        </UIModal>
      )}
    </Formik>
  );
};

export default ActivityForm;
