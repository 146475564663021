import { alias, list, object, primitive, serializable } from 'serializr';
import { Student } from '../Student/student.model';

export class CreateComment {
  @serializable(alias('content', primitive()))
  content?: string;

  @serializable(alias('attachment', primitive()))
  attachment?: any;
}
export class QueryAttachment {
  @serializable(alias('original_filename', primitive()))
  originalFilename?: string;

  @serializable(alias('tempfile', primitive()))
  tempfile?: string;

  @serializable(alias('content_type', primitive()))
  contentType?: string;
}
export class CreateQuery {
  @serializable(alias('content', primitive()))
  content?: string;

  @serializable(alias('attachment', object(QueryAttachment)))
  attachment?: QueryAttachment;

  @serializable(alias('related_subject_type', primitive()))
  relatedSubjectType?: string;

  @serializable(alias('related_subject_id', primitive()))
  relatedSubjectId?: number;
}
export class Subject {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('display_picture_url', primitive()))
  displayPictureUrl?: string;

  @serializable(alias('color_code', primitive()))
  colorCode?: string;
}

export class ClassRoomSubject {
  @serializable(alias('subject', object(Subject)))
  subject?: Subject;
}

export class RelatedSubject {
  @serializable(alias('type', primitive()))
  type?: string;

  @serializable(alias('class_room_subject', object(ClassRoomSubject)))
  classRoomSubject?: ClassRoomSubject;

  @serializable(alias('exam_subject', object(ClassRoomSubject)))
  examSubject?: ClassRoomSubject;
}
export class Tutor {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('email', primitive()))
  email?: string;

  @serializable(alias('first_name', primitive()))
  firstName?: string;

  @serializable(alias('last_name', primitive()))
  lastName?: string;

  @serializable(alias('phone_number', primitive()))
  phoneNumber?: string;

  @serializable(alias('full_name', primitive()))
  fullName?: string;

  @serializable(alias('display_picture_url', primitive()))
  displayPictureUrl?: string;
}
export class User {
  @serializable(alias('type', primitive()))
  type?: string;

  @serializable(alias('student', object(Student)))
  student?: Student;

  @serializable(alias('tutor', object(Tutor)))
  tutor?: Tutor;
}
export class Comments {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('content', primitive()))
  content?: string;

  @serializable(alias('attachment_url', primitive()))
  attachmentUrl?: string;

  @serializable(alias('created_at', primitive()))
  createdAt?: string;

  @serializable(alias('user', object(User)))
  user?: User;
}
export class Query {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('content', primitive()))
  content?: string;

  @serializable(alias('attachment_url', primitive()))
  attachmentUrl?: string;

  @serializable(alias('is_resolved', primitive()))
  isResolved?: boolean;

  @serializable(alias('created_at', primitive()))
  createdAt?: string;

  @serializable(alias('related_subject', object(RelatedSubject)))
  relatedSubject?: RelatedSubject;

  @serializable(alias('comments', list(object(Comments))))
  comments?: Comments[];
}
