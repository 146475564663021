
import React, { FC, useEffect, useRef, useState } from "react";
import { Form, Formik, FormikValues, Field } from "formik";

import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import { serialize } from "serializr";

import {
  AnnouncementCreateModel,
  AnnouncementModel
} from "../../../models/Announcement.model";
import { MetaModel } from "../../../models/meta.model";
import AnnouncementService from "../../../services/AnnouncementService/Announcement.service";
import { MetaService } from "../../../services/MetaService/meta.service";

import ToggleSwitch from "../../../shared/components/ToggleSwitch";
import UploadContent from "../../../shared/components/UploadContent";
import InputField from "../../../shared/components/InputField";
import UIModal from "../../../shared/components/UIModal";
import DropdownField from "../../../shared/components/DropdownField";


import "./announcementForm.scss";


interface AnnouncementFormProps {
  visible: boolean;
  closeHandler: () => void;
  refreshList: () => void;
}

const AnnouncementForm: FC<AnnouncementFormProps> = (props) => {

  const formRef = useRef<any>();
  const { visible, closeHandler, refreshList } = props;
  const [initialValues, setInitialValues] = useState<any>({
    classId: 0,
    SectionId: undefined,
    Message: '',
    AllowComment: true,
    AttachmentList: null
  });


  const [loading, setLoading] = useState(false);
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  const [sections, setSections] = useState<MetaModel[]>([]);

  const handleSubmit = (values: FormikValues) => {
    setLoading(true);
    let jsonData = serialize(AnnouncementCreateModel, values);
    // if (values?.AttachmentList) jsonData["AttachmentList"] = values["AttachmentList"];
    let formData = convertJSONToFormData({ ...jsonData });
    if (values?.AttachmentList)
      values?.AttachmentList.forEach(file => {
        formData.append('AttachmentList', file)
      })
    AnnouncementService.createAnnouncement(
      formData,
      () => {
        closeHandler();
        refreshList();
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  };



  const fetchClassRooms = () => {
    MetaService.fetchClassRoomsMeta(
      (classRooms) => {
        setClassRooms(
          classRooms?.map((value) => ({ label: value?.name, value: value?.id }))
        );
      },
      () => { },
      () => { }
    );
  };

  const fetchSections = (classRoomId: number) => {
    MetaService.fetchSectionsMeta(
      classRoomId,
      (sections) => {
        setSections(sections);
      },
      () => { },
      () => { }
    );
  };



  useEffect(() => {
    fetchClassRooms();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, setFieldValue, resetForm, handleSubmit, errors }) => (
        <UIModal
          visible={visible}
          closeModal={closeHandler}
          onClosed={resetForm}
          submitProps={{
            text: "Post",
            type: "submit",
            clickHandler: handleSubmit,
            buttonLoading: loading,
          }}
          cancelProps={{
            text: "Cancel",
            clickHandler: closeHandler,
          }}
          className="announcement-form"
        >
          <Form>
            <div className="announcement-form__header">
              <div className="announcement-form__title">
                {"New Post"}
              </div>
            </div>
            <div className="activity-form__input">
              <div className="activity-form__label">Class</div>
              <DropdownField
                name="ClassId"
                mode="single"
                placeholder="Select"
                value={values?.ClassId}
                options={classRooms}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("ClassId", value);
                  fetchSections(value);
                }}
              />
            </div>

            <div className="activity-form__input">
              <div className="activity-form__label">Section</div>
              <DropdownField
                name="SectionId"
                mode="single"
                placeholder="Select"
                value={values?.SectionId}
                options={sections}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("SectionId", value);
                }}
              />
            </div>

            <div className="announcement-form__input">
              <InputField
                type="textarea"
                name="Message"
                placeholder="Type Message"
                style={{ height: 120, resize: 'none' }}
                showCount
                maxLength={300}
              />
            </div>



            <div className="announcement-form__toggle">
              <Field name="AllowComment" as="switch">
                <ToggleSwitch
                  checked={values?.AllowComment}
                  handleChange={(checked) => {
                    setFieldValue("AllowComment", checked);
                  }}
                />
              </Field>
              <div className="toggle-field__label">allow comments</div>
            </div>

            <div className="announcement-form__input">
              {/* <div className="announcement-form__label">Attecment</div> */}
              <UploadContent
                maxCount={1}
                title="Attachment"
                setUploadedFile={(file) => setFieldValue("AttachmentList", file)}
              />
            </div>


          </Form>
        </UIModal>
      )}
    </Formik>
  );
};

export default AnnouncementForm;
