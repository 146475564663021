import { serializable, alias, primitive, object, list } from "serializr";
import { number } from 'yup';


export class FileMetaModel {
  @serializable(alias("attachmentId", primitive()))
  attachmentId?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("path", primitive()))
  path?: string;

  @serializable(alias("isDownload", primitive()))
  isDownload?: boolean;
}

export class WorksheetModel {
  @serializable(alias("worksheetId", primitive()))
  worksheetId?: number;
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("instructions", primitive()))
  instructions?: string;
  @serializable(alias("dueDate", primitive()))
  dueDate?: string;
  @serializable(alias("points", primitive()))
  points?: number;
  @serializable(alias("classId", primitive()))
  classId?: number;
  @serializable(alias("sectionId", primitive()))
  sectionId?: number;
  @serializable(alias("subjectId", primitive()))
  subjectId?: number;
  @serializable(alias("className", primitive()))
  className?: string;
  @serializable(alias("sectionName", primitive()))
  sectionName?: string;
  @serializable(alias("subjectName", primitive()))
  subjectName?: string;
  @serializable(alias("createdOn", primitive()))
  createdOn?: string;
  @serializable(alias("attachmentList", list(object(FileMetaModel))))
  attachmentList?: FileMetaModel[] = [] ;
  @serializable(alias("assignedCount", primitive()))
  assignedCount?: number;
  @serializable(alias("turnitCount", primitive()))
  turnitCount?: number;

  @serializable(alias("studentId", primitive()))
  studentId?: number;
  @serializable(alias("studentName", primitive()))
  studentName?: string;
  @serializable(alias("studentAttachmentList", list(object(FileMetaModel))))
  studentAttachmentList?: FileMetaModel[] = [] ;
  @serializable(alias("evaluationAttachmentList", list(object(FileMetaModel))))
  evaluationAttachmentList?: FileMetaModel[] = [] ;
  @serializable(alias("studentPoint", primitive()))
  studentPoint?: number;
  @serializable(alias("privateMessage", primitive()))
  privateMessage?: number;
  @serializable(alias("turnInStatus", primitive()))
  turnInStatus?: number;
  @serializable(alias("turnInDate", primitive()))
  turnInDate?: number;
}


export class WorksheetCreateModel {
  @serializable(alias("worksheetId", primitive()))
  WorksheetId?: number;
  @serializable(alias("name", primitive()))
  Name?: string;
  @serializable(alias("instructions", primitive()))
  Instructions?: string;
  @serializable(alias("dueDate", primitive()))
  DueDate?: string;
  @serializable(alias("points", primitive()))
  Points?: number;
  @serializable(alias("classId", primitive()))
  ClassId?: number;
  @serializable(alias("sectionId", list(primitive())))
  SectionId?: number[];
  @serializable(alias("subjectId", primitive()))
  SubjectId?: number;
}

export class WorksheetUpdateModel {
  @serializable(alias("worksheetId", primitive()))
  WorksheetId?: number;
  @serializable(alias("StudentId", primitive()))
  StudentId?: number;
  @serializable(alias("name", primitive()))
  Name?: string;
  @serializable(alias("instructions", primitive()))
  Instructions?: string;
  @serializable(alias("dueDate", primitive()))
  DueDate?: string;
  @serializable(alias("points", primitive()))
  Points?: number;
  @serializable(alias("classId", primitive()))
  ClassId?: number;
  @serializable(alias("sectionId", primitive()))
  SectionId?: number;
  @serializable(alias("subjectId", primitive()))
  SubjectId?: number;
}


export class StudentSubmissionModel {
  @serializable(alias("worksheetId", primitive()))
  WorksheetId?: number;
  @serializable(alias("StudentId", primitive()))
  StudentId?: number;
  @serializable(alias("StudentPoint", primitive()))
  StudentPoint?: number;
  @serializable(alias("PrivateMessage", primitive()))
  PrivateMessage?: string;
}


export class FilterWorksheet {
  @serializable(alias("classId", primitive()))
  ClassId?: number;
  @serializable(alias("sectionId", primitive()))
  SectionId?: number;
  @serializable(alias("subjectId", primitive()))
  SubjectId?: number;
  @serializable(alias('CurrentPage', primitive()))
  CurrentPage?: number;
  @serializable(alias('Pagesize', primitive()))
  Pagesize?: number;
}
