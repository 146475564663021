import { serializable, alias, primitive, object, list } from 'serializr';

export class Option {
  @serializable(alias('option1', primitive()))
  option1?: string;

  @serializable(alias('option2', primitive()))
  option2?: string;

  @serializable(alias('option3', primitive()))
  option3?: string;

  @serializable(alias('option4', primitive()))
  option4?: string;

  @serializable(alias('image_option1', primitive()))
  image_option1?: string;

  @serializable(alias('image_option2', primitive()))
  image_option2?: string;

  @serializable(alias('image_option3', primitive()))
  image_option3?: string;

  @serializable(alias('image_option4', primitive()))
  image_option4?: string;
}
export class QuestionAttachmentModel {
  @serializable(alias('question_attachment_id', primitive()))
  questionAttachmentId?: number;

  @serializable(alias('question_attachment_url', primitive()))
  questionAttachmentUrl?: string;
}
export class SolutionAttachmentModel {
  @serializable(alias('question_solution_attachment_id', primitive()))
  questionSolutionAttachmentId?: number;

  @serializable(alias('question_solution_attachment_url', primitive()))
  questionSolutionAttachmentUrl?: string;
}
export class QuestionImageOptionAttachmentModel {
  @serializable(alias('question_option_image_id', primitive()))
  questionOptionImageId?: number;

  @serializable(alias('question_option_image_url', primitive()))
  questionOptionImageUrl?: string;

  @serializable(alias('option', primitive()))
  option?: string;
}
export class ExcAtmptQuestAttchmtModel {
  @serializable(alias('exc_atmpt_quest_attchmt_id', primitive()))
  excAtmptQuestAttchmtId?: number;
  @serializable(alias('exc_atmpt_quest_attchmt_url', primitive()))
  excAtmptQuestAttchmtUrl?: string;
}
export class Question {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('content', primitive()))
  content?: string;

  @serializable(alias('only_free_text', primitive()))
  onlyFreeText?: string;

  @serializable(alias('started_at', primitive()))
  startedAt?: string;

  @serializable(alias('tutor_answer', primitive()))
  tutorAnswer?: string;

  @serializable(alias('options', object(Option)))
  options?: Option;

  @serializable(alias('correct_answer', primitive()))
  correctAnswer?: string;

  @serializable(alias('is_correct_for_tutor', primitive()))
  isCorrect?: string;

  @serializable(alias('solution', primitive()))
  solution?: string;

  @serializable(alias('attachment_url', primitive()))
  attachmentUrl?: string;

  @serializable(alias('comment', primitive()))
  comment?: string;

  @serializable(
    alias('question_attachments', list(object(QuestionAttachmentModel)))
  )
  questionAttachments?: QuestionAttachmentModel[];

  @serializable(
    alias(
      'question_solution_attachments',
      list(object(SolutionAttachmentModel))
    )
  )
  questionSolutionAttachments?: SolutionAttachmentModel[];

  @serializable(
    alias('exc_atmpt_quest_attchmts', list(object(ExcAtmptQuestAttchmtModel)))
  )
  excAtmptQuestAttchmts?: ExcAtmptQuestAttchmtModel[];

  //don't remove, this is to mark and unmark question in quiz
  @serializable(alias('marked', primitive()))
  marked?: true;

  //don't remove, this is to mark and unmark question in quiz
  @serializable(alias('visited', primitive()))
  visited?: true;

  //don't remove, this is for per quesion timer in quiz
  @serializable(alias('time', primitive()))
  time?: number;
}

export default class QuestionModel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('content', primitive()))
  question?: string;

  @serializable(alias('correct_answer', primitive()))
  correctAnswer?: string;

  @serializable(alias('option1', primitive()))
  option1?: string;

  @serializable(alias('option2', primitive()))
  option2?: string;

  @serializable(alias('option3', primitive()))
  option3?: string;

  @serializable(alias('option4', primitive()))
  option4?: string;

  @serializable(alias('image_option1', primitive()))
  image_option1?: string;

  @serializable(alias('image_option2', primitive()))
  image_option2?: string;

  @serializable(alias('image_option3', primitive()))
  image_option3?: string;

  @serializable(alias('image_option4', primitive()))
  image_option4?: string;

  @serializable(alias('solution', primitive()))
  solution?: string;

  @serializable(alias('is_active', primitive()))
  isActive?: boolean = false;

  @serializable(alias('only_free_text', primitive()))
  noOptions?: boolean;

  @serializable(
    alias('question_attachments', list(object(QuestionAttachmentModel)))
  )
  questionAttachments?: QuestionAttachmentModel;

  @serializable(
    alias(
      'question_solution_attachments',
      list(object(SolutionAttachmentModel))
    )
  )
  questionSolutionAttachments?: SolutionAttachmentModel;

  @serializable(
    alias(
      'question_option_images',
      list(object(QuestionImageOptionAttachmentModel))
    )
  )
  questionOptionImages?: QuestionImageOptionAttachmentModel;

  @serializable(alias('excercise_id', primitive()))
  exerciseId?: number;
}
