import { DatePicker, Radio, Skeleton } from "antd";
import React, { FC, useEffect, useState } from "react";
import StudentsIcon from "../../../assets/icons/self-onboarded-students.svg";
import LoginsIcon from "../../../assets/icons/logins.svg";
import ActiveUsersIcon from "../../../assets/icons/active-users.svg";
import TimeSpentIcon from "../../../assets/icons/time-spent.svg";
import { DownOutlined } from "@ant-design/icons";
import "./studentActivities.scss";
import DashboardFilter from "../DashboardFilter";
import { serialize } from "serializr";
import {
  DashboardFilterModel,
  StudentActivitiesModel,
} from "../../../models/dashboard.model";
import DashboardService from "../../../services/DashboardService/dashboard.service";
import { UserModel } from "../../../models/user.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { dateFilterFunc } from "../../../shared/utils/dataFormatConverter";
import moment from "moment";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { DownloadOutlined } from "@ant-design/icons";
import DashboardLoginReportFilter from "../DashboardLoginReportFilter";

interface StudentActivitiesProps {
  user: UserModel;
}

const StudentActivities: FC<StudentActivitiesProps> = (props) => {
  const { user } = props;


  const defaultFilter = {
    institute: user?.institute?.id,
    branch: user?.branch?.id,
    board: 0,
    classRoom: 0,
    startingDate: '0',
    endingDate: '0',
  }


  const [classified, setClassified] = useState("");
  const [studentActivities, setStudentActivities] = useState<StudentActivitiesModel>({});
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<DashboardFilterModel>({});
  const [params, setParams] = useState<DashboardFilterModel>({})

  const handleDownload = (params) => {
    params['id'] = user.id;
    setLoading(true)
    DashboardService.fetchLoginReport(
        serialize(DashboardFilterModel, params ?? {}),
        (contents) => { },
        () => { },
        () => { setLoading(false) }
    );
  }

  const fetchStudentActivities = (params?: DashboardFilterModel) => {
    const payload = serialize(DashboardFilterModel, params ?? defaultFilter);
    setLoading(true);
    DashboardService.fetchStudentActivities(
      payload,
      (activities) => {
        setStudentActivities(activities);
        setLoading(false);
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    if (user?.tutorClassRooms) {
      let classId = Number(user?.tutorClassRooms[0]?.id)
      setFilters({ ...defaultFilter, classRoom: classId ?? 0 });
      fetchStudentActivities({ ...defaultFilter, classRoom: classId ?? 0 })
    }
  }, []);

  return (
    <div className="student-activities">
      <div className="student-activities__header">
        <div className="student-activities__header-title">
          <div className="student-activities__header-title__left">
            {user?.tutorClassRooms?.filter((data) => data?.id === filters.classRoom)?.[0]?.name
              ? (
                <p className="class">
                  {
                    user?.tutorClassRooms?.filter((data) => data?.id === filters.classRoom)?.[0]?.name
                  }
                </p>
              ) : (
                ""
              )}

            <p className={user?.tutorClassRooms?.filter((data) => data?.classTeacher)?.[0]
              ?.name ? "title" : "not-a-class-teacher__title"}>Student Activities</p>
          </div>
          <DashboardFilter
            applyHandler={(values) => {
              setFilters({ ...defaultFilter, ...values });
              setClassified("");
              // if (values?.startingDate) {
              fetchStudentActivities({ ...defaultFilter, ...values })
              // }
            }}
            clearHandler={(values) => {
              setClassified("");
              setFilters({ ...defaultFilter,...values});
              fetchStudentActivities({ ...defaultFilter,...values})
            }}
          />
        </div>
        <div className="student-activities__header-classifieds">
          <Radio.Group
            onChange={(e) => {
              setClassified(e?.target?.value);
              if (e?.target?.value !== "month") {
                let dataFilter = dateFilterFunc(e?.target?.value)
                fetchStudentActivities({ ...filters, ...dataFilter })
              }
            }}
            value={classified}
            // disabled={
            //   filters?.startingDate || filters?.endingDate ? true : false
            // }
          >
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="yesterday">Yesterday</Radio.Button>
            <Radio.Button value="week">Week</Radio.Button>
            <Radio.Button value="month">Month</Radio.Button>
          </Radio.Group>
          {classified == "month" && (
            <DatePicker
              picker={"month"}
              format="MMM YYYY"
              suffixIcon={<DownOutlined />}
              onChange={(e) => {
                let dataFilter = dateFilterFunc(classified, moment(e))
                fetchStudentActivities({ ...filters, ...dataFilter })
              }}
            />
          )}
        </div>
      </div>
      <div className="student-activities-content__container">
        <div className="student-activities-content__card signups">
          <div className="student-activities-content__icon">
            <img src={StudentsIcon} />
          </div>
          <div className="student-activities-content__count">
            <p className="title">Signups</p>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <p className="value">{studentActivities?.totalSignups}</p>
            )}
          </div>
        </div>
        <div className="student-activities-content__card logins">
          <div className="student-activities-content__icon">
            <img src={LoginsIcon} />
          </div>
          <div className="student-activities-content__count">
            <p className="title">Logins</p>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <p className="value">{studentActivities?.totalLogins}</p>
            )}
          </div>
        </div>
        <div className="student-activities-content__card active-users">
          <div className="student-activities-content__icon">
            <img src={ActiveUsersIcon} />
          </div>
          <div className="student-activities-content__count">
            <p className="title">Active users</p>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <p className="value">{studentActivities?.totalActiveUsers}</p>
            )}
          </div>
        </div>
        <div className="student-activities-content__card time-spent">
          <div className="student-activities-content__icon">
            <img src={TimeSpentIcon} />
          </div>
          <div className="student-activities-content__count">
            <p className="title">Time spent</p>
            <p className="value">
              {studentActivities?.timeSpents !== undefined &&
                studentActivities?.timeSpents !== null &&
                studentActivities?.timeSpents !== 0
                ? (studentActivities?.timeSpents / 60).toFixed(2)
                : 0}{" "}
              hrs
            </p>
          </div>
        </div>
      </div>
      <div className="download-report-row">
        <div className="filter-row">
          Download Report Filter
          <DashboardLoginReportFilter
            applyHandler={(values) => {
              setParams(values)
            }}
            clearHandler={() => {
              setParams({})
            }}
            user={user}
          />
        </div>
        <ButtonComponent
          disabled={loading}
          type="text"
          iconLeft={<DownloadOutlined />}
          buttonContent="Downlaod Login Report"
          onClick={() => handleDownload(params)}
        />
    </div>
    </div>
  );
};

export default AuthContainer(StudentActivities);
