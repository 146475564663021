import { Menu } from "antd";
import React, { FC, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import AuthContainer from "../../../store/container/AuthContainer";
import AppLogo from "../../../assets/background-images/app-logo.svg";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";

import dashboardIcon from "../../../assets/icons/nav-icons/dashboard.svg";
import dashboardIconActive from "../../../assets/icons/nav-icons/dashboard-active.svg";

import learnIcon from "../../../assets/icons/nav-icons/learn.svg";
import learnIconActive from "../../../assets/icons/nav-icons/learn-active.svg";

import reportIcon from "../../../assets/icons/nav-icons/exams.svg";
import reportIconActive from "../../../assets/icons/nav-icons/exams-active.svg";

import LiveClassIcon from "../../../assets/icons/sidebar/live-class.svg";
import LiveClassIconActive from "../../../assets/icons/sidebar/live-class-active.svg";

import activityIcon from "../../../assets/icons/nav-icons/activity.svg";
import activityIconActive from "../../../assets/icons/nav-icons/activity-selected.svg";

import announcementIcon from "../../../assets/icons/nav-icons/announcement.svg";
import announcementIconActive from "../../../assets/icons/nav-icons/announcement-selected.svg";


import "./sidebar.scss";


let LoadImage = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../../assets/${appName}/app-icon-blue.svg`)} alt="app-logo" {...rest} />;
  }
  catch (err) {
    return <img src={require('../../../assets/background-images/app-logo.svg')} alt="app-logo" {...rest} />;
  }
}

interface SidebarProps {
  app_name: String;
  setAppName: (value: String) => void;
}

const Sidebar: FC<SidebarProps> = (props: any) => {
  const { app_name } = props;
  const handleClick = (e: any) => {
    setActiveKey(e.key);
  };

  useEffect(() => {
    let hostName = window.location.host.split('.')[0].toLocaleLowerCase()
    sessionStorage.setItem("APP_NAME", hostName);
    props.setAppName(hostName)
  }, [])

  console.log(props?.location?.pathname);


  const [activeKey, setActiveKey] = useState<string>("dashboard");
  return !props.location.pathname.includes("auth") &&
    !props.location.pathname.includes("change-password") ? (
    <div className="sidebar">
      <Menu
        triggerSubMenuAction={"click"}
        onClick={handleClick}
        mode="inline"
        selectedKeys={[props?.location?.pathname?.split("/")[1]]}
      >
        <Menu.Item className="sidebar-item logo" key="logo">
          {props.app_name !== null && <LoadImage className="mindjjo__logo" appName={props.app_name} />}
          {/* <img src={AppLogo} alt="app-logo" /> */}
        </Menu.Item>
        <Menu.Item
          className={`sidebar-item ${app_name}`}
          key="dashboard"
          onClick={() => props.history.push(appRoutes.DASHBOARD)}
        >
          <div className="sidebar-item__wrapper">
            <img
              src={
                props?.location?.pathname.includes("dashboard")
                  ? dashboardIconActive
                  : dashboardIcon
              }
              alt=""
            />
            <p>Dashboard</p>
          </div>
        </Menu.Item>
        <Menu.Item
          className={`sidebar-item ${app_name}`}
          key="learn"
          onClick={() => props.history.push(appRoutes.LEARN)}
        >
          <div className="sidebar-item__wrapper">
            <img
              src={
                props?.location?.pathname.includes("learn")
                  ? learnIconActive
                  : learnIcon
              }
              alt=""
            />
            <p>Learn</p>
          </div>
        </Menu.Item>
        {/* <Menu.Item
            className="navbar-item"
            key="queries"
            onClick={() => props.history.push(appRoutes.QUERIES)}
          >
            <img
              src={activeKey === "queries" ? libraryIconActive : libraryIcon}
              alt=""
            />
            <span>Queries</span>
          </Menu.Item>
          <Menu.Item
            className="navbar-item"
            key="assessment"
            onClick={() => props.history.push(appRoutes.ASSESSMENT)}
          >
            <img
              src={activeKey === "assessment" ? courseIconActive : courseIcon}
              alt=""
            />
            <span>Assessment</span>
          </Menu.Item> */}
        <Menu.Item
          className={`sidebar-item ${app_name}`}
          key="live-class"
          onClick={() => props.history.push(appRoutes.LIVE_CLASS)}
        >
          <div className="sidebar-item__wrapper">
            <img
              src={
                props?.location?.pathname?.includes("live-class")
                  ? LiveClassIconActive
                  : LiveClassIcon
              }
              alt=""
            />
            <p>Live Classes</p>
          </div>
        </Menu.Item>

        <Menu.Item
          className={`sidebar-item ${app_name}`}
          key="activity"
          onClick={() => props.history.push(appRoutes.ACTIVITY)}
        >
          <div className="sidebar-item__wrapper">
            <img
              src={
                props?.location?.pathname === "/activity"
                  ? reportIconActive
                  : reportIcon
              }
              alt=""
            />
            <p>Activity</p>
          </div>
        </Menu.Item>

        <Menu.SubMenu
          title="Report"
          icon={<img
            src={activityIcon}
            alt=""
          />}
        >
          <Menu.Item
            className={`sidebar-item ${app_name}`}
            key="activity-report"
            onClick={() => props.history.push(appRoutes.ACTIVITY_REPORT)}
          >
            <div className="sidebar-item__wrapper">
              <img
                src={
                  props?.location?.pathname === "/activity-report"
                    ? activityIconActive
                    : activityIcon
                }
                alt=""
              />
              <span>Activity Report</span>
            </div>
          </Menu.Item>

          <Menu.Item
            className={`sidebar-item ${app_name}`}
            key="login-report"
            onClick={() => props.history.push(appRoutes.LOGIN_REPORT)}
          >
            <div className="sidebar-item__wrapper">
              <img
                src={
                  props?.location?.pathname === "/login-report"
                    ? activityIconActive
                    : activityIcon
                }
                alt=""
              />
              <span>Login Report</span>
            </div>
          </Menu.Item>

          <Menu.Item
            className={`sidebar-item ${app_name}`}
            key="chapterwise-report"
            onClick={() => props.history.push(appRoutes.CHAPTERWISR_REPORT)}
          >
            <div className="sidebar-item__wrapper">
              <img
                src={
                  props?.location?.pathname === "/chapterwise-report"
                    ? activityIconActive
                    : activityIcon
                }
                alt=""
              />
              <span>Chapterwise Report</span>
            </div>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item
          className={`sidebar-item ${app_name}`}
          key="announcement"
          onClick={() => props.history.push(appRoutes.ANNOUNCEMENT)}
        >
          <div className="sidebar-item__wrapper">
            <img
              src={
                props?.location?.pathname?.includes("announcement")
                  ? announcementIconActive
                  : announcementIcon
              }
              alt=""
            />
            <p>Messages</p>
          </div>
        </Menu.Item>
        {/* <SubMenu
                    key="sub2"
                    icon={
                      <img src={avatarPlaceholder} alt="" className="submenu-icon" />
                    }
                    popupOffset={[-175, -75]}
                    title={userDetails?.fullName || "Tutor"}
                  >
                    <Menu.Item
                      key="my-profile"
                      onClick={() => setProfileVisibility(true)}
                    >
                      My Profile
                    </Menu.Item>
                    <Menu.Item
                      key="change-password"
                      onClick={() => props.history.push(appRoutes.CHANGE_PASSWORD)}
                    >
                      Change password
                    </Menu.Item>
                    <Menu.Item key="logout" onClick={handleLogout}>
                      Logout
                    </Menu.Item>
                  </SubMenu> */}
      </Menu>
    </div>
  ) : (
    <></>
  );
};

export default withRouter(AuthContainer(Sidebar));
