import { serializable, alias, primitive, list, object } from 'serializr';

export class ExcerciseAttempt {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('title', primitive()))
  title?: string;

  @serializable(alias('description', primitive()))
  description?: string;

  @serializable(alias('time', primitive()))
  time?: string;

  @serializable(alias('started_at', primitive()))
  startedAt?: string;

  @serializable(alias('completed_at', primitive()))
  completedAt?: string;

  @serializable(alias('timer_options', primitive()))
  timerOptions?: string;
}

export class Exercise {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('description', primitive()))
  description?: string;

  @serializable(alias('excercise_attempt', object(ExcerciseAttempt)))
  excerciseAttempt?: ExcerciseAttempt;

  @serializable(alias('class_room_subject_id', primitive()))
  classRoomSubjectId?: number;

  @serializable(alias('class_room_subject_name', primitive()))
  classRoomSubjectName?: string;

  @serializable(alias('exam_subject_id', primitive()))
  examSubjectId?: number;

  @serializable(alias('exam_subject_name', primitive()))
  examSubjectName?: string;

  @serializable(alias('is_active', primitive()))
  isActive?: string;

  @serializable(alias('time', primitive()))
  time?: string;

  @serializable(alias('timer_options', primitive()))
  timerOptions?: string;

  @serializable(alias('title', primitive()))
  title?: string;

  @serializable(alias('questions_count', primitive()))
  questionsCount?: string;

  @serializable(alias('exam_questions_count', primitive()))
  examQuestionsCount?: string;

  @serializable(alias('exam_exercise_attempt_count', primitive()))
  examExerciseAttemptCount?: string;

  @serializable(alias('exercise_attempt_count', primitive()))
  exerciseAttemptCount?: string;
}

export class Exercises {
  @serializable(alias('excercises', list(object(Exercise))))
  excercises?: Exercise[];
}
