import { Input } from 'antd';
import * as React from 'react';
import './addcomment.scss'
import { SendOutlined, PaperClipOutlined } from '@ant-design/icons';

interface InputProps {
    placeholder: string
    onClick: (value: string) => void,
    onAttach: () => void
}
export const AddComment = (props: InputProps) => {
    const {placeholder, onClick, onAttach} = props
    const [comment, setComment] = React.useState("")
    return(
        <Input
        placeholder={placeholder}
        value={comment}
        prefix={<PaperClipOutlined onClick={onAttach}/>}
        onChange={(e) => setComment(e.target.value)}
        onKeyPress={event => {if(event.key == "Enter") {
            onClick(comment)
            setComment("")
        }}}
        className="addcomment__input"
        addonAfter={<SendOutlined onClick={() => {
            onClick(comment)
            setComment("")
        }}/>
    }
        />
    )
}