export const validateFormValues = (values, index) => {
  let error = {};
  let questionsLength = Array(index).fill(undefined)

  if (!values[`question`]) {
    error[`question`] = `Question is required!`;
  }
  if (!values[`option1`] && !values[`option_image1`]) {
    error[`option1`] = `Option 1 is required!`;
  }
  if (!values[`option2`] && !values[`option_image2`]) {
    error[`option2`] = `Option 2 is required!`;
  }
  if (!values[`option3`] && !values[`option_image3`]) {
    error[`option3`] = `Option 3 is required!`;
  }
  if (!values[`option4`] && !values[`option_image4`]) {
    error[`option4`] = `Option 4 is required!`;
  }
  if (!values[`correctAnswer`]) {
    error[`correctAnswer`] = `Correct answer is required!`;
  }

  questionsLength[index] = error

  return {questions: questionsLength};
}
