import React, { useEffect, useMemo, useState } from "react";
import EmptyState from "../../shared/components/EmptyState";
import PageView from "../../shared/components/PageView";
import faker from "faker";
import LiveClassCard from "./LiveClassCard";
import "./liveClass.scss";
import LiveClassForm from "./LiveClassForm";
import { LiveClassModel } from "../../models/liveClass.model";
import LiveClassService from "../../services/LiveClassService/LiveClass.service";
import moment from "moment";
import { generatePath, withRouter } from "react-router-dom";
import { LIVE_CLASS_MEETING } from "../../routes/routeConstants/appRoutes";
import { debounce } from "lodash";
import DeleteLiveClassAlert from "./LiveClassMeeting/DeleteLiveClassAlert";
import { Skeleton } from "antd";

import AuthContainer from "../../store/container/AuthContainer";
import { UserModel } from "../../models/user.model";
import TitleContainer from '../../store/container/TitleContainer';

export const SkeletonLoader = (): any => {
  return Array(2)
    .fill(" ")
    .map((value) => {
      return (
        <div className="skeleton-loader">
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        </div>
      );
    });
};

interface LiveClassProps {
  setTitle: Function;
  history: any,
  user: UserModel
}

const LiveClass = (props: LiveClassProps) => {
  const { history, user, setTitle } = props;



  const [upcomingClasses, setUpcomingClasses] = useState<LiveClassModel[]>([]);
  const [currentLiveClass, setCurrentLiveClass] = useState<LiveClassModel>();
  const [showForm, setFormVisibility] = useState(false);
  const [liveClassesNow, setLiveClassesNow] = useState<LiveClassModel[]>([]);
  const [loadingUpcoming, setLoadingUpcoming] = useState(false);
  const [loadingLive, setLoadingLive] = useState(false);

  const [isDeleleAlertShown, setIsDeleleAlertShown] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const fetchUpcomingClasses = (params: { search?: string }) => {
    setLoadingUpcoming(true);
    LiveClassService.fetchUpcomingLiveClasses(
      params,
      (liveClasses) => {
        setUpcomingClasses(liveClasses);
      },
      () => { },
      () => {
        setLoadingUpcoming(false);
      }
    );
  };
  const fetchLiveClassesNow = (params: { search?: string }) => {
    setLoadingLive(true);
    LiveClassService.fetchLiveClassesNow(
      params,
      (liveClasses) => {
        setLiveClassesNow(liveClasses);
      },
      () => { },
      () => {
        setLoadingLive(false);
      }
    );
  };
  useEffect(() => {
    fetchLiveClassesNow({});
    fetchUpcomingClasses({});
  }, []);

  const refreshHandler = () => {
    fetchLiveClassesNow({});
    fetchUpcomingClasses({});
  };

  const handleSearch = (search: string) => {
    fetchLiveClassesNow({ search });
    fetchUpcomingClasses({ search });
  };
  const debouncedSearchHandler = useMemo(() => debounce(handleSearch, 500), [handleSearch]);

  const joinHandler = (liveClassId: number) => {
    setLoadingLive(true);
    const data = {
      "userType": "teacher",
      "userId": user.id,
      "liveClassId": liveClassId,
      'requestFrom':'web'
    }
    LiveClassService.joinLiveClass_Zoom(
      data,
      (joinLink) => {
        window.open(joinLink, "_blank")
      },
      () => { },
      () => { setLoadingLive(false); }
    )
    // history?.push(generatePath(LIVE_CLASS_MEETING, { liveClassId }));
  };



  const handleDelete = () => {
    if (currentLiveClass?.id) {
      setIsDeleteLoading(true);
      LiveClassService.deleteLiveClass(
        currentLiveClass?.id?.toString(),
        () => {
          fetchUpcomingClasses({});
          setIsDeleleAlertShown(false);
        },
        () => { },
        () => {
          setIsDeleteLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    props.setTitle({
      title: `Live Class`,
      showBack: false,
      subText: "",
      icon: null,
      showSearch: false,
      float: false,
      searchText: "Search subjects, topics ...",
      twoBgs: false,
    });
  }, [props]);

  return (
    <PageView
      title="Live Class"
      className="live-class"
      showAdd
      addHandler={() => {
        setFormVisibility(true);
      }}
      showSearch={{
        placeholder: "Search live class",
        onChange: (value) => {
          fetchLiveClassesNow({ search: value });
          fetchUpcomingClasses({ search: value });
        },
      }}
    >
      <LiveClassForm
        data={currentLiveClass}
        closeHandler={() => {
          setCurrentLiveClass(undefined);
          setFormVisibility(false)
        }}
        visible={showForm}
        refreshList={refreshHandler}
      />
      <DeleteLiveClassAlert
        liveClass={currentLiveClass}
        visible={isDeleleAlertShown}
        successHandler={handleDelete}
        cancelHandler={() => {
          setCurrentLiveClass(undefined);
          setIsDeleleAlertShown(false);
        }}
        isButtonLoading={isDeleteLoading}
      />
      {loadingLive || liveClassesNow?.length ? (
        <div className="live-class-live-now">
          <h3>Live now</h3>
          {loadingLive ? (
            <SkeletonLoader />
          ) : (
            liveClassesNow?.map((value, index) => {
              return (
                <LiveClassCard
                  data={value}
                  isLive
                  joinHandler={() => {
                    if (value?.id) joinHandler(value?.id);
                  }}
                />
              );
            })
          )}
        </div>
      ) : (
        ""
      )}
      {(loadingUpcoming || upcomingClasses?.length) ? <div
        className={`live-class-upcoming-classes ${!liveClassesNow?.length ? "no-live" : ""
          }`}
      >
        <h3>Upcoming Classes</h3>
        {loadingUpcoming ? (
          <SkeletonLoader />
        ) : (
          upcomingClasses?.map((value, index) => {
            return (
              <LiveClassCard
                data={value}
                editHandler={() => {
                  setCurrentLiveClass(value);
                  setFormVisibility(true);
                }}
                deleteHandler={() => {
                  setCurrentLiveClass(value);
                  setIsDeleleAlertShown(true);
                }}
              />
            );
          })
        )}
      </div> : ""}
    </PageView>
  );
};

export default withRouter(AuthContainer(TitleContainer(LiveClass)));
