import React, { FC, useEffect, useState } from "react";

import faker from "faker";
import { serialize } from "serializr";

import { FilterChapterwise, ChapterwiseSummaryModel } from "../../../models/Report/chapterwise.model";


import ReportService from "../../../services/ReportsService/ChapterwiseReport.service";
import { Button, Skeleton, Table } from "antd";
import { SkeletonLoader } from "../../LiveClass";
import ReportFilter from "./ReportFilter";
import { UserModel } from "../../../models/user.model";
import AuthContainer from "../../../store/container/AuthContainer";
import PageView from "../../../shared/components/PageView";


import "./style.scss";


interface ActivityReportProps {
    user: UserModel;
}

const ActivityReport: FC<ActivityReportProps> = (props) => {
    const { user } = props;

    const defaultFilter = {
      instituteId:user?.institute?.id,
      branchId:user?.branch?.id,
      boardId:0,
      sectionId:0,
      sectionSubjectId:0,
      branchClassRoomId:0
    }

    const [activitySummary, setActivitySummary] = useState<ChapterwiseSummaryModel[]>([]);
    const [filters, setFilters] = useState<FilterChapterwise>({});
    const [loading, setLoading] = useState(false);


    const fetchActivitySummary = (params?: FilterChapterwise) => {
        setLoading(true);
        const payload = serialize(FilterChapterwise, params ?? defaultFilter);
        ReportService.fetchActivitySummary(
            payload,
            (liveClasses) => {
                setActivitySummary(liveClasses);
            },
            () => { },
            () => {
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        if (user?.tutorClassRooms) {
            setFilters({ ...defaultFilter });
            //fetchActivitySummary({ ...defaultFilter })
        }
    }, []);

    //Download Report 
    const handleDownload = (params?: any) => {
        setLoading(true)
        const payload = serialize(FilterChapterwise, params ?? defaultFilter);
        ReportService.downloadActivitySummary(
            payload,
            (contents) => { },
            () => { },
            () => { setLoading(false) }
        );
    }
    return (
        <PageView title="Report">
            {activitySummary ? (
                <div className="activity-report">
                    <div className="activity-report__header">
                        <div className="activity-report__left">
                            <div className="activity-report__title">Chapterwise Report</div>
                            <ReportFilter
                                applyHandler={(values) => {
                                    setFilters({ ...defaultFilter, ...values });
                                    fetchActivitySummary({ ...defaultFilter, ...values })

                                }}
                                clearHandler={(values) => {
                                    setFilters({ ...defaultFilter, ...values });
                                    fetchActivitySummary({ ...defaultFilter, ...values })
                                }}
                            />
                        </div>

                        <div>
                            <Button onClick={() => handleDownload(filters)}>Download Report</Button>
                        </div>
                    </div>
                    {loading ? (
                        <div className="activity-report">
                            <SkeletonLoader />
                        </div>
                    ) : activitySummary?.length ? (
                        <Table
                        columns={[
                          {
                            title: 'Class',
                            dataIndex: 'grade',
                            key: 'grade',
                          },
                          {
                            title: 'Section',
                            dataIndex: 'section',
                            key: 'section',
                          },
                          {
                            title: 'Contents Viewed',
                            dataIndex: 'contentviwed',
                            width: 400,
                            children: [
                              {
                                title: 'Name',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['contentviwed']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.name}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              },
                              {
                                title: 'Status',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['contentviwed']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.status}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              },
                              {
                                title: 'Points',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['contentviwed']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.points}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              },
                              {
                                title: 'Quick Quiz',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['contentviwed']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.quickquiz}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              },
                              {
                                title: 'Points',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['contentviwed']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.quickquizpoint}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              },
                              
                            ]
                          },
                          {
                            title: 'Worksheet(PDF)',
                            dataIndex: 'pdfviwed',
                            width: 400,
                            children: [
                              {
                                title: 'Name',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['pdfviwed']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.name}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              },
                              {
                                title: 'Status',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['pdfviwed']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.status}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              }
                            ]
                          },
                          {
                            title: 'Exercise',
                            dataIndex: 'excercise',
                            width: 400,
                            children: [
                              {
                                title: 'Name',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['excercise']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.name}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              },
                              {
                                title: 'Status',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['excercise']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.status}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              },
                              {
                                title: 'Points',
                                render: (text, row) => <div className="sub_div">
                                  {
                                    row['excercise']?.map(data => <div >
                                      <div className="sub_row_div">
                                        <div style={{ width: 100 }}>{data.points}</div>
                                      </div>
                                    </div>)
                                  }
                                </div>
                              },
                
                              
                            ]
                          },
                        ]}
                        bordered
                        scroll={{ x: 2000 }}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={activitySummary}
                        loading={loading}
                      />
                
                    ) : (
                        <div className="no-activity-report-text">No Activity Summary !</div>
                    )}
                </div>
            ) : (
                <div />
            )
            }

        
        </PageView>
    )

};

export default AuthContainer(ActivityReport);
