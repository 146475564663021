import React, { FC, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";

import "./bulkUpload.scss";
import { Button, message, Upload } from "antd";
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { AcceptedUploadTypes } from "./acceptedUploadTypes";

interface UploadContentProps {
  title?: string
  setUploadedFile: Function,
  maxCount?: number
}

const UploadContent: FC<UploadContentProps> = (props) => {
  const {
    title = 'Click to Upload',
    setUploadedFile,
    maxCount = 5 } = props;


  const [fileList, setFileList] = useState<UploadFile[]>([])

  const beforeUpload = (file: File) => {
    const validTypes = AcceptedUploadTypes.exerciseUpload.assignment_doc.includes(file.type);
    if (!validTypes) {
      message.error("You can only upload PDF/JPEG file!").then();
      return Upload.LIST_IGNORE;
    }
    return validTypes;
  };


  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList.map(file => {
      file.status = 'done'
      return file
    }))
    setUploadedFile(newFileList.map(file => file.originFileObj));
  };


  return (
    <Upload
      maxCount={maxCount}
      fileList={fileList}
      beforeUpload={beforeUpload}
      onChange={onChange}
      accept={
        "application/pdf, image/jpeg"
      }
    >
      <Button className="upload__btn" icon={<UploadOutlined />}>
        {title}
      </Button>
    </Upload>
  );
};

export default UploadContent;
