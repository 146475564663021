import React, { FC, useState, useRef } from "react";

import { Col, Input, Row, Avatar, Skeleton, Button } from "antd";
import { Field, Form, Formik } from "formik";

import { CommentsModel } from "../../../models/Announcement.model";
import { SendOutlined } from '@ant-design/icons';


import "./commentForm.scss";
import AnnouncementService from "../../../services/AnnouncementService/Announcement.service";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";


export const SkeletonLoader = (): any => {
  return Array(2)
    .fill(" ")
    .map((value) => {
      return (
        <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
      );
    });
};

interface CommentFormProps {
  refreshList: () => void;
  comments?: CommentsModel[];
  id?: number

}

const CommentForm: FC<CommentFormProps> = (props) => {
  const { comments, id, refreshList } = props;
  const formRef = useRef<any>();
  const [initialValues, setInitialValues] = useState<any>({ Comment: '' });
  const [loading, setLoading] = useState(false);

  // const _handleKeyDown = (e, resetForm: Function) => {
  //   if (e.key === 'Enter') {
  //     if (e.target.value !== '')
  //       handleSubmit(e.target.value, resetForm)
  //   }
  // }

  const handleSubmit = (values: string, resetForm: Function) => {
    setLoading(true);
    let formData = convertJSONToFormData({ AnnouncementId: id, Comment: values });
    AnnouncementService.createComment(
      formData,
      () => {
        setInitialValues({ Comment: '' });
        refreshList();
        resetForm()
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  };


  return (

    <div className="announcement-comment">

      <Formik
        initialValues={initialValues}
        onSubmit={() => { }}
        enableReinitialize
        innerRef={formRef}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <div className="announcement-comment__input">
              <Field
                as={Input}
                type="text"
                name="Comment"
                placeholder="write a comment"
                // onKeyDown={(e) => _handleKeyDown(e, resetForm)}
                disabled={loading}
                maxLength={300}
              />
              <Button
                disabled={values.Comment === '' ? true : false}
                type="primary"
                shape="circle"
                icon={<SendOutlined />}
                size={"middle"}
                onClick={() => {
                  handleSubmit(values.Comment, resetForm)
                }}
              />
            </div>

          </Form>
        )}
      </Formik>

      {loading ?
        <SkeletonLoader />
        : <>
          {comments?.map(comment => (<div key={comment?.commentId} className="announcement-comment__card">
            <Avatar
              style={{
                verticalAlign: 'middle',
                marginRight: 5
              }}
              size="small"
            >
              {"M"}
            </Avatar>

            <div className="announcement-comment__text">
              <span className="announcement-comment__postby">{comment?.createdByName}</span>
              <p className="announcement-comment__data">
                {comment?.comment}
              </p>
            </div>
          </div>))}
        </>
      }

    </div>

  );
};

export default CommentForm;
