import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Select } from 'antd';
import Error from "../Error";
import './dropdownField.scss';

interface DropdownFieldProps {
    name: string;
    placeholder: string;
    mode?: string;
    options: any;
    showSearch: boolean;
    value:any;
    onChange: Function;
    optionFilterProp: string;
    defaultValue?: any;
    className?: string;
    disabled?: boolean;
}

const DropdownField: FC<DropdownFieldProps> = (props) => {
    const { name } = props;
    return (
        <div className='dropdown-field'>
            <Field as={Select} {...props}/>
            <ErrorMessage name={name}>
                {(message: string) => <Error message={message} />}
            </ErrorMessage>
        </div>
    )
}

export default DropdownField;
