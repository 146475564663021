import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import QuestionModel, {
  QuestionAttachmentModel,
  QuestionImageOptionAttachmentModel,
  SolutionAttachmentModel,
} from "../../models/Learn/Question/question.model";
import {
  CONTENT_OPTION_ATTACHMENTS,
  CONTENT_QUESTION_ATTACHMENTS,
  CONTENT_SOLUTION_ATTACHMENTS,
  EXAM_QUESTION_ATTACHMENTS,
  EXAM_QUESTION_OPTION_ATTACHMENTS,
  EXAM_SOLUTION_ATTACHMENTS,
  PAPER_QUESTION_ATTACHMENTS,
  PAPER_QUESTION_OPTION_ATTACHMENTS,
  PAPER_SOLUTION_ATTACHMENTS,
  QUESTION,
  QUESTION_ATTACHMENTS,
  QUESTION_ID,
  QUESTION_OPTION_ATTACHMENTS,
  SOLUTION_ATTACHMENTS,
} from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class QuestionService {
  static createQuestion(
    payload: any,
    onSuccess: (question: QuestionModel) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const question = serialize(Object.assign(new QuestionModel(), payload));

    return axiosInstance
      .post(QUESTION, { question: question })
      .then((response) => {
        const question = deserialize(QuestionModel, response["data"]?.question);
        onSuccess(question);
      })
      .catch((error) => {
        console.log(error?.message);
      })
      .finally(() => {
        onError();
      });
  }

  static updateQuestion(
    id: any,
    payload: any,
    onSuccess: (question: QuestionModel) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const question = serialize(Object.assign(new QuestionModel(), payload));

    return axiosInstance
      .put(QUESTION + `/${id}`, { question: question })
      .then((response) => {
        const question = deserialize(QuestionModel, response["data"]?.question);
        onSuccess(question);
      })
      .catch((error) => {
        console.log(error?.message);
      })
      .finally(() => {
        onError();
      });
  }

  static deleteQuestion(id: any, onSuccess: () => void, onError: () => void) {
    return axiosInstance
      .delete(generatePath(QUESTION_ID, { id }))
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        console.log(error?.message);
        onError();
      });
  }

  static getQuestionsByExercise(
    params: any,
    onSuccess: (data: any) => void,
    onError: (data: any) => void
  ) {
    return axiosInstance
      .get(QUESTION, { params })
      .then((response) => {
        const questions = deserialize(
          QuestionModel,
          response["data"]?.questions
        );
        onSuccess(questions);
      })
      .catch((error) => {
        Notification({ message: "Error", description: "something went wrong!", type: NotificationTypes.ERROR });
        onError(error);
      });
  }
  static createQuestionAttachment(
    data: any,
    onSuccess: (value: QuestionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(QUESTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          QuestionAttachmentModel,
          response["data"]["question_attachment"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createSolutionAttachment(
    data: any,
    onSuccess: (value: SolutionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(SOLUTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          SolutionAttachmentModel,
          response["data"]["question_solution_attachment"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createQuestionImageOptionAttachment(
    data: any,
    onSuccess: (value: QuestionImageOptionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(QUESTION_OPTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          QuestionImageOptionAttachmentModel,
          response["data"]["question_option_image"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createContentQuestionAttachment(
    data: any,
    onSuccess: (value: QuestionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(CONTENT_QUESTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          QuestionAttachmentModel,
          response["data"]["content_question_attachment"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createContentSolutionAttachment(
    data: any,
    onSuccess: (value: SolutionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(CONTENT_SOLUTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          SolutionAttachmentModel,
          response["data"]["cont_quest_sol_atchmnt"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createContentQuestionImageOptionAttachment(
    data: any,
    onSuccess: (value: QuestionImageOptionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(CONTENT_OPTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          QuestionImageOptionAttachmentModel,
          response["data"]["cont_quest_option_image"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createExamQuestionAttachment(
    data: any,
    onSuccess: (value: QuestionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(EXAM_QUESTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          QuestionAttachmentModel,
          response["data"]["exam_question_attachment"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createExamSolutionAttachment(
    data: any,
    onSuccess: (value: SolutionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(EXAM_SOLUTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          SolutionAttachmentModel,
          response["data"]["exam_question_solution_attachment"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createExamQuestionImageOptionAttachment(
    data: any,
    onSuccess: (value: QuestionImageOptionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(EXAM_QUESTION_OPTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          QuestionImageOptionAttachmentModel,
          response["data"]["exam_question_option_image"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createPaperQuestionAttachment(
    data: any,
    onSuccess: (value: QuestionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(PAPER_QUESTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          QuestionAttachmentModel,
          response["data"]["paper_question_attachment"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createPaperSolutionAttachment(
    data: any,
    onSuccess: (value: SolutionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(PAPER_SOLUTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          SolutionAttachmentModel,
          response["data"]["paper_question_solution_attachment"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
  static createPaperQuestionImageOptionAttachment(
    data: any,
    onSuccess: (value: QuestionImageOptionAttachmentModel) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(PAPER_QUESTION_OPTION_ATTACHMENTS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const attachment = deserialize(
          QuestionImageOptionAttachmentModel,
          response["data"]["paper_exam_question_option_image"]
        );
        onSuccess(attachment);
      })
      .catch((error) => {
        Notification({
          message: "Attachment",
          description: "Unable to create attachment",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }
}
