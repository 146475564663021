import AgoraRTC, {
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  ScreenVideoTrackInitConfig,
} from "agora-rtc-sdk-ng";
import React, { FC, useEffect, useState } from "react";
import { useClient } from "..";
import MuteIcon from "../../../../assets/icons/live-class/microphone-fill.svg";
import UnmuteIcon from "../../../../assets/icons/live-class/microphone-slash-fill.svg";
import StopVideoIcon from "../../../../assets/icons/live-class/video-camera-fill.svg";
import StartVideoIcon from "../../../../assets/icons/live-class/video-camera-slash-fill.svg";
import StartShareScreenIcon from "../../../../assets/icons/live-class/share-screen.svg";
import StopShareScreenIcon from "../../../../assets/icons/live-class/stop-share-screen.svg";
import ParticipantsIcon from "../../../../assets/icons/live-class/users-fill.svg";
import InfoIcon from "../../../../assets/icons/live-class/info-fill.svg";

import "./controls.scss";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import DeleteLiveClassAlert from "../DeleteLiveClassAlert";
import LiveClassService from "../../../../services/LiveClassService/LiveClass.service";
import { useParams } from "react-router";

interface ControlsProps {
  leaveHandler: () => void;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  callType: "audio" | "video";
  audioTrack: IMicrophoneAudioTrack | null;
  videoTrack: [IMicrophoneAudioTrack, ICameraVideoTrack] | null;
  users: IAgoraRTCRemoteUser[];
  participantsHandler: () => void;
  infoHandler: () => void;
  shareScreenHandler: () => void;
  isScreenSharing: boolean;
  clientControlsHandler: (controls: { audio: boolean; video: boolean }) => void;
  muteAllHandler: (value: "mute" | "unmute") => void;
}

const Controls: FC<ControlsProps> = (props) => {
  const client = useClient();
  const {
    videoTrack,
    audioTrack,
    callType,
    setStart,
    leaveHandler,
    users,
    participantsHandler,
    infoHandler,
    shareScreenHandler,
    isScreenSharing,
    clientControlsHandler,
    muteAllHandler
  } = props;
  const params: any = useParams();
  const [trackState, setTrackState] = useState({
    video: true,
    audio: true,
  });
  const [showDeleteAlert, setDeleteAlertVisibility] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const mute = async (type: "audio" | "video") => {
    if (callType == "video") {
      if (type === "audio") {
        await videoTrack?.[0].setEnabled(!trackState.audio);
        clientControlsHandler({ ...trackState, audio: !trackState.audio });
        setTrackState((ps) => {
          return { ...ps, audio: !ps.audio };
        });
      } else if (type === "video") {
        await videoTrack?.[1].setEnabled(!trackState.video);
        clientControlsHandler({ ...trackState, video: !trackState.video });
        setTrackState((ps) => {
          return { ...ps, video: !ps.video };
        });
      }
    } else {
      await audioTrack?.setEnabled(!trackState.audio);
      clientControlsHandler({ ...trackState, audio: !trackState.audio });
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    }
  };

  const handleScreenShare = async () => {
    shareScreenHandler();
  };

  const leaveChannel = () => {
    if (params?.liveClassId) {
      setDeleteLoading(true);
      LiveClassService.endLiveClass(
        params?.liveClassId,
        async () => {
          await client.leave();
          client.removeAllListeners();
          if (callType == "video") {
            videoTrack?.[0].close();
            videoTrack?.[1].close();
          } else {
            audioTrack?.close();
          }
          setStart(false);
          leaveHandler();
          setDeleteAlertVisibility(false);
        },
        () => {},
        () => {
          setDeleteLoading(false);
        }
      );
    }
  };

  return (
    <div className="controls">
      <DeleteLiveClassAlert
        isButtonLoading={deleteLoading}
        cancelHandler={() => {
          setDeleteAlertVisibility(false);
        }}
        visible={showDeleteAlert}
        successHandler={leaveChannel}
      />
      <div className={`controls__container ${!videoTrack && "audio-only"}`}>
        <div className="controls__item">
          <img
            src={trackState.audio ? MuteIcon : UnmuteIcon}
            onClick={() => mute("audio")}
          />
          <p>{trackState.audio ? "Mute" : "Unmute"}</p>
        </div>
        {videoTrack && (
          <div className="controls__item">
            <img
              src={trackState.video ? StopVideoIcon : StartVideoIcon}
              onClick={() => mute("video")}
            />
            <p>{trackState.video ? "Stop Video" : "Start Video"}</p>
          </div>
        )}

        <div className="controls__item">
          <img
            src={isScreenSharing ? StopShareScreenIcon : StartShareScreenIcon}
            onClick={handleScreenShare}
          />
          <p>{isScreenSharing ? "Stop Sharing" : "Share Screen"}</p>
        </div>
        <div className="controls__item">
          <img src={ParticipantsIcon} onClick={participantsHandler} />
          <p>Participants ({users?.length + 1})</p>
        </div>
        <div className="controls__item" onClick={infoHandler}>
          <img src={InfoIcon} />
          <p>Info</p>
        </div>
        <div className="controls__item">
          <img
            src={
              users?.filter((user) => user?.hasAudio)?.length
                ? MuteIcon
                : UnmuteIcon
            }
            onClick={() => muteAllHandler(users?.filter((user) => !user?.hasAudio)?.length ? "unmute": "mute")}
          />
          <p>{users?.filter((user) => !user?.hasAudio)?.length ? "Unmute all" : "Mute all"}</p>
        </div>
      </div>
      <div className="end-btn__container">
        <ButtonComponent
          type="primary"
          danger
          onClick={() => {
            setDeleteAlertVisibility(true);
          }}
          buttonContent="End Live Class"
        />
      </div>
      {/* <p className={trackState.audio ? "on" : ""} onClick={() => mute("audio")}>
        {trackState.audio ? "MuteAudio" : "UnmuteAudio"}
      </p>
      <p className={trackState.video ? "on" : ""} onClick={() => mute("video")}>
        {trackState.video ? "MuteVideo" : "UnmuteVideo"}
      </p>
      <p onClick={() => handleScreenShare()}>
        {trackState?.screen ? "Stop sharing" : "Share the screen"}
      </p>
      {<p onClick={() => leaveChannel()}>Leave</p>} */}
    </div>
  );
};

export default Controls;
