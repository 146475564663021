import { serializable, alias, object, list, primitive } from "serializr";

export class StudentChapterResult {
  @serializable(alias("chapterId", primitive()))
  id?: number;

  @serializable(alias("chapterId", primitive()))
  chapterId?: number;

  @serializable(alias("score", primitive()))
  score?: number;

  @serializable(alias("accuracy", primitive()))
  accuracy?: number;

  @serializable(alias("chapterName", primitive()))
  name?: string;

  @serializable(alias("speed", primitive()))
  averageTimePerQuestion?: number;

  @serializable(alias("duration", primitive()))
  exerciseAttemptDuration?: number;

  @serializable(alias("excerciseDone", primitive()))
  numberOfExerciseAttempts?: number;

  @serializable(alias("totalContent", primitive()))
  totalContentCount?: number;

  @serializable(alias("contentDone", primitive()))
  completedContentCount?: number;

  @serializable(alias("contentCompleted", primitive()))
  contentCompletionPercentage?: number;

  @serializable(alias("totalExcercise", primitive()))
  totalExerciseCount?: number;
  @serializable(alias("totalExcercise", primitive()))
  exercisesCount?: number;

  @serializable(alias("excerciseDone", primitive()))
  completedExerciseCount?: number;

  @serializable(alias("excerciseCompleted", primitive()))
  exerciseCompletionPercentage?: number;

  @serializable(alias("exam_exercises_count", primitive()))
  examExercisesCount?: number;

  @serializable(alias('completedPerc', primitive()))
  cummulativeCompletionPercentage?: number;
}

export class ExamChapterResult {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("score", primitive()))
  score?: number;

  @serializable(alias("accuracy", primitive()))
  accuracy?: number;

  @serializable(alias("average_time_per_question", primitive()))
  averageTimePerQuestion?: number;

  @serializable(alias("duration", primitive()))
  exerciseAttemptDuration?: number;

  @serializable(alias("number_of_attempts", primitive()))
  numberOfExerciseAttempts?: number;

  @serializable(alias("exam_chapter_id", primitive()))
  chaptorId?: number;

  @serializable(alias("exam_chapter_name", primitive()))
  chaptorName?: string;

  @serializable(alias("content_count", primitive()))
  totalContentCount?: number;

  @serializable(alias("student_exam_content_count", primitive()))
  completedContentCount?: number;

  @serializable(alias("total_exercise_count", primitive()))
  totalExerciseCount?: number;

  @serializable(alias("attempted_exercise_count", primitive()))
  completedExerciseCount?: number;
}
