import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  SET_APP_NAME,
  SET_CURRENT_CLASS_ROOM,
  SET_RECENT_WATCHED_CONTENT,
} from '../definitions/authConstants';
import { CreateReducer } from '../../shared/utils/createReducer';
import { LocalStorage } from '../../shared/utils/LocalStorageHelpers';

export interface IAuthState {
  authenticated: boolean;
  user?: any;
  app_name: string | null;
  type: null;
  currentClassRoom: any;
  recentWatchedContent?: any;
}

const initState: IAuthState = {
  authenticated: true,
  user: LocalStorage.getItem('USER'),
  app_name: sessionStorage.getItem('APP_NAME'),
  currentClassRoom: LocalStorage.getItem('CURRENT_CLASS_ROOM'),
  type: null,
  recentWatchedContent: LocalStorage.getItem('RECENT_WATCHED_CONTENT'),
};

const authReducer = CreateReducer(initState, {
  [AUTHENTICATED](state: any, action: any) {
    const { authenticated, user } = action.payload;
    return {
      ...state,
      authenticated,
      user,
    };
  },
  [UNAUTHENTICATED](state: any, payload: any) {
    const { authenticated, user, courseId, currentCourse } = payload;
    return { ...state, authenticated, user, courseId, currentCourse };
  },
  [SET_APP_NAME](state: any, action: any) {
    const { app_name } = action.payload;
    return {
      ...state,
      app_name,
    };
  },
  [SET_CURRENT_CLASS_ROOM](state: any, action: any) {
    const { currentClassRoom } = action.payload;
    return {
      ...state,
      currentClassRoom,
    };
  },
  [SET_RECENT_WATCHED_CONTENT](state: any, action: any) {
    const { recentWatchedContent } = action.payload;

    if (recentWatchedContent) {
      LocalStorage.setItem('RECENT_WATCHED_CONTENT', recentWatchedContent);
      console.log('recentWatchedContent', LocalStorage.getItem('RECENT_WATCHED_CONTENT'))
    }
    return {
      ...state,
      recentWatchedContent,
    };
  },
});

export default authReducer;
