import { serializable, alias, primitive, list, object } from "serializr";

export class FilterReportModel {
    @serializable(alias("ClassId", primitive()))
    ClassId?: number;
    @serializable(alias("SectionId", primitive()))
    SectionId?: number;
    @serializable(alias("subjectId", primitive()))
    SubjectId?: number;
}

export class ActivitySummaryFilterModel {
    @serializable(alias('SectionId', primitive()))
    SectionId?: number;
    @serializable(alias('SubjectId', primitive()))
    SubjectId?: number;
}

export class ActivitySummaryModel {
    @serializable(alias("activityId", primitive()))
    activityId?: number;
    @serializable(alias("activityName", primitive()))
    activityName?: string;
    @serializable(alias("postedOn", primitive()))
    postedOn?: string;
    @serializable(alias("dueOn", primitive()))
    dueOn?: string;
    @serializable(alias("className", primitive()))
    className?: string;
    @serializable(alias("sectionName", primitive()))
    sectionName?: string;
    @serializable(alias("subjectName", primitive()))
    subjectName?: string;
    @serializable(alias("maxPoints", primitive()))
    maxPoints?: number;
    @serializable(alias("assignedCount", primitive()))
    assignedCount?: number;
    @serializable(alias("turnInCount", primitive()))
    turnInCount?: number;
    @serializable(alias("reviewedCount", primitive()))
    reviewedCount?: number;
}

export class SubmissionDetailsModel {
    @serializable(alias("activityId", primitive()))
    activityId?: string;
    @serializable(alias("activityName", primitive()))
    activityName?: string;
    @serializable(alias("postedOn", primitive()))
    postedOn?: string;
    @serializable(alias("dueOn", primitive()))
    dueOn?: string;
    @serializable(alias("sectionName", primitive()))
    sectionName?: string;
    @serializable(alias("subjectName", primitive()))
    subjectName?: string;
    @serializable(alias("maxPoints", primitive()))
    maxPoints?: string;
    @serializable(alias("turnInDate", primitive()))
    turnInDate?: string;
    @serializable(alias("studentName", primitive()))
    studentName?: string;
    @serializable(alias("points", primitive()))
    points?: string;

}

