import React, { FC } from "react";
import "./filters.scss";
import { CloseOutlined } from "@ant-design/icons";
import ButtonComponent from "../ButtonComponent";
import { Col, Row } from "antd";
interface FiltersProps {
  children: any;
  expand: boolean;
  handleReset: () => void;
  handleApply: () => void;
  closeHandler: () => void;
}
const Filters: FC<FiltersProps> = ({
  children,
  expand,
  closeHandler,
  handleReset,
  handleApply,
}) => {
  return (
    <div className={`filter ${expand ? "filter__expand" : "filter__collapse"}`}>
      <div
        className={`filter__container ${
          expand ? "filter-container__expand" : "filter-container__collapse"
        }`}
      >
        <div className="filter__close" onClick={closeHandler}>
          <CloseOutlined />
        </div>
        <div className="filter__title">Filters</div>
        <div className="filter__children">{children}</div>
        <Row className="filter__footer" justify="space-around">
          <Col span={6}>
          <ButtonComponent
            buttonContent="Reset"
            type="text"
            onClick={handleReset}
          />
          </Col>
          <Col span={6}>
          <ButtonComponent
            buttonContent="Apply"
            type="primary"
            onClick={handleApply}
          />
          </Col>
         
        </Row>
      </div>
    </div>
  );
};
export default Filters;
