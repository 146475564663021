import { serializable, alias, object, list, primitive } from 'serializr';


export class FilterLoginActivity {
    @serializable(alias('InstId', primitive()))
    instituteId?: number;

    @serializable(alias('BranchId', primitive()))
    branchId?: number;

    @serializable(alias('BoardId', primitive()))
    boardId?: number;

    @serializable(alias('SectionId', primitive()))
    sectionId?: number;

    @serializable(alias('SubjectId', primitive()))
    sectionSubjectId?: number;

    @serializable(alias('GradeId', primitive()))
    branchClassRoomId?: number;

    @serializable(alias('StartDate', primitive()))
    startingDate?: string;

    @serializable(alias('EndDate', primitive()))
    endingDate?: string;
}

export class LoginDetailsModel {
    @serializable(alias("logintime", primitive()))
    logintime?: string;
    @serializable(alias("timeused", primitive()))
    timeused?: string;
}

export class ContentModel {
    @serializable(alias("name", primitive()))
    name?: string;
    @serializable(alias("quickquiz", primitive()))
    quickquiz?: string;
}
export class ActivityModel {
    @serializable(alias("activityname", primitive()))
    activityname?: string;
    @serializable(alias("status", primitive()))
    status?: string;
}

export class ChapterModel {
    @serializable(alias("chaptername", primitive()))
    chaptername?: string;
    @serializable(alias("content", list(object(ContentModel))))
    content?: ContentModel[];
    
}


export class ContentviwedModel {
    @serializable(alias("subjectname", primitive()))
    subjectname?: string;
    @serializable(alias("chapter", list(object(ChapterModel))))
    chapter?: ChapterModel[];
    @serializable(alias("content", list(object(ActivityModel))))
    activities?: ActivityModel[];
}

export class LoginActivitySummaryModel {
    @serializable(alias("studentid", primitive()))
    studentid?: number;
    @serializable(alias("studentname", primitive()))
    studentname?: string;
    @serializable(alias("grade", primitive()))
    grade?: string;
    @serializable(alias("section", primitive()))
    section?: string;
    @serializable(alias("login", list(object(LoginDetailsModel))))
    login?: LoginDetailsModel[];
    @serializable(alias("contentviwed", list(object(ContentviwedModel))))
    contentviwed?: ContentviwedModel[];
    @serializable(alias("pdfviwed", list(object(ContentviwedModel))))
    pdfviwed?: ContentviwedModel[];
    @serializable(alias("excercise", list(object(ContentviwedModel))))
    excercise?: ContentviwedModel[];
    @serializable(alias("activity", list(object(ContentviwedModel))))
    activityviwed?: ContentviwedModel[];
}