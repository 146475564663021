import React, { FC, useState } from "react";

import { Col, Input, Row, Avatar, Spin } from "antd";
import ReadMore from "../../../shared/components/ReadMore";
import CommentForm from "../CommentForm";

import { serialize } from "serializr";
import { AnnouncementModel, FileMetaModel } from "../../../models/Announcement.model";
import FileDownloadService from "../../../services/FileDownloadService/FileDownloadService";

import pdfIcon from "../../../assets/icons/attachment-svgrepo-com.svg";

import "./announcementCard.scss";
import moment from "moment";



interface AnnouncementCardProps {
  data: AnnouncementModel;
  onClick?: () => void;
  refreshList: () => void;

}

const AnnouncementCard: FC<AnnouncementCardProps> = (props) => {
  const { data, onClick, refreshList } = props;
  const [loading, setLoading] = useState(false)

  const downloadFile = () => {
    setLoading(true)
    const payload = serialize(FileMetaModel, { attachmentId: 0, name: data.attachmentName, path: data.attachmentPath });
    FileDownloadService.fileDownload(
      payload,
      () => { },
      () => { },
      () => { setLoading(false); }
    );
  }

  return (
    <Row >
      <Col span={24} className={`announcement-card`}>
        <div className="announcement-card-date__container">
          <div className="announcement-card__date">
            <span>
              <Avatar
                shape="square"
                style={{
                  backgroundColor: '#f56a00',
                  verticalAlign: 'middle',
                  marginRight: 5
                }}
                size="small"
              >
                {"M"}
              </Avatar>
            </span>
            <span>{data?.createdByName}</span>
            <span className="dot"></span>
            <span>{'Message'}</span>
            <span className="dot"></span>
            <span>{moment(data?.createdAt).format('MMMM Do YYYY  h:mm a')}</span>
            {/* {moment(data?.date).format("ddd DD, MMM")} */}
          </div>
          <ReadMore>
            {data?.message}
          </ReadMore>

          {data.attachmentName !== null && <div className="attachment_file" >
            {loading&&<Spin size="small" />}
            <img src={pdfIcon} alt="" className="pdf_icon" />
            <div
              onClick={downloadFile}
              className="file_title">
              {data.attachmentName}
            </div>

          </div>
          }

        </div>

        <div className="announcement_comment__container">

          <CommentForm
            id={data?.announcementId}
            comments={data?.comments}
            refreshList={refreshList}
          />

          <div className="more_label" onClick={onClick}>
            See More
          </div>

        </div>
      </Col>


    </Row>
  );
};

export default AnnouncementCard;
