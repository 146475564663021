import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Col, Input, Row, Avatar, Spin, Skeleton } from "antd";
import PageView from "../../../shared/components/PageView";
import ReadMore from "../../../shared/components/ReadMore";


import { serialize } from "serializr";
import { AnnouncementModel, FileMetaModel } from "../../../models/Announcement.model";
import AnnouncementService from "../../../services/AnnouncementService/Announcement.service";
import FileDownloadService from "../../../services/FileDownloadService/FileDownloadService";

import pdfIcon from "../../../assets/icons/attachment-svgrepo-com.svg";

import "./announcementDetails.scss";
import moment from "moment";
import CommentForm from "../CommentForm";


export const SkeletonLoader = (): any => {
  return Array(3)
    .fill(" ")
    .map((value) => {
      return (
        <div className="announcement-card-date__container">
          <div className="announcement-card__date">
            <Skeleton paragraph={{ rows: 2, width: "100%" }} active />
          </div>
        </div>
      );
    });
};
const AnnouncementDetails = (props) => {
  const { state }: { state: AnnouncementModel } = useLocation();
  //console.log(state);

  const [announcementInfo, setAnnouncementInfo] = useState<AnnouncementModel>({});
  const [loading, setLoading] = useState(false);
  const [loadingFile, setFileLoading] = useState(false);

  const fetchAnnouncementInfo = () => {
    setLoading(true);
    AnnouncementService.fetchAnnouncementInfo(
      state.announcementId || 0,
      (activities) => {
        setAnnouncementInfo(activities);
      },
      (error) => { console.log(error) },
      () => { setLoading(false); }
    );
  };

  const downloadFile = () => {
    setFileLoading(true)
    const payload = serialize(FileMetaModel, {
      attachmentId: 0,
      name: announcementInfo.attachmentName,
      path: announcementInfo.attachmentPath
    });
    FileDownloadService.fileDownload(
      payload,
      () => { },
      () => { },
      () => { setFileLoading(false); }
    );
  }

  useEffect(() => {
    fetchAnnouncementInfo();
  }, []);


  return (
    <PageView
      title="Messages"
      className="announcement-details"
      showBack
    >
      <Row className="announcement-details-conatiner">
        <Col span={24} className={`announcement-card`}>
          {loading
            ? <SkeletonLoader />
            : <>
              <div className="announcement-card-date__container">
                <div className="announcement-card__date">
                  <span>
                    <Avatar
                      shape="square"
                      style={{
                        backgroundColor: '#f56a00',
                        verticalAlign: 'middle',
                        marginRight: 5
                      }}
                      size="small"
                    >
                      {"M"}
                    </Avatar>
                  </span>
                  <span>{announcementInfo?.createdByName}</span>
                  <span className="dot"></span>
                  <span>{'Message'}</span>
                  <span className="dot"></span>
                  <span>{moment(announcementInfo?.createdAt).format('MMMM Do YYYY  h:mm a')}</span>
                  {/* {moment(data?.date).format("ddd DD, MMM")} */}
                </div>
                <p>
                  {announcementInfo?.message}
                </p>


                {announcementInfo.attachmentName !== null && <div className="attachment_file" >
                  {loadingFile && <Spin size="small" />}
                  <img src={pdfIcon} alt="" className="pdf_icon" />
                  <div
                    onClick={downloadFile}
                    className="file_title">
                    {announcementInfo.attachmentName}
                  </div>
                </div>}

              </div>

              <div className="announcement_comment__container">
                <CommentForm
                  id={announcementInfo?.announcementId}
                  comments={announcementInfo?.comments}
                  refreshList={fetchAnnouncementInfo}
                />
              </div>
            </>
          }
        </Col>
      </Row>
    </PageView>
  );
};

export default AnnouncementDetails;
