import { generatePath } from 'react-router-dom';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { CHAPTERS, STUDENT_COURSES, CLASS_ROOM_SUBJECTS } from '../../routes/routeConstants/apiRoutes';
import { ClassRoomSubject } from '../../models/query.model';
import { Chapters } from '../../models/Learn/Chapters/chapter.model';
import { Course } from '../../models/Learn/Course/course.model';

export class LearnService {
  static getStudentCourses(
    onSuccess: (data: any) => void,
    onError: () => void
  ) {
    return axiosInstance
      .get(STUDENT_COURSES)
      .then((response) => {
        try {
          const data = deserialize(Course, response.data["student_courses"]);
          onSuccess(data);
        } catch (error) {
          //console.log(error.message);
        }
      })
      .catch((error) => {});
  }

  static getSubjects(
    params: any,
    branch_class_room_id: any,
    onSuccess: (data: any) => void
  ) {
    return axiosInstance
      .get(generatePath(CLASS_ROOM_SUBJECTS, { branch_class_room_id }), { params: params })
      .then((response) => {
        const data = deserialize(
          ClassRoomSubject,
          response.data['class_room_subjects']
        );
        onSuccess(data);
      })
      .catch((error) => {
        //console.log(error.message);
      });
  }

  static getChapters(
    queryParams: any,
    {
      ...params
    }: {  subjectId: number | string, courseId: number | string},
    onSuccess: (data: any) => void
  ) {
    return axiosInstance
      .get(generatePath(CHAPTERS, { ...params }), {
        params: queryParams,
      })
      .then((response) => {
        const { chapters } = deserialize(Chapters, response?.data);
        onSuccess(chapters);
      })
      .catch((error) => {
        //console.log(error.message);
      });
  }
}
