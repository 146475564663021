import { combineReducers } from "redux";
import authReducer from "./authReducer";
import QuestionReducer from './questionReducer';
import titleReducer from "./titleReducer";

const RootReducer = combineReducers({
    auth: authReducer,
    ques: QuestionReducer,
    nav: titleReducer,
});

export default RootReducer;
