import { Badge, Button, Col, DatePicker, Dropdown, Row, Select } from "antd";
import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import FilterIcon from "../../../assets/icons/filter-icon-filled.svg";
import CalendarIcon from "../../../assets/icons/calendar-primary.svg";
import moment from "moment";

import AuthContainer from "../../../store/container/AuthContainer";
import { DashboardFilterModel } from "../../../models/dashboard.model";
import { MetaModel } from "../../../models/meta.model";

const { RangePicker } = DatePicker;
interface DashboardLoginReportFilterProps {
  applyHandler: (values: DashboardFilterModel) => void;
  clearHandler: () => void,
  user: any;
}

const DashboardLoginReportFilter: FC<DashboardLoginReportFilterProps> = (props) => {
  const {
    applyHandler,
    clearHandler,
    user
  } = props;

  const [initialValues, setInitialValues] = useState<DashboardFilterModel>({ });
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  const [sections, setSections] = useState<MetaModel[]>([]);

  const fetchGrades = () => {
    let data = user?.tutorClassRooms?.map((value) => {
      return {
        value: value?.classRoomId,
        label: value?.name,
      };
    }) as MetaModel[];

    setClassRooms(data);
  };

  const fetchSections = (classId: number) => {
    let dta = user?.tutorClassRooms?.filter(data => data.classRoomId === classId)[0]
    if (dta?.tutorSections) {
      let data = dta.tutorSections.map((value) => {
        return {
          value: value?.id,
          label: value?.name,
        };
      }) as MetaModel[];


      setSections(data);
    }
  };

  useEffect(() => {
    fetchGrades();
  }, []);

  const fetchCount = () => {
    let count = 0;
    if (initialValues?.lastLoginStartingDate) count += 1;
    if (initialValues?.classRoom) count += 1;
    if (initialValues?.section) count += 1;
    if (initialValues?.loggedIn !== undefined) count += 1;
    return count;
  };

  return (
    <Formik initialValues={initialValues} onSubmit={() => { }} enableReinitialize>
      {({ values, setFieldValue, resetForm }) => (
        <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <Dropdown
            overlayClassName="dashboard-filter__overlay"
            className="dashboard-filter"
            trigger={["click"]}
            overlay={
              <div>
                 <div className="dashboard-filter-form__wrapper">
                    <div
                    className="dashboard-filter-form__field"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    >
                    <label>Date Range</label>
                    <Field
                        name="lastLoginDate"
                        as={RangePicker}
                        value={[values?.lastLoginStartingDate ? moment(values?.lastLoginStartingDate, "YYYY-MM-DD") : undefined, values?.lastLoginEndingDate ? moment(values?.lastLoginEndingDate, "YYYY-MM-DD") : undefined]}
                        onChange={(dates) => {
                        if (dates?.length) {
                            setFieldValue("lastLoginStartingDate", moment(dates?.[0])?.format("YYYY-MM-DD"));
                            setFieldValue("lastLoginEndingDate", moment(dates?.[1])?.format("YYYY-MM-DD"));
                        } else {
                            setFieldValue("lastLoginStartingDate", 0);
                            setFieldValue("lastLoginEndingDate", 0);
                        }

                        }}
                        suffixIcon={
                        <img className="calendar__icon" src={CalendarIcon} alt="Calender"/>
                        }
                    />
                    </div>
                </div>
              <div className="dashboard-filter-form__wrapper">
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Class</label>
                  <Field
                    as={Select}
                    name="classRoom"
                    placeholder="Select Class"
                    options={classRooms}
                    value={values?.classRoom}
                    onChange={(value) => {
                      setFieldValue("classRoom", value);
                      fetchSections(value)
                    }}
                  />
                </div>
              </div>
              <div className="dashboard-filter-form__wrapper">
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Section</label>
                  <Field
                    as={Select}
                    name="section"
                    placeholder="Select Section"
                    options={sections}
                    value={values?.section}
                    onChange={(value) => {
                      setFieldValue("section", value);
                    }}
                  />
                </div>
              </div>
              <div className="dashboard-filter-form__wrapper">
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Logged In</label>
                  <Field
                    as={Select}
                    name="loggedIn"
                    placeholder="Select Option"
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' }
                    ]}
                    value={values?.loggedIn}
                    onChange={(value) => {
                      setFieldValue("loggedIn", value);
                    }}
                  />
                </div>
              </div>
                <Row gutter={12} className="dashboard-filter__controllers">
                  <Col span={12}>
                    <Button
                      type="default"
                      onClick={() => {
                        resetForm({});
                        setInitialValues({})
                        clearHandler()
                      }}
                    >
                      Clear Filters
                    </Button>

                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      onClick={() => {
                        resetForm({ values });
                        setInitialValues(values);
                        applyHandler(values);
                      }}
                    >
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            }
          >
            <Badge count={fetchCount()}>
              <div className="filter__icon">
                <img src={FilterIcon} alt="Fliter" />
              </div>
            </Badge>
          </Dropdown>
        </Form>
      )}
    </Formik>
  );
};

export default AuthContainer(DashboardLoginReportFilter);
