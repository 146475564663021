import axios from 'axios';
import { store } from "../store";
import { UNAUTHENTICATED } from "../store/definitions/authConstants";
import * as ApiRoutes from '../routes/routeConstants/apiRoutes';
import { LocalStorage } from '../shared/utils/LocalStorageHelpers';
import { LOGIN } from '../routes/routeConstants/appRoutes';
import Notification from '../shared/components/Notification';
import { NotificationTypes } from '../enums/notificationTypes';

export const getHeaders = (): any => {
    let headers;
    const accessToken = LocalStorage.getItem("ACCESS_TOKEN");
    headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken ? accessToken : ""}`,
    };
    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 60000,
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers = getHeaders();
    return config;
});

axiosInstance.interceptors.response.use(
    (response): any => {
        return {
            data: response.data,
            message: response.statusText,
            status: response.status
        }
    },
    (error) => {
        const { response } = error;
        if (response?.status === 401) {
            const originalRequest = error?.config;
            originalRequest._retry = true;
            const refreshToken = LocalStorage?.getItem("REFRESH_TOKEN");
            const payload = {
                student: {
                    grant_type: "refresh_token",
                    refresh_token: refreshToken,
                },
            };
            return axios
                .post(`${ApiRoutes.BASE_URL}${ApiRoutes.USER_LOGIN}`, payload)
                .then((response) => {
                    if (response.status >= 200 && response.status <= 299) {
                        const { token } = response.data;
                        LocalStorage.setItem(
                            "ACCESS_TOKEN",
                            token.access_token
                        );
                        LocalStorage.setItem(
                            "REFRESH_TOKEN",
                            token.refresh_token
                        );
                        axios.defaults.headers.common["Authorization"] =
                            "bearer " + token.access_token;
                        originalRequest.headers["Authorization"] =
                            "bearer " + token.access_token;
                        return axios(originalRequest);
                    }
                })
                .catch(() => {
                    localStorage.clear();
                    window.location.replace(LOGIN)
                    store.dispatch({
                        type: UNAUTHENTICATED,
                        payload: "Request for logout",
                    });
                });
        }
        if(response?.status === 401) {
            store.dispatch({
                type: UNAUTHENTICATED,
                payload: "Request for logout",
            });
        }
        if(response?.status == 500) {
            Notification({
                type: NotificationTypes.ERROR,
                message: "500 ",
                description: "Something went wrong."
            })
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
