import React, { FC, useEffect, useState } from "react";
import Heading from "../../../../shared/components/Heading";
import UICarousel from "../../../../shared/components/UICarousel";
import ViewAll from "../../../../assets/icons/viewall.svg";
import "./chapter.scss";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { CHAPTER } from "../../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../../store/container/AuthContainer";

interface ChapterProps {
  chapterNo: number;
  title: string;
  videos: any;
  id?: number;
  subjectName: string;
  courseId: number;
}

const Chapter: FC<ChapterProps> = ({
  id,
  title,
  videos,
  chapterNo,
  courseId,
  subjectName,
}) => {
  const history = useHistory();
  const params = useParams();
  const handleViewAll = () => {
    const URL = generatePath(CHAPTER, {
      ...params,
      id,
      chapterId: id
    });

    history.push({
      pathname: URL,
      state: { id, subjectName: subjectName, chapterName: title },
    });
  };

  return (

    <div className="chapter__container">
      <div className="title">
        <div>
          <h1>{chapterNo}</h1>
          <Heading>{title}</Heading>
        </div>
        <div>
          <p onClick={handleViewAll}>View all</p>
          <img src={ViewAll} alt="" />
        </div>
      </div>
      <div className="chapter-content">
         <UICarousel data={videos} />
      </div>
    </div>

  );
};

export default AuthContainer(Chapter);
