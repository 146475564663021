import React, { useEffect, useState, useRef, FC } from "react";
import { generatePath, withRouter } from "react-router-dom";

import { Field, Form, Formik } from "formik";
import { Skeleton } from "antd";
import EmptyState from "../../shared/components/EmptyState";
import PageView from "../../shared/components/PageView";
import Filters from "../../shared/components/Filters";
import DropdownField from "../../shared/components/DropdownField";
import PaginationComponent from "../../shared/components/PaginationComponent";

import ActivityForm from "./ActivityForm";
import ActivityCardCard from "./ActivityCard";

import { FilterWorksheet, WorksheetModel } from "../../models/worksheet.model";
import WorksheetService from "../../services/WorksheetService/Worksheet.service";


import { MetaModel } from "../../models/meta.model";
import { MetaService } from "../../services/MetaService/meta.service";


import "./activity.scss";
import { ACTIVITY_VIEW } from "../../routes/routeConstants/appRoutes";
import DeleteAlert from "../../shared/components/DeleteAlert";

export const SkeletonLoader = (): any => {
  return Array(2)
    .fill(" ")
    .map((value) => {
      return (
        <div className="skeleton-loader">
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        </div>
      );
    });
};


const Activity = (props: { history: any }) => {
  const { history } = props;

  const [worksheet, setWorksheet] = useState<WorksheetModel[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<any>({});
  const [loadinWorksheets, setLoadinWorksheets] = useState(false);

  const [currentWorksheet, setCurrentWorksheet] = useState<WorksheetModel>();
  const [showForm, setFormVisibility] = useState(false);
  const [deleteVisible, setDeleteVisibility] = useState(false);

  const formRef = useRef<any>();
  const [showFilter, setFilterVisibility] = useState(false);

  const defaultFilter = { CurrentPage: 1, Pagesize: 10 }
  const [filters, setFilters] = useState<FilterWorksheet>(defaultFilter);
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  const [subjects, setSubjects] = useState<MetaModel[]>([]);
  const [sections, setSections] = useState<MetaModel[]>([]);


  const fetchWorksheetList = (queryParams: FilterWorksheet) => {
    setLoadinWorksheets(true);
    //let queryParams = { ClassId: 0, CurrentPage: pageNumber, Pagesize: perPage }
    WorksheetService.fetchWorksheets(
      queryParams,
      (worksheets: any, pageMeta: any) => {
        setWorksheet(worksheets);
        setPaginationMeta(pageMeta);
      },
      () => { },
      () => {
        setLoadinWorksheets(false);
      }
    );
  };
  useEffect(() => {
    fetchClassRooms()
  }, []);

  const refreshHandler = () => {
    fetchWorksheetList(filters);
  }

  const handlePageChange = (page: number, pageSize: any) => {
    fetchWorksheetList({ ...filters, CurrentPage: page, Pagesize: pageSize });
    setFilters({ ...filters, CurrentPage: page, Pagesize: pageSize });
  };

  const fetchClassRooms = () => {
    setLoadinWorksheets(true);
    MetaService.fetchClassRoomsMeta(
      (classRooms) => {
        if (classRooms.length) {
          setClassRooms(classRooms?.map((value) => ({ label: value?.name, value: value?.id })));
          fetchWorksheetList({ ...filters, ClassId: classRooms[0].id });
          setFilters({ ...filters, ClassId: classRooms[0].id })
          fetchSections(classRooms[0].id ?? 0)
        } else {
          fetchWorksheetList(filters);
        }
      },
      () => { },
      () => { }
    );
  };

  const fetchSections = (classRoomId: number) => {
    MetaService.fetchSectionsMeta(
      classRoomId,
      (sections) => {
        setSections(sections);
      },
      () => { },
      () => { }
    );
  };


  const fetchSubjects = (sectionIds: number[]) => {
    MetaService.fetchSubjectsMeta(
      sectionIds,
      (subjects) => {
        setSubjects(subjects);
      },
      () => { },
      () => { }
    );
  };

  const handleDelete = () => {
    if (currentWorksheet?.worksheetId) {
      setDeleteVisibility(false);
      setLoadinWorksheets(true);
      WorksheetService.deleteWorksheet(
        currentWorksheet?.worksheetId,
        () => {
          fetchWorksheetList(filters);
          setDeleteVisibility(false);
          setCurrentWorksheet({});
        },
        () => { },
        () => { setLoadinWorksheets(false) }
      );
    }

  };

  return (
    <PageView
      title="Actvity"
      className="activity-class"
      showAdd
      addHandler={() => { setFormVisibility(true) }}
      showFilter={true}
      handleFilter={() => setFilterVisibility(true)}
    >
      <ActivityForm
        data={currentWorksheet}
        closeHandler={() => setFormVisibility(false)}
        visible={showForm}
        refreshList={refreshHandler}
      />

      <DeleteAlert
        title={currentWorksheet?.name}
        detail={""}
        visible={deleteVisible}
        setVisible={setDeleteVisibility}
        deleteText="Worksheet"
        deleteWarning="This Worksheet will be deleted permanently"
        handleDelete={handleDelete}
      />

      {loadinWorksheets || worksheet?.length ? (
        <div className="activity-class-activity-now">

          {loadinWorksheets ? (
            <SkeletonLoader />

          ) : (
            <>
              {
                worksheet?.map((record, index) => {
                  return (
                    <ActivityCardCard
                      key={record.worksheetId}
                      data={record}
                      isLive
                      deleteHandler={() => {
                        setCurrentWorksheet(record);
                        setDeleteVisibility(true);
                      }}
                      onClick={() => {
                        setCurrentWorksheet(record);
                        history.push(
                          generatePath(ACTIVITY_VIEW, {
                            worksheetId: record?.worksheetId,
                          }),
                          record
                        )
                      }}
                    />
                  );
                })
              }
              <div className="pagination__footer">
                <PaginationComponent
                  handlePageChange={handlePageChange}
                  paginationMeta={paginationMeta}
                />
              </div>
            </>


          )}

        </div>
      ) : (
        <EmptyState text={"No activites found."} />
      )}


      <Formik
        initialValues={filters}
        onSubmit={() => { }}
        enableReinitialize
        innerRef={formRef}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Filters
            expand={showFilter}
            handleApply={() => {
              fetchWorksheetList(values)
              setFilterVisibility(false)
            }}
            handleReset={() => {
              resetForm({});
              setFilterVisibility(false);
              fetchClassRooms()
              //setFilters(defaultFilter)
              //fetchWorksheetList(defaultFilter);
            }}
            closeHandler={() => setFilterVisibility(false)}
          >
            <Form>
              <div className="filter-form__input">
                <div className="filter-form__label">Class</div>
                <DropdownField
                  name="ClassId"
                  mode="single"
                  placeholder="Select"
                  value={values?.ClassId}
                  options={classRooms}
                  showSearch={false}
                  optionFilterProp="label"
                  onChange={(value) => {
                    setFieldValue("ClassId", value);
                    fetchSections(value);
                  }}
                />
              </div>
              <div className="filter-form__input">
                <div className="filter-form__label">Section</div>
                <DropdownField
                  name="SectionId"
                  mode="single"
                  placeholder="Select"
                  value={values?.SectionId}
                  options={sections}
                  showSearch={false}
                  optionFilterProp="label"
                  onChange={(value) => {
                    setFieldValue("SectionId", value);
                    fetchSubjects(value);
                  }}
                />
              </div>

              <div className="filter-form__input">
                <div className="filter-form__label">Subject</div>
                <DropdownField
                  name="SubjectId"
                  mode="single"
                  placeholder="Select"
                  value={values?.SubjectId}
                  options={subjects}
                  showSearch={false}
                  optionFilterProp="label"
                  onChange={(value) => {
                    setFieldValue("SubjectId", value);
                  }}
                />
              </div>
            </Form>
          </Filters>
        )}
      </Formik>

    </PageView>
  );
};

export default withRouter(Activity);
