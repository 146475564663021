import React, { FC, useEffect, useState } from 'react';
// import Heading from "../../../../../shared/components/Heading";
import icon from '../../../../assets/icons/SVG/icon.png';
import './listSubjects.scss';
import SubjectCard from './SubjectCard';
import { generatePath, useHistory } from 'react-router-dom';
import { LearnService } from '../../../../services/LearnService/learn.service';
import AuthContainer from '../../../../store/container/AuthContainer';
import { Col, Row } from 'antd';
import Heading from '../../../../shared/components/Heading';
import { ClassRoomMetaModel } from '../../../../models/meta.model';
import { ClassRoomSubject } from '../../../../models/query.model';

const colors = [
  '#EB6296',
  '#1CC29E',
  '#5140A0',
  '#FD9000',
  '#3A9EEB',
  '#FFC107',
  '#713C46',
];

interface ListSubjectsProps {
  currentClassRoom: ClassRoomMetaModel;
  title?: string;
  url: string;
}

const ListSubjects: FC<ListSubjectsProps> = ({
  title = 'Learn',
  currentClassRoom,
  url,
}) => {
  const history = useHistory();
  const [subjects, setSubjects] = useState<any[]>([]);

  const handleClick = (subjectId: number, name: string) => {
    const URL = generatePath(url, {
      subjectId,
    });

    history.push({
      pathname: URL,
      state: {
        subjectName: name,
      },
    });
  };

  useEffect(() => {
    if (currentClassRoom?.id) {
      LearnService.getSubjects(
        { branch_class_room_id: currentClassRoom?.id },
        currentClassRoom?.id,
        (data: ClassRoomSubject[]) => {
          const subjects = data.map((subject, index) => ({
            ...subject,
            icon,
            color: subject.subject?.colorCode,
          }));
          setSubjects(subjects);
        }
      );
    }
  }, [currentClassRoom]);
  return (
    <div className='list-subjects__container'>
      <Heading sub>{`${title} by Subjects`} </Heading>
      <Row className='list-subject__wrapper' gutter={[16, 12]}>
        {subjects.map((sub) => {
          return (
            <Col xs={5} xl={3} xxl={4} key={sub.id}>
              <SubjectCard
                name={sub?.subject?.name}
                color={sub?.subject?.colorCode ?? sub?.color}
                icon={sub?.subject?.displayPictureUrl ?? icon}
                completion={
                  url.includes('practice')
                    ? (sub?.exerciseCompletionPercentage ||
                        sub?.examExerciseCompletionPercentage ||
                        0) + '%'
                    : (sub?.contentCompletionPercentage ||
                        sub?.examContentCompletionPercentage ||
                        0) + '%'
                }
                clickHandler={() => {
                  sub?.id &&
                    sub?.subject?.name &&
                    handleClick(sub?.id, sub?.subject?.name);
                }}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default AuthContainer(ListSubjects);
