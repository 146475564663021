import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./ForgotPasswordValidation";
import UserService from "../../../services/AuthService/auth.service";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../auth.scss";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import backArrow from "../../../assets/icons/back_arrow.svg";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import AuthService from "../../../services/AuthService/auth.service";
import { message } from "antd";
import PasswordResetLink from "../PasswordResetLink";
import { useHistory } from "react-router-dom";

interface User {
  email: string;
}

const initialValue = {
  email: "",
};

const ForgotPassword = (props: RouteComponentProps) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const formRef = useRef<any>(null);
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const history = useHistory();

  const onSubmit = (user: User) => {
    setButtonLoading(true);

    AuthService.sendPasswordResetEmail(
      {
        email: user?.email,
        redirectUrl: `${window.location.origin}${appRoutes.RESET_PASSWORD}`,
      },
      () => {
        setEmail(user?.email);
        setStep(1);
        // setButtonLoading(false);
        // message.success(`Password Reset Link is sent to ${user?.email}`);
        // formRef?.current?.resetForm({values: {}})
      },
      () => {},
      () => {
        setButtonLoading(false);
      }
    );
  };

  return step ? (
    <PasswordResetLink
      email={email}
      wrongHandler={() => {
        setStep(0);
        setEmail("");
      }}
      loginHandler={() => {
        history.push(appRoutes.LOGIN);
      }}
    />
  ) : (
    <div className="auth__form">
      <h2 className="auth__header">Forgot Password</h2>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        <Form>
          <div className="auth__input">
            <InputField
              type="email"
              name="email"
              placeholder="Registered Email ID"
            />
          </div>
          <div className="auth__action forgot-password__action">
            <ButtonComponent
              buttonContent="Send Reset Link To Mail"
              type="primary"
              htmlType="submit"
              loading={buttonLoading}
            />
          </div>
        </Form>
      </Formik>
      <p className="auth__footer">
        Click here to{" "}
        <span onClick={() => props?.history?.push(appRoutes.LOGIN)}>Login</span>
      </p>
    </div>
  );
};

export default withRouter(ForgotPassword);
