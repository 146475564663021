import { Badge, Button, Col, DatePicker, Dropdown, Row, Select } from "antd";
import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import "./reportFilter.scss";
import FilterIcon from "../../../../assets/icons/filter-icon-filled.svg";
import { FilterReportModel } from "../../../../models/Report/report.model";
import { MetaModel } from "../../../../models/meta.model";
import { MetaService } from "../../../../services/MetaService/meta.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import { UserModel } from "../../../../models/user.model";

interface ReportFilterProps {
  applyHandler: (values: FilterReportModel) => void;
  clearHandler: (values: FilterReportModel) => void;
  user: UserModel;
}

const ReportFilter: FC<ReportFilterProps> = (props) => {
  const { applyHandler, clearHandler, user } = props;

  const [initialValues, setInitialValues] = useState<FilterReportModel>({});
  const [defaultValues, setDefaultValues] = useState<FilterReportModel>({});
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  const [subjects, setSubjects] = useState<MetaModel[]>([]);
  const [sections, setSections] = useState<MetaModel[]>([]);


  const fetchClassRooms = () => {
    // DashboardService.fetchClassRoomsMeta(
    //   boardId,
    //   (data) => {
    //     const meta = deserialize(MetaModel, data) as MetaModel[];
    //     setClassRooms(meta);
    //   },
    //   () => {},
    //   () => {}
    // );
    let data = user?.tutorClassRooms?.map((value) => {
      return {
        value: value?.id,
        label: value?.name,
      };
    }) as MetaModel[];

    if (data.length) {
      setDefaultValues({ ClassId: Number(data[0].value) })
      setInitialValues({ ClassId: Number(data[0].value) })
      fetchSections(Number(data[0].value) ?? 0)
    }
    setClassRooms(data);
  };

  const fetchSections = (classRoomId: number) => {
    setSections([])
    MetaService.fetchSectionsMeta(
      classRoomId,
      (sections) => {
        setSections(sections);
        if (sections.length) {
          setDefaultValues((prevState) => {
            return {
              ...prevState, ClassId: classRoomId, SectionId: Number(sections[0].value)
            }
          })
          setInitialValues((prevState) => {
            return {
              ...prevState, ClassId: classRoomId, SectionId: Number(sections[0].value)
            }
          })
          applyHandler({ ...defaultValues, ClassId: classRoomId, SectionId: Number(sections[0].value) })
          fetchSubjects([Number(sections[0].value) ?? 0])
        }
      },
      () => { },
      () => { }
    );
  };


  const fetchSubjects = (sectionIds: number[]) => {
    setSubjects([]);
    MetaService.fetchSubjectsMeta(
      sectionIds,
      (subjects) => {
        setSubjects(subjects);

      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    fetchClassRooms();
  }, []);

  const fetchCount = () => {
    let count = 0;
    if (initialValues?.ClassId) count += 1;
    if (initialValues?.SectionId) count += 1;
    if (initialValues?.SubjectId) count += 1;
    return count;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      enableReinitialize
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Dropdown
            overlayClassName="dashboard-filter__overlay"
            className="dashboard-filter"
            trigger={["click"]}
            overlay={
              <div className="dashboard-filter-form__wrapper">
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Class</label>
                  <Field
                    as={Select}
                    name="ClassId"
                    placeholder="Select Class"
                    options={classRooms}
                    value={values?.ClassId}
                    onChange={(value) => {
                      setFieldValue("ClassId", value);
                      setFieldValue("SectionId", undefined);
                      setFieldValue("SubjectId", undefined);
                      fetchSections(value);
                    }}
                  />
                </div>
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Section</label>
                  <Field
                    as={Select}
                    name="SectionId"
                    placeholder="Select Class"
                    options={sections}
                    value={values?.SectionId}
                    onChange={(value) => {
                      setFieldValue("SectionId", value);
                      setFieldValue("SubjectId", undefined);
                      fetchSubjects(value);
                    }}
                  />
                </div>
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Subject</label>
                  <Field
                    as={Select}
                    name="SubjectId"
                    placeholder="Select Class"
                    options={subjects}
                    value={values?.SubjectId}
                    onChange={(value) => {
                      setFieldValue("SubjectId", value);
                    }}
                  />
                </div>
                <Row gutter={12} className="dashboard-filter__controllers">
                  <Col span={12}>
                    <Button
                      type="default"
                      onClick={() => {
                        resetForm({});
                        setInitialValues({ ...defaultValues });
                        clearHandler({ ...defaultValues });
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      onClick={() => {
                        resetForm({ values });
                        setInitialValues(values);
                        applyHandler(values);
                      }}
                    >
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            }
          >
            <Badge count={fetchCount()}>
              <div className="filter__icon">
                <img src={FilterIcon} />
              </div>
            </Badge>
          </Dropdown>
        </Form>
      )}
    </Formik>
  );
};

export default AuthContainer(ReportFilter);
