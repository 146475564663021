import { serializable, alias, primitive, object, serialize } from 'serializr';
export class AttachmentModel {
  @serializable(alias('original_filename', primitive()))
  originalFilename?: string;

  @serializable(alias('tempfile', primitive()))
  tempfile?: string;

  @serializable(alias('content_type', primitive()))
  contentType?: string;
}

export class BoardModel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('acronym', primitive()))
  acronym?: string;
}

export class ClassRoom {
  @serializable(alias('id', primitive()))
  id?: number;
  @serializable(alias('name', primitive()))
  name?: string;
  @serializable(alias('board_name', primitive()))
  boardName?: string;
  @serializable(alias('board_acronym', primitive()))
  boardAcronym?: string;
  @serializable(alias('amount_paise', primitive()))
  amountPaise?: number;
  @serializable(alias('amount_rupees', primitive()))
  amountRupees?: string;
  @serializable(alias('is_active', primitive()))
  isActive?: boolean;
  @serializable(alias('class_room_subject_count', primitive()))
  classRoomSubjectCount?: number;
  @serializable(alias('display_picture_url', primitive()))
  displayPictureUrl?: string;
  @serializable(alias('board', object(BoardModel)))
  board?: BoardModel;
}
export class Exam extends ClassRoom {
  @serializable(alias('id', primitive()))
  id?: number;
  @serializable(alias('name', primitive()))
  name?: string;
  @serializable(alias('acronym', primitive()))
  acronym?: string;
  @serializable(alias('description', primitive()))
  description?: string;
  @serializable(alias('amount_paise', primitive()))
  amountPaise?: number;
  @serializable(alias('amount_rupees', primitive()))
  amountRupees?: string;
  @serializable(alias('is_active', primitive()))
  isActive?: boolean;
  @serializable(alias('exam_subject_count', primitive()))
  examSubjectCount?: number;
  @serializable(alias('display_picture_url', primitive()))
  displayPictureUrl?: string;
}

export class CourseObject {
  @serializable(alias('type', primitive()))
  type?: string;
  @serializable(alias('class_room', object(ClassRoom)))
  classRoom?: ClassRoom;
  @serializable(alias('exam', object(Exam)))
  exam?: Exam;
}

export class Course {
  @serializable(alias('id', primitive()))
  id?: number;
  @serializable(alias('is_active', primitive()))
  isActive?: boolean;
  @serializable(alias('course', object(CourseObject)))
  course?: CourseObject;
}
