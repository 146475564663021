import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { DatePicker, Input } from "antd";
import Error from "../Error";
import "./datePickerField.scss";
import moment from "moment";
import CalendarIcon from "../../../assets/icons/calendar.svg"

const { TextArea } = Input;

interface DatePickerProps {
  title?: string;
  name: string;
  placeholder: string;
  className?: string;
  disabled?: boolean;
  format?: string;
  picker?: string;
  value?: string | null;
  onSelect: (date: moment.Moment | null) => void;
}

const DatePickerField: FC<DatePickerProps> = ({
  title,
  className,
  disabled = false,
  picker,
  value,
  onSelect,
  ...rest
}) => {
  const { name, format } = rest;
  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className={`date-picker ${className}`}>
          <label htmlFor={name}>{title}</label>
          <DatePicker
            value={value ? moment(value, format) : null}
            disabled={disabled}
            onChange={(value) => {
              onSelect(value);
            }}
            {...rest}
            suffixIcon={<img src={CalendarIcon}/>}
          />
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
};

export default DatePickerField;
