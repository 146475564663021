import { Badge, Button, Col, DatePicker, Dropdown, Row, Select } from "antd";
import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import "./dashboardFilter.scss";
import FilterIcon from "../../../assets/icons/filter-icon-filled.svg";
import CalendarIcon from "../../../assets/icons/calendar-primary.svg";
import { deserialize } from "serializr";

import moment from "moment";
import { DashboardProgressFilterModel } from "../../../models/dashboard.model";
import { MetaModel } from "../../../models/meta.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { UserModel } from "../../../models/user.model";
const { RangePicker } = DatePicker;
interface DashboardFilterProps {
  applyHandler: (values: DashboardProgressFilterModel) => void;
  clearHandler: () => void;
  user: UserModel;
}

const DashboardFilter: FC<DashboardFilterProps> = (props) => {
  const { applyHandler, clearHandler, user } = props;

  const [initialValues, setInitialValues] = useState<DashboardProgressFilterModel>({});
  const [defaultValues, setDefaultValues] = useState<DashboardProgressFilterModel>({});
  const [sections, setSections] = useState<MetaModel[]>([]);
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  
  const fetchClassRooms = () => {
    let data = user?.tutorClassRooms?.map((value) => {
      return {
        value: value?.id,
        label: value?.name,
      };
    }) as MetaModel[];

    if (data.length) {
      setDefaultValues({ ClassId: Number(data[0].value) })
      setInitialValues({ ClassId: Number(data[0].value) })
      fetchSections(Number(data[0].value))
    }
    setClassRooms(data);
  };

  const fetchSections = (classRoomId) => {
    let dta = user?.tutorClassRooms?.filter(data => data.classRoomId === classRoomId)[0]
    if (dta?.tutorSections) {
      let data = dta.tutorSections.map((value) => {
        return {
          value: value?.id,
          label: value?.name,
        };
      }) as MetaModel[];


      setSections(data);
    }

  };

  useEffect(() => {
    fetchClassRooms();
  }, []);

  const fetchCount = () => {
    let count = 0;
    if (initialValues?.StartDate) count += 1;
    if (initialValues?.EndDate) count += 1;
    if (initialValues?.SectionId) count += 1;
    if (initialValues?.ClassId) count += 1;
    return count;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      enableReinitialize
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Dropdown
            overlayClassName="dashboard-filter__overlay"
            className="dashboard-filter"
            trigger={["click"]}
            overlay={
              <div className="dashboard-filter-form__wrapper">

                {/* <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Board</label>
                  <Field
                    as={Select}
                    name="board"
                    placeholder="Select Board"
                    options={boards}
                    value={values?.board}
                    onChange={(value) => {
                      setFieldValue("board", value);
                      fetchClassRooms(value)
                    }}
                  />
                </div> */}
                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Class</label>
                  <Field
                    as={Select}
                    name="ClassId"
                    placeholder="Select Class"
                    options={classRooms}
                    value={values?.ClassId}
                    onChange={(value) => {
                      setFieldValue("ClassId", value);
                      setFieldValue("SectionId", undefined);
                      fetchSections(value)
                    }}
                  />
                </div>

                <div
                  className="dashboard-filter-form__field"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <label>Section</label>
                  <Field
                    as={Select}
                    name="SectionId"
                    placeholder="Select Class"
                    options={sections}
                    value={values?.SectionId}
                    onChange={(value) => {
                      setFieldValue("SectionId", value);
                    }}
                  />
                </div>
                <Row gutter={12} className="dashboard-filter__controllers">
                  <Col span={12}>
                    <Button
                      type="default"
                      onClick={() => {
                        resetForm({});
                        setInitialValues({ });
                        //clearHandler();
                        applyHandler(defaultValues)
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      onClick={() => {
                        resetForm({ values });
                        setInitialValues(values);
                        applyHandler(values);
                      }}
                    >
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            }
          >
            <Badge count={fetchCount()}>
              <div className="filter__icon">
                <img src={FilterIcon} />
              </div>
            </Badge>
          </Dropdown>
        </Form>
      )}
    </Formik>
  );
};

export default AuthContainer(DashboardFilter);
