import { alias, primitive, serializable, list, object } from 'serializr';

export class MetaModel {
  @serializable(alias('id', primitive()))
  value?: number | string;

  @serializable(alias('name', primitive()))
  label?: string;
}

export class ClassRoomSubjectMetaModel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('subject_name', primitive()))
  subjectName?: string;
}

export class ClassRoomModal {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('name', primitive()))
  name?: string;
}

export class BoardMetaModal {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('acronym', primitive()))
  acronym?: string;

  @serializable(alias('display_picture_url', primitive()))
  displayPictureUrl?: string;
}

export class ClassRoomMetaModel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('type', primitive()))
  type?: string;

  @serializable(
    alias('class_room_subjects', list(object(ClassRoomSubjectMetaModel)))
  )
  classRoomSubjects?: ClassRoomSubjectMetaModel[] = [];

  @serializable(alias('class_room', object(ClassRoomModal)))
  classRoom?: ClassRoomModal;

  @serializable(alias('board', object(BoardMetaModal)))
  board?: BoardMetaModal;
}

export class TutorMetaModel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('full_name', primitive()))
  fullName?: string;

  @serializable(alias('email', primitive()))
  email?: string;

  @serializable(alias('display_picture_url', primitive()))
  displayPictureUrl?: string;
}
