import { AgoraVideoPlayer } from "agora-rtc-react";
import React, { FC, useEffect } from "react";
import "./videos.scss";
import {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import StartVideoIcon from "../../../../assets/icons/live-class/video-camera-slash-fill.svg";
import LiveClassParticipants from "../LiveClassParticipants";

interface VideosProps {}

const Videos = (props: {
  users: IAgoraRTCRemoteUser[];
  videoTrack: [IMicrophoneAudioTrack, ICameraVideoTrack] | null;
  audioTrack: IMicrophoneAudioTrack | null;
  isParticipantsVisible: boolean;
  isInfoVisible: boolean;
  client: IAgoraRTCClient;
}) => {
  const { users, videoTrack, audioTrack, isParticipantsVisible, isInfoVisible, client } = props;
  
  return (
    <div
      className={`live-class-videos ${
        (isParticipantsVisible || isInfoVisible) &&
        "live-class-videos__drawer-shown"
      }`}
    >
      {users.length > 0 && (
        <div className="participants-videos__wrapper">
          {users?.map((user) => {
            return user?.videoTrack ? (
              <div className="live-class-video-player__wrapper">
                <AgoraVideoPlayer
                  className="live-class-video-player participants"
                  videoTrack={user.videoTrack}
                  key={user.uid}
                />
                <div className="live-class-video-player__name">
                  {user?.uid?.toString()?.split("-")?.[0]?.replace(/ /gi, " ")}
                </div>
              </div>
            ) : (
              <div className="live-class-video-player__stopped">
                <img src={StartVideoIcon} />
                <div className="live-class-video-player-stopped__name">
                  {user?.uid?.toString()?.split("-")?.[0]?.replace(/ /gi, " ")}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {videoTrack?.[1]?.enabled ? (
        <div className="live-class-video-player__wrapper">
          <AgoraVideoPlayer
            className={`live-class-video-player host ${
              users.length ? "participants-joined" : ""
            }`}
            videoTrack={videoTrack[1]}
          />
          <div className="live-class-video-player__name">
            {client?.uid?.toString()?.split("-")?.[0]?.replace(/ /gi, " ")}
          </div>
        </div>
      ) : (
        <div className={`live-class-video-player__stopped host ${users.length ? "participants-joined" : ""}`}>
          <img src={StartVideoIcon} />
          <div className="live-class-video-player-stopped__name">
            {client?.uid?.toString()?.split("-")?.[0]?.replace(/ /gi, " ")}
          </div>
        </div>
      )}
    </div>
  );
};

export default Videos;
