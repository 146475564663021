import { Col, message, Row, Upload } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { FC, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import UploadIcon from '../../../../assets/icons/upload.svg';
import './question.scss';
import ButtonComponent from '../../../../shared/components/ButtonComponent';
import QuestionService from '../../../../services/QuestionService/Question.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { serialize } from 'serializr';
import { convertJSONToFormData } from '../../../../shared/utils/convertJsonToFormData';
import { ContentLibraryQuestionModel } from '../../../../models/Learn/ContentLibraryModel/contentLibrary.model';
import DropdownField from '../../../../shared/components/DropdownField';
import CKEditorComponent from '../../../../shared/components/CKEditorComponent';
import QuestionAttachment from '../../../../shared/components/QuestionAttachment';
import ContentLibraryService from '../../../../services/ContentLibraryService/contentLibrary.service';
import { validateFormValues } from '../FormValidations/formValidations';

interface QuestionType {
  question: string;
  correctAnswer: string;
  option1: string;
  option2: string;
  option3: string;
  noOptions: boolean;
  solution: string;
  questionAttachments: any;
  questionSolutionAttachments: any;
  questionOptionImages: any;
  imageUrl: any;
  id: any;
}

interface QuestionProps extends RouteComponentProps {
  index: number;
  value: QuestionType | any;
  deleteIndex: any;
  exerciseId: number | string | undefined;
  setFieldValue: (key: string, value: any) => void;
  onCreate?: (quesiton: any, index: number) => void;
  showFreeText?: boolean;
  showAttachment?: boolean;
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const Question: FC<QuestionProps> = ({
  index,
  value,
  setFieldValue,
  exerciseId,
  deleteIndex,
  onCreate,
  location,
  showAttachment = false,
  showFreeText = true,
}) => {
  const [questionId, setQuestionId] = useState();
  const [loading, setLoading] = useState(false);
  const [showReferenceContent, setReferenceContentVisibility] =
    useState<boolean>(false);
  const [referenceContents, setReferenceContents] = useState<any[]>([]);
  const [correctAnswerOptions, setCorrectAnswerOptions] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    let options: any = [];
    for (const field in value) {
      if (
        (field.includes('option') || field.includes('image_option')) &&
        value[field]
      ) {
        const label = `Option ${field.charAt(field.length - 1)}`
        if (!options.find(option => option.label == label)) {
          options.push({
            value: field,
            label: label,
          });
        }
      }
    }
    setCorrectAnswerOptions(options);
  }, [value]);

  function getBase64(img: any, callback: (data: any) => void) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handleChange = (info: any) =>
    getBase64(info.file.originFileObj, (imageUrl: any) => {
      setFieldValue(`questions[${index}]attachment`, info.file.originFileObj);
      setFieldValue(`questions[${index}]imageUrl`, imageUrl);
    });
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <img src={UploadIcon} alt='' />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleQuestionAttachment = (imageFile: any) => {
    const jsonData = {
      content_question_attachment: {
        image: imageFile,
      },
    };
    const formData = convertJSONToFormData(jsonData);
    QuestionService.createContentQuestionAttachment(
      formData,
      (attachment) => {
        let attachments = value?.questionAttachments;
        attachments.push(attachment);
        setFieldValue(`questions[${index}]questionAttachments`, [
          ...attachments,
        ]);
      },
      () => {}
    );
  };
  const handleSolutionAttachment = (imageFile: any) => {
    const jsonData = {
      cont_quest_sol_atchmnt: {
        image: imageFile,
      },
    };
    const formData = convertJSONToFormData(jsonData);
    QuestionService.createContentSolutionAttachment(
      formData,
      (attachment) => {
        let attachments = value?.questionSolutionAttachments;
        attachments.push(attachment);
        setFieldValue(`questions[${index}]questionSolutionAttachments`, [
          ...attachments,
        ]);
      },
      () => {}
    );
  };
  const handleOptionAttachment = (
    imageFile: any,
    option: string,
    no: number
  ) => {
    const jsonData = {
      cont_quest_option_image: {
        image: imageFile,
        option: option,
      },
    };
    const formData = convertJSONToFormData(jsonData);
    QuestionService.createContentQuestionImageOptionAttachment(
      formData,
      (attachment) => {
        let attachments = value.questionOptionImages;
        attachments.push(attachment);
        setFieldValue(
          `questions[${index}]image_option${no}`,
          attachment.questionOptionImageUrl
        );
        setFieldValue(`questions[${index}]questionOptionImages`, [
          ...attachments,
        ]);
      },
      () => {}
    );
  };

  const handleDeleteOptions = (option: string) => {
    let index = value.questionOptionImages.findIndex(
      (data: any) => data.option === option
    );
    let optionImages = value.questionOptionImages;
    optionImages.splice(index, 1);
    setFieldValue('questionOptionImages', [...optionImages]);
  };

  const handleQuestion = () => {
    let formErrors = validateFormValues(value, index)
    if (Object.keys(formErrors.questions[index]).length > 0) {
      setErrors(formErrors);
      return;
    }

    value['isActive'] = true;
    value['contentLibraryId'] = exerciseId;

    if (value['noOptions']) {
      value['onlyFreeText'] = true;
      delete value['correctAnswer'];
      delete value['option1'];
      delete value['option2'];
      delete value['option3'];
    }

    const serializeData = serialize(ContentLibraryQuestionModel, value);
    const payload = {
      content_question: { ...serializeData, attachment: value['attachment'] },
    };

    if (!value.id) {
      ContentLibraryService.createContentQuestion(
        payload,
        (question: any) => {
          console.log(question);
          setQuestionId(question?.id as any);
          onCreate && onCreate(question, index);
        },
        () => {},
        () => {}
      );
    } else {
      ContentLibraryService.updateContentQuestion(
        value?.id,
        payload,
        (question: any) => {
          console.log(question);
          setQuestionId(question?.id as any);
          onCreate && onCreate(question, index);
        },
        () => {},
        () => {}
      );
    }
  };

  return (
    <div className='pj-question-form__container'>
      <Row>
        <Col span={24}>
          <CKEditorComponent
            title='Question'
            value={value?.question ?? ''}
            onChange={(data) => {
              setFieldValue(`questions[${index}].question`, data);
            }}
          />
          <QuestionAttachment
            data={value.questionAttachments}
            multipleAttachments
            handleDelete={(index) => {
              let attachments = value.questionAttachments;
              attachments.splice(index, 1);
              setFieldValue(
                `questions[${index}]questionAttachments`,
                attachments
              );
            }}
            onAttach={(value) => {
              console.log(value);
              handleQuestionAttachment(value);
            }}
          />
          {errors.questions?.[index]?.question && <div className='input__error'>{errors.questions[index].question}</div>}
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='mt-4'>
        <Col span={24}>
          <CKEditorComponent
            title='Option 1'
            value={value?.option1 ?? ''}
            onChange={(data) =>
              setFieldValue(`questions[${index}].option1`, data)
            }
          />
          <QuestionAttachment
            data={
              value.questionOptionImages.filter(
                (data: any) => data.option == 'image_option1'
              ).length
                ? value.questionOptionImages.filter(
                    (data: any) => data.option == 'image_option1'
                  )[0]
                : null
            }
            onAttach={(value) => {
              setFieldValue(
                `questions[${index}].option4`,
                value?.option1 ?? ''
              );
              handleOptionAttachment(value, 'image_option1', 1);
            }}
            handleDelete={() => handleDeleteOptions('image_option1')}
          />
          {errors.questions?.[index]?.option1 && <div className='input__error'>{errors.questions[index].option1}</div>}
        </Col>
        <Col span={24}>
          <CKEditorComponent
            title='Option 2'
            value={value?.option2 ?? ''}
            onChange={(data) =>
              setFieldValue(`questions[${index}].option2`, data)
            }
          />
          <QuestionAttachment
            data={
              value.questionOptionImages.filter(
                (data: any) => data.option == 'image_option2'
              ).length
                ? value.questionOptionImages.filter(
                    (data: any) => data.option == 'image_option2'
                  )[0]
                : null
            }
            onAttach={(value) => {
              setFieldValue(
                `questions[${index}].option4`,
                value?.option2 ?? ''
              );
              handleOptionAttachment(value, 'image_option2', 2);
            }}
            handleDelete={() => handleDeleteOptions('image_option2')}
          />
          {errors.questions?.[index]?.option2 && <div className='input__error'>{errors.questions[index].option2}</div>}
        </Col>
        <Col span={24}>
          <CKEditorComponent
            title='Option 3'
            value={value?.option3 ?? ''}
            onChange={(data) =>
              setFieldValue(`questions[${index}].option3`, data)
            }
          />
          <QuestionAttachment
            data={
              value.questionOptionImages.filter(
                (data: any) => data.option == 'image_option3'
              ).length
                ? value.questionOptionImages.filter(
                    (data: any) => data.option == 'image_option3'
                  )[0]
                : null
            }
            onAttach={(value) => {
              setFieldValue(
                `questions[${index}].option4`,
                value?.option3 ?? ''
              );
              handleOptionAttachment(value, 'image_option3', 3);
            }}
            handleDelete={() => handleDeleteOptions('image_option3')}
          />
          {errors.questions?.[index]?.option3 && <div className='input__error'>{errors.questions[index].option3}</div>}
        </Col>
        <Col span={24}>
          <CKEditorComponent
            title='Option 4'
            value={value?.option4 ?? ''}
            onChange={(data) =>
              setFieldValue(`questions[${index}].option4`, data)
            }
          />
          <QuestionAttachment
            data={
              value.questionOptionImages.filter(
                (data: any) => data.option == 'image_option4'
              ).length
                ? value.questionOptionImages.filter(
                    (data: any) => data.option == 'image_option4'
                  )[0]
                : null
            }
            onAttach={(value) => {
              setFieldValue(
                `questions[${index}].option4`,
                value?.option4 ?? ''
              );
              handleOptionAttachment(value, 'image_option4', 4);
            }}
            handleDelete={() => handleDeleteOptions('image_option4')}
          />
          {errors.questions?.[index]?.option4 && <div className='input__error'>{errors.questions[index].option4}</div>}
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <div className='question-form__label'>Correct answer</div>
          <DropdownField
            name={`questions[${index}].correctAnswer`}
            placeholder='Select correct answer'
            options={correctAnswerOptions}
            value={value.correctAnswer}
            showSearch={false}
            onChange={(value: any) =>
              setFieldValue(`questions[${index}].correctAnswer`, value)
            }
            optionFilterProp='label'
            className='options__dropdown'
          />
          {errors.questions?.[index]?.correctAnswer && <div className='input__error'>{errors.questions[index].correctAnswer}</div>}
        </Col>
      </Row>

      {showFreeText && (
        <Row className='question__no-options mt-4'>
          <Checkbox
            value={value?.noOptions}
            onChange={({ target: { checked } }) => {
              setFieldValue(`questions[${index}].noOptions`, checked);
            }}
          />{' '}
          &nbsp;&nbsp; No options. Only free text
        </Row>
      )}

      <Row className='mt-4'>
        <Col span={24}>
          <CKEditorComponent
            title='Solution'
            value={value?.solution ?? ''}
            onChange={(data) =>
              setFieldValue(`questions[${index}].solution`, data)
            }
          />
          <QuestionAttachment
            multipleAttachments
            data={value.questionSolutionAttachments}
            handleDelete={(index) => {
              let attachments = value.questionSolutionAttachments;
              attachments.splice(index, 1);
              setFieldValue(
                `questions[${index}]questionSolutionAttachments`,
                attachments
              );
            }}
            onAttach={(value) => {
              handleSolutionAttachment(value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div
            className='add-reference__content'
            onClick={() => setReferenceContentVisibility(true)}
          >
            +&nbsp;&nbsp;Add Reference Content
          </div>
          {referenceContents.length ? <div></div> : <div />}
        </Col>
      </Row>
      <Row className='question__solution__control'>
        {!showAttachment && (
          <Col span={12}>
            <Upload
              name='avatar'
              listType='picture-card'
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {value.imageUrl ? (
                <img
                  src={value.imageUrl}
                  alt='avatar'
                  style={{ width: '100%' }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Col>
        )}
        <Col span={12} className='btn-submit'>
          <ButtonComponent
            buttonContent='Submit'
            type='primary'
            onClick={handleQuestion}
          />
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(Question);
