import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SubIcon from '../../../assets/icons/math.svg';
import { LearnService } from '../../../services/LearnService/learn.service';
import VideoCard from '../../../shared/components/VideoCard';
import { LocalStorage } from '../../../shared/utils/LocalStorageHelpers';
import TitleContainer from '../../../store/container/TitleContainer';
import { ITitleState } from '../../../store/reducers/titleReducer';
import {
  Chapter as ChapterModel,
  ChapterContent,
} from '../../../models/Learn/Chapters/chapter.model';
import './chapter.scss';
import AuthContainer from '../../../store/container/AuthContainer';
// import ExamStats from "../../../shared/components/ExamPannel/QuestionsDrawer/Stats";
// import { ExamService } from "../../../../services/ExamService/exam.service";
import { ContentService } from '../../../services/ContentService/content.service';
import { Col, Row } from 'antd';
import PaginationComponent from '../../../shared/components/PaginationComponent';
import { PaginationModel } from '../../../models/pagination.model';
import PageView from '../../../shared/components/PageView';

interface ChapterProps extends ITitleState {
  setTitle: Function;
  type: any;
  onSearch: string;
  currentProduct: any;
}

const Chapter: FC<ChapterProps> = ({
  setTitle,
  type,
  onSearch,
  currentProduct,
}) => {
  const [chapter, setChapter] = useState<ChapterModel>();
  const [contents, setContents] = useState<ChapterContent[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<any>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const { state }: { state: any } = useLocation();
  const urlParams: { subjectId: any; chapterId: any } = useParams();

  const fetchChapterContents = useCallback(
    (params: any) => {
      if (type == 'exam') {
        ContentService.getExamChapterContents(
          params,
          {
            courseId: LocalStorage.getItem('COURSE_ID'),
            chapterId: state.id,
          },
          (contents: ChapterContent[], meta: PaginationModel) => {
            if (
              currentProduct?.studenttype === 'institution' &&
              currentProduct?.instituteId === 1
            )
              setContents(contents.filter((data) => data?.isActive === true));
            setContents(contents);
            setPaginationMeta(meta);
          },
          () => {}
        );
      } else {
        ContentService.getChapterContents(
          params,
          {
            courseId: LocalStorage.getItem('COURSE_ID'),
            subjectId: urlParams.subjectId,
            chapterId: state.id,
          },
          (contents: ChapterContent[], meta: PaginationModel) => {
            setContents(contents);
            setPaginationMeta(meta);
          },
          () => {}
        );
      }
    },
    [currentProduct, state.id, type, urlParams.subjectId]
  );

  const handlePageChange = (page: number, pageSize: any) => {
    fetchChapterContents({ page });
    setCurrentPageNumber(page);
  };

  useEffect(() => {
    fetchChapterContents({ search: onSearch, page: currentPageNumber });
  }, [currentPageNumber, fetchChapterContents, onSearch]);

  useEffect(() => {
    setTitle({
      title: state?.chapterName,
      subText: `${state.subjectName}    |    ${
        contents?.filter((data) => data.contentLibrary?.contentType == 'video')
          .length
      } Videos, ${
        contents?.filter((data) => data.contentLibrary?.contentType == 'pdf')
          .length
      } PDF &
      ${
        contents?.filter(
          (data) => data.contentLibrary?.contentType == 'external_link'
        ).length
      } External link
      `,
      showBack: true,
      icon: null,
      showSearch: {placeholder: 'Search contents'}
    });
  }, [contents, setTitle, state]);

  return (
    <PageView title={state.chapterName} className='no-bg-image'>
      <div className='list-all-chapters__container'>
        <Row className='chapter__outer__container' gutter={[24, 24]}>
          {contents.map(({ title, description, contentLibrary, id }) => (
            <Col xs={8} xl={6}>
              <VideoCard
                color='#FD6C00'
                icon={SubIcon}
                subject={state?.subjectName}
                content={title}
                thumbnail={contentLibrary?.thumbnailUrl}
                id={id}
                chapterName={state?.chapterName}
                chapterId={state?.id}
                contentLibrary={contentLibrary}
                url={contentLibrary?.attachmentUrl}
                contentType={contentLibrary?.contentType}
                subjectId={urlParams?.subjectId}
              />
            </Col>
          ))}
        </Row>
        <PaginationComponent
          handlePageChange={handlePageChange}
          paginationMeta={paginationMeta}
        />
      </div>
    </PageView>
  );
};

export default AuthContainer(TitleContainer(Chapter));
