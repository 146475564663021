import { message } from "antd";
import { generatePath } from "react-router-dom";
import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { Chapter, Chapters } from "../../models/Learn/Chapters/chapter.model";
import { ExamChapterModel } from "../../models/Learn/Exam/ChapterModel/chapter.model";
import {
  ExamExercise,
  ExamExercises,
} from "../../models/Learn/Exam/ExerciseModel/exercise.model";
import {
  ExamSubjectModel,
  SubjectModel,
} from "../../models/Learn/Exam/SubjectModel/subject.model";
import {
  ExcerciseAttempt,
  Exercise,
  Exercises,
} from "../../models/Learn/Exercise/exercise.model";
import { PaginationModel } from "../../models/pagination.model";
import { Question } from "../../models/Learn/Question/question.model";
import {
  COMPLETE_EXERCISE_QUIZ,
  EXAM_ATTEMPT,
  EXAM_CHAPTERS,
  EXAM_CHAPTER_CONTENTS,
  EXAM_CHAPTER_EXERCISES,
  EXAM_SUBJECTS,
  GET_ALL_QUESTIONS_WITH_ANSWERS,
  GET_QUESTIONS,
  GET_SINGLE_QUESTION,
  LIST_EXAM_EXERCISES,
  RESET_EXERCISE_EXAM,
  UPDATE_ANSWER,
  VIEW_EXERCISE_ATTEMPT,
} from "../../routes/routeConstants/apiRoutes";
import { LocalStorage } from "../../shared/utils/LocalStorageHelpers";
export class ExamService {
  static createAttempt(
    params: { chaptorId: number },
    payload: {
      excercise_attempt: {
        excercise_id: number;
      };
    },
    onSuccess: (exerciseAttempt: ExcerciseAttempt) => void,
    onError: Function
  ) {
    const courseId = LocalStorage.getItem("COURSE_ID");
    return axiosInstance
      .post(generatePath(EXAM_ATTEMPT, { ...params, courseId }), payload)
      .then((response) => {
        const { excerciseAttempt } = deserialize(Exercise, response?.data);
        onSuccess(excerciseAttempt ?? new ExcerciseAttempt());
      })
      .catch((error) => {
        // message.error("")
        //console.log(error);
        onError(error);
      });
  }

  static getExamQuestions(
    attemptId: number,
    onSuccess: (questions: any) => void
  ) {
    const courseId = LocalStorage.getItem("COURSE_ID");
    if (courseId) {
      return axiosInstance
        .get(
          generatePath(GET_QUESTIONS, {
            courseId,
            attemptId,
          })
        )
        .then((response) => {
          //console.log(response);
          const questions = deserialize(
            Question,
            response["data"]?.excercise_attempt_questions
          );
          onSuccess(questions);
        })
        .catch((error) => {
          //console.log(error?.message);
        });
    }
  }

  static getExamQuestionsWithAnswers(
    attemptId: number,
    onSuccess: (questions: any) => void
  ) {
    const courseId = LocalStorage.getItem("COURSE_ID");
    if (courseId) {
      return axiosInstance
        .get(
          generatePath(GET_ALL_QUESTIONS_WITH_ANSWERS, {
            courseId,
            attemptId,
          })
        )
        .then((response) => {
          const questions = deserialize(
            Question,
            response["data"]?.excercise_attempt_questions
          );
          onSuccess(questions);
        })
        .catch((error) => {
          //console.log(error?.message);
        });
    }
  }

  static getQuestion(
    params: { attemptId: any; id: any },
    onSuccess: (question: Question) => void,
    onError: () => void
  ) {
    const courseId = LocalStorage.getItem("COURSE_ID");
    // if (params?.id) {
    //     onError();
    // }
    return axiosInstance
      .get(
        generatePath(GET_SINGLE_QUESTION, {
          ...params,
          courseId,
        })
      )
      .then((response) => {
        const question = deserialize(
          Question,
          response?.data?.excercise_attempt_question
        );
        onSuccess(question);
      })
      .catch((error) => {
        //console.log(error?.message);
      });
  }

  static updateAnswer(
    params: { attemptId: any; id: any },
    answer: any,
    onSuccess: () => void
  ) {
    const courseId = LocalStorage.getItem("COURSE_ID");
    const payload = {
      excercise_attempt_question: answer,
    };
    return axiosInstance
      .put(
        generatePath(UPDATE_ANSWER, {
          ...params,
          courseId,
        }),
        payload
      )
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        //console.log(error?.message);
      });
  }

  static completeExam(
    params: {
      chaptorId: any;
      attemptId: any;
    },
    onSuccess: () => void
  ) {
    const courseId = LocalStorage.getItem("COURSE_ID");
    return axiosInstance
      .put(
        generatePath(COMPLETE_EXERCISE_QUIZ, {
          ...params,
          courseId,
        })
      )
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        //console.log(error?.message);
      });
  }

  static resetExam(
    params: {
      chapterId: any;
      attemptId: any;
    },
    onSuccess: (exerciseAttempt: ExcerciseAttempt) => void
  ) {
    const courseId = LocalStorage.getItem("COURSE_ID");
    return axiosInstance
      .put(
        generatePath(RESET_EXERCISE_EXAM, {
          ...params,
          courseId,
        })
      )
      .then((response) => {
        const { excerciseAttempt } = deserialize(Exercise, response?.data);
        onSuccess(excerciseAttempt ?? new ExcerciseAttempt());
      })
      .catch((error) => {
        //console.log(error?.message);
      });
  }
  static getExamSubjects(
    params: any,
    courseId: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(generatePath(EXAM_SUBJECTS, { courseId: courseId }), {
        params: params,
      })
      .then((response) => {
        const data = deserialize(SubjectModel, response?.data?.exam_subjects);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static getExamSubjectChapters(
    queryParams: any,
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    //console.log(data);
    return axiosInstance
      .get(
        generatePath(EXAM_CHAPTERS, {
          courseId: data?.courseId,
          subjectId: data?.subjectId,
        }),
        { params: queryParams }
      )
      .then((response) => {
        //console.log(response);
        const chapters = deserialize(
          ExamChapterModel,
          response?.data?.exam_chapters
        );
        onSuccess(chapters);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static getExamExercises(
    params: any,
    data: any,
    onSuccess: (exercises: Exercise[], meta: PaginationModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(
        generatePath(LIST_EXAM_EXERCISES, {
          courseId: data?.courseId,
          chapterId: data?.chapterId,
        }),
        { params: params }
      )
      .then((response) => {
        const { exercises } = deserialize(ExamExercises, response?.data);
        const meta = deserialize(PaginationModel, response?.data?.meta)
        onSuccess(exercises as ExamExercise[], meta);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal)
  }
  static deleteExerciseAttempt(
    chapterId: any,
    attemptId: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .delete(
        generatePath(VIEW_EXERCISE_ATTEMPT, {
          courseId: LocalStorage.getItem("COURSE_ID"),
          chaptorId: chapterId,
          attemptId: attemptId,
        })
      )
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  }
}
