import { Col, Row } from "antd";
import React, { FC, Fragment } from "react";

import UsernameIcon from "../../../../assets/icons/username.svg";
import pdfIcon from "../../../../assets/icons/pdf-chapter-icon.svg";

import { WorksheetModel } from "../../../../models/worksheet.model";

// styles
import "./assignedCard.scss";

interface AssignedCardProps {
  data: WorksheetModel;
  onClick?: () => void;
}

const AssignedCard: FC<AssignedCardProps> = (props) => {
  const { data, onClick } = props;
  console.log(data);


  return (
    <div
      className="content-list__card"
      onClick={onClick}
    >


      <Row className="content-list__info" align="middle" justify="space-around">
        <Col span={3}>
          <div className="content-list__thumbnail">
            <img src={UsernameIcon} alt="" className="content-list__thumbnail__image" />
          </div>
        </Col>
        <Col span={16} className="info_container">
          <div className="content-list__title">
            {/* <span>
              <img src={UsernameIcon} />
            </span> */}
            {data?.studentName}
          </div>
          <div className="info_text">
            {data.turnInStatus ? 'Turned In' : ''}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AssignedCard;
