import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/_axiosInstance";

import { deserialize, serialize } from "serializr";

import { ChapterwiseSummaryModel } from "../../models/Report/chapterwise.model";


export default class ReportService {
    static fetchActivitySummary(
        params: any,
        onSuccess: (data: ChapterwiseSummaryModel[]) => void,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(ApiRoutes.CHAPTERWISE_SUMMARY, { params })
            .then((response) => {
                const data = deserialize(ChapterwiseSummaryModel, response?.["data"]) as ChapterwiseSummaryModel[];
                onSuccess(data);
            })
            .catch((e) => {
                onError(e);
            })
            .finally(onFinal);
    }

    static downloadActivitySummary(
        params: any,
        onSuccess: (value: any) => void,
        onError: Function,
        onFinal: () => void
      ) {
        return axiosInstance
          .get(ApiRoutes.DOWNLOAD_CHAPTERWISE_SUMMARY, { params })
          .then((response) => new Blob([response.data]))
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `Report.csv`,
            );
            // Append to html link element page
            document.body.appendChild(link);
    
            // Start download
            link.click();
    
            // Clean up and remove the link
            link.parentNode?.removeChild(link);
    
            onSuccess("Download");
          })
          .catch((e) => {
            onError(e);
          })
          .finally(onFinal);
      }
    

}


