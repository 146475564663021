import { Form, Formik, FormikValues } from "formik";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import { LiveClassDurationTypes } from "../../../enums/liveClassDurationTypes";
import {
  LiveClassCreateModel,
  LiveClassModel,
} from "../../../models/liveClass.model";
import { MetaModel } from "../../../models/meta.model";
import LiveClassService from "../../../services/LiveClassService/LiveClass.service";
import { MetaService } from "../../../services/MetaService/meta.service";
import DatePickerField from "../../../shared/components/DatePickerField";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import TimePickerField from "../../../shared/components/TimePickerField";
import UIModal from "../../../shared/components/UIModal";
import "./liveClassForm.scss";

interface LiveClassFormProps {
  visible: boolean;
  closeHandler: () => void;
  data?: LiveClassModel;
  refreshList: () => void;
}

const initialState = {
  title: "",
  startTime: "",
  date: "",
  duration: undefined,
  tutorIds: [],
  classRoomId: undefined,
  sectionIds: undefined,
  subjectId: undefined,
}

const LiveClassForm: FC<LiveClassFormProps> = (props) => {
  const { visible, closeHandler, data, refreshList } = props;
  const [initialValues, setInitialValues] = useState<LiveClassCreateModel>({ ...initialState });

  const [loading, setLoading] = useState(false);
  const [classRooms, setClassRooms] = useState<MetaModel[]>([]);
  const [tutors, setTutors] = useState<MetaModel[]>([]);
  const [subjects, setSubjects] = useState<MetaModel[]>([]);
  const [sections, setSections] = useState<MetaModel[]>([]);

  const handleSubmit = (values: FormikValues) => {
    setLoading(true);
    if (data && data?.id) {
      LiveClassService.updateLiveClass(
        data?.id,
        values,
        () => {
          closeHandler();
          refreshList();
        },
        () => { },
        () => {
          setLoading(false);
        }
      );
    } else {
      LiveClassService.createLiveClass(
        values,
        () => {
          closeHandler();
          refreshList();
        },
        () => { },
        () => {
          setLoading(false);
        }
      );
    }
  };

  const formRef = useRef<any>();

  useEffect(() => {
    if (data) {
      if (data?.classRoom?.branchClassRoomId) fetchSections(data?.classRoom?.branchClassRoomId);
      if (data?.sections) fetchSubjects(data?.sections?.map((section) => section?.id))
      setInitialValues({
        title: data?.title,
        tutorIds: data?.tutors?.filter((tutor) => tutor?.id !== data?.createdTutorId)?.map(value => value?.id),
        startTime: moment(data?.startTime)?.format("HH:mm"),
        date: data?.date,
        classRoomId: data?.classRoom?.branchClassRoomId,
        sectionIds: data?.sections?.map((section) => section?.id),
        subjectId: data?.subject?.id,
        duration: data?.duration,
      });
    } else {
      setInitialValues({ ...{ ...initialState } })
    }
  }, [data]);



  const fetchClassRooms = () => {
    MetaService.fetchClassRoomsMeta(
      (classRooms) => {
        setClassRooms(
          classRooms?.map((value) => ({ label: value?.name, value: value?.id }))
        );
      },
      () => { },
      () => { }
    );
  };

  const fetchSections = (classRoomId: number) => {
    MetaService.fetchSectionsMeta(
      classRoomId,
      (sections) => {
        setSections(sections);
      },
      () => { },
      () => { }
    );
  };

  const fetchTutors = () => {
    MetaService.fetchTutorsMeta(
      (tutors) => {
        setTutors(
          tutors?.map((value) => ({ value: value?.id, label: value?.fullName }))
        );
      },
      () => { },
      () => { }
    );
  };

  const fetchSubjects = (sectionIds: number[]) => {
    MetaService.fetchSubjectsMeta(
      sectionIds,
      (subjects) => {
        setSubjects(subjects);
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    fetchClassRooms();
    fetchTutors();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, setFieldValue, resetForm, handleSubmit, errors }) => (
        <UIModal
          visible={visible}
          closeModal={closeHandler}
          onClosed={resetForm}
          submitProps={{
            text: data ? "Update" : "Create",
            type: "submit",
            clickHandler: handleSubmit,
            buttonLoading: loading,
          }}
          cancelProps={{
            text: "Cancel",
            clickHandler: closeHandler,
          }}
          className="live-class-form"
        >
          <Form>
            <div className="live-class-form__header">
              <div className="live-class-form__title">
                {data ? "Update Live Class" : "New Live Class"}
              </div>
            </div>
            <div className="live-class-form__input">
              <div className="live-class-form__label">Live Class Title</div>
              <InputField
                type="text"
                name="title"
                placeholder="Type Session Name"
              />
            </div>
            <div className="live-class-form__input">
              <div className="live-class-form__label">Date</div>
              <DatePickerField
                name="date"
                placeholder="Select Date"
                value={values?.date}
                format="YYYY-MM-DD"
                onSelect={(date) => {
                  setFieldValue("date", date?.format("YYYY-MM-DD"));
                }}
              />
            </div>
            <div className="live-class-form__input">
              <div className="live-class-form__label">Time</div>
              <TimePickerField
                name="startTime"
                format="HH:mm"
                placeholder="Select Time"
                value={values?.startTime}
                onSelect={(date) => {
                  setFieldValue("startTime", date?.format("HH:mm"));
                }}
              />
            </div>
            <div className="live-class-form__input">
              <div className="live-class-form__label">Duration</div>
              <DropdownField
                name="duration"
                mode="single"
                placeholder="Select Duration"
                value={values?.duration}
                options={[
                  {
                    value: LiveClassDurationTypes.MINUTES_30,
                    label: "30 Minutes",
                  },
                  {
                    value: LiveClassDurationTypes.HOURS_1_MINUTES_0,
                    label: "1 Hour",
                  },
                  {
                    value: LiveClassDurationTypes.HOURS_1_MINUTES_30,
                    label: "1 Hour 30 Minutes",
                  },
                  {
                    value: LiveClassDurationTypes.HOURS_2_MINUTES_0,
                    label: "2 Hours",
                  },
                  {
                    value: LiveClassDurationTypes.HOURS_2_MINUTES_30,
                    label: "2 Hours 30 Minutes",
                  },
                  {
                    value: LiveClassDurationTypes.HOURS_3_MINUTES_0,
                    label: "3 Hours",
                  },
                ]}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("duration", value);
                }}
              />
            </div>
            <div className="live-class-form__input">
              <div className="live-class-form__label">Class</div>
              <DropdownField
                name="classRoomId"
                mode="single"
                placeholder="Select"
                value={values?.classRoomId}
                options={classRooms}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("classRoomId", value);
                  fetchSections(value);
                }}
              />
            </div>
            <div className="live-class-form__input">
              <div className="live-class-form__label">Section</div>
              <DropdownField
                name="sectionIds"
                mode="multiple"
                placeholder="Select"
                value={values?.sectionIds}
                options={sections}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("sectionIds", value);
                  fetchSubjects(value);
                }}
              />
            </div>
            <div className="live-class-form__input">
              <div className="live-class-form__label">Teacher</div>
              <DropdownField
                name="tutorIds"
                mode="multiple"
                placeholder="Select"
                value={values?.tutorIds}
                options={tutors}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("tutorIds", value);
                }}
              />
            </div>
            <div className="live-class-form__input">
              <div className="live-class-form__label">Tag Subject</div>
              <DropdownField
                name="subjectId"
                mode="single"
                placeholder="Select"
                value={values?.subjectId}
                options={subjects}
                showSearch={false}
                optionFilterProp="label"
                onChange={(value) => {
                  setFieldValue("subjectId", value);
                }}
              />
            </div>
          </Form>
        </UIModal>
      )}
    </Formik>
  );
};

export default LiveClassForm;
