import React, { FC, Fragment } from "react";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "./examPannelController.scss";
import UIButton from "../../UIButton";
import { Col, Row } from "antd";

interface ButtonProps {
  disabled: boolean;
  clickHandler: () => void;
}

interface ExamPannelController {
  onNextHandler: () => void;
  onPrevHandler: () => void;
  submitHandler: () => void;
  selected: boolean;
  disableNext: boolean;
  disablePrev: boolean;
  freeTextAnswer: string;
  resultPage: boolean;
  disableSubmit: boolean;
  disableSkip: any;
}

const Button: FC<ButtonProps> = ({ disabled, children, clickHandler }) => {
  return (
    <div
      className={`exam-button__container ${
        disabled && "exam-button__disabled"
      }`}
      onClick={clickHandler}
    >
      {children}
    </div>
  );
};
const RoundedButton: FC<ButtonProps> = ({
  disabled,
  children,
  clickHandler,
}) => {
  return (
    <div
      className={`exam-button-rounded__container ${
        disabled && "exam-button__disabled"
      }`}
      onClick={clickHandler}
    >
      {children}
    </div>
  );
};

const GhostButton: FC<ButtonProps> = ({ disabled, children, clickHandler }) => {
  return (
    <div
      className={`exam-button-ghost__container ${
        disabled && "exam-button__disabled"
      }`}
      onClick={clickHandler}
    >
      {children}
    </div>
  );
};
const SubmitButton: FC<ButtonProps> = ({
  disabled,
  children,
  clickHandler,
}) => {
  return (
    <div
      className={`exam-button-submit__container ${
        disabled && "exam-button__disabled"
      }`}
      onClick={clickHandler}
    >
      {children}
    </div>
  );
};

const ExamPannelController: FC<ExamPannelController> = ({
  selected,
  disableNext,
  disablePrev,
  onNextHandler,
  onPrevHandler,
  submitHandler,
  disableSubmit,
  freeTextAnswer,
  resultPage,
  disableSkip
}) => {
  //console.log(selected);
  return (
    <Row className="exam-pannel-controller__container horizontal-padding" justify="space-between">
      {
        !resultPage ? (
          <>
            <Col xs={8} xl={6}>
              <div className="exam-panel-controller__left">
                <RoundedButton
                  disabled={disablePrev}
                  clickHandler={onPrevHandler}
                >
                  <LeftOutlined className="icon-left" />
                </RoundedButton>
                <RoundedButton
                  disabled={disableNext}
                  clickHandler={onNextHandler}
                >
                  <RightOutlined className="icon" />
                </RoundedButton>
              </div>
            </Col>
            <Col xs={8} xl={6}>
              <div className="exam-panel-controller__right">
                {selected || freeTextAnswer ? (
                  <SubmitButton
                    clickHandler={submitHandler}
                    disabled={disableSubmit}
                  >
                    Submit
                  </SubmitButton>
                ) : (
                  !disableSkip
                  &&
                  <GhostButton
                    disabled={disableSubmit || disableNext}
                    clickHandler={onNextHandler}
                  >
                    Skip
                  </GhostButton>
                )}
              </div>
            </Col>
          </>
        ) : (
          <Col xs={8} xl={24}>
            <div className="flex space-between">
              <Button disabled={disablePrev} clickHandler={onPrevHandler}>
                <ArrowLeftOutlined className="icon-left" />
                Previous
              </Button>
              <Button disabled={disableNext} clickHandler={onNextHandler}>
                Next
                <ArrowRightOutlined className="icon" />
              </Button>
            </div>
          </Col>
        )

        //)
      }
    </Row>
  );
};

export default ExamPannelController;
