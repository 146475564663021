import React, { useState } from "react";
import UserPlaceholderIcon from "../../../assets/placeholder/avatar_placeholder.png";
import { DateFormatter } from "../HOC/dateFormatter";
import moment from "moment";
import "./conversation.scss";
import { Comments } from "../../../models/query.model";
import { CloseOutlined } from "@ant-design/icons"
import Modal from "antd/lib/modal/Modal";
interface ConversationProps {
  data?: Comments[];

}
export const Conversation = (props: ConversationProps) => {
  const { data } = props;

  const [selectedImgUrl, setSelectedImgUrl] = useState("");
  const [showModal, setModalVisibility] = useState(false);
  return (
    <div>
      <Modal className="image__modal" centered visible={showModal} footer={null} closeIcon={<CloseOutlined onClick={() => setModalVisibility(false)}/>}>
        <img src={selectedImgUrl} />
      </Modal>
      {data?.map((value: Comments, index: number) => {
        return (
          <div className="comment__container">
            <div className="comment-header__wrapper">
              <div>
                <img
                  src={
                     value.user?.type == "tutor"
                      ? value.user?.tutor?.displayPictureUrl
                      : value.user?.student?.displayPictureUrl
                      ? value.user.student?.displayPictureUrl
                      : UserPlaceholderIcon
                  }
                />
              </div>
              <div>
                <p className="comment__username">
                  {value.user?.type == "tutor"
                    ? value.user?.tutor?.fullName
                    : value.user?.student?.fullName}
                </p>
                <p className="comment__time">
                  {value.createdAt
                    ? DateFormatter(value.createdAt)
                    : moment().format("MMM DD, YYYY [at] h:mm a")}
                </p>
              </div>
            </div>
            <div className="comment__text">
              <p>{value.content}</p>
            </div>
            {value.attachmentUrl ? (
              <div
                onClick={() => {
                    setModalVisibility(true)
                   value.attachmentUrl && setSelectedImgUrl(value.attachmentUrl)
                }
                }
              >
                <img src={value.attachmentUrl} className="comment__image" />
              </div>
            ) : (
              <div />
            )}
          </div>
        );
      })}
    </div>
  );
};
