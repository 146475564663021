import { Col, Row, Button } from "antd";
import moment from "moment";
import React, { FC, Fragment } from "react";
import "./activityCard.scss";
import { ClockCircleOutlined, DeleteFilled } from "@ant-design/icons";
import SubjectIcon from "../../../assets/icons/subject.svg";
import UsernameIcon from "../../../assets/icons/username.svg";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import CardMenu from "../../../shared/components/CardMenu";

import { WorksheetModel } from "../../../models/worksheet.model";

interface ActivityCardCardProps {
  data: WorksheetModel;
  isLive?: boolean;
  isJoinLoading?: boolean;
  onClick?: () => void;
  deleteHandler?: () => void;
}

const ActivityCardCard: FC<ActivityCardCardProps> = (props) => {
  const { data, isLive, onClick,deleteHandler } = props;


  return (
    <Row className={`activity-card ${isLive && "btn"}`} >
      <Col span={23} className={`col_info`} onClick={onClick}>
        <div className="activity-card-date__container ">
          <p className="activity-card__date">
            <span>
              <img src={SubjectIcon} />
            </span>
            <span className="__text">
              <span className="__sub_title">{data?.className}{" : "}{data?.sectionName}{" : "}{data.subjectName}</span>
              <span>{data?.name}</span>
            </span>
            {/* {moment(data?.date).format("ddd DD, MMM")} */}
          </p>
          <p className="activity-card__time">
            <ClockCircleOutlined />
            {"Posted on"}:{" "}
            {moment(data?.createdOn).format('MMMM Do YYYY  h:mm a')}
          </p>
        </div>
      </Col>
      <Col span={1} className={`col_button`}>
        <Button
          className="deleteButton"
          type="primary"
          shape="circle"
          icon={<DeleteFilled />}
          size={"middle"}
          onClick={deleteHandler}
        />
      </Col>

    </Row>
  );
};

export default ActivityCardCard;
