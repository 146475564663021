import { serializable, alias, primitive, object, serialize } from 'serializr';
export class AttachmentModel {
  @serializable(alias('original_filename', primitive()))
  originalFilename?: string;

  @serializable(alias('tempfile', primitive()))
  tempfile?: string;

  @serializable(alias('content_type', primitive()))
  contentType?: string;
}

export class CreateStudent {
  @serializable(alias('first_name', primitive()))
  firstName?: string;

  @serializable(alias('last_name', primitive()))
  lastName?: string;

  @serializable(alias('dob', primitive()))
  dob?: string;

  @serializable(alias('is_current', primitive()))
  isCurrent?: boolean;
}
export class Student {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('email', primitive()))
  email?: string;

  @serializable(alias('first_name', primitive()))
  firstName?: string;

  @serializable(alias('last_name', primitive()))
  lastName?: string;

  @serializable(alias('phone_number', primitive()))
  phoneNumber?: string;

  @serializable(alias('full_name', primitive()))
  fullName?: string;

  @serializable(alias('display_picture_url', primitive()))
  displayPictureUrl?: string;

  @serializable(alias('step', primitive()))
  step?: number;

  @serializable(alias('dob', primitive()))
  dob?: string;

  @serializable(alias('is_current', primitive()))
  isCurrent?: boolean;

  @serializable(alias('is_class_room_linked', primitive()))
  isClassRoomLinked?: boolean;

  @serializable(alias('is_blocked', primitive()))
  isBlocked?: boolean;

  @serializable(alias('confirmed_at', primitive()))
  confirmedAt?: string;
}
export class StudentCourse {
  @serializable(alias('course_id', primitive()))
  courseId?: number;

  @serializable(alias('course_type', primitive()))
  courseType?: string;
}
export class UpdateStudentCourse {
  @serializable(alias('is_active', primitive()))
  isActive?: number;
}

export class BoardModel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('acronym', primitive()))
  acronym?: string;
}

export class ClassRoom {
  @serializable(alias('id', primitive()))
  id?: number;
  @serializable(alias('name', primitive()))
  name?: string;
  @serializable(alias('board_name', primitive()))
  boardName?: string;
  @serializable(alias('board_acronym', primitive()))
  boardAcronym?: string;
  @serializable(alias('amount_paise', primitive()))
  amountPaise?: number;
  @serializable(alias('amount_rupees', primitive()))
  amountRupees?: string;
  @serializable(alias('is_active', primitive()))
  isActive?: boolean;
  @serializable(alias('class_room_subject_count', primitive()))
  classRoomSubjectCount?: number;
  @serializable(alias('display_picture_url', primitive()))
  displayPictureUrl?: string;
  @serializable(alias('board', object(BoardModel)))
  board?: BoardModel;
}
export class Exam extends ClassRoom {
  @serializable(alias('id', primitive()))
  id?: number;
  @serializable(alias('name', primitive()))
  name?: string;
  @serializable(alias('acronym', primitive()))
  acronym?: string;
  @serializable(alias('description', primitive()))
  description?: string;
  @serializable(alias('amount_paise', primitive()))
  amountPaise?: number;
  @serializable(alias('amount_rupees', primitive()))
  amountRupees?: string;
  @serializable(alias('is_active', primitive()))
  isActive?: boolean;
  @serializable(alias('exam_subject_count', primitive()))
  examSubjectCount?: number;
  @serializable(alias('display_picture_url', primitive()))
  displayPictureUrl?: string;
}

export class CourseObject {
  @serializable(alias('type', primitive()))
  type?: string;
  @serializable(alias('class_room', object(ClassRoom)))
  classRoom?: ClassRoom;
  @serializable(alias('exam', object(Exam)))
  exam?: Exam;
}

export class Course {
  @serializable(alias('id', primitive()))
  id?: number;
  @serializable(alias('is_active', primitive()))
  isActive?: boolean;
  @serializable(alias('course', object(CourseObject)))
  course?: CourseObject;
}
