import React, { useEffect } from "react";
import AuthRouter from "../authRouters";
import "../auth.scss";
import { Col, Row } from "antd";
import AuthContainer from "../../../store/container/AuthContainer";


let LoadLog = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../../assets/${appName}/app-icon-blue.svg`)}  {...rest} />;
  }
  catch (err) {
    return <img src={require('../../../assets/background-images/app-logo.svg')} {...rest} />;
  }
}

let LoadBackgroundImage = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../../assets/${appName}/signup.svg`)}  {...rest} />;
  }
  catch (err) {
    return <img src={require('../../../assets/background-images/auth-background.svg')} {...rest} />;
  }
}

const AuthWrapper = ({ app_name, setAppName }) => {

  useEffect(() => {
    let hostName = window.location.host.split('.')[0].toLocaleLowerCase()
    sessionStorage.setItem("APP_NAME", hostName);
    setAppName(hostName)
  }, [])

  return (
    <div className="auth-component">
      <Row className="auth-container__wrapper">
        <Col span={14}>
          <div className="image__wrapper">
            <LoadBackgroundImage appName={app_name} />
          </div>

        </Col>
        <Col span={8}>
          <div className="auth-logo__container">
            {app_name == null && <LoadLog className="auth-logo" appName={app_name} />}
          </div>
          <AuthRouter />
        </Col>
      </Row>
    </div>
  );
};

export default AuthContainer(AuthWrapper);
